import React from 'react';
import ExclusiveLicense from 'components/Licenses/ExclusiveLicense';

const ExclusiveExamplePage = () => (
  <div id="exclusive-example-page" className="container">
    <ExclusiveLicense />
  </div>
);

export default ExclusiveExamplePage;
