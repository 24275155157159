import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import { Mutation } from 'react-apollo';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { avatarImageUrl } from 'config';
import PlugImage from 'components/common/PlugImage';
import displayTrackImage, {
  displaySmallImage,
} from 'utils/display-track-image';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { reset } from 'redux-form';
import { onDesktop } from 'utils/screentype';
import * as modalActions from 'actions/modals';
import CreateComment from 'components/Tracks/Track/CreateComment';
import { AudioWrapper, AudioSlider } from 'utils/AudioControls';
import CommentList from 'components/common/CommentList';
import Hashtags from './Hashtags';
import TrackBody from './TrackBody';
import TrackButtons from './TrackButtons';

const TRACK_DESKTOP_TRACK = gql`
  fragment TrackDesktop_track on Track {
    id
    name
    picture
    fileUrl
    description
    publicId
    ...TrackBody_track
    ...TrackButtons_track
    ...Hashtags_track
    comments: commentsByTrackId(first: 100, orderBy: CREATED_AT_ASC)
      @connection(key: "CommentList_comments") {
      ...CommentList_comments
    }
  }
  ${TrackBody.fragments.track}
  ${TrackButtons.fragments.track}
  ${Hashtags.fragments.track}
  ${CommentList.fragments.comments}
`;

const TRACK_DESKTOP_USER = gql`
  fragment TrackDesktop_user on User {
    id
    username
    displayName
    ...TrackBody_user
  }
  ${TrackBody.fragments.user}
`;

const CREATE_COMMENT = gql`
  mutation CreateComment($input: CreateCommentInput!) {
    createComment(input: $input) {
      track: trackByTrackId {
        ...TrackDesktop_track
      }
    }
  }
  ${TRACK_DESKTOP_TRACK}
`;

const DELETE_COMMENT = gql`
  mutation DeleteCommentByIdMutation($input: DeleteCommentByIdInput!) {
    deleteCommentById(input: $input) {
      track: trackByTrackId {
        ...TrackDesktop_track
      }
    }
  }
  ${TRACK_DESKTOP_TRACK}
`;

class TrackDesktop extends Component {
  static fragments = { user: TRACK_DESKTOP_USER, track: TRACK_DESKTOP_TRACK };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    track: PropTypes.object.isRequired,
    tracks: PropTypes.array.isRequired,
    currentUser: PropTypes.string,
    className: PropTypes.string,
    purchasedType: PropTypes.string,
    user: PropTypes.object.isRequired,
    showModal: PropTypes.func.isRequired,
    soundbar: PropTypes.object.isRequired,
    resetForm: PropTypes.func.isRequired,
  };

  static contextTypes = {
    myself: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    currentUser: '',
    className: '',
    purchasedType: '',
    removeOtherTracksOnPlay: false,
    addOtherTracksOnPlay: null,
  };

  state = {
    showComment: false,
    showTags: false,
    otherTracksRemoved: false,
    otherTracksAdded: false,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleRequestClose = () => {
    this.setState({
      contextMenu: false,
    });
  };

  handleDeleteClick = () => {
    const {
      track: { id },
      showModal,
    } = this.props;
    this.handleRequestClose();
    showModal('DeleteTrackModal', { trackId: id });
  };

  handleShowTags = () => {
    this.handleRequestClose();
    this.setState({ showTags: !this.state.showTags });
  };

  render() {
    const {
      track,
      tracks,
      currentUser,
      user,
      className,
      soundbar,
      auth: { userId, isAuthenticated },
      showModal,
      purchasedType,
      resetForm,
    } = this.props;
    const { myself } = this.context;
    const currentTrackId = get(soundbar, 'currentTrack.id');
    const isCurrentTrack = currentTrackId === track.id;
    const isPlaying = isCurrentTrack && soundbar.playing;
    const { anchorEl } = this.state;

    console.log('classname', className);

    return (
      <AudioWrapper track={track} tracks={tracks}>
        {({ togglePlay }) => (
          <Mutation
            mutation={CREATE_COMMENT}
            onCompleted={() => {
              resetForm('CreateComment');
            }}
          >
            {createComment => (
              <Mutation mutation={DELETE_COMMENT}>
                {deleteComment => (
                  <li className={className}>
                    <div
                      className="bg-white p-2 rounded-px-2"
                      style={onDesktop({ border: '#dad9d9 1px solid' })}
                    >
                      <article className="flex w-full relative">
                        <Link to={`/tracks/${track.publicId}`}>
                          <PlugImage
                            imageSource={displaySmallImage(
                              track.fileUrl,
                              track.picture,
                            )}
                            fallbacks={[
                              displayTrackImage(track.fileUrl, track.picture),
                            ]}
                            placeholder={`${avatarImageUrl}placeholder.png`}
                            className={'profile-trackplayer-img-desk'}
                          />
                        </Link>
                        <div className="w-full flex flex-col ml-3 md:ml-4">
                          <TrackBody
                            track={filter(TrackBody.fragments.track, track)}
                            user={user}
                            mine={myself}
                            purchasedType={purchasedType}
                            togglePlay={togglePlay}
                            isPlaying={isPlaying}
                            onEdit={() =>
                              showModal('EditTrackModal', { trackId: track.id })
                            }
                          />
                          <AudioSlider trackId={track.id} />
                          <TrackButtons
                            track={filter(TrackButtons.fragments.track, track)}
                            userId={userId}
                            mine={myself}
                            toggleComments={() =>
                              this.setState({
                                showComment: !this.state.showComment,
                              })
                            }
                            onClick={this.handleClick}
                          />
                        </div>
                      </article>
                      {this.state.showTags && (
                        <div className="w-full inline">
                          <Hashtags
                            track={filter(Hashtags.fragments.track, track)}
                            containerStyle={{
                              borderTop: '1px solid #cccccc',
                              paddingTop: '14px',
                              marginTop: '20px',
                              fontSize: '14px',
                            }}
                            showNoTags
                          />
                        </div>
                      )}
                      {this.state.showComment && (
                        <div>
                          <CommentList
                            comments={filter(
                              CommentList.fragments.comments,
                              track.comments,
                            )}
                            onDelete={commentId => deleteComment({ variables: { input: { id: commentId } } })}
                            mine={myself}
                            currentUser={currentUser}
                          />
                          <CreateComment
                            onSubmit={data => createComment({
                              variables: {
                                input: {
                                  comment: { userId, trackId: track.id, body: data.comment },
                                },
                              },
                            })}
                            disabled={!isAuthenticated}
                          />
                        </div>
                      )}
                    </div>
                    <Menu
                      id="more-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={this.handleClose}
                    >
                      <MenuItem
                        onClick={() => {
                          this.handleDeleteClick();
                          this.handleClose();
                        }}
                      >
                        Delete Track
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          this.handleShowTags();
                        }}
                      >
                        {this.state.showTags ? 'Hide Tags' : 'Show Tags'}
                      </MenuItem>
                    </Menu>
                  </li>
                )}
              </Mutation>
            )}
          </Mutation>
        )}
      </AudioWrapper>
    );
  }
}

export default connect(
  state => ({ soundbar: state.soundbar, auth: state.auth }),
  Object.assign({ resetForm: reset }, modalActions),
)(TrackDesktop);
