import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as modalActions from 'actions/modals';

const propTypes = {
  submitType: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  showModal: PropTypes.func.isRequired,
};

const LicenseLink = ({ submitType, productType, children, showModal }) => {
  if (submitType.toLowerCase() === 'create') {
    return (
      <a
        role="presentation"
        className="font-semibold"
        onClick={() => showModal('LicenseModal', { type: productType })}
      >
        {children}
      </a>
    );
  } else {
    return (
      <Link to="/licenses/exclusive" className="font-semibold" target="_blank">
        {children}
      </Link>
    );
  }
};

LicenseLink.propTypes = propTypes;

export default connect(null, modalActions)(LicenseLink);
