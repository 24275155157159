import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import { withRouter } from 'react-router-dom';
import { AudioWrapper } from 'utils/AudioControls';
import ImagePlay from './ImagePlay';
import Text from './Text';

const propTypes = {
  track: PropTypes.object.isRequired,
  tracks: PropTypes.array.isRequired,
  className: PropTypes.string,
  textClassName: PropTypes.string,
  imageClassName: PropTypes.string,
  hasText: PropTypes.bool,
  mainTextClassName: PropTypes.string,
  subTextClassName: PropTypes.string,
  showPlayByDefault: PropTypes.bool,
  circlePlayIcon: PropTypes.bool,
};

const defaultProps = {
  className: '',
  textClassName: '',
  imageClassName: '',
  isLink: false,
  mainTextClassName: 'text-xs font-bold color-white',
  subTextClassName: 'text-xs tile-subText-color',
  hasText: false,
  showPlayByDefault: false,
  soundbarTrack: {},
  removeOtherTracksOnPlay: false,
  circlePlayIcon: true,
};

const tileFrag = gql`
  fragment Tile_track on Track {
    ...Text_track
    ...ImagePlay_track
    id
    name
    picture
    fileUrl
    description
    publicId
    user: userByUserId {
      id
      username
      displayName
    }
  }
  ${Text.fragments.track}
  ${ImagePlay.fragments.track}
`;

class Tile extends PureComponent {
  getContents = () => {
    const {
      track,
      imageClassName,
      showPlayByDefault,
      hasText,
      textClassName,
      mainTextClassName,
      subTextClassName,
      circlePlayIcon,
      tracks,
    } = this.props;
    return (
      <AudioWrapper track={track} tracks={tracks}>
        {({ togglePlay }) => (
          <Fragment>
            <ImagePlay
              track={filter(ImagePlay.fragments.track, track)}
              imageClassName={imageClassName}
              showPlayByDefault={showPlayByDefault}
              togglePlay={togglePlay}
              circlePlayIcon={circlePlayIcon}
            />
            {hasText && (
              <Text
                track={filter(Text.fragments.track, track)}
                textClassName={textClassName}
                mainTextClassName={mainTextClassName}
                subTextClassName={subTextClassName}
              />
            )}
          </Fragment>
        )}
      </AudioWrapper>
    );
  };

  render() {
    const { className } = this.props;
    return (
      <div
        role="presentation"
        className={classnames(
          className,
          'default-image-play inline-block relative outline-none play-pause-hover leading-0 h100 flex flex-col cursor-pointer',
        )}
      >
        {this.getContents()}
      </div>
    );
  }
}

Tile.propTypes = propTypes;
Tile.defaultProps = defaultProps;
Tile.fragments = {
  track: tileFrag,
};

export default withRouter(Tile);
