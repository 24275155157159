import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import ToastMessage from './ToastMessage';

// const toasts = [
//   { id: 'toast-1', type: 'warning', title: 'Warning', description: 'Something not ideal might be happening.' },
//   { id: 'toast-2', type: 'error', title: 'Error', description: 'Some error is happening somewhere.' },
//   { id: 'toast-3', type: 'info', title: 'Info', description: 'Some information needs to be passed to you.' },
//   { id: 'toast-4', type: 'success', title: 'Success', description: 'Your action succeeded in promoting something' },
// ];

const propTypes = {
  toasts: PropTypes.instanceOf(Immutable.Map).isRequired,
};

const ToastMessages = ({ toasts }) => (
  <div className="absolute mt-8 pt-8 z-50 w-full flex flex-col items-center">
    {toasts
        .toList()
        .sortBy(toast => toast.get('createdAt'))
        .map(toast => (
          <ToastMessage
            key={toast.get('id')}
            id={toast.get('id')}
            text={toast.get('text')}
            type={toast.get('type')}
          />
        ))}
  </div>
  );

ToastMessages.propTypes = propTypes;

export default connect(({ toasts }) => ({
  toasts: toasts.get('data', Immutable.Map()),
}))(ToastMessages);
