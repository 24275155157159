import { Map, fromJS } from 'immutable';
import * as types from 'types';

const initialState = fromJS({
  zoomCount: 7,
  bpm: 120,
  snap: false,
});

export default function zoomLevel(state = initialState, { type, payload }) {
  if (!(state instanceof Map)) state = fromJS(state); // eslint-disable-line

  switch (type) {
  case types.RESET_SESSION:
    return initialState;

  case types.ZOOM_IN: {
    const zoomCount = state.get('zoomCount');
    if (zoomCount - 1 >= 0) return state.set('zoomCount', zoomCount - 1);
    return state;
  }

  case types.ZOOM_OUT:
    return state.set('zoomCount', state.get('zoomCount') + 1);

  case types.CHANGE_TIMEGRID:
  case types.SNAP_TO_GRID:
    return state.set(payload.property, payload.value);

  default:
    return state;
  }
}
