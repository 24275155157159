import { Map, fromJS } from 'immutable';
import * as types from 'types';

const initialState = fromJS({
  initialLoad: false,
  isFetching: false,
  projectId: '',
  error: '',
  items: [],
  height: null,
});

const trackHeight = 110;

export default function tracks(state = initialState, { type, payload }) {
  if (!(state instanceof Map)) state = fromJS(state); // eslint-disable-line

  switch (type) {
  case types.RESET_TRACKS:
    return initialState;

  case types.UPDATE_PROJECT_ID:
    return state.set('projectId', payload);

  case types.ADD_TRACK_CLIENT: {
    const newIndex =
        state
          .get('items')
          .map(track => track.get('index'))
          .reduce((max, cur) => Math.max(max, cur), 0) + 1;
    const solo = state
        .get('items')
        .filter(track => track.get('soundStatus') === 'solo').size;
    const track = fromJS({
      id: payload.trackId,
      projectId: payload.projectId,
      volume: 0.5,
      name: payload.name ? payload.name : 'New Track',
      status: 'perishable',
      index: newIndex,
      arm: false,
      soundStatus: solo ? 'silent' : 'normal',
      height: trackHeight,
    });
    const items = state.get('items').push(track);
    return state.set('items', items);
  }

  case types.TOGGLE_MUTE: {
    const solos = state
        .get('items')
        .filter(track => track.get('soundStatus') === 'solo');
    const isSolo = solos.find(item => item.get('id') === payload.id);
    const otherSolos = isSolo ? solos.size > 1 : solos.size > 0;
    const items = state.get('items').map((track) => {
      if (track.get('status') === 'perishing') return track;
      const find = track.get('id') === payload.id;
      const normal = !otherSolos && track.get('soundStatus') === 'silent';
      if (find) {
        if (payload.value) return track.set('soundStatus', 'mute');
        return track
            .set('soundStatus', otherSolos ? 'silent' : 'normal')
            .set('status', 'unstable');
      } else if (isSolo && normal) {
        return track.set('soundStatus', 'normal').set('status', 'unstable');
      }
      return track;
    });
    return state.withMutations((map) => {
      map.set('items', items).set('error', '').set('isFetching', false);
    });
  }

  case types.TOGGLE_SOLO: {
    const otherSolos =
        state.get('items').filter(track => track.get('soundStatus') === 'solo')
          .size > 1;
    const items = state.get('items').map((track) => {
      if (track.get('status') === 'perishing') return track;
      const find = track.get('id') === payload.id;
      if (payload.value) {
        const silence =
            track.get('soundStatus') === 'normal' || !track.get('soundStatus');
        if (find) {
          return track.set('soundStatus', 'solo').set('status', 'unstable');
        } else if (silence) {
          return track.set('soundStatus', 'silent').set('status', 'unstable');
        }
      } else {
        const normal = !otherSolos && track.get('soundStatus') === 'silent';
        if (find) {
          return track
              .set('soundStatus', otherSolos ? 'silent' : 'normal')
              .set('status', 'unstable');
        } else if (normal) {
          return track.set('soundStatus', 'normal').set('status', 'unstable');
        }
      }
      return track;
    });
    return state.withMutations((map) => {
      map.set('items', items).set('error', '').set('isFetching', false);
    });
  }

  case types.UPDATE_VOLUME: {
    const items = state.get('items').map((track) => {
      if (track.get('status') === 'perishing') return track;
      const find = payload.id === track.get('id');
      if (find) {
        return track.set('volume', payload.value).set('status', 'unstable');
      }
      return track;
    });
    return state.withMutations((map) => {
      map.set('items', items).set('error', '').set('isFetching', false);
    });
  }

  case types.ADD_TRACK_SUCCESS: {
    const find = state
        .get('items')
        .find(track => track.get('id') === payload.id);
    if (payload.projectId === state.get('projectId')) {
      let items;
      const newPayload = Object.assign({}, payload, {
        height: payload.height || trackHeight,
        status: 'saved',
      });
      if (!find) items = state.get('items').push(fromJS(newPayload));
      else {
        items = state
            .get('items')
            .map(
              track =>
                (track.get('id') === newPayload.id
                  ? track.merge(fromJS(newPayload))
                  : track),
            );
      }
      return state.withMutations((map) => {
        map.set('items', items).set('error', '').set('isFetching', false);
      });
    }
    return state;
  }

  case types.TRASH_TRACK_CLIENT: {
    let index = null;
    const items = state
        .get('items')
        .map((track) => {
          if (track.get('id') === payload.id) {
            if (track.get('status') === 'perishable') return null;
            return track.withMutations((newTrack) => {
              index = newTrack.get('index');
              newTrack.set('index', -1);
              newTrack.set('status', 'perishing');
            });
          }
          return track;
        })
        .filter(track => track)
        .map((track) => {
          if (track.get('index') > index) {
            return track.set('index', track.get('index') - 1);
          }
          return track;
        });

    return state.withMutations((map) => {
      map.set('items', items).set('error', '').set('isFetching', false);
    });
  }

  case types.TOGGLE_ARM: {
    const items = state.get('items').map((track) => {
      if (track.get('id') === payload.id) {
        return track.set('arm', true);
      } else if (track.get('arm')) {
        return track.set('arm', false);
      }
      return track;
    });
    return state.withMutations((map) => {
      map.set('items', items).set('error', '').set('isFetching', false);
    });
  }

  case types.UPDATE_NAME: {
    const items = state.get('items').map((track) => {
      if (track.get('id') === payload.id) {
        if (track.get('status') === 'saved') { track = track.set('status', 'unstable'); } // eslint-disable-line
        return track.set(payload.property, payload.value);
      }
      return track;
    });
    return state.withMutations((map) => {
      map.set('items', items).set('error', '').set('isFetching', false);
    });
  }

  case types.UPDATE_TRACK_SUCCESS: {
    if (payload.projectId === state.get('projectId')) {
      const newPayload = Object.assign({}, payload, { status: 'saved' });
      const items = state
          .get('items')
          .map(
            track =>
              (track.get('id') === payload.id
                ? track.merge(fromJS(newPayload))
                : track),
          );
      return state.withMutations((map) => {
        map.set('items', items).set('error', '').set('isFetching', false);
      });
    }
    return state;
  }

  case types.DELETE_TRACK_SUCCESS: {
    const items = state
        .get('items')
        .filter(track => track.get('id') !== payload.id);
    return state.withMutations((map) => {
      map.set('items', items).set('error', '').set('isFetching', false);
    });
  }

  case types.FETCH_TRACKS_SUCCESS: {
    const adjustedPayload = payload.map(track =>
        Object.assign({}, track, {
          height: track.height || trackHeight,
          status: 'saved',
        }),
      );
    const items = fromJS(adjustedPayload);
    return state.withMutations((map) => {
      map.set('items', items).set('error', '').set('isFetching', false);
    });
  }

  case types.FETCH_TRACKS_REQUEST:
  case types.ADD_TRACK_REQUEST:
  case types.DELETE_TRACK_REQUEST:
  case types.UPDATE_TRACK_REQUEST:
    return state.withMutations((map) => {
      map.set('error', '').set('isFetching', false);
    });

  case types.FETCH_TRACKS_FAILURE:
  case types.ADD_TRACK_FAILURE:
  case types.DELETE_TRACK_FAILURE:
  case types.UPDATE_TRACK_FAILURE:
    return state.withMutations((map) => {
      map.set('error', payload).set('isFetching', false);
    });

  default:
    return state;
  }
}
