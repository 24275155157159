// If we have separate routes for public and private use
import React from 'react';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const propTypes = {
  auth: PropTypes.object.isRequired,
  private: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  public: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

const DualRoute = ({
  auth,
  private: PrivateComponent,
  public: PublicComponent,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      auth.isAuthenticated
        ? <PrivateComponent {...props} auth={auth} />
        : <PublicComponent {...props} auth={auth} />
    )}
  />
);

DualRoute.propTypes = propTypes;

export default withRouter(connect(state => ({ auth: state.auth }))(DualRoute));
