import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  PrivateRoute,
  PublicRoute,
  AuthRoute,
  DualRoute,
} from 'components/Route';
import Login from 'components/Login';
import Landing from 'components/Landing';
import Dashboard from 'components/Dashboard';
import RegisterPage from 'components/Register';
import CreateTrackPage from 'pages/CreateTrackPage';
import InvitesPage from 'components/Invites';
import ValidatePage from 'components/Validate';
import Search from 'components/Search';
// import ExplorePage from 'pages/ExplorePage';
import ProjectsWrapper from 'components/Projects/ProjectsWrapper';
import ErrorPage from 'components/ErrorPage';
import CartCheckoutPage from 'pages/CartCheckoutPage';
import PreCheckoutPage from 'pages/PreCheckoutPage';
import NotificationsPage from 'pages/NotificationsPage';
import FollowersPage from 'pages/FollowersPage';
import FollowingPage from 'pages/FollowingPage';
import ProfilePage from 'pages/ProfilePage';
import HashtagPage from 'pages/HashtagPage';
import TrackPage from 'pages/TrackPage';
import LikesPage from 'pages/LikesPage';
import SettingsPage from 'pages/SettingsPage';
import StripeConnectPage from 'pages/StripeConnectPage';
import MoodPage from 'pages/MoodPage';
import GenrePage from 'pages/GenrePage';
import StaffPicksPage from 'pages/StaffPicksPage';
import NewestArrivalsPage from 'pages/NewestArrivalsPage';
import GenresAndMoodsPage from 'pages/GenresAndMoodsPage';
import DeliverBeatPage from 'pages/DeliverBeatPage';
import DeliverBeatPageNew from 'pages/DeliverBeatPageNew';
import SellBeatsPage from 'pages/SellBeatsPage';
import ContactUsPage from 'pages/ContactUsPage';
import PrivacyPage from 'pages/PrivacyPage';
import AboutPage from 'pages/AboutPage';
import TermsPage from 'pages/TermsPage';
import ExclusiveExamplePage from 'pages/ExclusiveExamplePage';
import PremiumExamplePage from 'pages/PremiumExamplePage';
import BasicExamplePage from 'pages/BasicExamplePage';
import LicensePage from 'pages/LicensePage';
import CartPage from 'pages/CartPage';
import SignUpPage from 'pages/SignUpPage';
import SignUpSuccessPage from 'pages/SignUpSuccessPage';
import OnboardingPage from 'pages/OnboardingPage';

const AppRoutes = () => (
  <Switch>
    <Route
      sensitive
      path="/:anything*([A-Z]):anything*/"
      render={props => (
        <Redirect to={`${props.location.pathname.toLowerCase()}`} /> // eslint-disable-line
      )}
    />
    {/* Dual route renders different components depending on route */}
    <DualRoute exact path="/" private={Dashboard} public={Landing} />

    {/* Public routes: require unauthenticated user */}
    <PublicRoute path="/login" component={Login} />
    <PublicRoute path="/register" component={RegisterPage} />
    <PublicRoute path="/email/invites" component={InvitesPage} />
    <PublicRoute path="/validate" component={ValidatePage} />
    <PublicRoute path="/sellbeats" component={SellBeatsPage} />
    <PublicRoute exact path="/signup" component={SignUpPage} />
    <PublicRoute path="/signup/success" component={SignUpSuccessPage} />

    {/* Private routes require authenticated user */}
    <PrivateRoute path="/stripe/connect" component={StripeConnectPage} />
    <PrivateRoute path="/tracks/create" exact component={CreateTrackPage} />
    <PrivateRoute path="/settings" component={SettingsPage} />
    <PrivateRoute path="/projects" component={ProjectsWrapper} />
    <PrivateRoute path="/notifications" component={NotificationsPage} />

    {/* auth routes pass auth reducer down to component */}
    <Route path="/tags/:tag" component={HashtagPage} />
    <Route path="/tracks/:publicId" exact component={TrackPage} />
    <LikesPage path="/tracks/:publicId/likes" />
    <AuthRoute path="/search/:query?" component={Search} />
    <AuthRoute path="/explore" component={Landing} />
    <Route path="/moods/:mood" component={MoodPage} />
    <Route path="/genres/:genre" component={GenrePage} />
    <Route path="/staffpicks" component={StaffPicksPage} />
    <NewestArrivalsPage path="/newestarrivals" />
    <Route path="/checkout" component={CartCheckoutPage} />
    <GenresAndMoodsPage path="/genresandmoods" />
    <Route path="/orders/:orderId/delivery-new" component={DeliverBeatPageNew} />
    <Route path="/orders/:orderId/delivery" component={DeliverBeatPage} />
    <AuthRoute path="/orders/delivery/error" />
    <Route path="/cart" component={CartPage} />
    <AuthRoute path="/precheckout" component={PreCheckoutPage} />
    <OnboardingPage path="/onboarding" />

    {/* Static pages */}
    <Route path="/error" component={ErrorPage} />
    <Route path="/contact-us" component={ContactUsPage} />
    <Route path="/privacy" component={PrivacyPage} />
    <Route path="/terms" component={TermsPage} />
    <Route path="/about" component={AboutPage} />
    <Route path="/exclusive-example" component={ExclusiveExamplePage} />
    <Route path="/premium-example" component={PremiumExamplePage} />
    <Route path="/basic-example" component={BasicExamplePage} />
    <Route path="/licenses/:licenseType" component={LicensePage} />

    {/* User pages */}
    <FollowersPage path="/:username/followers" />
    <FollowingPage path="/:username/following" />
    <AuthRoute path="/:username" exact component={ProfilePage} />
  </Switch>
);

export default AppRoutes;
