import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset, SubmissionError } from 'redux-form';
import { postFeedback } from 'rest/feedback';
import FeedbackForm from 'components/Modals/FeedbackModal/FeedbackForm';

class ContactUsPage extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    reset: PropTypes.func.isRequired,
  };

  handleFeedbackSubmit = (values) => {
    const { auth: { isAuthenticated, userId } } = this.props;
    const data = isAuthenticated ? { userId, ...values } : values;
    return postFeedback(data)
      .then(() => {
        this.props.reset('feedback');
      })
      .catch((err) => {
        const error = err.message || 'Invalid Feedback Post';
        throw new SubmissionError({
          _error: error,
        });
      });
  };

  render() {
    return (
      <div id="contact-us-page" className="container my-8 p-6">
        <h1 className="title">Contact Us</h1>
        <FeedbackForm contactUs onSubmit={this.handleFeedbackSubmit} />
      </div>
    );
  }
}

export default connect(state => ({ auth: state.auth }), { reset })(
  ContactUsPage,
);
