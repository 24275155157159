import React from 'react';

const SignUpSuccess = () => (
  <div id="sign-up-success" className="container mt-8 pt-8 text-center">
    <h1 className="title">Thank you for signing up to Plug Music!</h1>
    <h2 className="subtitle">
      We are in{' '}
      <span className="font-bold">beta</span> at the moment. We will
      email you to create an account when we are out of beta.
    </h2>
  </div>
);

export default SignUpSuccess;
