import querystring from 'query-string';
import { fetch, authHeader } from 'utils/fetch';

export function login(data) {
  return fetch('/api/login', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function getUser() {
  return fetch('/api/users/me', {
    headers: authHeader(),
  });
}

export function getUserEmail(data) {
  const query = querystring.stringify({ ...data, exists: 'true' });

  return fetch(`/api/users?${query}`);
}

export function createUser(data) {
  return fetch('/api/users/new', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'Application/json',
    },
  });
}

export function fetchUsersApi(id) {
  return fetch(`/api/projects/${id}/users`, {
    headers: authHeader(),
  });
}

export function addUserApi(userId, projectId) {
  return fetch(`/api/projects/${projectId}/users`, {
    method: 'POST',
    body: JSON.stringify({ userId }),
    headers: {
      'Content-Type': 'Application/json',
      ...authHeader(),
    },
  });
}

export function removeUserApi(userId, projectId) {
  return fetch(`/api/projects/${projectId}/users/${userId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'Application/json',
      ...authHeader(),
    },
  });
}

export function updateUserApi(update) {
  return fetch(`/api/users/${update.id}`, {
    method: 'PUT',
    body: JSON.stringify(update),
    headers: {
      'Content-Type': 'Application/json',
      ...authHeader(),
    },
  });
}

export function validateUserApi(body) {
  return fetch('/api/users/validate', {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'Application/json',
    },
  });
}

export function userRequest(body) {
  return fetch('/api/users/request', {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'Application/json',
    },
  });
}

export default module.exports;
