import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import { Query } from 'react-apollo';
import DeliverBeatNew from 'components/Checkout/DeliverBeatNew';


class DeliverBeatPage extends Component {
  state = {
    loading: true,
    data: null,
  };

  async componentDidMount() {
    const {
      match: {
        params: { orderId },
      },
    } = this.props;
    try {
      const response = await axios.get(`/rest/order/${orderId}`);
      this.setState({ data: response.data });
    } catch (err) {
      console.log('error getting order', err);
      this.setState({ error: true });
    }
    this.setState({ loading: false });
  }

  render() {
    const { loading, error, data } = this.state;
    if (loading) return <div>Loading ...</div>;
    if (error) return <div>Error</div>;
    return <DeliverBeatNew order={data} />;
  }
}


export default DeliverBeatPage;
