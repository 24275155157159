import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  signOut: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

const AuthButton = ({ auth, history, signOut, onRequestClose }) => (
  auth.isAuthenticated
    ? <div>
        Welcome! <button
          className="button"
          onClick={() => {
            signOut();
            history.push('/');
            onRequestClose();
          }}
        >
          Sign out
        </button>
    </div>
    : <Link to="/login" onClick={() => onRequestClose()} className="button">Log in</Link>
);

AuthButton.propTypes = propTypes;

export default withRouter(AuthButton);
