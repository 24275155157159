import React from 'react';
// import premiumExample from '../../markdown/premium-example.html';

const PremiumLicenseExample = () => (
  <div />
  // <div
  //   className="markdown"
  //   dangerouslySetInnerHTML={{ __html: premiumExample }}
  // />
);

export default PremiumLicenseExample;
