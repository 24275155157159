/**
 * @flow
 * @relayHash ffd9f6913e1af21faafd1322695fd7a5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserSkill = ('ARTIST' | 'PRODUCER' | '%future added value');
export type UpdateUserByIdMutationVariables = {|
  input: {
    clientMutationId?: ?string,
    userPatch: {
      id?: ?any,
      displayName?: ?string,
      picture?: ?string,
      description?: ?string,
      link?: ?string,
      username?: ?string,
      createdAt?: ?any,
      updatedAt?: ?any,
      deleted?: ?boolean,
      skill?: ?UserSkill,
    },
    id: any,
  },
|};
export type UpdateUserByIdMutationResponse = {|
  +updateUserById: ?{|
    +user: ?{|
      +id: any,
      +username: ?string,
      +displayName: string,
      +description: ?string,
    |},
  |},
|};
*/


/*
mutation UpdateUserByIdMutation(
  $input: UpdateUserByIdInput!
) {
  updateUserById(input: $input) {
    user {
      id
      username
      displayName
      description
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateUserByIdInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateUserById",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateUserByIdInput!"
      }
    ],
    "concreteType": "UpdateUserPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "username",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "displayName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UpdateUserByIdMutation",
  "id": null,
  "text": "mutation UpdateUserByIdMutation(\n  $input: UpdateUserByIdInput!\n) {\n  updateUserById(input: $input) {\n    user {\n      id\n      username\n      displayName\n      description\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateUserByIdMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateUserByIdMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node/*: any*/).hash = '8b9ef7226fe8137e7f2b50a9bb403023';
module.exports = node;
