/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type Comment_comment$ref = any;
import type { FragmentReference } from 'relay-runtime';
declare export opaque type CommentList_track$ref: FragmentReference;
export type CommentList_track = {|
  +comments: {|
    +edges: $ReadOnlyArray<{|
      +node: ?{|
        +id: any,
        +author: ?{|
          +id: any,
        |},
        +$fragmentRefs: Comment_comment$ref,
      |},
    |}>,
  |},
  +$refType: CommentList_track$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "CommentList_track",
  "type": "Track",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "comments"
        ]
      }
    ]
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "comments",
      "name": "__CommentList_comments_connection",
      "storageKey": "__CommentList_comments_connection(orderBy:\"CREATED_AT_ASC\")",
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "CREATED_AT_ASC",
          "type": "[CommentsOrderBy!]"
        }
      ],
      "concreteType": "CommentsConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CommentsEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Comment",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "LinkedField",
                  "alias": "author",
                  "name": "userByUserId",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "User",
                  "plural": false,
                  "selections": [
                    v0
                  ]
                },
                {
                  "kind": "FragmentSpread",
                  "name": "Comment_comment",
                  "args": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
(node/*: any*/).hash = '6392db820ca1958dcdf34d27c9500b18';
module.exports = node;
