import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const propTypes = {
  uploads: PropTypes.array,
  requiredFiles: PropTypes.object,
};

const defaultProps = {
  uploads: [],
  requiredFiles: {},
};

const Upload = ({ uploads, requiredFiles }) => {
  const aggregateProgress = () => {
    const n = Object.keys(requiredFiles).length;
    if (n) {
      let weightedTotal = 0;
      uploads.forEach((u) => {
        weightedTotal += (u.progress || 0) / n;
      });
      return Math.round(weightedTotal * 100) / 100;
    } else {
      return 0;
    }
  };

  const progress = uploads.length ? aggregateProgress() : 0;

  return (
    <Link
      to="/tracks/create"
      className="flex justify-center items-center nowrap hover:text-white"
    >
      <div className="navbar-divider-line" />
      {uploads.length && progress < 1 ? (
        <div className="desc px-4 color-bg">
          Uploading {`${(progress * 100).toFixed(0)}%`}
          <div
            style={{
              backgroundColor: 'white',
              height: 2,
              width: `${uploads.length && progress * 100}%`,
              position: 'relative',
              top: 14,
              left: 0,
            }}
          />
        </div>
      ) : (
        <div className="desc px-4 color-bg">Upload Beat</div>
      )}
      <div className="navbar-divider-line" />
    </Link>
  );
};

Upload.propTypes = propTypes;
Upload.defaultProps = defaultProps;

export default Upload;
