import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import Cookies from 'js-cookie';
import decode from 'jwt-decode';
import { apiUrl } from 'config';
import fetch from 'utils/fetch'; // eslint-disable-line

function fetchQuery(token) {
  return (operation, variables) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    if (token) headers.append('Authorization', `bearer ${token}`);
    return fetch(`${apiUrl}/api/graphql`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        query: operation.text,
        variables,
      }),
    });
  };
}

const createEnvironment = token =>
  new Environment({
    network: Network.create(fetchQuery(token)),
    store: new Store(new RecordSource()),
  });

const initialState = {
  isAuthenticated: false,
  token: '',
  userId: '',
  username: '',
  environment: createEnvironment(),
  error: '',
  isLoading: false,
};

export default function auth(state = initialState, { type, payload }) {
  switch (type) {
  case 'LOGIN_REQUEST':
  case 'REGISTER_REQUEST':
    return {
      ...state,
      isAuthenticated: false,
      token: '',
      userId: '',
      error: '',
      isLoading: true,
    };

  case 'LOGIN_FAILURE':
  case 'REGISTER_FAILURE':
    return {
      ...state,
      isAuthenticated: false,
      token: '',
      userId: '',
      error: payload,
      isLoading: false,
    };

  case 'LOGIN_SUCCESS': {
    const token = payload.jwtToken;
    Cookies.set('token', token);
    const { user_id: userId, username, skill } = decode(token);

    return {
      isAuthenticated: true,
      token,
      skill,
      userId,
      username,
      environment: createEnvironment(token),
      error: '',
      isLoading: false,
    };
  }

  case 'LOAD_USER': {
    const { user_id: userId, username, skill } = decode(payload);

    return {
      isAuthenticated: true,
      token: payload,
      skill,
      userId,
      username,
      environment: createEnvironment(payload),
      error: '',
      isLoading: false,
    };
  }

  case 'SIGN_OUT':
    return initialState;

  default:
    return state;
  }
}
