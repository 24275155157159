import { commitMutation } from 'react-relay';

const promiseMutation = (environment, config) =>
  new Promise((fulfill, reject) => {
    commitMutation(environment, {
      ...config,
      onCompleted: (response, err) => {
        if (err) reject(err);
        fulfill(response);
      },
    });
  });

export default promiseMutation;
