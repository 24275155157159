import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { s3Bucket } from 'config';
import classnames from 'classnames';
import { isDesktop } from 'utils/screentype';

const s3 = require('helpers/s3').s3;

const DOWNLOAD_TRACK = gql`
  fragment Download_track on Track {
    name
    stems
    fileUrl
  }
`;

class Download extends Component {
  static fragments = { track: DOWNLOAD_TRACK };
  static propTypes = {
    track: PropTypes.object.isRequired,
    purchasedType: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    purchasedType: '',
  };

  state = {
    anchorEl: null,
  };

  stemsClick = (track) => {
    this.handleClose();

    const params = {
      Bucket: s3Bucket,
      Key: `track/${track.fileUrl}/stems.zip`,
      Expires: 900, // this is the default - 15 minutes
      ResponseContentDisposition: `attachment;filename=${track.name}.zip`,
    };

    s3.getSignedUrl('getObject', params, (err, url) => {
      window.location.href = url;
    });
  };

  taggedClick = (track) => {
    this.handleClose();

    const params = {
      Bucket: s3Bucket,
      Key: `track/${track.fileUrl}/tagged.mp3`,
      Expires: 900, // this is the default - 15 minutes
      ResponseContentDisposition: `attachment;filename=${track.name}.mp3`,
    };

    s3.getSignedUrl('getObject', params, (err, url) => {
      window.location.href = url;
    });
  };

  mp3Click = (track) => {
    this.handleClose();

    const params = {
      Bucket: s3Bucket,
      Key: `track/${track.fileUrl}/clean.mp3`,
      Expires: 900, // this is the default - 15 minutes
      ResponseContentDisposition: `attachment;filename=${track.name}.mp3`,
    };

    s3.getSignedUrl('getObject', params, (err, url) => {
      window.location.href = url;
    });
  };

  wavClick = (track) => {
    this.handleClose();

    const params = {
      Bucket: s3Bucket,
      Key: `track/${track.fileUrl}/clean.wav`,
      Expires: 900, // this is the default - 15 minutes
      ResponseContentDisposition: `attachment;filename=${track.name}.wav`,
    };

    s3.getSignedUrl('getObject', params, (err, url) => {
      window.location.href = url;
    });
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { track, className, purchasedType } = this.props;
    const { anchorEl } = this.state;
    const type = purchasedType.toLowerCase();
    const exclusiveEnabled = track.stems && (type === 'premium' || type === 'exclusive');
    return (
      <span
        className={classnames(
          'naked-button pro-trkplayer-buttons fct',
          className,
        )}
      >
        {/* The inline styles here fix alignment issues, remove outline, disables material-ui's default uppercase, and
        removes the hover background color effect to more closely approximate hte previous design  */}
        <span>
          <Button
            aria-owns={anchorEl ? 'downloads-menu' : null}
            disableTouchRipple
            aria-haspopup="true"
            onClick={this.handleClick}
            size="small"
            style={{
              padding: 0,
              minHeight: 0,
              outline: 'none',
              textTransform: 'none',
              backgroundColor: 'transparent',
            }}
          >
            <i
              className={classnames('fa fa-download pro-trkplayer-icons', {
                is1: isDesktop(),
              })}
              aria-hidden="true"
            />
            <span className="mobile-hide vab">Download</span>
          </Button>
        </span>
        <Menu
          id="downloads-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem
            onClick={() => {
              this.mp3Click(track);
              this.handleClose();
            }}
          >
            MP3
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.taggedClick(track);
              this.handleClose();
            }}
          >
            Tagged
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.wavClick(track);
              this.handleClose();
            }}
          >
            WAV
          </MenuItem>
          <MenuItem
            disabled={!exclusiveEnabled}
            onClick={() => {
              if (exclusiveEnabled) {
                this.stemsClick(track);
              }
              this.handleClose();
            }}
          >
            Stems
          </MenuItem>
        </Menu>
      </span>
    );
  }
}

export default Download;
