import React from 'react';
import PropTypes from 'prop-types';
import LandingNewTracks from './LandingNewTracks';

const propTypes = {
  tracksByLikeCount: PropTypes.object.isRequired,
  tracksByRecentlyCreated: PropTypes.object.isRequired,
  removeOtherTracksOnPlay: PropTypes.bool,
};

const defaultProps = {
  removeOtherTracksOnPlay: false,
};

const Jaguar = ({
  tracksByLikeCount,
  tracksByRecentlyCreated,
  removeOtherTracksOnPlay,
}) => (
  <div id="page-3" className="h-screen">
    <div
      className="md:flex w-full h-full pt-5 md:pt-8"
      style={{ backgroundColor: '#823535' }}
    >
      <div className="h-full w-full">
        <LandingNewTracks
          tracks={tracksByRecentlyCreated}
          removeOtherTracksOnPlay={removeOtherTracksOnPlay}
        />
      </div>
    </div>
  </div>
);

Jaguar.propTypes = propTypes;
Jaguar.defaultProps = defaultProps;

export default Jaguar;
