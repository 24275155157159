import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Badge from 'material-ui/Badge';
import ReactTooltip from 'react-tooltip';

const propTypes = {
  unreadCount: PropTypes.number.isRequired,
  toolConfig: PropTypes.object.isRequired,
};

const Notifications = ({ unreadCount, toolConfig }) => (
  <Link
    to="/notifications"
    className="flex justify-center items-center mlr-8"
    data-tip
    data-for={'notifications'}
  >
    {unreadCount > 0 ? (
      <Badge
        badgeContent={unreadCount}
        primary
        badgeStyle={{ height: 20, width: 20, top: '-4px', right: '-8px' }}
        style={{ padding: 0, paddingTop: 4 }}
      >
        <i className="material-icons color-bg fs-24 icon-opacity">
          notifications
        </i>
      </Badge>
    ) : (
      <i className="material-icons color-bg fs-24 icon-opacity">
        notifications
      </i>
    )}
    <ReactTooltip
      id={'notifications'}
      type={toolConfig.type}
      effect={toolConfig.effect}
      delayShow={toolConfig.delayShow}
      className="tooltip"
    >
      <span>Notifications</span>
    </ReactTooltip>
  </Link>
);

Notifications.propTypes = propTypes;

export default connect(state => ({
  unreadCount: state.notifications.get('unread').size,
}))(Notifications);
