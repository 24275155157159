import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as modalActions from 'actions/modals';
import * as cartActions from 'actions/cart';
import displayPrice from 'utils/display-price';
import displayTrackImage, {
  displaySmallImage,
} from 'utils/display-track-image';
import PlugImage from 'components/common/PlugImage';
import BasicDropdown from 'components/common/BasicDropdown';

const propTypes = {
  product: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  removeCartItem: PropTypes.func.isRequired,
  swapCartItem: PropTypes.func.isRequired,
};

const CartItem = ({
  showModal,
  removeCartItem,
  swapCartItem,
  product: {
    id,
    price,
    type,
    track: {
      id: trackId,
      name,
      fileUrl,
      picture,
      publicId,
      trackUser: { displayName, username },
      products,
    },
  },
}) => (
  <div className="CartItem flex">
    <div className="mr-2">
      <PlugImage
        imageSource={displaySmallImage(fileUrl, picture)}
        fallbacks={[displayTrackImage(fileUrl, picture)]}
        className="profile-trackplayer-img-desk"
      />
    </div>
    <div className="flex flex-wrap w-full">
      <div className="flex flex-column justify-between pb-2 w-full">
        <div className="flex flex-wrap">
          <div className="sm:mr-4 sm:w-2/5 w-full">
            <Link to={`/tracks/${publicId}`} className="link">
              {name}
            </Link>{' '}
            by{' '}
            <Link to={`/${username}`} className="link">
              {displayName}
            </Link>
            <div>
              <a
                role="presentation"
                className="font-bold link"
                onClick={() => showModal('LicenseModal', { type })}
              >
                {type}
              </a>
            </div>
          </div>
          <div className="grow-1 flex justify-between">
            <div>
              <BasicDropdown
                id={`dropdown-${id}`}
                value={id}
                style={{ width: 106 }}
                options={products.edges.map(prod => ({
                  label: prod.node.type,
                  value: prod.node.id,
                }))}
                onChange={(product) => {
                  const item = products.edges.find(p => p.node.id === product.id);
                  swapCartItem({ id: product.id, trackId, price: item.node.price });
                }}
              />
            </div>
            <div className="font-bold text-black">{displayPrice(price)}</div>
          </div>
        </div>
        <div
          role="presentation"
          className="font-normal hover:underline cursor-pointer"
          onClick={() => removeCartItem(id)}
        >
          Remove
        </div>
      </div>
    </div>
  </div>
);

CartItem.propTypes = propTypes;

export default connect(
  null,
  { ...modalActions, ...cartActions },
)(CartItem);
