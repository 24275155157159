import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import graphql from 'babel-plugin-relay/macro';
import { QueryRenderer } from 'react-relay';
import VocalDashboard from './VocalDashboard';

const vocalQuery = graphql`
  query VocalDashboardContainerQuery {
    user: currentUser {
      ...VocalDashboard_user
    }
  }
`;

const propTypes = {
  auth: PropTypes.object.isRequired,
  user: PropTypes.object,
};

const defaultProps = {
  user: {},
};

const VocalDashboardContainer = ({ auth }) => (
  <QueryRenderer
    environment={auth.environment}
    query={vocalQuery}
    render={({ props, error }) => {
      if (props) {
        return <VocalDashboard user={props.user} />;
      } else if (error) {
        return <div>Error</div>;
      }
      return <div>Loading ...</div>;
    }}
  />
);

VocalDashboardContainer.propTypes = propTypes;
VocalDashboardContainer.defaultProps = defaultProps;

export default connect(({ auth }) => ({ auth }))(VocalDashboardContainer);
