import {
  ADD_TRACK_CLIENT,
  ADD_TRACK_SUCCESS,
  DELETE_TRACK_SUCCESS,
  TRASH_TRACK_CLIENT,
  UPDATE_NAME,
  TOGGLE_ARM,
  UPDATE_VOLUME,
  UPDATE_TRACK_SUCCESS,
  UPDATE_TRACK_FAILURE,
  UPDATE_TRACK_REQUEST,
  RESET_TRACKS,
} from 'types/tracks';
import {
  fetchTracksApi,
  addTrackApi,
  deleteTrackApi,
  updateTrackApi,
} from 'rest/tracks';
import { uploadClip } from './clips';
import { requestWrapper } from './helper';

export const fetchTracksAsync = requestWrapper('FETCH_TRACKS', fetchTracksApi);

export function addTrackClient(projectId, trackId, name) {
  return {
    type: ADD_TRACK_CLIENT,
    payload: {
      projectId,
      trackId,
      name,
    },
  };
}

export function addTrack(
  trackId,
  projectId,
  decodedId,
  clipId,
  trackBuffer,
  volume,
  delay,
  canvas,
) {
  return (dispatch) => {
    dispatch(addTrackClient(projectId, trackId));

    if (clipId) { dispatch(uploadClip(decodedId, clipId, trackBuffer, null, delay, canvas)); }
  };
}

export function addTrackSuccess(payload) {
  return {
    type: ADD_TRACK_SUCCESS,
    payload,
  };
}

export const addTrackAsync = requestWrapper('ADD_TRACK', addTrackApi);

export function deleteTrackSuccess(payload) {
  return {
    type: DELETE_TRACK_SUCCESS,
    payload,
  };
}

export const deleteTrackAsync = requestWrapper('DELETE_TRACK', deleteTrackApi);

export function trashTrackClient(id) {
  return {
    type: TRASH_TRACK_CLIENT,
    payload: {
      id,
    },
  };
}

export function updateValue(type, property, tracks) {
  return {
    type,
    payload: {
      tracks,
      property,
    },
  };
}

export function updateName(id, name) {
  return {
    type: UPDATE_NAME,
    payload: {
      id,
      property: 'name',
      value: name,
    },
  };
}

export function toggleArm(id) {
  return {
    type: TOGGLE_ARM,
    payload: {
      id,
    },
  };
}

export function updateBoolean(type, property, tracks, boolean) {
  return {
    type,
    payload: {
      property,
      tracks,
      boolean,
    },
  };
}

export function toggleMute(id, value) {
  return {
    type: 'TOGGLE_MUTE',
    payload: { id, value },
  };
}

export function toggleSolo(id, value) {
  return {
    type: 'TOGGLE_SOLO',
    payload: { id, value },
  };
}

export function updateVolume(id, value) {
  return {
    type: UPDATE_VOLUME,
    payload: { id, value },
  };
}

export function updateTrackSuccess(payload) {
  return {
    type: UPDATE_TRACK_SUCCESS,
    payload,
  };
}

export function updateTrackFailure(payload) {
  return {
    type: UPDATE_TRACK_FAILURE,
    payload,
  };
}

export function updateTrackAsync(track) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_TRACK_REQUEST,
    });

    return updateTrackApi(track.id, track)
      .then((trak) => {
        if (trak.id) {
          return dispatch(updateTrackSuccess(trak));
        }
        return dispatch(deleteTrackSuccess(trak.id));
      })
      .catch(err => dispatch(updateTrackFailure(err)));
  };
}

export const resetTracks = () => ({ type: RESET_TRACKS });

export default module.exports;
