import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reset } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import graphql from 'babel-plugin-relay/macro';
import hasIn from 'lodash/hasIn';
import createFragContainer from 'utils/create-frag-container';
import uploadImage from 'utils/upload-image';
import pick from 'lodash/pick';
import uuidV4 from 'uuid/v4';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import get from 'lodash/get';
import withS3 from 'utils/S3';
import FullDropzone from 'utils/FullDropzone';
import ImageField from 'components/Tracks/ImageField';
import TrackForm from 'components/Tracks/TrackForm';
import TrackPricingForm from 'components/Tracks/TrackPricingForm';
import ExitButton from 'components/common/ExitButton';
import * as toastActions from 'actions/toasts';
import UpdateTrackByIdMutation from 'mutations/track/UpdateTrackByIdMutation';
import UpdateProductByIdMutation from 'mutations/product/UpdateProductByIdMutation';
import CreateProductMutation from 'mutations/product/CreateProductMutation';
import DeleteProductByIdMutation from 'mutations/product/DeleteProductByIdMutation';

const editTrackFrag = graphql`
  fragment EditTrackModal_track on Track {
    id
    name
    description
    picture
    bpm
    stems
    free
    fileUrl
    genre
    mood
    products: productsByTrackId(first: 100) {
      edges {
        node {
          id
          type
          price
        }
      }
    }
  }
`;

const otherFrag = graphql`
  fragment EditTrackModal_user on User {
    private {
      stripeUser
    }
  }
`;

class EditTrackModal extends Component {
  static propTypes = {
    relay: PropTypes.object.isRequired,
    allMoods: PropTypes.object.isRequired,
    allGenres: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    s3Upload: PropTypes.func.isRequired,
    s3DestroyContext: PropTypes.func.isRequired,
    s3AddMetadata: PropTypes.func.isRequired,
    s3DeleteMetadata: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    track: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    s3Context: PropTypes.object.isRequired,
  };

  static defaultProps = {
    track: {},
    allGenres: {},
    allMoods: {},
  };

  state = { uploading: false };

  getInitialValues = () => {
    const { track } = this.props;
    const initialValues = pick(track, [
      'name',
      'description',
      'picture',
      'bpm',
      'genre',
      'mood',
    ]);
    const initialProducts = this.getInitialProducts();

    return Object.assign(
      {},
      { freeCheck: track.free },
      initialValues,
      initialProducts,
    );
  };

  getInitialProducts = () => {
    const { track: { products } } = this.props;
    const initialProducts = {};
    const pricingOptions = products.edges.map(edge => edge.node);

    pricingOptions.forEach((option) => {
      const type = option.type.toLowerCase();
      initialProducts[`${type}Check`] = true;
      initialProducts[type] = option.price / 100;
    });

    return initialProducts;
  };

  handleEditSubmit = (data) => {
    const {
      track: { id, picture, fileUrl, free },
      relay: { environment },
      s3Context,
      onClose,
      showMessage,
      reset: resetForm,
    } = this.props;
    console.log('data', data);
    const update = pick(data, [
      'name',
      'description',
      'mood',
      'genre',
      'bpm',
      'free',
    ]);

    const imageId = get(s3Context, 'metadata.imageId');
    if (imageId) update.picture = imageId;

    if (data.freeCheck !== free) update.free = data.freeCheck;

    Promise.all([
      UpdateTrackByIdMutation(environment, update, id),
      this.handleEditProducts(data),
    ])
      .then(() => {
        resetForm('Track');
        this.props.s3DestroyContext();
        onClose();
      })
      .catch((err) => {
        showMessage({ type: 'error', text: err.message });
      });
  };

  handleEditProducts = (data) => {
    const {
      track: { id: trackId, products },
      relay: { environment },
    } = this.props;
    return new Promise((fulfill, reject) => {
      const productTypes = ['basic', 'premium', 'exclusive'];
      const initialProducts = this.getInitialProducts();
      const oldProducts = productTypes.reduce(
        (acc, type) =>
          Object.assign({}, acc, {
            [type]:
              initialProducts[`${type}Check`] &&
              parseInt(initialProducts[type], 10),
          }),
        {},
      );
      const newProducts = productTypes.reduce(
        (acc, type) =>
          Object.assign({}, acc, {
            [type]: data[`${type}Check`] && parseInt(data[type], 10),
          }),
        {},
      );
      const productPromises = productTypes.map((product) => {
        if (!oldProducts[product] && newProducts[product]) {
          return CreateProductMutation(environment, {
            type: product.toUpperCase(),
            price: newProducts[product] * 100,
            trackId,
          });
        } else if (oldProducts[product] && !newProducts[product]) {
          const option = products.edges
            .map(edge => edge.node)
            .find(prod => prod.type.toLowerCase() === product);
          const id = get(option, 'id');
          return DeleteProductByIdMutation(environment, id);
        } else if (oldProducts[product] !== newProducts[product]) {
          const option = products.edges
            .map(edge => edge.node)
            .find(prod => prod.type.toLowerCase() === product);
          const id = get(option, 'id');
          return UpdateProductByIdMutation(environment, id, {
            price: newProducts[product] * 100,
          });
        }
        return null;
      });
      Promise.all(productPromises)
        .then(() => {
          fulfill();
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  handleClose = () => {
    const { onClose, reset: resetForm } = this.props;
    resetForm('Track');
    onClose();
  };

  handleDrop = (accepted, rejected) => {
    const {
      s3Context,
      s3AddMetadata,
      s3Upload,
      s3DeleteMetadata,
      track,
    } = this.props;
    if (rejected.length) {
      console.error('Some dropped files were rejected: ', rejected);
      return;
    }

    // Reset any errored files when starting a new drop
    if (get(this.props.s3Context, 'errors')) {
      this.props.s3Context.uploads
        .filter(upload => hasIn(upload, 'error'))
        .map(upload => upload.id)
        .forEach(id => this.resetFiles(id));
    }

    // Reset file requirements
    s3DeleteMetadata(['requiredFiles']);

    if (accepted.length === 1) {
      const file = accepted[0];
      console.log('idddd', track.id);
      if (file.type.includes('image')) {
        uploadImage(
          file,
          track.fileUrl,
          uuidV4(),
          s3AddMetadata.bind(s3Context.name),
          s3Upload.bind(s3Context.name),
        );
      } else {
        console.error(
          'One file was dropped and accepted, but it was not an image! It was: ',
          file.type,
        );
      }
    }
  };

  render() {
    const {
      track,
      allGenres,
      allMoods,
      user: { private: { stripeUser } },
      s3Context,
    } = this.props;
    const { uploading } = this.state;
    const initialValues = this.getInitialValues();
    return (
      <FullDropzone
        canHaveMulti={false}
        fileDropHandler={this.handleDrop}
        acceptableFiles="image/jpeg, image/png, image/jpg"
        rejectText="Invalid file type. Please choose a JPEG or PNG file."
      >
        {({ dropzoneRefClick }) => (
          <div id="edit-track-modal" className="p-4">
            <div className="flex justify-between mb-6">
              <h1 className="title" style={{ marginBottom: 0 }}>
                Edit Track
              </h1>
              <ExitButton id="edit-track-exit" onClick={this.handleClose} />
            </div>
            <Tabs>
              <TabList>
                <Tab>Basic info</Tab>
                <Tab>Pricing options</Tab>
              </TabList>
              <div>
                <ImageField
                  s3Context={s3Context}
                  dropzoneRefClick={dropzoneRefClick}
                  fileUrl={track.fileUrl}
                  picture={track.picture}
                />
                <TabPanel>
                  <TrackForm
                    allMoods={allMoods}
                    allGenres={allGenres}
                    onCancel={this.handleClose}
                    initialValues={initialValues}
                    onSubmit={this.handleEditSubmit}
                    submitType="Update"
                    uploading={uploading}
                    allowEditTags={false}
                    showImageUpload
                  />
                </TabPanel>
                <TabPanel>
                  <TrackPricingForm
                    onSubmit={this.handleEditSubmit}
                    onCancel={this.handleClose}
                    hasStems={track.stems}
                    submitType="Update"
                    showImageUpload={false}
                    isStripeUser={stripeUser}
                  />
                </TabPanel>
              </div>
            </Tabs>
          </div>
        )}
      </FullDropzone>
    );
  }
}

export default compose(
  connect(null, { reset, ...toastActions }),
  createFragContainer([editTrackFrag, otherFrag]),
  withS3,
)(EditTrackModal);
