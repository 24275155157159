import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import ExitButton from 'components/common/ExitButton';
import * as toastActions from 'actions/toasts';
import capitalize from 'lodash/capitalize';

const propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  dismissMessage: PropTypes.func.isRequired,
};

const typeColor = {
  error: 'red',
  warning: 'orange',
  info: 'blue',
  success: 'green',
};

const typeClass = type =>
  `bg-${typeColor[type]}-lightest border-${typeColor[type]} text-${
    typeColor[type]
  }-dark`;

const exitClass = type =>
  `border-${typeColor[type]}-dark hover:border-${typeColor[type]}-darker`;

const exitTextClass = type =>
  `text-${typeColor[type]}-dark hover:text-${typeColor[type]}-darker`;

const ToastMessage = ({ id, type, text, dismissMessage }) => (
  <div
    id={`toast-message-${id}`}
    className={classnames(
        'shadow border-l-4 p-4 w-1/2 my-2 rounded-sm flex items-center justify-between',
        typeClass(type),
      )}
    role="alert"
  >
    <div className="flex items-center">
      <div>
        <p className="font-bold">{capitalize(type)}</p>
        <p className="truncate">{text}</p>
      </div>
    </div>
    <ExitButton
      id={`toast-x-${id}`}
      className={exitClass(type)}
      textClassName={exitTextClass(type)}
      onClick={() => dismissMessage(id)}
    />
  </div>
  );

ToastMessage.propTypes = propTypes;

export default connect(null, toastActions)(ToastMessage);
