import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import RelayRoute from 'components/Route/RelayRoute';
import CreateTrack from 'components/Tracks/UploadTrack';

const createTrackQuery = graphql`
  query CreateTrackPageQuery($genreCondition: GenreCondition!) {
    user: currentUser {
      private {
        userId
        email
        stripeUser
      }
    }
    allGenres: allGenres(condition: $genreCondition){
      ...TrackForm_allGenres,
    },
    allMoods: allMoods{
      ...TrackForm_allMoods,
    }
  }
`;

const CreateTrackPage = props => (
  <RelayRoute
    {...props}
    setVariables={() => ({ genreCondition: { parent: 1 } })}
    component={CreateTrack}
    query={createTrackQuery}
  />
);

export default CreateTrackPage;
