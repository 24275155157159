export const FETCH_INVITES_REQUEST = 'FETCH_INVITES_REQUEST';
export const FETCH_INVITES_FAILURE = 'FETCH_INVITES_FAILURE';
export const FETCH_INVITES_SUCCESS = 'FETCH_INVITES_SUCCESS';

export const FETCH_INVITES_SENT_REQUEST = 'FETCH_INVITES_SENT_REQUEST';
export const FETCH_INVITES_SENT_FAILURE = 'FETCH_INVITES_SENT_FAILURE';
export const FETCH_INVITES_SENT_SUCCESS = 'FETCH_INVITES_SENT_SUCCESS';

export const FETCH_INVITES_RECEIVED_REQUEST = 'FETCH_INVITES_RECEIVED_REQUEST';
export const FETCH_INVITES_RECEIVED_FAILURE = 'FETCH_INVITES_RECEIVED_FAILURE';
export const FETCH_INVITES_RECEIVED_SUCCESS = 'FETCH_INVITES_RECEIVED_SUCCESS';

export const ADD_INVITE_REQUEST = 'ADD_INVITE_REQUEST';
export const ADD_INVITE_FAILURE = 'ADD_INVITE_FAILURE';
export const ADD_INVITE_SUCCESS = 'ADD_INVITE_SUCCESS';

export const UPDATE_INVITE_REQUEST = 'UPDATE_INVITE_REQUEST';
export const UPDATE_INVITE_FAILURE = 'UPDATE_INVITE_FAILURE';
export const UPDATE_INVITE_SUCCESS = 'UPDATE_INVITE_SUCCESS';

export const RESET_INVITES = 'RESET_INVITES';

export default module.exports;
