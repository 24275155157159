/**
 * @flow
 * @relayHash 2c9eedf32d15f9951147585b8a5eb61e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateUserAlbumMutationVariables = {|
  input: {
    clientMutationId?: ?string,
    userAlbum: {
      userId: any,
      albumId: string,
      createdAt?: ?any,
    },
  },
|};
export type CreateUserAlbumMutationResponse = {|
  +createUserAlbum: ?{|
    +userAlbum: ?{|
      +userId: any,
      +albumId: string,
    |},
  |},
|};
*/


/*
mutation CreateUserAlbumMutation(
  $input: CreateUserAlbumInput!
) {
  createUserAlbum(input: $input) {
    userAlbum {
      userId
      albumId
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateUserAlbumInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createUserAlbum",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "CreateUserAlbumInput!"
      }
    ],
    "concreteType": "CreateUserAlbumPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userAlbum",
        "storageKey": null,
        "args": null,
        "concreteType": "UserAlbum",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "userId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "albumId",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CreateUserAlbumMutation",
  "id": null,
  "text": "mutation CreateUserAlbumMutation(\n  $input: CreateUserAlbumInput!\n) {\n  createUserAlbum(input: $input) {\n    userAlbum {\n      userId\n      albumId\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateUserAlbumMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateUserAlbumMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node/*: any*/).hash = 'cbde28d0a89889bfafb251c964e68c60';
module.exports = node;
