import React from 'react';
import { avatarImageUrl, trackUrl } from 'config';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import displayImage from 'utils/display-image';
import displayTrackImage from 'utils/display-track-image';
import PlugImage from 'components/common/PlugImage';

const styles = {
  row: {
    padding: '6px 22px 0px 22px',
  },
  rowColor: {
    backgroundColor: 'white',
  },
  title: {
    fontSize: '16px',
    color: 'rgb(121, 121, 121)',
    marginBottom: '4px',
    borderBottom: '1px solid rgb(220, 220, 220)',
    paddingBottom: '2px',
  },
  contentContainer: {
    width: '100%',
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  textContainer: {
    display: 'inline-block',
  },
  textStyle: {
    marginTop: '6px',
    fontSize: '14px',
    width: '70px',
    display: 'inline-block',
  },
  containerStyle: {
    marginRight: '16px',
    cursor: 'pointer',
    display: 'flex',
  },
};

const propTypes = {
  tags: PropTypes.array,
  tracks: PropTypes.array,
  users: PropTypes.array,
  genres: PropTypes.array,
  moods: PropTypes.array,
  history: PropTypes.object.isRequired,
  handleHide: PropTypes.func.isRequired,
  exploring: PropTypes.bool,
};

const defaultProps = {
  tags: [],
  tracks: [],
  users: [],
  genres: [],
  moods: [],
  exploring: false,
};

const MiniSearchDisplay = ({
  tags,
  tracks,
  users,
  genres,
  moods,
  history,
  handleHide,
  exploring,
}) => {
  const listContent = (parameters) => {
    const {
      url,
      imgUrl,
      imgClassName,
      imgStyle,
      key,
      mainText,
      subText,
      clickHandler,
    } = parameters;
    return (
      <div
        key={key}
        role="button"
        className="miniexplore-row"
        // style={containerStyle}
        tabIndex={0}
        onClick={clickHandler}
      >
        <PlugImage
          imageSource={imgUrl}
          alt="User"
          className={imgClassName}
          style={imgStyle}
        />
        {/* <img
          src={imgUrl}
          alt="User"
          className={imgClassName}
          style={imgStyle}
        /> */}
        <div className="flex flex-col justify-center ml-3">
          <div className="font-semibold text-sm leading-normal">{mainText}</div>
          <div className="font-medium text-xs text-gray-dark">{subText}</div>
        </div>
      </div>
    );
  };

  const renderUsers = () => (
    <div style={styles.row}>
      <div style={styles.title}>{exploring ? 'Hot Artists' : 'Artists'}</div>
      <div style={styles.contentContainer}>
        {users.map(user =>
          listContent({
            key: user.id,
            url: avatarImageUrl,
            containerStyle: styles.containerStyle,
            imgUrl: displayImage(avatarImageUrl, user.picture),
            imgStyle: { display: 'inline-block' },
            imgClassName: 'miniexplore-avatar-circle',
            mainText: user.displayName,
            subText: `@${user.username}`,
            textStyle: styles.textStyle,
            clickHandler: () => {
              history.push(`/${user.username}`);
              handleHide();
            },
          }),
        )}
      </div>
    </div>
  );

  const renderTracks = () => (
    <div style={styles.row}>
      <div style={styles.title}>{exploring ? 'Poppin Tracks' : 'Tracks'}</div>
      <div style={styles.contentContainer}>
        {tracks.map(track =>
          listContent({
            key: track.id,
            url: trackUrl,
            containerStyle: {
              marginRight: '24px',
              cursor: 'pointer',
            },
            imgUrl: displayTrackImage(track.fileUrl, track.picture),
            imgStyle: {},
            imgClassName: 'miniexplore-avatar-rec',
            mainText: track.name,
            subText: track.userByUserId.displayName,
            textStyle: styles.textStyle,
            clickHandler: () => {
              history.push(`/tracks/${track.publicId}`);
              handleHide();
            },
          }),
        )}
      </div>
    </div>
  );

  const renderGenres = () => (
    <div style={styles.row}>
      <div style={styles.title}>{exploring ? 'Top Genres' : 'Genres'}</div>
      <div style={{ padding: '6px 0' }}>
        {genres.map((genre, index) => (
          <div
            style={{ display: 'flex', marginBottom: '4px' }}
            key={`${index * 2}`}
          >
            <div className="miniexplore-symbol">~</div>
            <div className="miniexplore-text-container">
              <p
                className="miniexplore-main-text"
                style={{
                  textTransform: 'capitalize',
                  color: 'rgb(218, 167, 53)',
                }}
              >
                <Link
                  to={`/genres/${genre.genre}`}
                  key={`${index * 2}`}
                  onClick={() => {
                    handleHide();
                  }}
                  style={{ color: 'rgb(75, 50, 250)' }}
                >
                  {genre.genre}
                </Link>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderMoods = () => (
    <div style={styles.row}>
      <div style={styles.title}>{exploring ? 'Top Moods' : 'Moods'}</div>
      <div style={{ padding: '6px 0' }}>
        {moods.map((mood, index) => (
          <div
            style={{ display: 'flex', marginBottom: '4px' }}
            key={`${index * 2}`}
          >
            <div className="miniexplore-symbol">~</div>
            <div className="miniexplore-text-container">
              <p
                className="miniexplore-main-text"
                style={{
                  textTransform: 'capitalize',
                  color: 'rgb(218, 167, 53)',
                }}
              >
                <Link
                  to={`/moods/${mood.mood}`}
                  key={`${index * 2}`}
                  onClick={() => {
                    handleHide();
                  }}
                  style={{ color: 'orange' }}
                >
                  {mood.mood}
                </Link>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderTags = () => (
    <div style={styles.row}>
      <div style={styles.title}>{exploring ? 'Trending Tags' : 'Tags'}</div>
      <div style={{ overflow: 'auto', padding: '6px 0' }}>
        {tags.map((tag, index) => (
          <Link
            to={`/tags/${tag.tag}`}
            key={`${index * 2}`}
            style={{ marginRight: 10 }}
            onClick={() => {
              handleHide();
            }}
          >
            #{tag.tag}
          </Link>
        ))}
      </div>
    </div>
  );

  const renderExploreMore = () => (
    <Link
      to="/explore"
      style={{ cursor: 'pointer' }}
      onClick={() => {
        handleHide();
      }}
    >
      <div
        style={{
          textAlign: 'center',
          fontWeight: 500,
          fontFamily: 'Roboto',
        }}
      >
        <p style={{ color: 'black', display: 'none' }}>EXPLORE MORE...</p>
      </div>
    </Link>
  );

  const renderResults = () => (
    <div>
      {!!users.length && renderUsers()}
      {!!tracks.length && renderTracks()}
      {!!genres.length && renderGenres()}
      {!!moods.length && renderMoods()}
      {!!tags.length && renderTags()}
      {exploring && renderExploreMore()}
    </div>
  );

  const renderNoResults = () => <div>No Results Found</div>;

  return (
    <div style={{ marginTop: '5px' }}>
      {!!users.length || !!tracks.length || !!genres.length || !!tags.length
        ? renderResults()
        : renderNoResults()}
    </div>
  );
};

MiniSearchDisplay.propTypes = propTypes;

MiniSearchDisplay.defaultProps = defaultProps;

export default withRouter(MiniSearchDisplay);
