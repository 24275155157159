import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import TrackProduct from './TrackProduct';

const propTypes = {
  track: PropTypes.object.isRequired,
};

const BUY_FORM_FRAGMENT = gql`
  fragment BuyForm_track on Track {
    id
    purgatory
    products: productsByTrackId(first: 100, orderBy: PRICE_ASC) {
      totalCount
      edges {
        node {
          type
          ...TrackProduct_product
        }
      }
    }
  }
  ${TrackProduct.fragments.product}
`;

const BuyForm = ({ track: { id, products, purgatory } }) => {
  if (!products.totalCount || purgatory) return <div id="buy-track" />;
  return (
    <div id="buy-track" className="mb-6 w-9/10 mx-auto md:flex md:w-desk">
      {/* <div className="font-semibold text-lg">Purchasing Options</div> */}
      {products.totalCount ? (
        <Fragment>
          {products.edges.map(edge => (
            <TrackProduct
              key={edge.node.type}
              product={edge.node}
              trackId={id}
            />
          ))}
        </Fragment>
      ) : (
        <div className="flex justify-center">No purchasing options!</div>
      )}
    </div>
  );
};

BuyForm.propTypes = propTypes;
BuyForm.fragments = {
  track: BUY_FORM_FRAGMENT,
};

export default BuyForm;
