const routes = [
  'login',
  'register',
  'email',
  'validate',
  'sell beats',
  'stripe',
  'tracks',
  'settings',
  'projects',
  'notifications',
  'tags',
  'tracks',
  'search',
  'explore',
  'moods',
  'genres',
  'checkouts',
  'orders',
  'create',
  'following',
  'followers',
  'beats',
  'signup',
];

export default potential => routes.indexOf(potential.toLowerCase()) > -1;
