import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Slider from '@material-ui/lab/Slider';
import * as soundbarActions from 'actions/soundbar';

const propTypes = {
  progress: PropTypes.number.isRequired,
  currentTrack: PropTypes.object.isRequired,
  trackId: PropTypes.string,
  isSeeking: PropTypes.bool.isRequired,
  seekPosition: PropTypes.number.isRequired,
  soundbarUpdate: PropTypes.func.isRequired,
  updating: PropTypes.bool.isRequired,
  rootClass: PropTypes.string,
  trackClass: PropTypes.string,
  trackBeforeClass: PropTypes.string,
  trackAfterClass: PropTypes.string,
  thumbClass: PropTypes.string,
};

const defaultProps = {
  trackId: '',
  rootClass: '',
  trackClass: '',
  trackBeforeClass: '',
  trackAfterClass: '',
  thumbClass: 'audioslider-thumb-outline',
};

const AudioSlider = ({
  trackId,
  progress,
  isSeeking,
  seekPosition,
  soundbarUpdate,
  updating,
  rootClass,
  trackClass,
  trackBeforeClass,
  trackAfterClass,
  thumbClass,
  currentTrack,
}) => {
  const seek = (event, value) => {
    const newValue = value / 100;
    if (isSeeking) {
      soundbarUpdate({
        seekPosition: newValue,
      });
    } else {
      soundbarUpdate({
        update: true,
        updatedTime: newValue,
      });
    }
  };

  const stopSeeking = () =>
    soundbarUpdate({
      update: true,
      updatedTime: seekPosition,
      seeking: false,
    });

  const startSeeking = () =>
    soundbarUpdate({
      seeking: true,
      seekPosition: progress,
    });

  const hasCurrentTrack = currentTrack.id;
  let sliderValue;
  let disabled;
  if (hasCurrentTrack) {
    const trackIsPlaying = !trackId || trackId === currentTrack.id;
    if (trackIsPlaying) {
      sliderValue = isSeeking || updating ? seekPosition * 100 : progress * 100;
      disabled = false;
    } else {
      sliderValue = 0;
      disabled = true;
    }
  } else {
    sliderValue = 0;
    disabled = true;
  }

  return (
    <Slider
      value={sliderValue}
      onChange={seek}
      onDragEnd={stopSeeking}
      onDragStart={startSeeking}
      disabled={disabled}
      classes={{
        root: rootClass,
        track: trackClass,
        trackBefore: trackBeforeClass,
        trackAfter: trackAfterClass,
        thumb: thumbClass,
      }}
    />
  );
};

AudioSlider.propTypes = propTypes;
AudioSlider.defaultProps = defaultProps;

export default connect(
  state => ({
    progress: state.soundbar.progress,
    isSeeking: state.soundbar.seeking,
    seekPosition: state.soundbar.seekPosition,
    updating: state.soundbar.update,
    currentTrack: state.soundbar.currentTrack,
  }),
  soundbarActions,
)(AudioSlider);
