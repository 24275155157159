import React from 'react';
import PropTypes from 'prop-types';
import ActionAnnouncement from 'material-ui/svg-icons/action/announcement';
import IconButton from 'material-ui/IconButton';
import { blueGray800, blueGray50 } from 'material-ui/styles/colors';

const propTypes = {
  onClick: PropTypes.func,
};

const defaultProps = {
  onClick: () => {},
};

const FeedbackButton = ({ onClick }) => (
  <IconButton
    tooltip="feedback"
    tooltipPosition="top-center"
    onClick={onClick}
    style={{
      backgroundColor: blueGray50,
      borderRadius: '100%',
      boxShadow: 'rgba(0, 0, 0, 0.06) 0px 4px 4px 0px',
    }}
  >
    <ActionAnnouncement color={blueGray800} />
  </IconButton>
);

FeedbackButton.propTypes = propTypes;
FeedbackButton.defaultProps = defaultProps;

export default FeedbackButton;
