import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom';
import { moodImageUrl } from 'config';
import TrackPlayer from 'components/common/ApolloTrackPlayer';
import GenreAndMoodDisplay from './GenreAndMoodDisplay';

const propTypes = {
  match: PropTypes.object.isRequired,
  moodTracks: PropTypes.object.isRequired,
};

const MOOD_LIST = gql`
  fragment MoodListContainer_moodTracks on TracksConnection {
    totalCount
    tracks: edges {
      node {
        id
        name
        picture
        fileUrl
        publicId
        userId
        ...TrackPlayer_track
        user: userByUserId {
          ...TrackPlayer_user
          id
          displayName
          username
        }
      }
    }
  }
  ${TrackPlayer.fragments.track}
  ${TrackPlayer.fragments.user}
`;

const MoodListContainer = ({
  match: {
    params: { mood },
  },
  moodTracks,
}) => (
  <GenreAndMoodDisplay
    tracks={moodTracks.tracks}
    name={mood}
    url={moodImageUrl}
    totalCount={moodTracks.totalCount}
    background={
      'linear-gradient(rgb(142, 158, 171), rgba(238, 238, 255, 0.133))'
    }
    textBackgroundColor={'rgb(109, 116, 121)'}
  />
);

MoodListContainer.propTypes = propTypes;
MoodListContainer.fragments = { moodTracks: MOOD_LIST };

export default withRouter(MoodListContainer);
