import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as actionCreators from 'actions/projects';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import ProjectList from './ProjectList';
import SoldBeats from './SoldBeats';

class Projects extends Component {
  static propTypes = {
    addProjectAsync: PropTypes.func.isRequired,
    deleteProjectAsync: PropTypes.func.isRequired,
    auth: PropTypes.shape({
      token: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
      environment: PropTypes.object.isRequired,
    }).isRequired,
    coolProjects: PropTypes.object.isRequired,
    fetchProjectsAsync: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    beats: PropTypes.object.isRequired,
    query: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { token, userId } = this.props.auth;
    if (token && userId) {
      this.props.fetchProjectsAsync(userId);
    }
  }

  handleNewClick = () => {
    const { addProjectAsync, history } = this.props;
    return addProjectAsync({
      name: 'Untitled project',
      bpm: 120,
    }).then((project) => {
      history.push(`/projects/${project.id}`);
    });
  };

  render() {
    const {
      beats,
      query,
      auth: { userId },
    } = this.props;
    const projects = this.props.coolProjects.edges.map((edge) => {
      const newProject = Object.assign({}, edge.node);
      newProject.users = newProject.userProjectsByProjectId.edges
        .map((user) => {
          const newUser = Object.assign({}, user.node.userByUserId);
          if (newUser.id === newProject.createdBy) {
            newProject.createdBy = Object.assign({}, newUser);
          }
          return user.node.userByUserId.id === userId
            ? null
            : user.node.userByUserId;
        })
        .filter(user => user);
      return newProject;
    });

    const showProjects = false;
    return (
      <div className="page-width mx-auto mt-3 pcont my3">
        <Tabs className="p-6">
          <TabList>
            <Tab>Beats sold</Tab>
            {showProjects && <Tab>Projects</Tab>}
          </TabList>

          <TabPanel>
            <SoldBeats beats={beats} query={query} />
          </TabPanel>
          {showProjects && (
            <TabPanel>
              <div className="flex items-center justify-between mt-3 mb-6">
                <h1 className="title" style={{ marginBottom: 0 }}>
                  Projects
                </h1>
                <button
                  className="b4p rounded-full"
                  style={{
                    backgroundColor: 'rgb(100, 185, 147)',
                    color: 'white',
                  }}
                  onClick={this.handleNewClick}
                >
                  New Project
                </button>
              </div>
              <ProjectList
                projects={projects}
                handleDelete={this.props.deleteProjectAsync}
                userId={this.props.auth.userId}
              />
            </TabPanel>
          )}
        </Tabs>
      </div>
    );
  }
}

export default compose(
  connect(
    null,
    actionCreators,
  ),
  withRouter,
)(Projects);
