/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type LikedTracks_user$ref = any;
type PurchasedTracks_user$ref = any;
import type { FragmentReference } from 'relay-runtime';
declare export opaque type VocalDashboard_user$ref: FragmentReference;
export type VocalDashboard_user = {|
  +$fragmentRefs: (PurchasedTracks_user$ref & LikedTracks_user$ref),
  +$refType: VocalDashboard_user$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "VocalDashboard_user",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "PurchasedTracks_user",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "LikedTracks_user",
      "args": null
    }
  ]
};
(node/*: any*/).hash = '667aa89c3d20ec0910efc3624db05f06';
module.exports = node;
