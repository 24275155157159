import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import Tile from 'components/common/ApolloImagePlay/Tile';
import Carousel from 'utils/Carousel';

const propTypes = {
  tracks: PropTypes.object.isRequired,
  removeOtherTracksOnPlay: PropTypes.bool,
};

const defaultProps = {
  removeOtherTracksOnPlay: false,
};

// currently uses New Tracks instead of Hot Tracks only because Hot Tracks does
// not currently have enough tracks in it.

const NEW_TRACKS_FRAG = gql`
  fragment LandingNewTracks_tracks on TracksConnection {
    edges {
      node {
        id
        name
        picture
        fileUrl
        publicId
        description
        userByUserId {
          id
          username
          displayName
        }
        ...Tile_track
      }
    }
  }
  ${Tile.fragments.track}
`;

const LandingNewTracks = ({ tracks, removeOtherTracksOnPlay }) =>
   (
     <div className=" w-9/10 mx-auto h-full flex flex-col pb-8 md:w-desk md:pb-16">
       <div className="font-bold text-lg pb-6 color-white text-center md:pb-8">
        Newest Arrivals
      </div>
       <Carousel
         settings={{
           columns: 2,
           rows: 3,
           slide: false,
           marginBottom: 28,
           marginRight: 16,
           hasArrows: false,
         }}
         breakpoints={[
           {
             breakpoint: 768,
             columns: 4,
             rows: 3,
             marginBottom: 36,
             marginRight: 24,
           },
           {
             breakpoint: 998,
            // marginRight: 32,
           },
         ]}
       >
         {tracks.edges.map(track => (
           <div className="h-full" key={`hot${track.node.id}`}>
             <Tile
               track={filter(Tile.fragments.track, track.node)}
               tracks={tracks.edges}
               className="w-full"
               imageClassName="rounded-none"
               textClassName="text-left"
               hasText
               isLink
               removeOtherTracksOnPlay={removeOtherTracksOnPlay}
             />
           </div>
        ))}
       </Carousel>
     </div>
  );

LandingNewTracks.propTypes = propTypes;
LandingNewTracks.defaultProps = defaultProps;
LandingNewTracks.fragments = { tracks: NEW_TRACKS_FRAG };

export default LandingNewTracks;
