/* eslint-disable */
import AWS from 'aws-sdk';
import { s3Bucket } from 'config';
import serverCheck from 'utils/server-check';

const config = {
  accessKeyId: 'AKIAJZUVDQSMIASEBNTQ',
  secretAccessKey: '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',
  bucket: s3Bucket,
  trackDirectory: '/tracks',
  imagesDirectory: '/images',
};

AWS.config.update({
  accessKeyId: config.accessKeyId,
  secretAccessKey: config.secretAccessKey,
});

AWS.config.update({ region: 'us-west-1' });

// Right now s3Actions still has its own config, where the timeout is
// disabled (to accomodate large files over slow connections)
// This can be done here in the following ways:
// AWS.config.update({ httpOptions: { timeout: 0 } });
// AWS.config.httpOptions.timeout = 0;
// AWS.config.httpOptions = {
// timeout: 1200000 // or whatever
// }


export const s3 = new AWS.S3();

export default module.exports;
