import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Immutable from 'immutable';

const propTypes = {
  sender: PropTypes.instanceOf(Immutable.Map).isRequired,
};

const NotificationSenderName = ({ sender }) => (
  sender.get('type') === 'user' ? (
    <Link to={sender.get('slug')} className="mr-1 link bold">
      {sender.get('name')}
    </Link>
  ) : (
    <div className="mr-1 underline">{sender.get('name')}</div>
  )
);

NotificationSenderName.propTypes = propTypes;

export default NotificationSenderName;
