import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from 'material-ui/CircularProgress';
import MiniSearchDisplay from './MiniSearchDisplay';

const propTypes = {
  loading: PropTypes.bool,
};

const defaultProps = {
  loading: false,
};

class SearchStatePreserver extends Component {
  state = {
    hasPrevState: false,
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading && !this.state.hasPrevState) this.setState({ hasPrevState: true });
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.loading && this.state.hasPrevState) return false;
    return true;
  }

  renderLoading = () => (
    <div style={{ textAlign: 'center' }}>
      <CircularProgress size={150} thickness={10} />
    </div>
  );

  renderDisplay = () => (
    <MiniSearchDisplay {...this.props} />
  )

  render() {
    return (
      this.props.loading && !this.state.hasPrevState
      ? this.renderLoading()
      : this.renderDisplay()
    );
  }
}

SearchStatePreserver.propTypes = propTypes;

SearchStatePreserver.defaultProps = defaultProps;

export default SearchStatePreserver;
