import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Radium from 'radium';
import userActions from 'actions/users';
import * as authActions from 'actions/auth';
import { grid, align } from 'styles/flex';
import { getUserEmail } from 'rest/users';
import qs from 'query-string';
import pick from 'lodash/pick';
import PasswordForm from './PasswordForm';

const actionCreators = {
  ...userActions,
  ...authActions,
};

class Invites extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    signupFailure: PropTypes.func.isRequired,
    logIn: PropTypes.func.isRequired,
    registerUser: PropTypes.func.isRequired,
  };

  componentWillMount() {
    const {
      location: { search },
      history: { push },
      signupFailure,
    } = this.props;
    const query = qs.parse(search);
    const expired = new Date(parseInt(query.exp)); // eslint-disable-line
    const now = new Date();
    if (!query.token) window.location = '/';

    if (expired <= now) {
      push('/email/invites/error');
      signupFailure(
        'Your invite has expired. No stressing necessary! We have already sent you a new one.',
      );
    }
    getUserEmail({ email: query.email }).then((data) => {
      if (data.message === 'Someone has this email.') {
        push('/email/invites/error');
        signupFailure('A user with this email already exists.');
      }
    });
  }

  handleSubmit = (data) => {
    const { location: { search }, history: { push }, registerUser, logIn } = this.props;
    const query = qs.parse(search);
    const token = decodeURIComponent(
      Buffer.from(query.token, 'base64').toString('ascii'),
    );
    const user = Object.assign({}, data, query, { token });
    const authUser = pick(user, ['email', 'password']);


    registerUser(user)
      .then(() => logIn(authUser))
      .then(() =>  push('/'))
      .catch(() => push('/email/invites/expired'));
  };

  render() {
    return (
      <div style={{ textAlign: 'center' }}>
        <h2>Your account is almost created</h2>
        <div style={[grid.row, align.center]}>
          <PasswordForm onSubmit={this.handleSubmit} />
        </div>
      </div>
    );
  }
}

export default compose(
  connect(state => ({ users: state.users }), actionCreators),
  withRouter,
  Radium,
)(Invites);
