import React from 'react';
import PropTypes from 'prop-types';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { saveAs } from 'file-saver';
import { s3Bucket } from 'config';

const s3 = require('helpers/s3').s3;

const propTypes = {
  track: PropTypes.object.isRequired,
};

const mp3Click = (track) => {
  const params = {
    Bucket: s3Bucket,
    Key: `track/${track.fileUrl}/tagged.mp3`,
    Expires: 900, // this is the default - 15 minutes
    ResponseContentDisposition: `attachment;filename=${track.name}.mp3`,
  };

  s3.getSignedUrl('getObject', params, (err, url) => {
    window.location.href = url;
  });
};

const DownloadFree = ({ track }) => (
  <span
    id="download-free"
    className="naked-button pro-trkplayer-buttons fct"
    role="button"
    tabIndex={0}
    onClick={(e) => {
      e.stopPropagation();
      mp3Click(track);
    }}
  >
    <i className="fa fa-download is1 pro-trkplayer-icons" aria-hidden="true" />
    <span className="mobile-hide vab">Free Download</span>
  </span>
);

DownloadFree.propTypes = propTypes;

export default createFragmentContainer(
  DownloadFree,
  {track: graphql`
    fragment DownloadFree_track on Track {
      name
      fileUrl
    }
  `,}
);
