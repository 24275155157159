import React from "react";

const Privacy = () => (
  <div className="markdown">
    <h1 id="privacy-policy">Privacy Policy</h1>
    <h2 id="what-information-do-we-collect">What Information Do We Collect</h2>
    <p>
      The types of personal information we collect may include your name, user
      name, password, email address, photograph, purchase history, IP address,
      browser information, and any other information you provide in connection
      with your user account. This information is used to enhance and customize
      your experience. You can choose not to provide certain information, but
      then you may not be able to utilize some features on the Site.
    </p>
    <p>
      When you make purchases on the Site, you may need to provide financial
      account information, such as your credit card number, to our third party
      service provides, such as Stripe, and that information may be stored by
      such third-party providers. We do not receive your credit card information
      directly, and we do not store your credit card information on our servers.
    </p>
    <h2 id="cookies">Cookies</h2>
    <p>
      Cookies are small pieces of information sent by a web server to a web
      browser, which enables the server to collect information from the browser.
      We use cookies to recognize your browser, to tell us how and when pages on
      the Site are visited, to simplify your login process, and various other
      purposes to help improve your experience.
    </p>
    <p>
      Most browsers allow you to disable cookies. However, we recommend that you
      leave the cookies activated because cookies will enable you to use more of
      our features.
    </p>
    <h2 id="information-collected-automatically">
      Information Collected Automatically
    </h2>
    <p>
      When you interact with our service, we and our third-party partners, such
      as usage analytics providers, may receive and store certain types of
      information such as your IP address, cookie information, and other usage
      activities.
    </p>
    <h2 id="security-of-personal-information">
      Security of Personal Information
    </h2>
    <p>
      Plug Music diligently strives to protect all user information. However,
      unauthorized entry or use, hardware or software failure, and other
      factors, may compromise the security of user information at any time. We
      have protocols and security measures in place to severely mitigate this
      risk, however, we cannot guarantee the security of user information.
    </p>
    <h2 id="changes-to-our-privacy-policy">Changes to our Privacy Policy</h2>
    <p>
      From time to time, we may change our Privacy Policy. We will post those
      changes on this page so that our users will always be aware of what
      information we collect, how we use it, and under what circumstances we
      share the information with others.
    </p>
    <h2 id="sharing-personal-information">Sharing Personal Information</h2>
    <p>
      We understand your personal information is very important to you.
      Therefore, we will not rent nor sell your personal information to anyone.
    </p>
  </div>
);

export default Privacy;
