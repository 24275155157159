import React from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';

const propTypes = {
  rows: PropTypes.number.isRequired,
  marginBottom: PropTypes.number.isRequired,
  marginRight: PropTypes.number.isRequired,
  totalColumns: PropTypes.number.isRequired,
  lazyLoad: PropTypes.bool.isRequired,
  max: PropTypes.number.isRequired,
  lazyLoadedIndex: PropTypes.number.isRequired,
  columnPercentage: PropTypes.number.isRequired,
  children: PropTypes.array.isRequired,
};

const Slides = ({
  rows,
  marginBottom,
  marginRight,
  lazyLoad,
  max,
  lazyLoadedIndex,
  columnPercentage,
  totalColumns,
  children,
}) => {
  const renderSlideElement = (slideIndex, isLastElement) => {
    const hasLoaded = slideIndex < lazyLoadedIndex;
    const marginSpace = marginBottom * (rows - 1) / rows; //eslint-disable-line
    const elementHeight = `calc(${100 / rows}% - ${marginSpace}px)`;
    return !lazyLoad || hasLoaded ? (
      <div
        key={`slide${slideIndex}`}
        className="h100"
        style={{
          marginBottom: isLastElement || marginBottom,
          height: elementHeight,
        }}
      >
        {children[slideIndex]}
      </div>
    ) : (
      <div
        key={`slide${slideIndex}`}
        className="w-full h100"
        style={{
          width: `${columnPercentage}%`,
          height: elementHeight,
        }}
      />
    );
  };

  const gatherSlideElements = (columnIndex) => {
    const slideElements = [];
    const indexStartNumber = columnIndex * rows;
    let j;
    for (j = 0; j < rows; j++) {
      const slideIndex = indexStartNumber + j;
      const isLastElement = j === rows - 1;
      slideElements.push(renderSlideElement(slideIndex, isLastElement));
    }
    return slideElements;
  };

  const renderColumn = (columnIndex, isLastColumn) => (
    <div
      className="flex flex-col min-w-0 w-full h100"
      key={`column${columnIndex}`}
      style={{ marginRight: isLastColumn || marginRight }}
    >
      {gatherSlideElements(columnIndex)}
    </div>
  );

  const gatherColumns = () => {
    const columns = [];
    let i;
    for (i = 0; i < totalColumns; i += 1) {
      const isLastColumn = i === totalColumns - 1;
      columns.push(renderColumn(i, isLastColumn));
    }
    return columns;
  };

  return <div className="inline-flex w-full h100">{gatherColumns()}</div>;
};

Slides.propTypes = propTypes;

export default Slides;
