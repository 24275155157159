export const LOGIN_USER = 'LOGIN_USER';

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';

export const REFRESH_USER_REQUEST = 'REFRESH_USER_REQUEST';
export const REFRESH_USER_FAILURE = 'REFRESH_USER_FAILURE';
export const REFRESH_USER_SUCCESS = 'REFRESH_USER_SUCCESS';

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';

export const VALIDATE_USER_REQUEST = 'VALIDATE_USER_REQUEST';
export const VALIDATE_USER_SUCCESS = 'VALIDATE_USER_SUCCESS';
export const VALIDATE_USER_FAILURE = 'VALIDATE_USER_FAILURE';

export const ADD_USER_MESSAGE = 'ADD_USER_MESSAGE';

export const USERS_INITIALIZE = 'USERS_INITIALIZE';

export const REFRESH_USERS = 'REFRESH_USERS';
export const RESET_USERS = 'RESET_USERS';


export default module.exports;
