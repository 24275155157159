import React, { Component } from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';

class FeatureFlagProvider extends Component {
  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool,
    config: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    error: '',
    loading: false,
  };

  static childContextTypes = {
    error: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    config: PropTypes.object.isRequired,
  };

  getChildContext() {
    const { config, loading, error } = this.props;
    return { config, loading, error };
  }

  render() {
    return this.props.children;
  }
}

export default FeatureFlagProvider;
