export const loadStorage = (key) => {
  try {
    const serializedItem = localStorage.getItem(key);
    if (serializedItem === null) {
      return undefined;
    }
    return JSON.parse(serializedItem);
  } catch (err) {
    return undefined;
  }
};

export const saveStorage = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (err) {
    // Ignore save errors!
    console.error('error', err);
  }
};
