import React from 'react';
import PropTypes from 'prop-types';
import 'components/Explore/TopPicks/css/toppicks.css';
import LandingTopGenres from './LandingTopGenres';
import LandingTopMoods from './LandingTopMoods';

const propTypes = {
  genresByGenreCount: PropTypes.object.isRequired,
  moodsByMoodCount: PropTypes.object.isRequired,
};

const Gorilla = ({
  genresByGenreCount,
  moodsByMoodCount,
}) => (
  <div id="page-2" className="h-screen">
    <div
      className="w-full h-full pt-5 gorilla-bg md:pt-8"
    >
      <div className="h-full w-full md:flex md:w-desk md:pb-12">
        <div className="h-3/5 w-9/10 mx-auto md:h-full md:w-4/5 md:pr-6">
          <LandingTopGenres genres={genresByGenreCount} />
        </div>
        <div className="h-2/5 md:h-full md:w-1/5">
          <LandingTopMoods moods={moodsByMoodCount} />
        </div>
      </div>
    </div>
  </div>
);

Gorilla.propTypes = propTypes;

export default Gorilla;
