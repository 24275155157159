import React from 'react';
import PropTypes from 'prop-types';
import graphql from 'babel-plugin-relay/macro';
import createFragContainer from 'utils/create-frag-container';
import { compose } from 'redux';
import { connect } from 'react-redux';
import TrackDesktop from './TrackDesktop';
import TrackMobile from './TrackMobile';

const propTypes = {
  type: PropTypes.string.isRequired,
  track: PropTypes.object.isRequired,
  tracks: PropTypes.array.isRequired,
};

const trackPlayerFrag1 = graphql`
  fragment TrackPlayer_track on Track {
    ...TrackDesktop_track
    ...TrackMobile_track
  }
`;

const trackPlayerFrag2 = graphql`
  fragment TrackPlayer_user on User {
    ...TrackDesktop_user
    ...TrackMobile_user
  }
`;


const TrackPlayer = ({
  type,
  ...props
}) => {
  const desktop = type === 'desktop';
  return desktop ? <TrackDesktop {...props} /> : <TrackMobile {...props} />;
};

TrackPlayer.propTypes = propTypes;

export default compose(
  connect(state => ({ type: state.windowSession.type })),
  createFragContainer([trackPlayerFrag1, trackPlayerFrag2]),
)(TrackPlayer);
