import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import { onMobile, onDesktop } from 'utils/screentype';
import displayPrice from 'utils/display-price';

const propTypes = {
  track: PropTypes.object.isRequired,
  textClassName: PropTypes.string.isRequired,
  mainTextClassName: PropTypes.string.isRequired,
  subTextClassName: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

const textFrag = gql`
  fragment Text_track on Track {
    name
    publicId
    user: userByUserId {
      username
      displayName
    }
    products: productsByTrackId {
      edges {
        node {
          type
          price
        }
      }
    }
  }
`;

const Text = ({
  track: { name, publicId, user: { username, displayName }, products },
  textClassName,
  mainTextClassName,
  subTextClassName,
  history: { push },
}) => {
  const prices = products.edges.length
    ? products.edges.map(edge => edge.node.price)
    : [5000];
  const startPrice = prices.reduce(
    (price, max) => Math.min(price, max),
    prices[0],
  );
  const underlineHover = onDesktop('hover:underline');
  return (
    <div
      role="presentation"
      className={classnames('play-pause-text leading-none pt-1', textClassName)}
      onClick={onMobile(() => push(`/tracks/${publicId}`))}
    >
      <div
        role="presentation"
        className={classnames(
          'fm pb-1 truncate pointer play-pause-track-text leading-tight block',
          underlineHover,
          mainTextClassName,
        )}
        // onclick is done this way to prevent div from highlighting on click on mobile
        onClick={onDesktop(() => push(`/tracks/${publicId}`))}
      >
        {name}
      </div>
      <div
        role="presentation"
        className={classnames(
          'fm truncate pointer play-pause-artist-text pb-1 block',
          underlineHover,
          subTextClassName,
        )}
        onClick={onDesktop(() => push(`/${username}`))}
      >
        {displayName}
      </div>
      <div
        role="presentation"
        className={classnames(
          'font-semibold hover:text-white text-xs italic',
          underlineHover,
          subTextClassName,
        )}
        onClick={onDesktop(() => push(`/tracks/${publicId}`))}
      >
        From {displayPrice(startPrice)}
      </div>
    </div>
  );
};

Text.propTypes = propTypes;
Text.fragments = {
  track: textFrag,
};

export default withRouter(Text);
