import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { avatarImageUrl } from 'config';
import ImageDropzone from './ImageDropzone';
import '../ProfileBanner.css';

const propTypes = {
  user: PropTypes.object.isRequired,
  s3Upload: PropTypes.func.isRequired,
  s3Context: PropTypes.object.isRequired,
  s3AddMetadata: PropTypes.func.isRequired,
  myself: PropTypes.bool.isRequired,
  image: PropTypes.string,
};

const defaultProps = {
  image: null,
};

const navbarHeight = 48;

const PROFILE_BANNER_FRAG = gql`
  fragment ProfileBannerDesktop_user on User {
    id
    displayName
  }
`;

const UPDATE_USER_MUTATION = gql`
  mutation UpdateUserByIdMutation($input: UpdateUserByIdInput!) {
    updateUserById(input: $input) {
      user {
        id
        username
        displayName
        description
      }
    }
  }
`;

const ProfileBannerDesktop = ({
  user,
  s3Upload,
  s3Context,
  s3AddMetadata,
  image,
  myself,
}) => {
  const minHeight = `${130 + navbarHeight}px`;
  const height = `${280 + navbarHeight}px`;
  return (
    <Mutation mutation={UPDATE_USER_MUTATION}>
      {updateUser => (
        <div>
          <section
            className="h-1/4 sm:h-1/3 banner-profile-default flex bg-cover bg-center absolute w-full"
            style={{ backgroundImage: image, minHeight, height }}
          />
          <div
            className="relative mx-auto h-1/4 sm:h-1/3 max-w-full-desk w-9/10 md:w-full md:pl-2"
            style={{ minHeight, height }}
          >
            <div
              className="relative banner-content-desktop"
              style={{ minHeight, height }}
            >
              <div className="inline-block leading-none">
                {image || !myself ? (
                  <div>
                    <div
                      className="banner-avatar bg-cover bg-center"
                      style={{
                        backgroundImage:
                          image || `${avatarImageUrl}placeholder.png`,
                      }}
                      alt="User Avatar"
                    />
                    <div className="img_description_layer">
                      <p className="img_description">
                        Change Avatar in Settings
                      </p>
                    </div>
                  </div>
                ) : (
                  <ImageDropzone
                    onUpdateUser={update =>
                      updateUser({
                        variables: {
                          input: { userPatch: update, id: user.id },
                        },
                      })
                    }
                    s3Upload={s3Upload}
                    s3Context={s3Context}
                    s3AddMetadata={s3AddMetadata}
                  />
                )}
              </div>
              <div className="banner-name">{user.displayName}</div>
            </div>
          </div>
        </div>
      )}
    </Mutation>
  );
};

ProfileBannerDesktop.propTypes = propTypes;
ProfileBannerDesktop.defaultProps = defaultProps;
ProfileBannerDesktop.fragments = { user: PROFILE_BANNER_FRAG };

export default ProfileBannerDesktop;
