// Autoplay is enabled by default, and as of now there is no way the user can
// disable it. Leaving in for the sake of maybe-in-the-future-we-might-want-it

const initialState = {
  playing: false,
  visible: true,
  currentTrack: {},
  tracks: [],
  currentTrackIndex: 0,
  repeat: false,
  shuffle: false,
  autoplay: true,
  progress: 0,
  seeking: false,
  waiting: false,
  stalled: false,
  duration: 0,
  seekPosition: 0,
  update: false,
  updatedTime: 0,
  volume: 1,
  mute: false
};

function shuffle(array) {
  let m = array.length;
  let t;
  let i;
  const newArray = Object.assign([], array);
  while (m) {
    i = Math.floor(Math.random() * (m -= 1));
    t = newArray[m];
    newArray[m] = newArray[i];
    newArray[i] = t;
  }

  return newArray;
}

/* eslint no-param-reassign: ["error", { "props": false }] */
const objectRemoveKeys = (object, keys) =>
  Object.keys(object).reduce((result, key) => {
    if (!keys.includes(key)) {
      result[key] = object[key];
    }
    return result;
  }, {});

export default function soundbar(state = initialState, action) {
  switch (action.type) {
    // Sets the progress (in float percentage) of the current track
    case "SOUNDBAR_SET_PROGRESS":
      // Progress can be NaN in some cases: track not loaded, switching tracks
      if (isNaN(action.progress)) {
        return Object.assign({}, state, {
          progress: 0
        });
      }
      return Object.assign({}, state, {
        progress: action.progress
      });

    // Sets the currently-playing track to a track object
    case "SOUNDBAR_SET_TRACK":
      return Object.assign({}, state, {
        currentTrack: action.track,
        currentTrackIndex: state.tracks.findIndex(
          track => track === action.track
        ),
        playing: false,
        progress: action.progress || 0
      });
    case "SOUNDBAR_SET_VISIBLE":
      return Object.assign({}, state, {
        visible: action.visible
      });

    case "SOUNDBAR_SET_DURATION":
      return Object.assign({}, state, {
        duration: action.duration
      });

    case "SOUNDBAR_SET_PLAYING":
      return Object.assign({}, state, {
        playing: action.playing
      });
    case "SOUNDBAR_UPDATE_TIME":
      return Object.assign({}, state, {
        update: action.update,
        updatedTime: action.updatedTime || state.updatedTime
      });
    case "SOUNDBAR_SET_SEEKPOSITION":
      return Object.assign({}, state, {
        seekPosition: action.seekPosition
      });
    case "SOUNDBAR_UPDATE": {
      const keys = Object.keys(action.payload);
      const newState = keys.reduce((acc, curr) => {
        acc[curr] = action.payload[curr];
        return acc;
      }, {});
      return { ...state, ...newState };
    }

    case "SOUNDBAR_SET_SEEKING":
      return Object.assign({}, state, {
        seeking: action.seeking,
        seekPosition: action.seekPosition || state.seekPosition
      });
    case "SOUNDBAR_SET_STALLED":
      return Object.assign({}, state, {
        stalled: action.stalled
      });
    case "SOUNDBAR_SET_WAITING":
      return Object.assign({}, state, {
        waiting: action.waiting
      });
    // Adds a track or tracks to the list of tracks tracked by the redux store
    // and sets the current track to the first in that list, if we didn't have
    // one before.
    // Note that our track object looks like this:
    // {id, name, picture, fileUrl, description, userId, userDisplayName}
    // if we have any of these missing, we'll run into trouble
    case "SOUNDBAR_ADD_TRACKS":
      if (action.tracks) {
        // We must have the following information to render the soundbar properly
        // 'description' isn't used right now but hey we might need it later
        const requiredKeys = [
          "id",
          "name",
          "picture",
          "fileUrl",
          "publicId",
          "username",
          // 'description',
          "userId",
          "userDisplayName"
        ];

        const tracksAreAllCool = action.tracks.every(track =>
          requiredKeys.every(item =>
            Object.prototype.hasOwnProperty.call(track, item)
          )
        );

        if (!tracksAreAllCool) {
          console.warn(
            "Improper track object(s) supplied to Soundbar. Was expecting array of objects with keys ",
            requiredKeys,
            " but got ",
            action.tracks
          );
        }
      }

      console.log('before currents', state.currentTrack);

      console.log(
        "currentTrack",
        Object.keys(state.currentTrack).length
          ? state.currentTrack
          : action.tracks[0]
      );

      console.log(
        "whatever this does",
        Array.from(
          [...state.tracks, ...action.tracks]
            .reduce((m, t) => m.set(t.id, t), new Map())
            .values()
        )
      );

      return Object.assign({}, state, {
        tracks: Array.from(
          [...state.tracks, ...action.tracks]
            .reduce((m, t) => m.set(t.id, t), new Map())
            .values()
        ),
        currentTrack: Object.keys(state.currentTrack).length
          ? state.currentTrack
          : action.tracks[0],
        currentTrackIndex:
          [...state.tracks, ...action.tracks].length > 1
            ? state.currentTrackIndex + 1
            : 0
      });
    case "SOUNDBAR_REMOVE_TRACKS": {
      if (action.trackIds) {
        return state;
      }
      const newTrackList = state.tracks.filter(
        track => !action.trackIds.includes(track.id)
      );
      return Object.assign({}, state, {
        tracks: newTrackList,
        currentTrack: action.trackIds.includes(state.currentTrack.id)
          ? newTrackList.length
            ? newTrackList[0]
            : {}
          : state.currentTrack,
        currentTrackIndex: action.trackIds.includes(state.currentTrack.id)
          ? newTrackList.length
            ? 0
            : 0
          : newTrackList.indexOf(state.currentTrack)
      });
    }
    case "SOUNDBAR_REMOVE_ALL_TRACKS":
      return state.tracks.length
        ? Object.assign({}, state, {
            tracks: action.includingCurrent ? [] : [state.currentTrack],
            currentTrack: action.includingCurrent ? {} : state.currentTrack,
            currentTrackIndex: 0
          })
        : state;
    case "SOUNDBAR_SET_CURRENT_TRACK_INDEX":
      return Object.assign({}, state, {
        currentTrackIndex: action.trackIndex
      });
    // Increments the currentTrackIndex if possible and updates the currentTrack
    case "SOUNDBAR_NEXT_TRACK":
      return Object.assign({}, state, {
        currentTrackIndex:
          state.currentTrackIndex < state.tracks.length - 1
            ? state.currentTrackIndex + 1
            : state.currentTrackIndex,
        currentTrack:
          state.currentTrackIndex < state.tracks.length - 1
            ? state.tracks[state.currentTrackIndex + 1]
            : state.currentTrack,
        progress: 0
      });
    // Decrements the currentTrackIndex if possible and updates the currentTrack
    case "SOUNDBAR_PREVIOUS_TRACK":
      return Object.assign({}, state, {
        currentTrackIndex:
          state.currentTrackIndex > 0
            ? state.currentTrackIndex - 1
            : state.currentTrackIndex,
        currentTrack:
          state.currentTrackIndex > 0
            ? state.tracks[state.currentTrackIndex - 1]
            : state.currentTrack,
        progress: 0
      });
    case "SOUNDBAR_TOGGLE_REPEAT":
      return Object.assign({}, state, {
        repeat: !state.repeat
      });
    case "SOUNDBAR_SHUFFLE_PLAYLIST":
      return Object.assign({}, state, {
        shuffle: !state.shuffle,
        tracks: Object.assign(
          [],
          [
            state.tracks[state.currentTrackIndex],
            ...shuffle(
              state.tracks.filter(
                (track, index) => index !== state.currentTrackIndex
              )
            )
          ]
        ),
        currentTrackIndex: 0
      });
    case "SOUNDBAR_TOGGLE_AUTOPLAY":
      return Object.assign({}, state, {
        autoplay: !state.autoplay
      });

    default:
      return state;
  }
}
