import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  dropzoneRefClick: PropTypes.func.isRequired,
  dropzoneRejectedFiles: PropTypes.array.isRequired,
  customErrorMessage: PropTypes.string,
  errors: PropTypes.array,
};

const defaultProps = {
  dropzoneRefClick: () => {},
  dropzoneRejectedFiles: [],
  customErrorMessage: null,
  errors: [],
};

const styles = {
  dragBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '14px',
    lineHeight: '1',
  },
  rejectedFiles: {
    width: '90%',
    marginTop: '-16px',
    marginBottom: '16px',
    marginRight: 'auto',
    marginLeft: 'auto',
    backgroundColor: '#ffbab6',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '4px',
  },
};

const MusicUpload = ({
  dropzoneRefClick,
  dropzoneRejectedFiles,
  customErrorMessage,
  errors,
}) => (
  <div>
    <div className="w-desk mt-3">
      <div className="pcont">
        <div style={{ minWidth: '300px' }}>
          <div>
            <div className="upload-title">Upload A Beat</div>
            {dropzoneRejectedFiles.length ? (
              <div style={styles.rejectedFiles}>
                <div className="fm fs2p" style={{ alignSelf: 'center' }}>
                  Please drop only MP3 or WAV files. You can also drop a ZIP
                  containing those files.
                </div>
              </div>
            ) : null}
            {customErrorMessage ? (
              <div style={styles.rejectedFiles}>
                <div className="fm fs2p" style={{ alignSelf: 'center' }}>
                  {customErrorMessage}
                </div>
              </div>
            ) : null}
            {errors.length // Cancel all current uploads if something goes wrong
              ? errors.map(file => (
                <div style={styles.rejectedFiles}>
                  <div className="fm fs2p" style={{ alignSelf: 'center' }}>
                    {`There was an error uploading a file: ${
                        file.error.message
                      }`}
                  </div>
                </div>
                ))
              : null}
            <div
              style={{
                width: '90%',
                border: '1px dashed #d2d2d2',
                margin: '0 auto',
                textAlign: 'center',
                height: '330px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <i
                className="fa fa-cloud-upload"
                aria-hidden="true"
                style={{
                  fontSize: '90px',
                  display: 'block',
                  color: 'rgb(191, 196, 202)',
                }}
              />
              <div
                role="button"
                tabIndex={0}
                className="font-semibold"
                style={{
                  display: 'inline-block',
                  textAlign: 'center',
                  border: '1px solid',
                  margin: '0 auto',
                  backgroundColor: '#0b749e',
                  color: 'white',
                  padding: '10px 20px',
                  borderRadius: '3px',
                  cursor: 'pointer',
                }}
                onClick={() => dropzoneRefClick()}
              >
                Choose files to upload
              </div>
              <div
                style={{
                  color: '#6b6b6b',
                  marginTop: '8px',
                }}
              >
                or drag and drop them here
              </div>
            </div>
            <div
              style={{
                marginLeft: '5%',
                marginRight: '5%',
                marginTop: '66px',
                marginBottom: '20px',
                fontSize: '14px',
                lineHeight: 1.3,
                color: '#404040',
                textAlign: 'initial',
              }}
            >
              <div>
                What kind of files can I upload? You can upload Wav, MP3, or
                ZIPs containing those files.
              </div>
              <div style={{ marginTop: '10px' }}>
                <b style={{ color: '#080808' }}>Important:</b> By sharing, you
                confirm that your sounds comply with our Terms of use and you
                don’t infringe anyone else’s rights. If in doubt, check our
                Copyright information pages and FAQs before uploading.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

MusicUpload.propTypes = propTypes;
MusicUpload.defaultProps = defaultProps;

export default MusicUpload;
