import React from 'react';
// import aboutMarkdown from '../../markdown/about.html';

const About = () => (
  <div />
  // <div
  //   className="markdown"
  //   dangerouslySetInnerHTML={{ __html: aboutMarkdown }}
  // />
);

export default About;
