import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { trackUrl, avatarImageUrl } from 'config';
import Paper from 'material-ui/Paper';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import PlugImage from 'components/common/PlugImage';
import * as soundbarActions from 'actions/soundbar';
import displayTrackImage from 'utils/display-track-image';

const propTypes = {
  tracks: PropTypes.array.isRequired,
  soundbarSetTrack: PropTypes.func.isRequired,
  soundbarSetPlaying: PropTypes.func.isRequired,
  currentTrackIndex: PropTypes.number.isRequired,
};

const Playlist = ({ tracks, soundbarSetTrack, soundbarSetPlaying, currentTrackIndex }) => (
  <Paper>
    <Menu
      // Note: TouchTap stuff has been broken for some time, for some reason
      // Upgrading to the newer '@material-ui/core/Menu' requires some rework, so
      // we're just sticking some logic in the <MenuItem> instead
      //
      // onItemTouchTap={(event, menuItem, index) => {
      //   soundbarSetTrack(tracks[index], true);
      // }}
      listStyle={{ paddingBottom: 0, paddingTop: 0 }}
    >
      {console.log('tracks', tracks)}
      {tracks.map((track, index) => (
        <MenuItem
          key={track.id}
          onClick={() => {
            soundbarSetTrack(track);
            soundbarSetPlaying(true);
          }}
          primaryText={`${track.name} - ${track.userDisplayName}`}
          style={
            index === currentTrackIndex ? { backgroundColor: '#b7c6c6' } : null
          }
          leftIcon={
            track.picture && (
              // The image height/width needs to be specified here (24x24 by default on the MenuItem)
              // because the MaterialUI component will break if the component needs to update, for
              // example when pulling a fallback image or placeholder
              <PlugImage
                imageSource={displayTrackImage(trackUrl, track.fileUrl)}
                placeholder={`${avatarImageUrl}placeholder.png`}
                imageStyle={{
                  objectFit: 'cover',
                  height: 24,
                  width: 24,
                }}
                style={{
                  marginTop: 6,
                }}
                alt="Track"
              />
            )
          }
        />
      ))}
    </Menu>
  </Paper>
);
Playlist.propTypes = propTypes;

export default connect(
  state => ({
    tracks: state.soundbar.tracks,
    currentTrackIndex: state.soundbar.currentTrackIndex,
  }),
  soundbarActions,
)(Playlist);
