/* eslint-disable */

let r;
let g;
let b;
let a;

export default (red, green, blue, alpha) => {
  [r, g, b, a] = [red, green, blue, alpha];
  const isPercent = (r + (a || '')).toString().includes('%');

  if (typeof red === 'string') {
    const res = red.match(/(0?\.?\d{1,3})%?\b/g).map(Number);
    [r, g, b, a] = res;
  } else if (a !== undefined) {
    a = parseFloat(a);
  }

  if (
    typeof r !== 'number' ||
    typeof g !== 'number' ||
    typeof b !== 'number' ||
    r > 255 ||
    g > 255 ||
    b > 255
  ) {
    throw new TypeError('Expected three numbers below 256');
  }

  if (typeof a === 'number') {
    if (!isPercent && a >= 0 && a <= 1) {
      a = Math.round(255 * a);
    } else if (isPercent && a >= 0 && a <= 100) {
      a = Math.round(255 * (a / 100));
    } else {
      throw new TypeError(
        `Expected alpha value (${a}) as a fraction or percentage`,
      );
    }
    a = (a | (1 << 8)).toString(16).slice(1);
  } else {
    a = '';
  }

  return `#${(b | (g << 8) | (r << 16) | (1 << 24)).toString(16).slice(1) + a}`;
};
