/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from 'relay-runtime';
declare export opaque type TrackForm_allGenres$ref: FragmentReference;
export type TrackForm_allGenres = {|
  +edges: $ReadOnlyArray<{|
    +node: ?{|
      +genreId: number,
      +genre: string,
    |},
  |}>,
  +$refType: TrackForm_allGenres$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "TrackForm_allGenres",
  "type": "GenresConnection",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "GenresEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Genre",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": "genreId",
              "name": "id",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "genre",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
(node/*: any*/).hash = '2a06157e67e1f5d33133c965f1d1fa20';
module.exports = node;
