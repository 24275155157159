import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { avatarImageUrl } from 'config';
import displayImage from 'utils/display-image';
import { displayTime } from 'utils/date';
import PlugImage from 'components/common/PlugImage';
import DeleteCommentByIdMutation from 'mutations/comment/DeleteCommentByIdMutation';

const propTypes = {
  comment: PropTypes.object.isRequired,
  relay: PropTypes.object.isRequired,
  deleteable: PropTypes.bool,
};

const defaultProps = {
  deleteable: false,
};

const Comment = ({ comment, deleteable, relay: { environment } }) => {
  const { author, createdAt, body } = comment;
  const handleDelete = () => {
    const { id } = comment;
    DeleteCommentByIdMutation(environment, { id });
  };

  return (
    <li className="mb-4">
      <article className="flex items-center">
        <figure>
          <Link
            to={`/${author.username}`}
            className="block sat-contrast-hover"
            style={{ width: '64px', height: '64px' }}
          >
            <PlugImage
              imageSource={displayImage(avatarImageUrl, author.picture)}
              className="rounded-full cover h-full"
              style={{ width: '64px', height: '64px' }}
              alt="this is fake"
            />
            {/* <img
              className="rounded-full cover h-full"
              alt="this is fake"
              src={displayImage(avatarImageUrl, author.picture)}
            /> */}
          </Link>
        </figure>
        <div className="ml-3 flex-grow flex-shrink">
          <div className="flex-row justify-between w-9/10">
            <Link className="font-semibold" to={`/${author.username}`}>
              {author.displayName}
            </Link>{' '}
            <small className="hidden lg:block">{displayTime(createdAt)}</small>
          </div>
          <div>{body}</div>
        </div>
        {deleteable && (
          <div className="pin-r self-start">
            <button className="delete" onClick={handleDelete} />
          </div>
        )}
      </article>
    </li>
  );
};

Comment.propTypes = propTypes;
Comment.defaultProps = defaultProps;

export default createFragmentContainer(Comment, {
  comment: graphql`
    fragment Comment_comment on Comment {
      id
      body
      createdAt
      author: userByUserId {
        username
        displayName
        picture
      }
    }
  `,
});
