import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  iconStyle: PropTypes.object,
  next: PropTypes.func.isRequired,
  canPlayNext: PropTypes.bool.isRequired,
};

const defaultProps = {
  iconStyle: {},
};

const Next = ({ iconStyle, canPlayNext, next }) => (
  <a
    role="button"
    className="outline-none flex"
    tabIndex={0}
    onClick={next}
    disabled={!canPlayNext}
  >
    <i
      className="material-icons text-gray-darkest text-3xl self-center pr-0"
      style={iconStyle}
    >
        skip_next
      </i>
  </a>
  );

Next.propTypes = propTypes;
Next.defaultProps = defaultProps;

export default Next;
