import React from "react";
import { connect } from "react-redux";
import graphql from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import Soundbar from "./Soundbar";

const propTypes = {
  auth: PropTypes.object.isRequired,
  currentTrack: PropTypes.object,
  follow: PropTypes.object,
  location: PropTypes.object.isRequired,
};

const defaultProps = {
  currentTrack: {},
  follow: {},
};

const SoundbarContainer = ({ currentTrack, auth, location }) => {
  if (!currentTrack) return null;
  const trackUserId = currentTrack.userId || "";
  const currentUser = auth.userId || "";
  const environment = auth.environment;
  if (!currentTrack.userId) return null;

  return (
    <Soundbar auth={auth} follow={null} location={location} />

    // <QueryRenderer
    //   environment={environment}
    //   query={graphql`
    //     query SoundbarContainerQuery($trackUserId: UUID!, $currentUser: UUID!) {
    //       follow: followByFollowerIdAndFollowedId(
    //         followerId: $currentUser
    //         followedId: $trackUserId
    //       ) {
    //         nodeId
    //       }
    //     }
    //   `}
    //   variables={{
    //     trackUserId,
    //     currentUser,
    //   }}
    //   render={({ error, props }) => {
    //     if (props) {
    //       return (
    //         <Soundbar auth={auth} follow={props.follow} location={location} />
    //       );
    //     } else if (error) {
    //       return <div>{error.message}</div>;
    //     }
    //     return <Soundbar auth={auth} follow={null} location={location} />;
    //   }}
    // />
  );
};

SoundbarContainer.propTypes = propTypes;
SoundbarContainer.defaultProps = defaultProps;

export default compose(
  connect((state) => ({
    currentTrack: state.soundbar.currentTrack,
    auth: state.auth,
  })),
  withRouter
)(SoundbarContainer);
