import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  iconStyle: PropTypes.object,
  previous: PropTypes.func.isRequired,
  canPlayPrevious: PropTypes.bool.isRequired,
};

const defaultProps = {
  iconStyle: {},
};

const Previous = ({ iconStyle, previous, canPlayPrevious }) => (
  <a
    role="button"
    className="flex outline-none"
    tabIndex={0}
    onClick={previous}
    disabled={!canPlayPrevious}
  >
    <i
      className="material-icons music-icon self-center text-gray-darkest text-3xl"
      style={iconStyle}
    >
      skip_previous
    </i>
  </a>
);

Previous.propTypes = propTypes;
Previous.defaultProps = defaultProps;

export default Previous;
