import React from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import get from 'lodash/get';

const propTypes = {
  name: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
};

const contextTypes = {
  config: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
};

const FeatureFlag = ({ name, render, ...otherProps }, { config, loading, error }) => {
  const condition = get(config, name);
  return render({ condition, loading, error, ...otherProps });
};

FeatureFlag.propTypes = propTypes;
FeatureFlag.contextTypes = contextTypes;

export default FeatureFlag;
