import { TOASTS_SHOW_MESSAGE, TOASTS_DISMISS_MESSAGE } from 'types/toasts';
import uuidV4 from 'uuid/v4';

export const addMessage = message => ({
  type: TOASTS_SHOW_MESSAGE,
  payload: message,
});

export const dismissMessage = messageId => ({
  type: TOASTS_DISMISS_MESSAGE,
  payload: messageId,
});

export const showMessage = message => (dispatch) => {
  const newMessage = { ...message, createdAt: new Date(), id: uuidV4() };
  dispatch(addMessage(newMessage));

  const dismissAfter = message.dismissAfter || 3000;
  setTimeout(() => dispatch(dismissMessage(newMessage.id)), dismissAfter);
};
