import {
  ADD_CLIP_SUCCESS,
  ADD_CLIP_FAILURE,
  DELETE_CLIPS_BY_TRACK,
  DELETE_CLIP_SUCCESS,
  DELETE_CLIP_FAILURE,
  CHANGE_UPLOAD_STATUS,
  TRASH_CLIP_CLIENT,
  ADD_CLIP_CLIENT,
  UPDATE_CLIP_SUCCESS,
  UPDATE_CLIP_FAILURE,
  ADD_RECORDED_CLIP,
  UPDATE_DELAY_TIME,
  UPDATE_CLIP_LENGTH,
  SPLIT_CLIPS,
  MOVE_TO_TRACK,
  UPLOAD_CLIP,
  PLAY,
  PAUSE,
  STOP,
  SEEK,
  SETUP_AUDIOCTX,
  UPDATE_LOOP_REGION,
  CHANGE_LOOP,
  COPY_CLIP,
  PASTE_CLIP,
  RESET_CLIPS,
  TOGGLE_RECORD,
} from 'types/clips';
import {
  fetchClipsApi,
  addClipApi,
  deleteClipApi,
  updateClipApi,
} from 'rest/clips';
import webAudio from 'helpers/webAudioHelper';
import { requestWrapper } from './helper';

export const fetchClipsAsync = requestWrapper('FETCH_CLIPS', fetchClipsApi);

export function addClipSuccess(payload) {
  return {
    type: ADD_CLIP_SUCCESS,
    payload,
  };
}

export function addClipFailure(payload) {
  return {
    type: ADD_CLIP_FAILURE,
    payload,
  };
}

export function deleteClipsByTrack(payload) {
  return {
    type: DELETE_CLIPS_BY_TRACK,
    payload,
  };
}

export const addClipAsync = clip =>
  requestWrapper('ADD_CLIP', addClipApi)(
    Object.assign({}, clip, { gainNode: null, image: null }),
  );

export function deleteClipSuccess(payload) {
  return {
    type: DELETE_CLIP_SUCCESS,
    payload,
  };
}

export function deleteClipFailure(payload) {
  return {
    type: DELETE_CLIP_FAILURE,
    payload,
  };
}

export function changeUploadStatus(id, currentlyUploading, uploadSuccess) {
  return {
    type: CHANGE_UPLOAD_STATUS,
    payload: {
      id,
      currentlyUploading,
      uploadSuccess,
    },
  };
}

export const deleteClipAsync = requestWrapper('DELETE_CLIP', deleteClipApi);

export function trashClipClient(clipIds) {
  return {
    type: TRASH_CLIP_CLIENT,
    payload: {
      clipIds,
    },
  };
}

export function addClipClient(clipId, name, trackId) {
  return {
    type: ADD_CLIP_CLIENT,
    payload: {
      clipId,
      name,
      trackId,
    },
  };
}

export function updateClipSuccess(payload) {
  return {
    type: UPDATE_CLIP_SUCCESS,
    payload,
  };
}

export function updateClipFailure(payload) {
  return {
    type: UPDATE_CLIP_FAILURE,
    payload,
  };
}

export const updateClipAsync = clip =>
  requestWrapper('UPDATE_CLIP', updateClipApi)(
    Object.assign({}, clip, { gainNode: null, image: null }),
  );

export function addRecordedClip(recordedData, canvas) {
  return {
    type: ADD_RECORDED_CLIP,
    payload: {
      recordedData,
      canvas,
    },
  };
}

export function updateDelayTime(id, delayTime) {
  return {
    type: UPDATE_DELAY_TIME,
    payload: {
      id,
      property: 'delayTime',
      value: delayTime,
    },
  };
}

export function updateClipLength(id, length) {
  return {
    type: UPDATE_CLIP_LENGTH,
    payload: {
      id,
      property: 'length',
      value: length,
    },
  };
}

export function splitClips(clip, split) {
  return {
    type: SPLIT_CLIPS,
    payload: {
      clip,
      splitClips: split,
    },
  };
}

export function moveToTrack(id, trackId, volume) {
  return {
    type: MOVE_TO_TRACK,
    payload: {
      id,
      trackId,
      volume,
    },
  };
}


export function createSource(trackBuffer) {
  return webAudio.createSource(trackBuffer); // eslint-disable-line
}

export function sendClip(
  decodedId,
  clipId,
  decodedData,
  volume,
  delay,
  canvas,
) {
  return {
    type: UPLOAD_CLIP,
    payload: {
      decodedId,
      id: clipId,
      decodedData,
      volume,
      delay,
      canvas,
    },
  };
}

export function uploadClip(
  decodedId,
  clipId,
  trackBuffer,
  volume,
  delay,
  canvas,
) {
  return (dispatch) => {
    createSource(trackBuffer).then(decodedBuffer =>
      dispatch(
        sendClip(decodedId, clipId, decodedBuffer, volume, delay, canvas),
      ),
    );
  };
}

export function toggleRecord(isRecording) {
  return (dispatch) => {
    if (isRecording) {
      webAudio // eslint-disable-line
        .openStream()
        .then(() => dispatch(sendRecord(isRecording))); // eslint-disable-line
    } else dispatch(sendRecord(isRecording)); // eslint-disable-line
  };
}

function sendRecord(isRecording) {
  return {
    type: TOGGLE_RECORD,
    payload: {
      isRecording,
    },
  };
}


export function play(payload) {
  return {
    type: PLAY,
    payload,
  };
}

export function pause(payload) {
  return {
    type: PAUSE,
    payload,
  };
}

export function stop(payload) {
  return {
    type: STOP,
    payload,
  };
}

export function seek(position, isPlaying) {
  return (dispatch) => {
    dispatch({
      type: SEEK,
      payload: {
        position,
      },
    });

    if (isPlaying) dispatch(play());
  };
}

export function setupAudioCtx(payload) {
  return {
    type: SETUP_AUDIOCTX,
    payload,
  };
}

export function updateLoopRegion(loopRegion) {
  return {
    type: UPDATE_LOOP_REGION,
    payload: {
      property: 'loopRegion',
      value: loopRegion,
    },
  };
}

export function changeLoop(loop) {
  return {
    type: CHANGE_LOOP,
    payload: {
      property: 'loop',
      value: loop,
    },
  };
}

export function copyClip(clipId, length, delayTime, status) {
  return {
    type: COPY_CLIP,
    payload: {
      id: clipId,
      length,
      delayTime,
      status,
    },
  };
}

export function pasteClip(trackId, volume, delayTime, canvas) {
  return {
    type: PASTE_CLIP,
    payload: {
      trackId,
      volume,
      delayTime,
      uniqueValue: Date.now(),
      canvas,
    },
  };
}

export const resetClips = () => ({ type: RESET_CLIPS });

export default module.exports;
