import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import displayTrackImage from 'utils/display-track-image';
import Tile from 'components/common/ApolloImagePlay/Tile';
import LikesLink from 'components/common/LikesLink';
import CommentsSection from './CommentsSection';
import BuyForm from './BuyForm';

const propTypes = {
  track: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const trackFrag = gql`
  fragment Track_track on Track {
    id
    name
    description
    fileUrl
    picture
    publicId
    dominantcolor
    alternativecolor
    overlaycolor
    user: userByUserId {
      id
      username
      displayName
    }
    ...BuyForm_track
    ...CommentsSection_track
    ...LikesLink_track
  }
  ${BuyForm.fragments.track}
  ${CommentsSection.fragments.track}
  ${LikesLink.fragments.track}
`;

const CREATE_COMMENT = gql`
  mutation CreateComment($input: CreateCommentInput!) {
    createComment(input: $input) {
      track: trackByTrackId {
        ...Track_track
      }
    }
  }
  ${trackFrag}
`;

const Track = ({ track, auth: { userId, isAuthenticated } }) => {
  const mine = userId === track.user.id;
  return (
    <Mutation
      mutation={CREATE_COMMENT}
    >
      {createComment => (
        <Fragment>
          <div
            className="relative bg-white mb-3 border-b"
            style={{ borderColor: '#bdbdbd' }}
          >
            <div
              className="absolute h-full w-full overflow-hidden"
              style={{ opacity: 0.2 }}
            >
              <img
                className="absolute transform-xy-center w-full h-full cover"
                style={{ filter: 'grayscale(100%)' }}
                src={displayTrackImage(track.fileUrl, track.picture)}
                alt="Background"
              />
            </div>
            <div className="flex relative z-10 pt-16 pb-8 w-9/10 mx-auto md:w-desk">
              <div className="mr-3" style={{ height: '128px', width: '128px' }}>
                <Tile
                  track={track}
                  tracks={[track]}
                  hasText={false}
                  imageClassName=""
                  className="w-full"
                  circlePlayIcon={false}
                  showPlayByDefault
                />
              </div>
              <div className="items-center">
                <p className="text-black font-semibold">{track.name}</p>
                <Link
                  className="block font-semibold pt-1 text-gray-darker text-sm"
                  to={`/${track.user.username}`}
                >
                  {track.user.displayName}
                </Link>
                <LikesLink
                  track={track}
                  userId={userId}
                  className="pt-4"
                  iconClassName="text-base mr-1"
                  disabled={!isAuthenticated}
                />
              </div>
            </div>
          </div>
          {!mine && <BuyForm track={filter(BuyForm.fragments.track, track)} />}
          <CommentsSection
            track={filter(CommentsSection.fragments.track, track)}
            disabled={!isAuthenticated}
            userId={userId}
            mine={mine}
            onCreateComment={data => createComment({
              variables: {
                input: {
                  comment: { userId, trackId: track.id, body: data.comment },
                },
              },
            })}
          />
        </Fragment>
      )}
    </Mutation>
  );
};

Track.propTypes = propTypes;
Track.fragments = {
  track: trackFrag,
};

export default compose(
  connect(({ auth }) => ({ auth })),
  withRouter,
)(Track);
