import get from 'lodash/get';
import objectToMap from 'utils/object-to-map';
import { reset } from 'redux-form';
import CreateTrackMutation from 'mutations/track/CreateTrackMutation';
import CreateHashtagMutation from 'mutations/hashtag/CreateHashtagMutation';
import CreateProductMutation from 'mutations/product/CreateProductMutation';
import { s3Bucket } from 'config';

const S3 = require('aws-sdk/clients/s3');

// S3 Parameters - these live in a separate file. Import them.
// Note that we disable the timeout here! Make sure new params do this as well
const s3 = new S3({
  apiVersion: '2006-03-01',
  region: 'us-west-1',
  credentials: {
    accessKeyId: 'AKIAJZUVDQSMIASEBNTQ',
    secretAccessKey: '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',
    region: 'us-west-1',
  },
  httpOptions: {
    timeout: 0,
  },
});

// Context
//    Used in creating a new space for a component or set of components to listen to
export const s3CreateContext = context => ({
  type: 'S3_CREATE_CONTEXT',
  context,
});

//    Used to reset any tracked files that no longer need to be tracked
export const s3DestroyContext = context => ({
  type: 'S3_DESTROY_CONTEXT',
  context,
});

//    Add arbitrary metadata to a context
export const s3AddMetadata = (context, metadata) => ({
  // Should rename to 'update?' Or have separate deep-clone?
  type: 'S3_UPDATE_METADATA',
  metadata,
  context,
});

export const s3DeleteMetadata = (context, keys) => ({
  type: 'S3_REMOVE_METADATA_KEYS',
  keys,
  context,
});

//    Un-tracks a file in a given context (useful for straight-up discarding a file)
// ABORT AN EXISTING UPLOAD ? REMOVE FILE ID FROM METADATA ?
export const s3ResetFile = (context, id) => ({
  type: 'S3_RESET_FILE',
  id,
  context,
});

// Uploading
//    Starts the upload and tracks its progress for a given context
const s3UploadStart = (context, request) => ({
  type: 'S3_UPLOAD_START',
  request,
  context,
});

//    Internal-use. Updates the upload's progress for a given context
const s3UploadStatus = (context, status) => ({
  type: 'S3_UPLOAD_STATUS',
  status,
  context,
});

// Dispatched Actions
//    Thunk middleware passes the dispatch method as an argument to these functions.
//    Note fat arrow () => syntax will not be interpreted by Thunk properly

export function s3Abort(context, request, completion) {
  return (dispatch) => {
    // Seems an uploadProgress event fires right before this thing
    // dispatch(
    //     context,
    //   s3UploadStatus(
    //     {
    //       status: 'aborting',
    //       request,
    //     },
    //   ),
    // );

    // onComplete also contains 'complete', 'error' param
    // error contains 'error' param
    request
      .abort()
      .on('complete', () => {
        if (completion) {
          completion('success');
        }
        dispatch(
          s3UploadStatus(
            context,
            {
              status: 'aborted',
              request,
            },
          ),
        );
      })
      .on('error', () => {
        if (completion) {
          completion('error');
        }
        dispatch(
          s3UploadStatus(
            context,
            {
              status: 'abort failed',
              request,
            },
          ),
        );
      });
  };
}

// "responses" are sent back by our original request
export function s3Upload(context, fileData, fileId, completion, rules) {
  return (dispatch, getState) => {
    console.log('contextttt', context);
    // Run initial error-checking (don't even start stuff if things are invalid)
    if (!get(getState(), `s3.${context}`)) {
      console.warn(
        'S3 Action: The context for starting an upload has been destroyed!',
      );
      return;
    } else if (rules) {
      const match = get(get(getState(), `s3.${context}`), rules.match);
      if (!match || !fileId.includes(match)) {
        console.warn(
          `S3 Action: Upload rules violated for starting an upload - fileId "${fileId}" did not satisfy rule "match" specifying string "${match}" - aborting.`,
        );
        return;
      }
    }

    const s3Parameters = {
      Body: fileData,
      Bucket: s3Bucket,
      Key: fileId,
      Metadata: {
        name: fileData.name,
      },
    };
    // progress object has .total and .loaded properties
    // An uploadProgress response can fire after an abort has been triggered
    // check _asm to see if currentState is "complete" - most of the time it
    // will be in the middle of abortion
    const request = s3
      .putObject(s3Parameters, () => {})
      .on('httpUploadProgress', (progress, response) => {
        const status = {
          status: 'uploading',
          progress: { loaded: progress.loaded, total: progress.total },
          response,
        };
        const currentState = get(getState(), `s3.${context}`);
        if (!currentState) {
          console.warn(
            'S3 Action: The context for starting an upload has been destroyed!',
          );
          dispatch(s3Abort(context, response.request, null));
        } else if (rules) {
          const match = get(currentState, rules.match);
          if (!match || !fileId.includes(match)) {
            console.warn(
              `Upload rules violated - fileId "${fileId}" did not satisfy rule "match" specifying string "${match}" - aborting.`,
            );
            dispatch(s3Abort(context, response.request, null));
          } else {
            dispatch(s3UploadStatus(context, status));
          }
        } else {
          dispatch(s3UploadStatus(context, status));
        }
      })
      .on('success', (response) => {
        if (completion) {
          completion(response);
        }
        const status = {
          status: 'uploaded',
          response,
        };
        dispatch(s3UploadStatus(context, status));
        // Check for any onSuccess we might want to handle
        const currentState = get(getState(), `s3.${context}`);
        const onSuccess = get(currentState, 'metadata.submitWhenDone');

        if (onSuccess) {
          const done = currentState.uploads
            .filter(upload => upload.status === 'uploaded')
            .map(file => file.id);
          const required = get(onSuccess, 'files');
          const massagedFileNames = done.map(
            file => file.split('/')[file.split('/').length - 1],
          );
          console.log(done, required, massagedFileNames);
          if (
            required &&
            required.every(file => massagedFileNames.includes(file))
          ) {
            const {
              environment,
              track,
              tags,
              pricingOptions,
              formName,
            } = onSuccess.data;

            CreateTrackMutation(environment, track).then((mutationResponse) => {
              const trackId = mutationResponse.createTrack.track.id;

              if (tags) {
                const hashtags = tags.split(' ');
                hashtags.forEach((tag) => {
                  CreateHashtagMutation(environment, { tag, trackId });
                });
              }

              const hasPricing = Object.keys(pricingOptions).length;
              if (hasPricing) {
                objectToMap(pricingOptions).forEach((price, type) => {
                  CreateProductMutation(environment, {
                    type: type.toUpperCase(),
                    price: price * 100,
                    trackId,
                  });
                });
              }

              alert('Success!');
              dispatch(s3DestroyContext(context));
              dispatch(reset(formName));
            });
          }
        }
      })
      .on('error', (error, response) => {
        if (rules) {
          const match = get(get(getState(), `s3.${context}`), rules.match);
          if (!match || !fileId.includes(match)) {
            // If the upload is failing because of a rule violation, don't error it out.
            console.warn("An upload did something bad, but we won't make it error out.");
            const status = {
              status: 'aborted',
              response,
            };
            dispatch(s3UploadStatus(context, status));
          } else {
            const status = {
              status: error.code === 'RequestAbortedError'
                ? 'aborted'
                : 'upload failed',
              error,
              response,
            };
            dispatch(s3UploadStatus(context, status));
          }
        }
      });

    dispatch(s3UploadStart(context, request));
  };
}

export function s3Delete(context, fileId, completion) {
  return (dispatch) => {
    const s3Parameters = {
      Bucket: s3Bucket,
      Key: fileId,
    };

    const request = s3 // this ain't used
      .deleteObject(s3Parameters, () => {})
      .on('success', (response) => {
        if (completion) {
          completion('success');
        }
        dispatch(s3UploadStatus(context, { status: 'deleted', response }));
      })
      .on('error', (error) => {
        if (completion) {
          completion('error');
        }
        dispatch(s3UploadStatus(context, { status: 'delete failed', error }));
      });

    dispatch(
      s3UploadStatus(
        context,
        {
          status: 'deleting',
        },
        // request?
      ),
    );
  };
}

export default module.exports;
