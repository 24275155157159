import { Map, fromJS } from 'immutable';
import * as types from 'types';

const initialState = fromJS({
  initialLoad: false,
  isFetching: false,
  error: '',
  isSaving: false,
  isCurrent: true,
  items: [],
});

export default function projects(state = initialState, { type, payload }) {
  if (!(state instanceof Map)) state = fromJS(state); // eslint-disable-line

  switch (type) {
  case types.RESET_PROJECTS:
    return initialState;

  case types.FETCH_PROJECT_SUCCESS: {
    const find = state
        .get('items')
        .find(item => item.get('id') === payload.id);
    if (!find) {
      const items = state.get('items').push(fromJS(payload));
      return state.withMutations((map) => {
        map
            .set('items', items)
            .set('initialLoad', true)
            .set('isFetching', false)
            .set('error', '');
      });
    }

    return state.withMutations((map) => {
      map.set('isFetching', false).set('initialLoad', true).set('error', '');
    });
  }

  case types.FETCH_PROJECTS_SUCCESS:
    return state.withMutations((map) => {
      map
          .set('items', fromJS(payload))
          .set('initialLoad', true)
          .set('isFetching', false)
          .set('error', '');
    });

  case types.ADD_PROJECT_SUCCESS: {
    const project = state
        .get('items')
        .find(item => item.get('id') === payload.id);
    if (!project) {
      const items = state.get('items').push(fromJS(payload).set('bpm', 120));
      return state.withMutations((map) => {
        map.set('items', items).set('isFetching', false).set('error', '');
      });
    }
    return state.withMutations((map) => {
      map.set('isFetching', false).set('error', '');
    });
  }

  case types.DELETE_PROJECT_SUCCESS: {
    const items = state
        .get('items')
        .filter(project => project.get('id') !== payload.id);
    return state.withMutations((map) => {
      map.set('items', items).set('isFetching', false).set('error', '');
    });
  }

  case types.UPDATE_PROJECT_SUCCESS: {
    const items = state.get('items').map((project) => {
      if (project.get('id') === payload.id) {
        return fromJS(payload);
      }
      return project;
    });
    return state.withMutations((map) => {
      map.set('items', items).set('isFetching', false).set('error', '');
    });
  }

  case types.SAVE:
    return state.withMutations((map) => {
      map.set('error', '').set('isSaving', payload);
    });

  case types.CHANGE_PROJECT_STATUS:
    return state.withMutations((map) => {
      map.set('error', '').set('isCurrent', payload);
    });

  case types.CHANGE_BPM: {
    const items = state.get('items').map((project) => {
      if (project.get('id') === payload.id) {
        return project.set('bpm', payload.value).set('status', 'unstable');
      }
      return project;
    });
    return state.withMutations((map) => {
      map.set('items', items).set('isFetching', false).set('error', '');
    });
  }

  case types.FETCH_PROJECT_FAILURE:
  case types.FETCH_PROJECTS_FAILURE:
  case types.ADD_PROJECT_FAILURE:
  case types.DELETE_PROJECT_FAILURE:
  case types.UPDATE_PROJECT_FAILURE:
    return state.withMutations((map) => {
      map
          .set('isFetching', false)
          .set('initialLoad', true)
          .set('error', payload);
    });

  case types.FETCH_PROJECT_REQUEST:
  case types.FETCH_PROJECTS_REQUEST:
  case types.ADD_PROJECT_REQUEST:
  case types.DELETE_PROJECT_REQUEST:
  case types.UPDATE_PROJECT_REQUEST:
    return state.withMutations((map) => {
      map.set('isFetching', true).set('error', '');
    });

  default:
    return state;
  }
}
