// 'like' is bool
export const likeSetLiking = (like, likedId) => ({
  type: 'LIKE_SET_LIKING',
  like,
  likedId,
});

// clear likes on logout

export default module.exports;
