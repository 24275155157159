import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import {
  soundbarSetVisible,
  soundbarSetPlaying,
} from 'actions/soundbar';

const propTypes = {
  visible: PropTypes.bool.isRequired,
  playing: PropTypes.bool.isRequired,
  trackUrl: PropTypes.string.isRequired,
  trackName: PropTypes.string.isRequired,
  soundbarSetVisible: PropTypes.func.isRequired,
  soundbarSetPlaying: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const MiniSoundbar = ({ ...props }) => {
  const onGrid = props.location.pathname.includes('projects/');

  const visibility = onGrid ? 'invisible' : 'visible';

  const toggleBar = () => {
    props.soundbarSetVisible(!props.visible);
  };

  return (
    <div
      id="soundbar-collapsed-controls"
      className={classnames(
        'soundbarCollapseControls pin-r rounded-full',
        visibility,
        {
          collapsed: !props.visible,
        },
      )}
      style={{
        visibility: onGrid ? 'hidden' : 'visible',
      }}
    >
      {props.visible
        ? <button
          style={{
            float: 'right',
            border: 'none',
            height: '30px',
            width: '30px',
            position: 'relative',
            outline: 'none',
            zIndex: 1,
          }}
          onClick={toggleBar}
        >
          <span className="icon is-small">
            <i
              className={
                  props.visible ? 'fa fa-chevron-down' : 'fa fa-chevron-up'
                }
            />
          </span>
        </button>
        : <span
          style={{
            cursor: 'pointer',
            bottom: 10,
            right: 10,
            zIndex: 101,
            outline: 'none',
            color: 'white',
            backgroundColor: 'rgb(229, 131, 78)',
            fontWeight: 'bold',
            boxShadow: '0px 2px 1px gray',
            float: 'right',
            position: 'fixed',
            borderRadius: 15,
          }}
        >
          <a style={{ color: 'white', outline: 'none', userSelect: 'none' }}>
            <i
              role="button"
              tabIndex={0}
              className={props.playing ? 'fa fa-pause' : 'fa fa-play'}
              aria-hidden="true"
              style={{
                verticalAlign: 'middle',
                paddingLeft: 15,
                paddingRight: 10,
                fontSize: 18,
                outline: 'none',
              }}
              onClick={() => {
                props.soundbarSetPlaying(!props.playing);
              }}
            />
            {' '}
          </a>
          <span
            style={{
              paddingRight: 10,
              fontSize: 14,
              outline: 'none',
              userSelect: 'none',
            }}
            role="button"
            tabIndex={0}
            onClick={() => {
              props.soundbarSetPlaying(!props.playing);
            }}
          >
            {props.playing
                ? 'Playing:'
                : props.trackUrl ? '(Paused)' : '(nothing to play)'}
            {' '}
            {props.trackName}{' '}
          </span>
          <button
            style={{
              width: 50,
              height: 30,
              borderLeft: '1px solid white',
              color: 'white',
              outline: 'none',
              userSelect: 'none',
            }}
            onClick={toggleBar}
          >
            <span
              className="icon is-small"
              style={{ padding: 10, outline: 'none', userSelect: 'none' }}
            >
              <i
                className={
                    props.visible ? 'fa fa-chevron-down' : 'fa fa-chevron-up'
                  }
              />
            </span>
          </button>
        </span>}
    </div>
  );
};

MiniSoundbar.propTypes = propTypes;

export default connect(
  state => ({
    trackName: state.soundbar.currentTrack.name,
    trackUrl: state.soundbar.currentTrack.fileUrl,
    playing: state.soundbar.playing,
    visible: state.soundbar.visible,
  }),
  { soundbarSetVisible, soundbarSetPlaying },
)(MiniSoundbar);
