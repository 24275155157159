import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import graphql from 'babel-plugin-relay/macro';
import { QueryRenderer } from 'react-relay';
import * as likeActions from 'actions/likeActions';
import CreateLikeMutation from 'mutations/like/CreateLikeMutation';
import DeleteLikeByUserIdAndTrackIdMutation from 'mutations/like/DeleteLikeByUserIdAndTrackIdMutation';
import LikeIcon from './LikeIcon';

const propTypes = {
  liking: PropTypes.array.isRequired,
  trackIdToLike: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
  likeSetLiking: PropTypes.func.isRequired,
  iconStyle: PropTypes.object,
};

const defaultProps = {
  iconStyle: {},
};

// Extremely hacky. Runs the query once and uses it as source for whether user
// has liked or not. Once user has pressed buttons, uses redux store as
// source for like/no like. This is due to Relay's shitty documentation
// on local cache/store management. Thanks, Facebook.
class LikeIconContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modified: false,
    };
  }

  // The mutation callback passes back a response we can capture
  handleCreateLike = () => {
    const { trackIdToLike, auth, likeSetLiking } = this.props;
    CreateLikeMutation(
      auth.environment,
      {
        userId: auth.userId,
        trackId: trackIdToLike,
      },
      () => {
        likeSetLiking(true, trackIdToLike);
        this.setState({ modified: true });
      },
    );
  };

  // The mutation callback passes back a response we can capture
  handleDeleteLike = () => {
    const { trackIdToLike, auth, likeSetLiking } = this.props;
    DeleteLikeByUserIdAndTrackIdMutation(
      auth.environment,
      {
        userId: auth.userId,
        trackId: trackIdToLike,
      },
      () => {
        likeSetLiking(false, trackIdToLike);
        this.setState({ modified: true });
      },
    );
  };

  render() {
    const { liking, trackIdToLike, auth, iconStyle } = this.props;

    return (
      <QueryRenderer
        environment={auth.environment}
        query={graphql`
        query LikeIconContainerQuery($userId: UUID!, $trackId: UUID!){
          like: likeByUserIdAndTrackId(userId: $userId, trackId: $trackId){
              nodeId
          }
        }
      `}
        variables={{
          userId: auth.userId,
          trackId: trackIdToLike,
        }}
        render={({ error, props }) => {
          if (error) {
            return <div>Error: ${error}</div>;
          } else if (props) {
            const currentlyLiking = this.state.modified
              ? liking.includes(trackIdToLike)
              : !!props.like;
            return (
              <LikeIcon
                currentlyLiking={currentlyLiking}
                trackIdToLike={trackIdToLike}
                handleCreateLike={this.handleCreateLike}
                handleDeleteLike={this.handleDeleteLike}
                iconStyle={iconStyle}
              />
            );
          }
          return <div>Loading...</div>;
        }}
      />
    );
  }
}

LikeIconContainer.propTypes = propTypes;
LikeIconContainer.defaultProps = defaultProps;

export default connect(
  state => ({
    liking: state.liking,
    auth: state.auth,
    trackIdToLike: state.soundbar.currentTrack.id,
  }),
  likeActions,
)(LikeIconContainer);
