import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { QueryRenderer, graphql } from 'react-relay';
import { trackUrl } from 'config';
import displayTrackImage from 'utils/display-track-image';
import { withRouter } from 'react-router-dom';
import Avatar from 'material-ui/Avatar';
import { List, ListItem } from 'material-ui/List';
import SortableResultsTable from './SortableResultsTable';

const propTypes = {
  variables: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  simple: PropTypes.bool,
  history: PropTypes.object.isRequired,
  showColumns: PropTypes.array,
  options: PropTypes.array,
};

const defaultProps = {
  simple: true,
  showColumns: null,
  options: [],
};

class TracksResults extends Component {
  render() {
    if (this.props.variables.search) {
      return (
        <QueryRenderer
          query={graphql`
            query TracksResultsQuery($search: String!) {
              tracks: searchTracks(search: $search, first: 10) {
                edges {
                  cursor
                  node {
                    id
                    name
                    publicId
                    length
                    bpm
                    mood
                    genre
                    picture
                    userByUserId {
                      id
                      displayName
                    }
                  }
                }
              }
            }
          `}
          variables={this.props.variables}
          environment={this.props.auth.environment}
          render={({ error, props }) => {
            if (error) {
              console.log(error);
            }
            if (props && props.tracks.edges.length) {
              if (!this.props.simple) {
                // Set up header info and sort configs
                // Note the "id" in columnData must exactly match the key
                // in trackData you wish to sort by!
                let columnData = [];
                if (this.props.showColumns && this.props.showColumns.length) {
                  if (this.props.showColumns.includes('picture')) {
                    columnData.push({
                      id: 'picture',
                      numeric: false,
                      disablePadding: true,
                      label: '',
                    });
                  }
                  if (this.props.showColumns.includes('name')) {
                    columnData.push({
                      id: 'track',
                      numeric: false,
                      disablePadding: true,
                      label: 'Track',
                    });
                  }
                  if (this.props.showColumns.includes('bpm')) {
                    columnData.push({
                      id: 'bpm',
                      numeric: true,
                      disablePadding: true,
                      label: 'BPM',
                    });
                  }
                  if (this.props.showColumns.includes('length')) {
                    columnData.push({
                      id: 'length',
                      numeric: true,
                      disablePadding: true,
                      label: 'Length',
                    });
                  }
                  if (this.props.showColumns.includes('mood')) {
                    columnData.push({
                      id: 'mood',
                      numeric: false,
                      disablePadding: true,
                      label: 'Mood',
                    });
                  }
                  if (this.props.showColumns.includes('genre')) {
                    columnData.push({
                      id: 'genre',
                      numeric: false,
                      disablePadding: true,
                      label: 'Genre',
                    });
                  }
                  if (this.props.showColumns.includes('author')) {
                    columnData.push({
                      id: 'author',
                      numeric: false,
                      disablePadding: true,
                      label: 'Author',
                    });
                  }
                } else {
                  columnData = [
                    {
                      id: 'picture',
                      numeric: false,
                      disablePadding: true,
                      label: '',
                    },
                    {
                      id: 'track',
                      numeric: false,
                      disablePadding: true,
                      label: 'Tracks',
                    },
                    {
                      id: 'bpm',
                      numeric: true,
                      disablePadding: true,
                      label: 'BPM',
                    },
                    {
                      id: 'mood',
                      numeric: false,
                      disablePadding: true,
                      label: 'Mood',
                    },
                    {
                      id: 'genre',
                      numeric: false,
                      disablePadding: true,
                      label: 'Genre',
                    },
                    {
                      id: 'author',
                      numeric: false,
                      disablePadding: true,
                      label: 'Author',
                    },
                  ];
                }

                const trackData =
                  props.tracks &&
                  props.tracks.edges.map(trackEdge => ({
                    id: trackEdge.node.id,
                    publicId: trackEdge.node.publicId,
                    track: trackEdge.node.name || 'Unnamed',
                    length: trackEdge.node.length || 0,
                    bpm: trackEdge.node.bpm || 0,
                    mood: trackEdge.node.mood || 'Unknown',
                    genre: trackEdge.node.genre || 'Unknown',
                    author:
                      (trackEdge.node.userByUserId &&
                        trackEdge.node.userByUserId.displayName) ||
                        'Unknown',
                    picture: displayTrackImage(
                      trackEdge.node.fileUrl,
                      trackEdge.node.picture,
                    ),
                  }));

                return (
                  <SortableResultsTable
                    tableData={{ columns: columnData, data: trackData }}
                    linkType="track"
                    options={this.props.options}
                  />
                );
              }

              // Default behavior
              return (
                <div>
                  <List>
                    {props.tracks &&
                      props.tracks.edges.map(trackEdge =>
                        (<ListItem
                          key={trackEdge.node.id}
                          primaryText={trackEdge.node.name}
                          leftAvatar={
                            <Avatar
                              src={displayTrackImage(
                                trackEdge.node.fileUrl,
                                trackEdge.node.picture,
                              )}
                              style={{ objectFit: 'cover' }}
                            />
                          }
                          rightIcon={
                            <i
                              className="fa fa-music"
                              style={{ float: 'left', color: 'black' }}
                            />
                          }
                          onClick={() => {
                            this.props.onSelect();
                            this.props.history.push(
                              `/tracks/${trackEdge.node.publicId}`,
                            );
                          }}
                        />),
                      )}
                  </List>
                </div>
              );
            }
            return (
              <List>
                <ListItem
                  key="notrackresults"
                  primaryText="No tracks match your query"
                  leftIcon={<i className="fa fa-frown-o" />}
                  rightIcon={<i className="fa fa-music" />}
                />
              </List>
            );
          }}
        />
      );
    }
    return null;
  }
}

TracksResults.propTypes = propTypes;
TracksResults.defaultProps = defaultProps;

export default withRouter(TracksResults);
