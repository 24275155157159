import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import LicenseTermsTable from 'components/LicenseTermsTable';

const propTypes = {
  match: PropTypes.object.isRequired,
};

const LicensePage = ({
  match: {
    params: { licenseType },
  },
}) => (
  <div id="license-page" className="container p-8 lg:w-2/3 md:w-4/5 mx-auto">
    <h1 className="font-bold text-4xl my-6">{`${capitalize(
      licenseType,
    )} License`}</h1>
    <LicenseTermsTable licenseType={licenseType} />
  </div>
);

LicensePage.propTypes = propTypes;

export default LicensePage;
