import React from 'react';
import { Link } from 'react-router-dom';
import emptyStateImage from 'assets/empty-state-dashboard.png';

const MainContent = () => (
  <div className="flex-1 w-1 cont text-center block py-4">
    <img
      src={emptyStateImage}
      alt="Empty State"
      className="mx-auto w-4/5"
      style={{ maxWidth: '340px' }}
    />
    <div className="text-2xl font-bold text-gray-darkest mt-2">
        Your Stream
      </div>
    <div
      className="leading-normal font-semibold text-gray-dark mx-auto mt-2"
      style={{ width: '273px' }}
    >
        When you follow someone, their latest music will appear here
      </div>
    <Link to="/tracks/create">
      <button
        className="btn dashboard-follow-btn btn-pill-color mt-2 font-bold outline-none py-2"
        style={{ width: '230px' }}
      >
          Upload A Beat
        </button>
    </Link>
  </div>
  );


export default MainContent;
