import WavEncoder from 'wav-encoder';
import webAudio from './webAudioHelper';

let mp3Worker;

const numOfChannels = 2;
const sampleRate = 44100;
// const taggedDuration = 30;

// fileType is the type you want the tracks converted to. The function expects wavs as input.
export const mergeAudio = (
  tracks,
  fileType,
  tag,
  callback,
  repeatTagEvery = 15,
) =>
  new Promise((resolve, reject) => {
    // Set up our contexts using the webAudio helper
    webAudio.setupContext();

    // Add webAudio sources for each track. They are in the form of
    // promises for decoded data
    const promises = [];

    tracks.forEach((track) => {
      promises.push(webAudio.createSource(track));
    });

    if (tag) {
      promises.push(webAudio.createSource(tag));
    }

    // Wait for our tracks to decode, then create a new offline context
    // with duration equal to maximum length of all tracks
    Promise.all(promises).then((decodedTracks) => {
      console.log('decoded tracks', decodedTracks);
      const maxDuration = decodedTracks
        .map(track => track.duration)
        .reduce((max, curr) => Math.max(max, curr), 0);

      const context = new OfflineAudioContext(
        numOfChannels,
        maxDuration * sampleRate,
        sampleRate,
      );

      // Create a new source for each track, and add the decoded track data
      // to that source's buffer. Connect the output of each source to the
      // same destination, and start the source. This will 'play' each
      // track simultaneously to the same 'output,' effectively merging them

      // If we have an audio tag, have that start at certain times
      // and business as usual
      if (tag) {
        if (tag.duration > repeatTagEvery) {
          throw new Error(
            "What are you thinking?! You can't have a tag (",
            tag.duration,
            ') longer than the repeat duration (',
            repeatTagEvery,
            ')!',
          );
        }

        // The tag is going to be the last decodedTrack (!)
        const decodedStems = decodedTracks.slice(0, decodedTracks.length - 1);
        const decodedTag = decodedTracks.slice(-1)[0];

        // Build a list of valid start times for the tag
        const validStartTimes = [0];
        for (
          let i = repeatTagEvery;
          i + decodedTag.duration < maxDuration;
          i += repeatTagEvery
        ) {
          validStartTimes.push(i);
        }

        // Get all the stems started
        decodedStems.forEach((decodedStem) => {
          const source = context.createBufferSource();
          source.buffer = decodedStem;
          source.connect(context.destination);
          source.start();
        });

        // Then get the tag started at each appropriate time
        validStartTimes.forEach((validTime) => {
          const source = context.createBufferSource();
          source.buffer = decodedTag;
          source.connect(context.destination);
          source.start(validTime);
        });
      } else {
        decodedTracks.forEach((decodedTrack) => {
          const source = context.createBufferSource();
          source.buffer = decodedTrack;
          source.connect(context.destination);
          source.start();
        });
      }

      // Add the oncomplete handler to the context before starting the render
      // This is the meat of the operation.
      context.oncomplete = (event) => {
        const rb = event.renderedBuffer;
        const channelData = [];
        for (let i = 0; i < rb.numberOfChannels; i++) {
          channelData.push(rb.getChannelData(i));
        }

        if (callback) {
          callback();
        }

        if (fileType === 'mp3') {
          console.log('Creating web worker');
          mp3Worker = new Worker('/workers/mp3Converter.js');
          mp3Worker.onmessage = e => resolve(e.data.mergedAudio);
          mp3Worker.postMessage({
            command: 'mergeAudio',
            channelData,
          });
          mp3Worker.postMessage({
            command: 'close',
          });
        }

        if (fileType === 'wav') {
          const wavData = {
            sampleRate: 44100,
            channelData,
          };
          WavEncoder.encode(wavData).then((buffer) => {
            resolve(new Blob([buffer], { type: 'wav' }));
          });
        }
      };

      // Start the render of the context, starting the entire process.
      context.startRendering();
    });
  });

export default mergeAudio;
