import React from 'react';
import PropTypes from 'prop-types';
import * as modalActions from 'actions/modals';
import { connect } from 'react-redux';
import { onMobile } from 'utils/screentype';
import TrackInfo from './TrackInfo';
import Controls from './Controls';

const propTypes = {
  audioElement: PropTypes.object.isRequired,
  volumeBarShowing: PropTypes.bool.isRequired,
  toggleVolumeControl: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};


const Content = ({
  audioElement,
  volumeBarShowing,
  toggleVolumeControl,
  showModal,
}) => (
  <div
    role="presentation"
    className="w-desk py-2 relative flex"
    onClick={onMobile(() => showModal('SoundbarModal'))}
  >
    <TrackInfo />
    <Controls
      audioElement={audioElement}
      volumeBarShowing={volumeBarShowing}
      toggleVolumeControl={toggleVolumeControl}
    />
  </div>
);

Content.propTypes = propTypes;

export default connect(
  state => ({
    isShowing: state.modals.get('name') === 'SoundbarModal',
  }),
  modalActions,
)(Content);
