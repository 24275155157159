import Cookies from 'js-cookie';
import {
  LOGIN_USER,
  SIGNUP_FAILURE,
  SIGNUP_SUCCESS,
  SIGNUP_REQUEST,
  RESET_USERS,
} from 'types/users';
import {
  getUser,
  createUser,
  fetchUsersApi,
  addUserApi,
  // deleteUserApi,
  updateUserApi,
  validateUserApi,
} from 'rest/users';
import { requestWrapper } from './helper';

export function loginUser(payload) {
  Cookies.set('token', payload.token);
  return {
    type: LOGIN_USER,
    payload,
  };
}

export const logoutUser = () => {
  Cookies.remove('token');
  window.location.href = '/';
};

export const fetchUsersAsync = requestWrapper('FETCH_USERS', fetchUsersApi);

export const fetchUser = requestWrapper('FETCH_USER', getUser);

export function signupSuccess(payload) {
  return {
    type: SIGNUP_SUCCESS,
    payload,
  };
}

export function signupFailure(payload) {
  return {
    type: SIGNUP_FAILURE,
    payload,
  };
}

export function signupUser(payload) {
  return (dispatch) => {
    dispatch({
      type: SIGNUP_REQUEST,
    });

    return createUser(payload)
      .then((data) => {
        Cookies.set('token', data.token);
        dispatch(signupSuccess(data));
      })
      .catch(err => dispatch(signupFailure(err)));
  };
}

export const addUserAsync = requestWrapper('ADD_USER', addUserApi);

export const updateUserAsync = requestWrapper('UPDATE_USER', updateUserApi);

// export const deleteUserAsync = requestWrapper('DELETE_USER', deleteUserApi);

export const validateUserAsync = requestWrapper(
  'VALIDATE_USER',
  validateUserApi,
);

export const resetUsers = () => ({ type: RESET_USERS });

export default module.exports;
