import { fetch, authHeader } from 'utils/fetch';

export function fetchProjectsApi() {
  return fetch('/api/projects', {
    headers: authHeader(),
  });
}

export function fetchProjectApi(id) {
  return fetch(`/api/projects/${id}`, {
    headers: authHeader(),
  });
}

export function addProjectApi(project) {
  return fetch('/api/projects/new', {
    method: 'POST',
    body: JSON.stringify(project),
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
  });
}

export function deleteProjectApi(id) {
  return fetch(`/api/projects/${id}`, {
    method: 'DELETE',
    headers: {
      ...authHeader(),
    },
  });
}

export function attachProductionApi(projectId, waveId) {
  return fetch(`/api/projects/${projectId}/production`, {
    method: 'PUT',
    body: JSON.stringify({ waveId }),
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
  });
}

export function updateProjectApi(id, project) {
  return fetch(`/api/projects/${id}`, {
    method: 'PUT',
    body: JSON.stringify(project),
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
  });
}

export default module.exports;
