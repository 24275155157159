import React from 'react';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import PropTypes from 'prop-types';
import Comment from './Comment';

const propTypes = {
  comments: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  mine: PropTypes.bool,
  currentUser: PropTypes.string,
  myStyle: PropTypes.object,
};

const defaultProps = {
  mine: false,
  currentUser: '',
  myStyle: null,
};

const COMMENT_LIST = gql`
  fragment CommentList_comments on CommentsConnection {
    edges {
      node {
        id
        author: userByUserId {
          id
        }
        ...Comment_comment
      }
    }
  }
  ${Comment.fragments.comment}
`;

const CommentList = ({ comments, mine, currentUser, myStyle, onDelete }) => (
  comments.edges.length > 0 ?
    <ul className="mt-4 pt-2 border-t border-gray-light" style={myStyle}>
      {comments.edges.map(comment => (
        <Comment
          key={comment.node.id}
          comment={filter(Comment.fragments.comment, comment.node)}
          onDelete={() => onDelete(comment.node.id)}
          deleteable={mine || comment.node.author.id === currentUser}
        />
      ))}
    </ul>
    :
    null
);

CommentList.propTypes = propTypes;
CommentList.defaultProps = defaultProps;
CommentList.fragments = {
  comments: COMMENT_LIST,
};

export default CommentList;
