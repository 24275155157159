import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as modalActions from 'actions/modals';
import Modal from 'components/common/Modal';
import * as allModals from 'components/Modals';
import { isDesktop } from 'utils/screentype';

const mobileStyles = {
  content: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 0,
    width: '100%',
    padding: '1.5rem',
  },
};

class ModalPortal extends Component {
  static propTypes = {
    modals: PropTypes.object.isRequired,
    hideModal: PropTypes.func.isRequired,
    updateStyles: PropTypes.func.isRequired,
  };

  componentWillMount() {
    const { updateStyles } = this.props;
    const isMobile = !isDesktop();
    if (isMobile) updateStyles(mobileStyles);
  }

  render() {
    const { modals, hideModal } = this.props;
    const name = modals.get('name');
    const styles = modals.get('styles').toJS();
    const data = modals.get('data');
    const CurrentModal = allModals[name];
    return (
      <Modal
        isOpen={!!name}
        contentLabel={modals.get('contentLabel')}
        onRequestClose={() => {
          hideModal().then(() => {
            window.scrollTo(0, Math.abs(document.body.style.top.slice(0, -2)));
          });
        }}
        styles={styles}
      >
        {name ? (
          <CurrentModal modals={modals} onClose={hideModal} data={data} />
        ) : (
          <div />
        )}
      </Modal>
    );
  }
}

export default connect(
  state => ({ modals: state.modals }),
  modalActions,
)(ModalPortal);
