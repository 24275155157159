const sizes = { xs: '0', sm: '768px', md: '992px', lg: '1200px' };
const extendItem = size => number => ({
  flex: '0 0 100%',
  position: 'relative',
  [`@media (min-width: ${sizes[size]})`]: {
    flex: `0 0 ${(100 * number) / 12}%`,
  },
});

const extendOffset = size => number => ({
  [`@media (min-width: ${sizes[size]})`]: {
    marginLeft: `${(100 * number) / 12}%`,
  },
});

const xsItem = extendItem('xs');
const smItem = extendItem('sm');
const mdItem = extendItem('md');
const lgItem = extendItem('lg');

const xsOffset = extendOffset('xs');
const smOffset = extendOffset('sm');
const mdOffset = extendOffset('md');
const lgOffset = extendOffset('lg');

const flex = { display: 'flex' };

export const grid = {
  row: {
    ...flex,
    flexFlow: 'row wrap',
  },
  column: {
    ...flex,
    flexFlow: 'column nowrap',
  },
};

export const container = {
  large: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [`@media (min-width: ${sizes.sm})`]: {
      width: '720px',
    },
    [`@media (min-width: ${sizes.md})`]: {
      width: '940px',
    },
    [`@media (min-width: ${sizes.lg})`]: {
      width: '1140px',
    },
  },
  small: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [`@media (min-width: ${sizes.sm})`]: {
      width: '700px',
    },
    [`@media (min-width: ${sizes.md})`]: {
      width: '910px',
    },
    [`@media (min-width: ${sizes.lg})`]: {
      width: '1030px',
    },
  },
};

// Align Items (along cross axis, usually vertical)
const top = {
  alignItems: 'flex-start',
  alignSelf: 'flex-start',
};

const middle = {
  alignItems: 'center',
  alignSelf: 'center',
};

const bottom = {
  alignItems: 'flex-end',
  alignSelf: 'flex-end',
};

const stretch = {
  alignItems: 'stretch',
  alignSelf: 'stretch',
};

const baseline = {
  alignItems: 'baseline',
  alignSelf: 'baseline',
};

// Justify Content (along main axis, usually horizontal)
const left = { justifyContent: 'flex-Start' };
const center = { justifyContent: 'center' };
const right = { justifyContent: 'flex-end' };
const between = { justifyContent: 'space-between' };
const around = { justifyContent: 'space-around' };

// Align (central object for align items and justify content)
export const align = {
  top,
  middle,
  bottom,
  stretch,
  baseline,
  left,
  center,
  right,
  between,
  around,
};

// Spacing
export const spacing = {
  top: {
    paddingTop: 10,
  },
  top2x: {
    paddingTop: 20,
  },
  top3x: {
    paddingTop: 30,
  },
  right: {
    paddingRight: 10,
  },
  right2x: {
    paddingRight: 20,
  },
  right3x: {
    paddingRight: 30,
  },
  bottom: {
    paddingBottom: 10,
  },
  bottom2x: {
    paddingBottom: 20,
  },
  bottom3x: {
    paddingBottom: 30,
  },
  left: {
    paddingLeft: 10,
  },
  left2x: {
    paddingLeft: 20,
  },
  left3x: {
    paddingLeft: 30,
  },
};

// Offsets
export const offset = {
  xs: {
    one: xsOffset(1),
    two: xsOffset(2),
    three: xsOffset(3),
    four: xsOffset(4),
    five: xsOffset(5),
    six: xsOffset(6),
    seven: xsOffset(7),
    eight: xsOffset(8),
    nine: xsOffset(9),
    ten: xsOffset(10),
    eleven: xsOffset(11),
    twelve: xsOffset(12),
  },
  sm: {
    one: smOffset(1),
    two: smOffset(2),
    three: smOffset(3),
    four: smOffset(4),
    five: smOffset(5),
    six: smOffset(6),
    seven: smOffset(7),
    eight: smOffset(8),
    nine: smOffset(9),
    ten: smOffset(10),
    eleven: smOffset(11),
    twelve: smOffset(12),
  },
  md: {
    one: mdOffset(1),
    two: mdOffset(2),
    three: mdOffset(3),
    four: mdOffset(4),
    five: mdOffset(5),
    six: mdOffset(6),
    seven: mdOffset(7),
    eight: mdOffset(8),
    nine: mdOffset(9),
    ten: mdOffset(10),
    eleven: mdOffset(11),
    twelve: mdOffset(12),
  },
  lg: {
    one: lgOffset(1),
    two: lgOffset(2),
    three: lgOffset(3),
    four: lgOffset(4),
    five: lgOffset(5),
    six: lgOffset(6),
    seven: lgOffset(7),
    eight: lgOffset(8),
    nine: lgOffset(9),
    ten: lgOffset(10),
    eleven: lgOffset(11),
    twelve: lgOffset(12),
  },
};

// Items
export const item = {
  xs: {
    fit: { flex: '1', position: 'relative' },
    one: xsItem(1),
    two: xsItem(2),
    three: xsItem(3),
    four: xsItem(4),
    five: xsItem(5),
    six: xsItem(6),
    seven: xsItem(7),
    eight: xsItem(8),
    nine: xsItem(9),
    ten: xsItem(10),
    eleven: xsItem(11),
    twelve: xsItem(12),
  },
  sm: {
    fit: { flex: '1', position: 'relative' },
    one: smItem(1),
    two: smItem(2),
    three: smItem(3),
    four: smItem(4),
    five: smItem(5),
    six: smItem(6),
    seven: smItem(7),
    eight: smItem(8),
    nine: smItem(9),
    ten: smItem(10),
    eleven: smItem(11),
    twelve: smItem(12),
  },
  md: {
    fit: { flex: '1', position: 'relative' },
    one: mdItem(1),
    two: mdItem(2),
    three: mdItem(3),
    four: mdItem(4),
    five: mdItem(5),
    six: mdItem(6),
    seven: mdItem(7),
    eight: mdItem(8),
    nine: mdItem(9),
    ten: mdItem(10),
    eleven: mdItem(11),
    twelve: mdItem(12),
  },
  lg: {
    fit: { flex: '1', position: 'relative' },
    one: lgItem(1),
    two: lgItem(2),
    three: lgItem(3),
    four: lgItem(4),
    five: lgItem(5),
    six: lgItem(6),
    seven: lgItem(7),
    eight: lgItem(8),
    nine: lgItem(9),
    ten: lgItem(10),
    eleven: lgItem(11),
    twelve: lgItem(12),
  },
};

export default module.exports;
