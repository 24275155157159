import Immutable from 'immutable';
import {
  ADD_CART_ITEM,
  REMOVE_CART_ITEM,
  UPDATE_CART_ITEM,
  SWAP_CART_ITEM,
  REFRESH_CART,
  LOAD_CART_ITEMS,
} from 'types/cart';

const initialState = Immutable.fromJS({
  isLoading: false,
  error: '',
  items: [],
});

export default function cart(state = initialState, { type, payload }) {
  switch (type) {
  case ADD_CART_ITEM: {
    const exists = state
        .get('items')
        .find(item => item.get('id') === payload.id);
    const trackExists = state
        .get('items')
        .find(item => item.get('trackId') === payload.trackId);
    if (exists || trackExists) return state;
    return state.update('items', items =>
        items.push(Immutable.fromJS(payload)),
      );
  }
  case REMOVE_CART_ITEM:
    return state.update('items', items =>
        items.filter(item => item.get('id') !== payload.id),
      );
  case UPDATE_CART_ITEM:
    return state.update('items', items =>
        items.map(
          item => (item.get('id') === payload.id ? item.merge(payload) : item),
        ),
      );

  case SWAP_CART_ITEM:
    return state.update('items', items =>
        items.map(
          item =>
            (item.get('trackId') === payload.trackId
              ? item.merge(payload)
              : item),
        ),
      );
  case LOAD_CART_ITEMS:
    return state.set('items', Immutable.fromJS(payload));
  case REFRESH_CART:
    return initialState;
  default:
    return state;
  }
}
