/**
 * @flow
 * @relayHash b553790c91241c6662e406114c00e440
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type LikesLink_track$ref = any;
export type CreateLikeMutationVariables = {|
  input: {
    clientMutationId?: ?string,
    like: {
      userId: any,
      trackId: any,
      createdAt?: ?any,
    },
  },
|};
export type CreateLikeMutationResponse = {|
  +createLike: ?{|
    +track: ?{|
      +$fragmentRefs: LikesLink_track$ref,
    |},
  |},
|};
*/


/*
mutation CreateLikeMutation(
  $input: CreateLikeInput!
) {
  createLike(input: $input) {
    track: trackByTrackId {
      ...LikesLink_track
    }
  }
}

fragment LikesLink_track on Track {
  id
  publicId
  likes: likesByTrackId(first: 10000) {
    totalCount
    edges {
      node {
        userId
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateLikeInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateLikeInput!"
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CreateLikeMutation",
  "id": null,
  "text": "mutation CreateLikeMutation(\n  $input: CreateLikeInput!\n) {\n  createLike(input: $input) {\n    track: trackByTrackId {\n      ...LikesLink_track\n    }\n  }\n}\n\nfragment LikesLink_track on Track {\n  id\n  publicId\n  likes: likesByTrackId(first: 10000) {\n    totalCount\n    edges {\n      node {\n        userId\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateLikeMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createLike",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateLikePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "track",
            "name": "trackByTrackId",
            "storageKey": null,
            "args": null,
            "concreteType": "Track",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "LikesLink_track",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateLikeMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createLike",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateLikePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "track",
            "name": "trackByTrackId",
            "storageKey": null,
            "args": null,
            "concreteType": "Track",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "publicId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": "likes",
                "name": "likesByTrackId",
                "storageKey": "likesByTrackId(first:10000)",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 10000,
                    "type": "Int"
                  }
                ],
                "concreteType": "LikesConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "totalCount",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LikesEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Like",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "userId",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "__typename",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "cursor",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "pageInfo",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "endCursor",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "hasNextPage",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedHandle",
                "alias": "likes",
                "name": "likesByTrackId",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 10000,
                    "type": "Int"
                  }
                ],
                "handle": "connection",
                "key": "LikesLink_likes",
                "filters": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
(node/*: any*/).hash = '36bc62095dd9d2c9c6b18092c6b2d7c7';
module.exports = node;
