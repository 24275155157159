import React from 'react';
import SellBeats from 'components/SellBeats';

const SellBeatsPage = () => (
  <div id="sell-beats-page">
    <SellBeats />
  </div>
);

export default SellBeatsPage;
