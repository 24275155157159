import React from 'react';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import TrackHashtagList from './TrackHashtagList';

const propTypes = {
  match: PropTypes.object.isRequired,
  hashtags: PropTypes.object.isRequired,
};

const TRACK_HASHTAGS = gql`
  fragment TrackHashtags_hashtags on HashtagsConnection {
    totalCount,
    ...TrackHashtagList_hashtags
  }
  ${TrackHashtagList.fragments.hashtags}
`;

const TrackHashtags = ({ match: { params: { tag } }, hashtags }) => {
  const displayTotal = hashtags.totalCount === 1
    ? `${hashtags.totalCount} track`
    : `${hashtags.totalCount} tracks`;
  return (
    <div className="container">
      <div className="hero">
        <div className="hero-body">
          <div className="container">
            <h1 className="title">{tag}</h1>
            <h2 className="subtitle">{displayTotal}</h2>
          </div>
        </div>
      </div>
      <TrackHashtagList hashtags={hashtags} />
    </div>
  );
};

TrackHashtags.propTypes = propTypes;
TrackHashtags.fragments = { hashtags: TRACK_HASHTAGS };

export default withRouter(TrackHashtags);
