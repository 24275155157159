import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as soundbarActions from 'actions/soundbar';

const propTypes = {
  type: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  seeking: PropTypes.bool.isRequired,
  updating: PropTypes.bool.isRequired,
  seekPosition: PropTypes.number.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: 'inline-block text-xs ml-1',
};

const AudioTime = ({
  type,
  duration,
  progress,
  seeking,
  updating,
  seekPosition,
  className,
}) => {
  const hasDuration = !!duration;

  const formatTime = (seconds) => {
    let sec = Math.floor(seconds);
    let min = Math.floor(sec / 60);
    min = min >= 10 ? min : `0${min}`;
    sec = Math.floor(sec % 60);
    sec = sec >= 10 ? sec : `0${sec}`;
    return `${min}:${sec}`;
  };

  const renderNada = () => (
    <p className={className}>
      {'--'}
    </p>
  );

  const renderCurrentTime = () => {
    if (hasDuration) {
      const currentTime =
        seeking || updating ? seekPosition * duration : progress * duration;
      const formattedTime = formatTime(currentTime);
      return (
        <p className={className}>
          {formattedTime}
        </p>
      );
    } else {
      return renderNada();
    }
  };

  const renderDurationTime = () => {
    if (hasDuration) {
      const formattedDuration = formatTime(duration);
      return (
        <p className={className}>
          {formattedDuration}
        </p>
      );
    } else {
      return renderNada();
    }
  };

  return type === 'current-time' ? renderCurrentTime() : renderDurationTime();
};

AudioTime.propTypes = propTypes;
AudioTime.defaultProps = defaultProps;

export default connect(
  state => ({
    duration: state.soundbar.duration,
    progress: state.soundbar.progress,
    seeking: state.soundbar.seeking,
    updating: state.soundbar.update,
    seekPosition: state.soundbar.seekPosition,
  }),
  soundbarActions,
)(AudioTime);
