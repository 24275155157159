import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Immutable from 'immutable';
import displayImage from 'utils/display-image';

const propTypes = {
  sender: PropTypes.instanceOf(Immutable.Map).isRequired,
};

const NotificationImage = ({ sender }) => (
  sender.get('type') === 'user' ? (
    <Link to={sender.get('slug')} className="mr-3">
      <img
        className="avatar"
        src="https://s3-us-west-1.amazonaws.com/plugnation1/avatars/fd4f4328-56a7-4a30-847b-e5dc568d93c6"
        alt="Event descriptor"
      />
    </Link>
  ) : (
    <img className="avatar avatar-no-hover mr-3" src={displayImage()} alt="Event descriptor" />
  )
);

NotificationImage.propTypes = propTypes;

export default NotificationImage;
