import React from "react";

const Terms = () => (
  <div className="markdown">
    <h1 id="terms-of-use">Terms of Use</h1>
    <p>
      Please read these Terms of Use (&quot;Agreement&quot; or &quot;Terms of
      Use&quot;) carefully before using the services offered by Plug Music, Inc.
      (&quot;Company,&quot; &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;).
      This agreement sets forth the legally binding terms and conditions for
      your use of the Website at{" "}
      <a href="http://www.plugmusic.co">www.plugmusic.co</a> (the
      &quot;Site&quot;) and the service owned and operated by company, including
      any mobile software application offered or published by Company
      (collectively with the Site, the &quot;Service). By using the Site or
      Service in any manner, including but not limited to visiting or browsing
      the Site, you agree to be bound by these Terms of Use. These Terms of Use
      apply to all users of the Site or Service, including users who are also
      contributors of content, information, and other materials or services on
      the Site.
    </p>
    <h3 id="acceptance-of-terms">Acceptance of Terms</h3>
    <p>
      The Service is offered subject to acceptance without modification of all
      of the terms and conditions contained herein (the &quot;Terms of
      Use&quot;), which Terms of Use also incorporate the Privacy Policy at{" "}
      <a href="http://www.plugmusic.co/privacy">
        http://www.plugmusic.co/privacy
      </a>
      , and all other operating rules, policies and procedures that may be
      published from time to time on the Site by Company, each of which is
      incorporated by reference into these Terms of Use and each of which may be
      updated by Company from time to time without prior notice to you. In
      addition, some services offered through the Service may be subject to
      additional terms and conditions; your use of such services is subject to
      those additional terms and conditions, which are incorporated into these
      Terms of Use by this reference.
    </p>
    <p>
      <em>
        If you are using or opening an account on behalf of a company, entity or
        organization (each an &quot;Artist Entity&quot;), then you represent and
        warrant that you : (i) are an authorized representative of that Artist
        Entity with the authority to bind such Artist Entity to these Terms of
        Use and (ii) agree to be bound by these Terms of Use on behalf of such
        Artist Entity and/or Representative.
      </em>
    </p>
    <p>
      You represent and warrant that you are of legal age to form a binding
      contract. The Service is available only to individuals who are at least 13
      years old. If you are 13 or older but under the age of 18, you should
      review these terms and conditions with your parent or guardian to make
      sure that you and your parent or guardian understand these terms and
      conditions.
    </p>
    <p>
      Company may, in its sole discretion, refuse to offer the Service to any
      person or entity and change its eligibility criteria at any time. This
      provision is void where prohibited by law and the right to access the
      Service is revoked in such jurisdictions.
    </p>
    <h3 id="warranty-disclaimer">Warranty Disclaimer</h3>
    <p>
      Company has no special relationship with or fiduciary duty to you. You
      acknowledge that Company has no control over, and no duty to take any
      action regarding: which users gains access to the Site; what Content you
      access via the Site; what effects the Content may have on you; how you may
      interpret or use the Content; or what actions you may take as a result of
      having been exposed to the Content. You release the Company from all
      liability for you having acquired or not acquired Content through the
      Site. The Site may contain, or direct you to websites containing,
      information that some people may find offensive or inappropriate. Company
      makes no representations concerning any Content contained in or accessed
      through the Site, and Company will not be responsible or liable for the
      accuracy, copyright compliance, legality or decency of material contained
      in or accessed through the Site or Service.
    </p>
    <p>
      The Service is provided &quot;as is&quot; and &quot;as available&quot; and
      is without warranty of any kind, express or implied, including, but not
      limited to, the implied warranties of title, non-infringement,
      merchantability and fitness for a particular purpose, and any warranties
      implied by any course of performance or usage of trade, all of which are
      expressly disclaimed. Company, and its directors, employees, agents,
      suppliers, partners and content providers do not warrant that: (i) the
      service will be secure or available at any particular time or location;
      (ii) any defects or errors will be corrected; (iii) any content or
      software available at or through the service is free of viruses or other
      harmful components; or (iv) the results of using the service will meet
      your requirements. Your use of the service is solely at your own risk. It
      is your responsibility to evaluate the accuracy, completeness or
      usefulness of any opinion, advice, information or other materials provided
      in connection with or otherwise available through the Site.
    </p>
    <p>
      Some states do not allow limitations on how long an implied warranty
      lasts, so the above limitations may not apply to you.
    </p>
    <p>
      Electronic Communications Privacy Act (18 U.S.C. §§ 2701-2711): Company
      makes no guarantee of confidentiality or privacy of any communication or
      information transmitted on the Site or any website linked to the Site.
      Company will not be liable for privacy of e-mail addresses, registration
      and identification information, disk space, communications, confidential
      or trade-secret information, or any other Content stored on Company&#39;s
      equipment, transmitted over networks accessed by the Site, or otherwise
      connected with your use of the Service.
    </p>
    <h3 id="limitation-of-liability">Limitation of Liability</h3>
    <p>
      In no event shall Company, nor its directors, employees, agents, partners,
      suppliers or content providers (collectively, &quot;plugmusic.co
      Affiliates&quot;), be liable under contract, tort, strict liability,
      negligence or any other legal or equitable theory with respect to the
      Service including but not limited to (i) any lost profits, data loss, cost
      of procurement of substitute goods or services, or special, indirect,
      incidental, punitive, or consequential damages of any kind whatsoever,
      substitute goods or services (however arising), (ii) any bugs, viruses,
      trojan horses, or the like (regardless of the source of origination), or
      (iii) any direct damages in excess of (in the aggregate) one-hundred U.S.
      dollars ($100.00).
    </p>
    <p>
      If you are a California resident, then you waive California civil code
      §1542, which says: &quot;a general release does not extend to claims which
      the creditor does not know or suspect to exist in his favor at the time of
      executing the release, which if known by him must have materially affected
      his settlement with the debtor.&quot; You, being aware of said code
      section, hereby expressly waive any rights you may have thereunder, as
      well as under any other statutes or common law principles of similar
      effect. You acknowledge and agree that this waiver is an essential and
      material term of this Agreement, and that without such waiver, this
      Agreement would not have been entered into by Company.
    </p>
    <h3 id="international-non-california-use">
      International/Non-California Use
    </h3>
    <p>
      Company makes no representation that the Content is appropriate or
      available for use in locations outside of California, and accessing the
      Service is prohibited from territories where such Content is illegal. If
      you access the Service from other locations, you do so at your own
      initiative and are responsible for compliance with local laws.
    </p>
    <h3 id="indemnification">Indemnification</h3>
    <p>
      You shall defend, indemnify, and hold harmless Company and its affiliates,
      authorized sublicensees and distributors, and each of their employees,
      contractors, directors, suppliers and representatives, from any and all
      liabilities, claims, and expenses, including reasonable attorneys&#39;
      fees and court costs, that arise from or relate to your use or misuse of,
      or access to, the Site, Service, Content or otherwise from your violation
      of these Terms of Use, or Infringement by you, or any third party using
      your account, of any of intellectual property or other right of any person
      or entity, and for breach of any of your representations and warranties in
      these Terms of Use. For the avoidance of doubt, you hereby agree to
      defend, indemnify, and hold harmless Company from any and all claims by a
      third party owning, controlling or claiming any right in or to your music,
      including claims for performance royalties, mechanical royalties, and use
      or re-use fees. Company reserves the right to assume the exclusive defense
      and control of any matter otherwise subject to indemnification by your, in
      which event you will assist and cooperate with Company in asserting any
      available defenses at your sole expense.
    </p>
    <h3 id="modification-of-terms-of-use">Modification of Terms of Use</h3>
    <p>
      Company reserve the right, at its sole discretion, to modify or replace
      any of the terms in these Terms of Use, or change, suspend, or discontinue
      the Service (including without limitation, the availability of any
      feature, database, or content) at any time by posting a notice on the Site
      or by sending you an e-mail. Company may also impose limits on certain
      features and services or restrict your access to parts or all of the
      Service without notice or liability. It is your responsibility to check
      these Terms of Use periodically for changes. Your continued use of the
      Service following the posting of any changes to these Terms of Use
      constitutes acceptance of those changes. Notwithstanding the preceding
      sentences of this section, no modifications to these Terms of Use will
      apply to any dispute between you and Company that arose prior to the date
      of such modifications.
    </p>
    <h3 id="rules-of-conduct">Rules of Conduct</h3>
    <p>
      As a condition of use, you promise not to use the Service for any purpose
      that is prohibited by these terms of use. For the purposes of these Terms
      of Use, the term &quot;Content&quot; includes, without limitation, any
      audio, comments, text, photographs, graphics provided, or otherwise made
      accessible by Company or its partners on or through the Service.
    </p>
    <p>
      You shall not (a) take any action or (b) upload, download, post, submit or
      otherwise distribute or facilitate distribution of any content on or
      through the Service, including by not limited to Content that:
    </p>
    <ul>
      <li>
        Is unlawful, threatening, abusive, defamatory, fraudulent, obscene,
        vulgar, harassing, or invasive of privacy or publicity rights
      </li>
      <li>
        Violates or infringes in any way upon the rights of others including but
        not limited to patents, or copyrights
      </li>
      <li>
        Contains viruses, trojans, worms, logic bombs or other material which is
        malicious or technologically harmful
      </li>
      <li>Impersonates a person or entity</li>
    </ul>
    <p>Additionally, you shall not:</p>
    <ul>
      <li>
        Attempt to modify, edit, disassemble, extract code or reverse engineer
        any part of the service
      </li>
      <li>
        Bypass any measure Company may use to prevent or restrict access to the
        Service
      </li>
      <li>Intentionally overload servers on the Site</li>
    </ul>
    <p>
      Company does not guarantee that any Content will be made available on the
      Site or through the Service. Company has no obligation to monitor the
      Site, Service, or Content and reserves the right to remove, edit or modify
      any Content in its sole discretion from the Site at any time without
      notice to you and for any reason.
    </p>
    <h3 id="integration-and-severability">Integration and Severability</h3>
    <p>
      These Terms of Use are the entire agreement between you and Company with
      respect to the Service and use of the Site, and supersede all prior or
      contemporaneous communications and proposals (whether oral, written or
      electronic) between you and Company with respect to the Site. If any
      provision of these Terms of Use is held to be invalid or unenforceable,
      such provision shall be struck and the remaining provisions shall be
      enforced. Any delay in enforcing our rights or bringing action against you
      shall not be a waiver of our rights. Even if we delay in enforcing this
      contract, we can still enforce later.
    </p>
    <h3 id="dispute-resolution">Dispute Resolution</h3>
    <p>
      Your correspondence or business dealing with parties found on or through
      the Site, including payment and delivery of related goods or services, and
      any other terms, conditions, warranties or representations applicable to
      such dealings, are solely between you and such parties.
    </p>
    <p>
      There Terms of Use are governed in all respects by the laws of the State
      of California as such laws are applied to agreements entered into and to
      be performed entirely within California between California residents.
      Legal proceedings related to the matters herein shall be brought in and
      adjudicated solely in the courts of Los Angeles County, California. Both
      parties consent to extra-territorial service of process and submit to the
      jurisdiction of said courts.
    </p>
    <p>
      If a dispute arises out of or relates to this Agreement, or if there is a
      breach of this Agreement, and the dispute cannot be setted or resolved,
      then the dispute or breach shall be settled by arbitration administered by
      the American Arbitration Association under its Commercial Arbitration
      Rules. The controversy or claim shall be settled by three (3) arbitrators,
      and all hearings shall be held in Los Angeles County, California. Judgment
      on the award rendered by the arbitrators may be entered in any court
      having jurisdiction. In rendering the award, the arbitrators shall
      interpret this Agreement in accordance with the substantive laws of
      California without regard to its conflict of laws rule. Notwithstanding
      the foregoing, if a third party claim is brought against the Company for
      copyright infringement, violation of rights of publicity, rights of
      privacy, or other unauthorized use of Content which is contrary to the
      rights granted by the Artist to Company in this Agreement, Company shall
      not be bound by this Arbitration provision and may defend itself and make
      a claim against Artist in the appropriate court of law and/or equity.
    </p>
    <h3 id="third-party-site">Third Party Site</h3>
    <p>
      The Service may permit you to link to other websites or resources on the
      internet, and other websites or resources may contain links to the
      Site.The inclusion of any such link does not imply endorsement by Company
      or any association with its operators. When you access third party
      websites, you enter at your own risk and you acknowledge that Company is
      not responsible nor liable for the content, functions, accuracy, legality,
      appropriateness or any other aspect of such websites or resources.
      Furthermore, you acknowledge and agree that Company shall not be
      responsible for any damages or loss caused by or in connection with the
      use of or reliance on any such Content, goods or services available on or
      through any such website or resource.
    </p>
    <h3 id="use-of-content-found-on-site">Use of Content Found on Site</h3>
    <p>
      The information, artwork, graphics, text, video, audio, photos, software
      and other intellectual property (collectively, &quot;Content&quot;)
      contained on the Site are protected by copyrights, trademarks, service
      marks, patents, trade secrets or other proprietary rights and laws. You
      shall abide by and maintain all copyright notices, information, and
      restrictions contained in any Content accessed through the Service.
    </p>
    <p>
      You may only access and use the Content for personal or educational
      purposes.You may not otherwise reproduce, distribute, publicly perform,
      publicly display, modify or create derivative works of the Content, unless
      authorized by the appropriate copyright owner(s).
    </p>
    <h3 id="copyright-and-trademark-infringement-policy">
      Copyright and Trademark Infringement Policy
    </h3>
    <p>
      Company does not own the Content posted by third parties to the Site.
      Third party Content is posted by an individual, group or company
      (collectively, the &quot;Artist&quot;) who has represented and warranted
      the Company that, among other things, neither the Content nor the names,
      trademarks and service marks under which Content is promoted infringes any
      third party&#39;s copyright, patent, trademark, trade secret or other
      proprietary rights.
    </p>
    <p>
      Disputes should be resolved directly with the Artists. The Company is not
      in a position to discern the prevailing claim. Sometimes Artists may not
      realize they are potentially infringing, so a notification to the Artist
      will stop them from using the disputed Content.
    </p>
    <p>
      Complainants can also contact Company at{" "}
      <a href="http://www.plugmusic.co/contact-us">
        http://www.plugmusic.co/contact-us
      </a>
      . Company is under no obligation to take any action, beyond what is
      required by any relevant laws or authorities, but typically the Company
      will remove the infringing content, or the Artists&#39; account if the
      Artist is believed to be complicit in the dispute.
    </p>
    <h3 id="termination">Termination</h3>
    <p>
      You may terminate your account at any time by following the instructions
      on the Site. Company may terminate your access to the Service at any time,
      with or without cause, with or without notice, effective immediately,
      which may result in the forfeiture and destruction of all information
      associated with your membership, including, but not limited to, any access
      to any Music you may have purchased through the Service.
    </p>
    <p>
      If you have sold Beats on the service, Company and all authorized
      sublicensees will cease the distribution of the Content. If a user has
      purchased your Beats, the user will still have access to that Beat but it
      will no longer be accessible to others. Our obligation to pay you amounts
      due to you under this Agreement survives termination.
    </p>
    <p>
      All provisions of these Terms of Use which by their nature should survive
      termination shall survive termination, including, without limitation,
      ownership provisions, warranty disclaimers, indemnity and limitations of
      liability.
    </p>
    <h3 id="miscellaneous">Miscellaneous</h3>
    <p>
      Company shall not be liable for any failure to perform its obligations
      hereunder where such failure results from any cause beyond Company&#39;s
      reasonable control, including, without limitation, mechanical, electronic
      or communications failure or degradation (including &quot;line-noise&quot;
      interference). These Terms of Use are personal to you, and are not
      assignable, transferable or sublicensable by you except with Company&#39;s
      prior written consent. Company may assign, transfer or delegate any of its
      rights and obligations hereunder without consent.
    </p>
    <p>
      This Agreement shall not be deemed to create a partnership or joint
      venture between the parties and neither is the other&#39;s partner or
      employee.
    </p>
    <p>
      The captions and headings in this Agreement are intended only for
      convenience, and will in no event be construed to define, limit or
      describe the scope or intent of this Agreement, or of any provision of
      this Agreement, nor in any way affect the interpretation of this
      Agreement.
    </p>
    <h2 id="selling-music">Selling Music</h2>
    <p>
      You have the right to terminate this agreement and all licenses granted to
      us at any time.
    </p>
    <p>
      If you provide us with sound recordings, compositions, musical works
      and/or other material such as photos, videos, song lyrics, graphics
      (collectively &quot;Content) the following terms apply:
    </p>
    <h3 id="ownership">Ownership</h3>
    <p>
      You retain full ownership of the copyrights and all other rights to your
      Content, subject to the non-exclusive rights granted to us under this
      agreement. You are free to grant similar rights to others during and after
      the term of this agreement.
    </p>
    <h3 id="grant-of-rights">Grant of Rights</h3>
    <p>
      The Artist grants the Company, and its authorized sublicensees if any, a
      non-exclusive, worldwide, royalty-free, license with the right to:
    </p>
    <ul>
      <li>
        Transfer, copy, edit, store, distribute, sell, and publicly perform the
        Content via all electronic, digital, and mobile platforms owned and/or
        operated by or on behalf of Company or its authorized sublicensees and
        distributors
      </li>
      <li>Use the Content for the promotion and marketing of the Company</li>
    </ul>
    <h3 id="producer-obligations">Producer Obligations</h3>
    <p>By uploading Content, you warrant and represent that:</p>
    <ul>
      <li>
        You own or otherwise control all rights to your Content and you have
        full authority and permission to make the Content available in the
        manner offered by you
      </li>
      <li>
        The sale, distribution, and exploitation of the Content, or any uses of
        the Content described herein will not violate any law or infringe on the
        rights of third parties, including writers, sample owners, or artists
      </li>
      <li>
        You will not purchase your own Beats or attempt to manipulate the proper
        functions of the Site
      </li>
      <li>
        No fees of any kind shall be due to any third party, including any
        union, guild, or engineer for the use of your Content as authorized
        under this Agreement
      </li>
    </ul>
    <h3 id="beat-pricing">Beat Pricing</h3>
    <p>
      You have the ability to set prices for your Beats anywhere above $5.00 and
      you may change prices at your sole discretion. You also have the ability
      to offer a free download of your Beat if you choose. The Company may
      redistribute previously purchased copies of your Beat to users who have,
      in the Company&#39;s sole determination, received a corrupted copy, an
      incorrect file format version, an incomplete copy, or suffered a loss of
      your Beat through hard drive failure, damage, theft or destruction, on a
      no-fee basis to the user. No additional payments shall be made to you for
      such redistributions.
    </p>
    <p>
      Company retains the right to immediately halt the offering or sale of
      Beats, prevent or restrict access to the Site or the Services or take any
      action in case of technical problems, inaccuracies, violations, or any
      other reason in the sole and absolute discretion of Company, and to
      correct any inaccuracies or technical problems on the Site.
    </p>
    <h3 id="fees-and-payments">Fees and Payments</h3>
    <p>
      You shall be solely responsible and liable for Stripe or Paypal fees,
      credit card transaction fees, bad debts, disputed payments, and refunds,
      except as provided in this Agreement.
    </p>
    <p>
      You shall be responsible for any taxes, duties, charges or levies imposed
      by government agencies or required by applicable law, rule or regulation.
      Company shall remit any such withheld taxes, duties, charges or levies to
      the appropriate tax authorities.
    </p>
    <p>
      When you receive a payment, you are liable to Plug Music for the full
      amount of the payment sent to you plus any fees if the payment is later
      invalidated for any reason. In other words, in addition to any other
      liability, you will be responsible for the amount of the payment sent by
      the sender, plus the applicable fees if there is a chargeback, a dispute,
      or if there is a reversal of the payment. You agree to allow Plug Music to
      recover any amounts due to Plug Music by debiting your account or
      withholding payments. If there are insufficient funds to cover you
      liability, you agree to reimburse Plug Music through other means.
    </p>
    <h2 id="buying-music">Buying Music</h2>
    <h3 id="conditions-of-sale">Conditions of Sale</h3>
    <p>
      Plug Music provides a platform for third parties (producers) to sell their
      Beats. When you purchase a Beat, your contractual relationship is with the
      seller of that beat (producer) and not with Plug Music. These producers
      are required to confirm that their Beats do not infringe on any third
      party rights and that they have the full permission to sell their Beats.
      However, upon buying a beat and distributing the composition you create
      with the Beat, you should still make sure that all samples, if any, are
      cleared and you are not infringing on any third party rights.
    </p>
    <p>
      The producer sets licensing terms on their Beats and they are available to
      you before and upon purchase. By accessing our website you agree that you
      shall not breach another party&#39;s intellectual property rights and that
      you will adhere to the terms of any license agreement. If you do not abide
      by those licensing terms, you will be liable to a potential breach of
      contract and/or intellectual property. If there is reason to believe you
      have breached the producer&#39;s terms, we reserve the right (without
      limiting any other rights and remedies) to terminate or suspend your
      account and share information with the relevant rights holders or
      authorities.
    </p>
    <h3 id="payments">Payments</h3>
    <p>
      Your total price for Beats will include the price of the product plus any
      applicable sales tax - which is based on your location and the sales tax
      rate in effect at the time you purchase the Beat. We will charge tax only
      in states or countries where digital goods are taxable.
    </p>
    <p>
      Beats you purchase cannot be guaranteed to be available to you
      perpetually. For example, the Beats may be removed from the Site because
      of infringement. If we are required by law to remove the Beat, the Company
      and Artists will not provide the user who purchased that Beat with a
      refund, except as required by applicable law. The purchaser bears all risk
      from the denial of access to any Content purchased through the Service. We
      recommend that you promptly download the Content you purchase through the
      Site to ensure that the Content will remain accessible to you.
    </p>
    <p>
      You warrant that if you enter into a Transaction, then you shall be able
      to make full and immediate payment for the requested products or services.
    </p>
    <h3 id="refund-policy">Refund Policy</h3>
    <p>
      All digital sales are final. You understand and agree that Plug Music is a
      platform for Producers to sell their Beats, and the Producer is solely
      responsible for the files.
    </p>
    <p>
      If you do not receive the Beat or have an issue with the Beat delivered,
      please contact us with your request and proof of payment, and we will
      notify the relevant Producer and work with them to resolve your issue. At
      our sole discretion, you may be credited or refunded for the Beat.
    </p>
  </div>
);

export default Terms;
