// This code is no longer in use, as the "early-submit" project required us to bundle all
// tracking of state into the S3 HOR (oh dayum) for simplicity
//
// This file can safely be deleted, but then the createTrackActions file should also be axed

const initialState = {
  fileIds: [],
  processing: false,
  imageDropped: false,
  filesDropped: false,
  formData: {
    name: '',
    description: '',
    tags: '',
    trackId: '',
    imageId: '',
    genre: '',
  },
};

export default function createTrack(state = initialState, action) {
  switch (action.type) {
    // Tracks ids in the create track form (to separate from other uploads which might be happening elsewhere in the app)
  case 'CREATE_TRACK_SET_FILE_IDS':
    return Object.assign({}, state, {
      fileIds: action.ids,
    });
  case 'CREATE_TRACK_ADD_FILE_IDS':
    return Object.assign({}, state, {
      fileIds: Array.from([...state.fileIds, ...action.ids]),
    });
  case 'CREATE_TRACK_REMOVE_FILE_IDS':
    return Object.assign({}, state, {
      fileIds: state.fileIds.filter(element => !action.ids.includes(element)),
    });
  case 'CREATE_TRACK_SET_PROCESSING':
    return Object.assign({}, state, {
      processing: action.processing,
    });
  case 'CREATE_TRACK_UPDATE_FORM_DATA':
    return Object.assign({}, state, {
      formData: Object.assign({}, state.formData, action.formData),
    });
  case 'CREATE_TRACK_SET_IMAGE_DROPPED':
    return Object.assign({}, state, {
      imageDropped: action.dropped,
    });
  case 'CREATE_TRACK_SET_FILES_DROPPED':
    return Object.assign({}, state, {
      filesDropped: action.dropped,
    });
  default:
    return state;
  }
}
