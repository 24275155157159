import {
  CHECKOUT_ERROR,
  CHECKOUT_LOADING,
  CHECKOUT_SUCCESS,
  CHECKOUT_FAILURE,
  CHECKOUT_RELOAD,
  CHECKOUT_ADD_GUEST,
} from 'types/checkout';

export const checkoutError = payload => ({
  type: CHECKOUT_ERROR,
  payload,
});

export const checkoutLoading = () => ({
  type: CHECKOUT_LOADING,
});

export const checkoutReload = () => ({
  type: CHECKOUT_RELOAD,
});

export const addGuestEmail = payload => ({
  type: CHECKOUT_ADD_GUEST,
  payload,
});

export const checkoutSuccess = () => ({
  type: CHECKOUT_SUCCESS,
});

export const checkoutFailure = () => ({
  type: CHECKOUT_FAILURE,
});

export default module.exports;
