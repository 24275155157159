import React from 'react';
import PropTypes from 'prop-types';
import ExitButton from 'components/common/ExitButton';
import RegisterForm from 'components/Register/RegisterForm';

const propTypes = {
  onClose: PropTypes.func.isRequired,
};

const RegisterModal = ({ onClose }) => (
  <div id="register-modal">
    <div className="flex justify-between mb-6">
      <h1 className="title" style={{ marginBottom: 0 }}>
        Register to join the site
      </h1>
      <ExitButton id="delete-track-exit" onClick={onClose} />
    </div>
    <div className="my-6">
      <h2 className="font-normal font-serif md:w-4/5 text-xl">
        By joining the website, you may access the beats you bought from anywhere. You may play them, download them, and upgrade the license on them.
      </h2>
    </div>
    <RegisterForm vocalist onAfterSubmit={onClose} />
  </div>
);

RegisterModal.propTypes = propTypes;

export default RegisterModal;
