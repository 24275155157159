import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from 'components/QueryRenderer';
import DeactivateAccountModal from './DeactivateAccountModal';

const deleteUserQuery = graphql`
  query DeactivateAccountModalContainerQuery($userId: UUID!) {
    user: userById(id: $userId) {
      ...DeactivateAccountModal_user
    }
  }
`;

const propTypes = {
  modals: PropTypes.instanceOf(Immutable.Map).isRequired,
  auth: PropTypes.object.isRequired,
};

const DeactivateAccountModalContainer = ({
  modals,
  auth,
  ...props
}) => {
  const userId = modals.getIn(['data', 'userId']);
  return (
    <QueryRenderer
      auth={auth}
      query={deleteUserQuery}
      variables={{ userId }}
      component={DeactivateAccountModal}
      {...props}
    />
  );
};

DeactivateAccountModalContainer.propTypes = propTypes;

export default connect(({ auth }) => ({ auth }))(DeactivateAccountModalContainer);
