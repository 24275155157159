/**
 * @flow
 * @relayHash 824e3ff36e146e5b05bf046577ddb59d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateTrackMutationVariables = {|
  input: {
    clientMutationId?: ?string,
    track: {
      id?: ?any,
      name: string,
      description?: ?string,
      userId: any,
      publicId?: ?string,
      picture?: ?string,
      dominantcolor?: ?string,
      overlaycolor?: ?string,
      alternativecolor?: ?string,
      fileUrl?: ?string,
      stems?: ?boolean,
      free?: ?boolean,
      bpm?: ?number,
      length?: ?number,
      mood?: ?string,
      genre?: ?string,
      createdAt?: ?any,
      updatedAt?: ?any,
      deleted?: ?boolean,
      mp3Loaded?: ?boolean,
    },
  },
|};
export type CreateTrackMutationResponse = {|
  +createTrack: ?{|
    +track: ?{|
      +id: any,
    |},
  |},
|};
*/


/*
mutation CreateTrackMutation(
  $input: CreateTrackInput!
) {
  createTrack(input: $input) {
    track {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateTrackInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createTrack",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "CreateTrackInput!"
      }
    ],
    "concreteType": "CreateTrackPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "track",
        "storageKey": null,
        "args": null,
        "concreteType": "Track",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CreateTrackMutation",
  "id": null,
  "text": "mutation CreateTrackMutation(\n  $input: CreateTrackInput!\n) {\n  createTrack(input: $input) {\n    track {\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateTrackMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateTrackMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node/*: any*/).hash = '1ad4b013241a65aa6129ca4ea4957acc';
module.exports = node;
