import React from 'react';
import ReactDrop from 'react-dropzone';
import PropTypes from 'prop-types';
import FullDropzoneView from './FullDropzoneView';

const propTypes = {
  children: PropTypes.node.isRequired,
  fileDropHandler: PropTypes.func.isRequired,
  acceptableFiles: PropTypes.string.isRequired,
  canHaveMulti: PropTypes.bool,
  rejectText: PropTypes.string,
};

const defaultProps = {
  canHaveMulti: true,
  rejectText: '',
};

const FullDropzone = ({
  children,
  fileDropHandler,
  acceptableFiles,
  canHaveMulti,
  rejectText,
}) => {
  let dropzoneRef;

  const handleClick = () => {
    dropzoneRef.open();
  };

  const createNewChildren = (
    isDragActive,
    isDragReject,
    acceptedFiles,
    rejectedFiles,
  ) => {
    const newChild = children({
      dropzoneRefClick: handleClick,
      dropzoneRejectedFiles: rejectedFiles,
      dropzoneAcceptedFiles: acceptedFiles,
    });
    return (
      <div>
        {isDragActive && (
          <FullDropzoneView isDragReject={isDragReject} rejectText={rejectText} />
        )}
        {newChild}
      </div>
    );
  };

  return (
    <ReactDrop
      disableClick
      accept={acceptableFiles}
      multiple={canHaveMulti}
      onDrop={fileDropHandler}
      ref={(node) => {
        dropzoneRef = node;
      }}
      className="w-full h-full"
    >
      {({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) =>
        createNewChildren(
          isDragActive,
          isDragReject,
          acceptedFiles,
          rejectedFiles,
        )
      }
    </ReactDrop>
  );
};

FullDropzone.propTypes = propTypes;
FullDropzone.defaultProps = defaultProps;

export default FullDropzone;
