import types from 'types/invites';
import {
  fetchInvitesApi,
  fetchInvitesSentApi,
  fetchInvitesReceivedApi,
  addInviteApi,
  updateInviteApi,
} from 'rest/invites';
import { requestWrapper } from './helper';

export const fetchInvitesAsync = requestWrapper(
  'FETCH_INVITES',
  fetchInvitesApi,
);

export const fetchInvitesSentAsync = requestWrapper(
  'FETCH_INVITES_SENT',
  fetchInvitesSentApi,
);

export const fetchInvitesReceivedAsync = requestWrapper(
  'FETCH_INVITES_RECEIVED',
  fetchInvitesReceivedApi,
);

export const addInviteAsync = requestWrapper('ADD_INVITE', addInviteApi);

export function addInviteSuccess(payload) {
  return {
    type: types.ADD_INVITE_SUCCESS,
    payload,
  };
}

export const updateInviteAsync = requestWrapper(
  'UPDATE_INVITE',
  updateInviteApi,
);

export function updateInviteSuccess(payload) {
  return {
    type: types.UPDATE_INVITE_SUCCESS,
    payload,
  };
}

export const resetInvites = () => ({ type: types.RESET_INVITES });

export default module.exports;
