import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Slider from 'material-ui/Slider';
import Paper from 'material-ui/Paper';
import * as soundbarActions from 'actions/soundbar';

const propTypes = {
  toggleVolumeControl: PropTypes.func.isRequired,
  volumeBarShowing: PropTypes.bool.isRequired,
  volume: PropTypes.number.isRequired,
  mute: PropTypes.bool.isRequired,
  soundbarUpdate: PropTypes.func.isRequired,
};

const VolumeSlider = ({
  toggleVolumeControl,
  volumeBarShowing,
  volume,
  mute,
  soundbarUpdate,
}) => {
  const toggleMute = () => {
    soundbarUpdate({ mute: !mute });
  };

  const changeVolume = (value) => {
    soundbarUpdate({ volume: value, mute: false });
  };

  let iconClass;
  if (mute || volume === 0) {
    iconClass = 'fa-volume-off';
  } else if (volume < 0.5) {
    iconClass = 'fa-volume-down';
  } else {
    iconClass = 'fa-volume-up';
  }

  return (
    <div
      className="flex items-center ml-5"
      style={{ minWidth: '30px', maxWidth: '100px', flexBasis: '20%' }}
    >
      <Paper
        style={{
          position: 'absolute',
          top: '-120px',
          height: '120px',
          width: '40px',
          display: 'inline-block',
          visibility: volumeBarShowing ? 'visible' : 'hidden',
          backgroundColor: '#fafafa',
        }}
        onMouseLeave={toggleVolumeControl}
      >
        <Slider
          value={mute ? 0 : volume}
          onChange={(e, value) => {
            changeVolume(value);
          }}
          axis="y"
          style={{
            height: '100%',
            padding: '15px 10px',
          }}
          sliderStyle={{
            padding: 10,
            marginTop: 0,
            marginBottom: 0,
          }}
        />
      </Paper>
      <a
        role="button"
        tabIndex={0}
        onClick={toggleMute}
        className="outline-none"
        onMouseEnter={toggleVolumeControl}
        style={{
          paddingLeft: 12,
        }}
      >
        <i
          className={classnames('icon-color fa', iconClass)}
          aria-hidden="true"
        />
      </a>
    </div>
  );
  // }
};

VolumeSlider.propTypes = propTypes;

export default connect(
  state => ({
    volume: state.soundbar.volume,
    mute: state.soundbar.mute,
  }),
  soundbarActions,
)(VolumeSlider);
