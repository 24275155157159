import graphql from 'babel-plugin-relay/macro';
import promiseMutation from 'utils/promise-mutation';


const mutation = graphql`
  mutation UpdateUserByIdMutation($input: UpdateUserByIdInput!) {
    updateUserById(input: $input) {
      user {
        id,
        username,
        displayName,
        description,
      }
    }
  }
`;

const commit = (environment, user, userId) =>
  promiseMutation(environment, {
    mutation,
    variables: { input: { id: userId, userPatch: user } },
  });

export default commit;
