import promiseMutation from 'utils/promise-mutation';
import graphql from 'babel-plugin-relay/macro';

const mutation = graphql`
  mutation DeleteProductByIdMutation($input: DeleteProductByIdInput!) {
    deleteProductById(input: $input) {
      track: trackByTrackId {
        ...TrackPlayer_track
      }
    }
  }
`;

const commit = (environment, id) =>
  promiseMutation(environment, {
    mutation,
    variables: { input: { id } },
  });

export default commit;
