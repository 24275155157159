import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import TextArea from 'components/common/TextArea';
import TextInput from 'components/common/TextInput';

const fields = ['email', 'title', 'description'];
const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.title) {
    errors.title = 'Required';
  }
  if (!values.description) {
    errors.description = 'Required';
  }
  return errors;
};

class FeedbackForm extends Component {
  static propTypes = {
    error: PropTypes.string,
    handleSubmit: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
    contactUs: PropTypes.bool,
  };

  static defaultProps = {
    error: '',
    submitting: false,
    contactUs: false,
  };

  componentDidMount() {
    if (!this.props.contactUs && this.emailRef) {
      this.emailRef.focus();
    }
  }

  setEmailRef = (input) => {
    this.emailRef = input;
  };

  render() {
    const { error, handleSubmit, submitting } = this.props;
    return (
      <form className="md:mr-8 md:pr-8 text-left" onSubmit={handleSubmit}>
        <Field
          className="w-full"
          name="email"
          label="Email"
          type="email"
          component={TextInput}
          setRef={this.setEmailRef}
        />

        <Field
          className="w-full"
          name="title"
          label="Title"
          type="text"
          component={TextInput}
        />

        <Field
          className="w-full"
          name="description"
          label="Description"
          placeholder="Help us improve your experience..."
          component={TextArea}
        />
        {error && <div className="error">{error}</div>}
        <button className="btn w-full md:w-32 mt-4" type="submit" disabled={submitting}>
          Submit
        </button>
      </form>
    );
  }
}

export default compose(
  reduxForm({
    form: 'feedback',
    fields,
    validate,
  }),
  connect(state => ({ auth: state.auth })),
)(FeedbackForm);
