import React from 'react';
import producerImage from 'assets/soundboard.jpg';
import SellBeatsButton from './SellBeatsButton';

const SellBeats = () => (
  <div className="text-center">
    <div
      className="h-screen flex-center"
      style={{
        background: `url(${producerImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <div>
        <h1
          className="title px-4 font-semibold text-white"
          style={{ marginTop: '6rem', marginBottom: '5rem', fontSize: '4rem' }}
        >
          The best platform to sell beats
        </h1>
        <div className="my-8">
          <SellBeatsButton className="text-white hover:text-white bg-transparent hover:bg-transparent border-gray-lighter" />
        </div>
      </div>
    </div>
    <div className="bg-gray-light md:flex md:flex-wrap mx-2 my-4 md:py-6 md:px-8 text-left">
      <div className="lg:px-4 lg:w-1/3 md:px-3 md:w-1/2 px-2 py-6">
        <div className="font-bold py-3 text-2xl text-3xl">Make Money</div>
        <div className="my-3 hidden">
          <i className="fa fa-dollar" style={{ color: '#00d1b2' }} />
        </div>
        <div className="text-xl font-serif leading-normal">
          Free to use, we only charge a small fee per sale. We use Stripe to
          securely process all transactions.
        </div>
      </div>
      <div className="lg:px-4 lg:w-1/3 md:px-3 md:w-1/2 px-2 py-6">
        <div className="font-bold py-3 text-2xl text-3xl">Easy to Use</div>
        <div className="my-3 hidden">
          <i className="fa fa-check-circle" style={{ color: '#00d1b2' }} />
        </div>
        <div className="text-xl font-serif leading-normal">
          Only worry about uploading your track. We handle the rest. We take
          care of File conversions, Security audio tags, Licensing.
        </div>
      </div>
      <div className="lg:px-4 lg:w-1/3 md:px-3 md:w-1/2 px-2 py-6">
        <div className="font-bold py-3 text-2xl text-3xl">Promotion</div>
        <div className="my-3 hidden">
          <i className="fa fa-comments" style={{ color: '#00d1b2' }} />
        </div>
        <div className="text-xl font-serif leading-normal">
          Rappers and singers will reach out to you after searching for your
          sound. And we promote our music creatives on our Instagram.
        </div>
      </div>
    </div>
    <div style={{ marginTop: '7rem', marginBottom: '7rem' }}>
      <SellBeatsButton />
    </div>
  </div>
);

export default SellBeats;
