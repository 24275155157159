/**
 * @flow
 * @relayHash faeb80dd4be3bbcd71992a0656527305
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LikeIconContainerQueryVariables = {|
  userId: any,
  trackId: any,
|};
export type LikeIconContainerQueryResponse = {|
  +like: ?{|
    +nodeId: string,
  |},
|};
*/


/*
query LikeIconContainerQuery(
  $userId: UUID!
  $trackId: UUID!
) {
  like: likeByUserIdAndTrackId(userId: $userId, trackId: $trackId) {
    nodeId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "userId",
    "type": "UUID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "trackId",
    "type": "UUID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": "like",
    "name": "likeByUserIdAndTrackId",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "trackId",
        "variableName": "trackId",
        "type": "UUID!"
      },
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId",
        "type": "UUID!"
      }
    ],
    "concreteType": "Like",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "nodeId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "LikeIconContainerQuery",
  "id": null,
  "text": "query LikeIconContainerQuery(\n  $userId: UUID!\n  $trackId: UUID!\n) {\n  like: likeByUserIdAndTrackId(userId: $userId, trackId: $trackId) {\n    nodeId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "LikeIconContainerQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "LikeIconContainerQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node/*: any*/).hash = '0b85659ffabd34573c4e97c58f1d62fe';
module.exports = node;
