export function requestWrapper(type, request) {
  return (...args) => (dispatch) => {
    dispatch({
      type: `${type}_REQUEST`,
    });

    return request(...args)
        .then((data) => {
          dispatch({
            type: `${type}_SUCCESS`,
            payload: data,
          });
          return data;
        })
        .catch((err) => {
          dispatch({
            type: `${type}_FAILURE`,
            payload: err,
          });
          return Promise.reject(err);
        });
  };
}