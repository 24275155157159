/**
 * @flow
 * @relayHash 367f5a98ae8e5ac3abad42176c99b851
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DeactivateAccountModal_user$ref = any;
export type DeactivateAccountModalContainerQueryVariables = {|
  userId: any,
|};
export type DeactivateAccountModalContainerQueryResponse = {|
  +user: ?{|
    +$fragmentRefs: DeactivateAccountModal_user$ref,
  |},
|};
*/


/*
query DeactivateAccountModalContainerQuery(
  $userId: UUID!
) {
  user: userById(id: $userId) {
    ...DeactivateAccountModal_user
  }
}

fragment DeactivateAccountModal_user on User {
  id
  username
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "userId",
    "type": "UUID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "userId",
    "type": "UUID!"
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DeactivateAccountModalContainerQuery",
  "id": null,
  "text": "query DeactivateAccountModalContainerQuery(\n  $userId: UUID!\n) {\n  user: userById(id: $userId) {\n    ...DeactivateAccountModal_user\n  }\n}\n\nfragment DeactivateAccountModal_user on User {\n  id\n  username\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DeactivateAccountModalContainerQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "user",
        "name": "userById",
        "storageKey": null,
        "args": v1,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "DeactivateAccountModal_user",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DeactivateAccountModalContainerQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "user",
        "name": "userById",
        "storageKey": null,
        "args": v1,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "username",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
(node/*: any*/).hash = '7238458577fe8470e0fb0c2c0213f3ae';
module.exports = node;
