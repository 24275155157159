import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import projects from 'assets/projects.svg';
import notifications from 'assets/notifications.svg';
import profile from 'assets/profile.svg';
import searchDesktop from 'assets/search-desktop.svg';
import home from 'assets/home.svg';

const propTypes = {
  auth: PropTypes.object.isRequired,
};
const NavbarMobile = ({ auth: { username } }) => (
  <div className="flex items-center w-full">
    <div className="icon-div-mobi">
      <Link to="/">
        <img
          src={home}
          alt="Home"
          style={{ height: '20px', maxWidth: 'none' }}
        />
      </Link>
      <img src={projects} alt="Projects" style={{ height: '20px' }} />
      <Link to="/search">
        <img src={searchDesktop} alt="Search" style={{ height: '20px' }} />
      </Link>
      <Link to="/notifications">
        <img
          src={notifications}
          alt="Notifications"
          style={{ height: '20px' }}
        />
      </Link>
      <Link to={`/${username}`} className="profile">
        <img
          src={profile}
          alt="Profile"
          style={{ height: '20px', maxWidth: 'none' }}
        />
      </Link>
    </div>
  </div>
);
NavbarMobile.propTypes = propTypes;
export default connect(state => ({ auth: state.auth }))(NavbarMobile);
