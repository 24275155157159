import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import getImagePalette from 'image-palette-core';
// import Jimp from 'jimp';
import get from 'lodash/get';
import hasIn from 'lodash/hasIn';
import { withRouter } from 'react-router-dom';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import { reset } from 'redux-form';
import { makeZip, unzip } from 'helpers/zip';
import { mergeAudio } from 'helpers/mergeAudio';
import withS3 from 'utils/S3';
import objectToMap from 'utils/object-to-map';
import rgbToHex from 'utils/rgb-hex';
import CreateTrackMutation from 'mutations/track/CreateTrackMutation';
import CreateHashtagMutation from 'mutations/hashtag/CreateHashtagMutation';
import CreateProductMutation from 'mutations/product/CreateProductMutation';

import FullDropzone from 'utils/FullDropzone';
import CreateTrackInfo from './CreateTrackInfo';
import MusicUpload from './MusicUpload';

const pica = require('pica/dist/pica')();
const uuidV4 = require('uuid/v4');

const propTypes = {
  reset: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  allMoods: PropTypes.object.isRequired,
  allGenres: PropTypes.object.isRequired,
  s3Context: PropTypes.object.isRequired,
  s3DestroyContext: PropTypes.func.isRequired,
  s3Upload: PropTypes.func.isRequired,
  s3Delete: PropTypes.func.isRequired,
  s3Abort: PropTypes.func.isRequired,
  s3ResetFile: PropTypes.func.isRequired,
  s3AddMetadata: PropTypes.func.isRequired,
  s3DeleteMetadata: PropTypes.func.isRequired,
};

const defaultTagUrl = 'https://s3-us-west-1.amazonaws.com/plugnation1/resources/PlugMusic_tag.mp3';
const s3TrackFolderName = 'track';
const s3CleanWavName = 'clean';
const s3StemsName = 'stems';
const s3ImageName = 'image';
const acceptableAudioTypes = ['mp3', 'wav', 'x-wav']; // Make sure this matches acceptableFiles prop in MusicUpload in renderMusicUpload

const showErrorDetails = false;
const uploadRules = { match: 'metadata.trackId' }; // This forces a dispatched action to abort any upload with fileId not matching the specified key
const isAcceptableAudio = fileType => acceptableAudioTypes.some(type => `audio/${type}` === fileType);
const formName = 'Track';

const getPricingOptions = (data) => {
  const types = ['basic', 'premium', 'exclusive'];
  return types.reduce((acc, next) => {
    if (data[`${next}Check`]) {
      acc[next] = data[next];
    }
    return acc;
  }, {});
};

class CreateTrack extends Component {
  //
  // --[[  UTILITY METHODS  ]]--
  //

  trackId = () => {
    if (get(this.props.s3Context, 'metadata.trackId')) {
      return this.props.s3Context.metadata.trackId;
    } else {
      const trackId = uuidV4();
      this.props.s3AddMetadata({ trackId });
      return trackId;
      // If we fail to add the metadata ..?
      // Should wipe trackId from redux on cancel or failure!
    }
  };

  filesAreValid = files => files.every(file => isAcceptableAudio(file.type));

  // Return true if other non-required files are uploading
  // Also returns true if there are no required files
  // eslint-disable-next-line no-confusing-arrow
  requiredFilesDone = () => {
    const requiredFiles = get(this.props.s3Context, 'metadata.requiredFiles');
    return (
      !requiredFiles
      || Object.keys(requiredFiles).every(key => requiredFiles[key] === 'done')
    );
  };

  audioTag = (files = null) => {
    if (files) {
      const tag = files.filter(
        tagFile => tagFile.name === 'audioTag.wav' || tagFile.name === 'audioTag.mp3',
      )[0];
      if (tag) {
        if (files.every(file => file.duration <= tag.duration)) {
          const tagUrl = window.URL.createObjectURL(tag);
          this.props.s3AddMetadata({ customAudioTag: tagUrl }); // clean metadata on new drop
          return tag;
        } else {
          console.error(
            'There was a custom audio tag file provided in the dropped files, but the audio tag is invalid because it exceeds the max combined track length.',
          );
        }
      }
    }

    if (get(this.props.s3Context, 'metadata.customAudioTag')) {
      return this.props.s3Context.metadata.customAudioTag;
    } else if (get(this.props.s3Context, 'metadata.audioTag')) {
      return this.props.s3Context.metadata.audioTag;
    } else {
      return null;
    }
  };

  // Return blob by default
  fetchDefaultAudioTag = (returnAs = 'blob') => fetch(defaultTagUrl)
    .then(response => response.blob())
    .then((tagBlob) => {
      const tagUrl = window.URL.createObjectURL(tagBlob);
      this.props.s3AddMetadata({ audioTag: tagUrl }); // clean metadata on new drop
      if (returnAs === 'url') {
        return tagUrl;
      }
      return tagBlob;
    });

  //
  // --[[  DROP HANDLING  ]]--
  //

  // Drop flow is as follows:
  // single zip?
  //    validate the zip, then upload it immediately
  //    generate a clean mp3 and upload it when done
  //    generate a tagged mp3 and upload it when done
  //    generate a clean wav and upload it when done
  //    generate a tagged wav and preview it only
  // multiple individual files?
  //    zip the files, then upload the zip immediately if valid
  //    generate a tagged wav and preview it only
  //    generate a clean mp3 and upload it when done
  //    generate a tagged mp3 and upload it when done
  //    generate a clean wav and upload it when done
  // single mp3?
  //    upload the clean mp3 immediately
  //    generate a tagged mp3 and upload it when done
  // single wav?
  //    upload the clean wav immediately
  //    generate a clean mp3 and upload it when done
  //    generate a tagged mp3 and upload it when done

  // Because the user can cancel the form during any of the following processes,
  // it becomes important to handle the following properly:
  //    Delete any uploaded files on S3
  //    Abort any currently-uploading files
  //    Terminate any web workers - This is going to be tricky, so we're skipping it here
  //      and instead letting the workers complete, but preventing upload if the context is gone

  uploadCleanWav = (files) => {
    console.log('uploadCleanWav: ', files);
    mergeAudio(files, 'wav').then((cleanWavBlob) => {
      const cleanWav = new File([cleanWavBlob], 'clean', {
        type: 'audio/wav',
      });
      console.log('cleanWav done: ', cleanWav, '. uploading...');
      this.props.s3Upload(
        cleanWav,
        `${s3TrackFolderName}/${this.trackId()}/${s3CleanWavName}.wav`,
        () => {
          this.props.s3AddMetadata({
            requiredFiles: Object.assign(
              this.props.s3Context.metadata.requiredFiles,
              { cleanWav: 'done' },
            ),
          });
        },
        uploadRules,
      );
    });
  };

  // We never upload a tagged wav file. We only "preview" it locally
  previewTaggedWav = (files, tag = this.audioTag()) => {
    console.log('preview taggedWav: ', files, tag);
    this.props.s3AddMetadata({ generatePreview: 'processing' });
    if (!tag) {
      // If we don't have a tag available, fetch it and re-run this once we do
      this.fetchDefaultAudioTag('blob').then(blob => this.previewTaggedWav(files, blob));
      return;
    }

    mergeAudio(files, 'wav', tag).then((taggedWavBlob) => {
      this.props.s3DeleteMetadata(['generatePreview']);
      const taggedWav = new File([taggedWavBlob], 'tagged', {
        type: 'audio/wav',
      });
      console.log('preview taggedWav done: ', taggedWav, '. previewing...');
      const previewUrl = window.URL.createObjectURL(taggedWav);
      this.props.s3AddMetadata({ preview: previewUrl });
    });
  };

  handleDroppedWav = (cleanWav) => {
    console.log('handleDroppedWav: ', cleanWav);

    this.props.s3AddMetadata({
      requiredFiles: {
        cleanWav: true,
      },
      preview: cleanWav.preview,
    });
    // 1   preview the file immediately
    this.props.s3AddMetadata({ preview: cleanWav.preview });
    // 2   upload the clean wav immediately
    this.props.s3Upload(
      cleanWav,
      `${s3TrackFolderName}/${this.trackId()}/${s3CleanWavName}.wav`,
      () => {
        this.props.s3AddMetadata({
          requiredFiles: Object.assign(
            this.props.s3Context.metadata.requiredFiles,
            { cleanWav: 'done' },
          ),
        });
      },
      uploadRules,
    );
  };

  handleDroppedZip = (zip) => {
    console.log('handleDroppedZip: ', zip);
    this.props.s3AddMetadata({
      requiredFiles: {
        cleanWav: true,
        stems: true,
      },
      unzipping: 'processing',
    });
    unzip(zip).then((files) => {
      this.props.s3AddMetadata({ unzipping: 'complete' });
      if (!this.filesAreValid(files)) {
        console.error(
          'Some of the files in the zip archive are not supported audio files: ',
          files,
        );
        return;
      }

      const tag = this.audioTag(files); // run audioTag once to extract any valid custom audio tag

      //    ...then upload it immediately if the files are cool
      this.props.s3Upload(
        zip,
        `${s3TrackFolderName}/${this.trackId()}/${s3StemsName}.zip`,
        () => {
          this.props.s3AddMetadata({
            requiredFiles: Object.assign(
              this.props.s3Context.metadata.requiredFiles,
              { stems: 'done' },
            ),
          });
        },
        uploadRules,
      );
      //    generate a clean wav and upload it immediately once done
      this.uploadCleanWav(files);
      //    generate a tagged wav and preview it only
      this.previewTaggedWav(files);
    });
  };

  handleDroppedMp3 = (cleanMp3) => {
    console.log('handleDroppedMp3: ', cleanMp3);
    // 1   preview the file immediately
    mergeAudio([cleanMp3], 'wav').then((cleanWavBlob) => {
      const cleanWav = new File([cleanWavBlob], 'clean', {
        type: 'audio/wav',
      });
      this.handleDroppedWav(cleanWav);
    });
  };

  handleDroppedMultiple = (files) => {
    console.log('handleDroppedMultiple: ', files);

    // If our validation of files on drop is accreate, this part of things can be left out entirely
    if (!this.filesAreValid(files)) {
      console.error(
        'Some of the files to be zipped are not supported audio files: ',
        files,
      );
      this.props.s3DeleteMetadata(['requiredFiles']);
      return;
    }

    const tag = this.audioTag(files); // run audioTag once to extract any valid custom audio tag

    //    zip the files...
    this.props.s3AddMetadata({ generateZip: 'processing' });
    makeZip(files, 'stems').then((zip) => {
      this.props.s3AddMetadata({ generateZip: 'complete' });
      //    ...then upload the archive immediately
      this.props.s3Upload(
        zip,
        `track/${this.trackId()}/${s3StemsName}.zip`,
        () => {
          this.props.s3AddMetadata({
            requiredFiles: Object.assign(
              this.props.s3Context.metadata.requiredFiles,
              { stems: 'done' },
            ),
          });
        },
        uploadRules,
      );
    });

    //    generate a tagged wav and preview it only
    this.previewTaggedWav(files, tag);
    //    generate a clean wav and upload it immediately once done
    this.uploadCleanWav(files);
  };

  handleDrop = (accepted, rejected) => {
    if (rejected.length) {
      console.error('Some dropped files were rejected: ', rejected);
      return;
    }

    // Reset any errored files when starting a new drop
    if (get(this.props.s3Context, 'errors')) {
      this.props.s3Context.uploads
        .filter(upload => hasIn(upload, 'error'))
        .map(upload => upload.id)
        .forEach(id => this.resetFiles(id));
    }

    // Reset file requirements
    this.props.s3DeleteMetadata(['requiredFiles']);

    if (accepted.length) {
      this.props.s3AddMetadata({ processing: true });

      if (accepted.length === 1) {
        const file = accepted[0];
        const zipFiles = 'application/zip, application/x-zip, application/x-zip-compressed, application/octet-stream, application/x-compress, application/x-compressed, multipart/x-zip'.split(', ');
        if (zipFiles.indexOf(file.type) >= 0) {
          this.handleDroppedZip(file);
        } else if (file.type === 'audio/mpeg' || file.type === 'audio/mp3') {
          this.handleDroppedMp3(file);
        } else if (file.type === 'audio/wav') {
          this.handleDroppedWav(file);
        } else if (file.type.includes('image')) {
          this.props.s3AddMetadata({
            requiredFiles: { image: true },
          });
          this.handleImage(file);
        } else {
          console.error(
            'One file was dropped and accepted, but it was not mpeg nor wav! It was: ',
            file.type,
          );
        }
      } else {
        this.props.s3AddMetadata({
          requiredFiles: {
            cleanWav: true,
            stems: true,
          },
        });
        this.handleDroppedMultiple(accepted);
      }
    }
  };

  handleImage = (image) => {
    const imageId = uuidV4();
    this.props.s3AddMetadata({
      image,
      imageId,
    });

    const fr = new FileReader();
    fr.onload = () => {
      const img = new Image();
      img.onload = () => {
        if (image.size > 100000) {
          const targetSize = 50000;
          this.props.s3AddMetadata({
            requiredFiles: Object.assign(
              this.props.s3Context.metadata.requiredFiles,
              { imagesm: true },
            ),
          });

          const pixels = img.width * img.height;
          const pixelBits = pixels / image.size;
          const hwRatio = img.height / img.width;

          /* eslint-disable no-mixed-operators */ const newWidth = Math.sqrt(
            (targetSize * pixelBits) / hwRatio,
          );
          /* eslint-enable no-mixed-operators */
          const newHeight = newWidth * hwRatio;

          const picaCanvas = document.createElement('canvas');
          picaCanvas.width = img.width;
          picaCanvas.height = img.height;

          const dest = document.createElement('canvas');
          dest.width = newWidth;
          dest.height = newHeight;

          const ctx = picaCanvas.getContext('2d');
          ctx.drawImage(img, 0, 0);

          pica
            .resize(picaCanvas, dest)
            .then(result => pica.toBlob(result, 'image/jpeg', 0.9))
            .then((blob) => {
              const imageSm = new File([blob], 'image_sm', {
                type: 'image/jpeg',
              });
              // saveAs(blob, `${image.name.split('.')[0]}_resized.jpg`);

              this.props.s3Upload(
                imageSm,
                `track/${this.trackId()}/${imageId}_sm.jpg`, // We need to validate extensions!
                () => {
                  this.props.s3AddMetadata({
                    requiredFiles: Object.assign(
                      this.props.s3Context.metadata.requiredFiles,
                      { imagesm: 'done' },
                    ),
                  });
                },
                null, // Add uploadRules
              );
            });
        }

        const palette = getImagePalette(img);
        // };
        if (palette) {
          this.props.s3AddMetadata({
            dominantColor: rgbToHex(palette.backgroundColor),
            overlayColor: palette.color,
            alternativeColor: palette.alternativeColor,
            // imageHex: color,
          });
        }
      };
      img.src = fr.result;
    }; // onload fires after reading is complete
    fr.readAsDataURL(image);

    console.log('qqqqrrr', this.props.s3Context.metadata.requiredFiles);

    // Let's use our resizy thing above for now
    this.props.s3Upload(
      image,
      `track/${this.trackId()}/${imageId}.jpg`, // We need to validate extensions!
      () => {
        this.props.s3AddMetadata({
          requiredFiles: Object.assign(
            this.props.s3Context.metadata.requiredFiles,
            { image: 'done' },
          ),
        });
      },
      null, // Add uploadRules
    );
  };

  //
  // --[[  SUBMISSION & CANCELLING  ]]--
  //

  abortFile = (fileId) => {
    const file = this.props.s3Context.uploads.find(f => f.id === fileId);
    const request = file.request;

    if (request) {
      this.props.s3Abort(request);
    } else {
      console.log('No request to abort for file with id: ', fileId);
    }
  };

  cancelUpload = (fileId) => {
    if (!fileId) {
      return;
    }
    const trimmedFileId = fileId.replace('tracks/', '').replace('stems/', '');
    const files = this.props.s3Context.uploads.filter(
      f => f.id.indexOf(trimmedFileId) > -1,
    );

    files.forEach((file) => {
      const idToCancel = file.id;

      if (file.status === 'uploaded') {
        this.props.s3Delete(idToCancel);
        this.resetFiles(idToCancel);
      } else {
        this.abortFile(idToCancel);
        this.resetFiles(idToCancel);
      }
    });
  };

  // THIS IS OLD STUFF!
  resetFiles = (fileId) => {
    // If this is our form's track, remove it from our form data
    if (
      fileId === `tracks/${this.trackId()}`
      || fileId === `stems/${this.trackId()}_stems`
    ) {
      this.props.s3DeleteMetadata(['file', 'trackId']);
      this.props.s3ResetFile(fileId);
    }

    // If this is our form's image, remove it from our form data
    if (fileId === `tracks/images/${this.props.s3Context.metadata.imageId}`) {
      this.props.s3DeleteMetadata(['image', 'imageId']);
      this.props.s3ResetFile(fileId);
    }

    // Regardless of all else, if we've cancelled all possible files,
    // return us to the undropped state
    if (!this.props.s3Context.uploads.length) {
      this.props.s3DeleteMetadata(['file', 'trackId', 'image', 'imageId']);
    }
  };

  handleFormCancel = () => {
    const choice = window.confirm('Are you sure you want to cancel?');
    if (choice) {
      this.props.s3Context.uploads.forEach((file) => {
        if (file.status === 'uploading') {
          console.log('Form cancelled. Aborting: ', file.id);
          this.abortFile(file.id);
        } else {
          console.log('Form cancelled. Deleting: ', file.id);
          this.props.s3Delete(file.id);
        }
      });
      this.props.s3DestroyContext();
      this.props.history.push('/');
      this.props.reset(formName);
      // Kill web workers... but how?!
    }
  };

  handleFormSubmit = (data) => {
    const {
      history,
      auth: { environment, userId },
      reset: resetForm,
    } = this.props;

    if (!data.name) {
      alert('Your track has no name!');
      return;
    }

    const pricingOptions = getPricingOptions(data);
    const freeOption = data.freeCheck;

    if (!(Object.keys(pricingOptions).length || freeOption)) {
      const returnVal = window.confirm(
        'You forgot to price your track! Are you sure you want to continue without pricing your track?',
      );
      if (!returnVal) return;
    }

    const {
      imageId, dominantColor, alternativeColor, requiredFiles,
    } = get(
      this.props.s3Context,
      'metadata',
      {},
    );
    const track = Object.assign(
      {},
      pick(data, ['name', 'description', 'mood', 'genre', 'bpm']),
      {
        userId,
        picture: imageId || '',
        dominantcolor: dominantColor,
        overlaycolor: dominantColor,
        alternativecolor: alternativeColor,
        // imagehex: '',
        fileUrl: this.trackId(),
        stems: requiredFiles.stems,
        free: freeOption,
      },
    );

    const tags = data.tags;

    // Check to see if all the files we're interested in have finished uploading.
    if (!this.requiredFilesDone()) {
      // If not, have redux take care of it and move on
      const files = [];
      const rf = this.props.s3Context.metadata.requiredFiles;
      // if (rf.cleanMp3) {
      //   files.push('clean.mp3');
      // }
      // if (rf.taggedMp3) {
      //   files.push('tagged.mp3');
      // }
      if (rf.cleanWav) {
        files.push('clean.wav');
      }
      if (rf.stems) {
        files.push('stems.zip');
      }

      this.props.s3AddMetadata({
        submitWhenDone: {
          files,
          data: {
            environment,
            track,
            tags,
            pricingOptions,
            resetForm,
            formName,
          },
        },
      });
      alert('You will be notified when your track is ready');
      history.push(`/${this.props.auth.username}`);
    } else {
      // If so, just run the mutation right away
      CreateTrackMutation(environment, track).then((response) => {
        const trackId = response.createTrack.track.id;
        // Consider running an S3 rename operation here, so we can use the trackId that's in the database instead of our own uuidV4!
        if (tags) {
          const hashtags = tags.split(' ');
          hashtags.forEach((tag) => {
            CreateHashtagMutation(environment, { tag, trackId });
          });
        }

        const hasPricing = Object.keys(pricingOptions).length;
        if (hasPricing) {
          objectToMap(pricingOptions).forEach((price, type) => {
            CreateProductMutation(environment, {
              type: type.toUpperCase(),
              price: price * 100,
              trackId,
            });
          });
        }

        alert('Success!');
        this.props.s3DestroyContext();
        history.push(`/${this.props.auth.username}`);
        resetForm(formName);
      });
    }
  };

  //
  // --[[  RENDERING METHODS  ]]--
  //

  renderTrackInfo = () => (
    <FullDropzone
      canHaveMulti={false}
      fileDropHandler={this.handleDrop}
      acceptableFiles="image/jpeg, image/png, image/jpg"
      rejectText="Invalid file type. Please choose a JPEG or PNG file."
    >
      {({ dropzoneRefClick, dropzoneRejectedFiles }) => (
        <CreateTrackInfo
          dropzoneRefClick={dropzoneRefClick}
          dropzoneRejectedFiles={dropzoneRejectedFiles}
          s3Context={this.props.s3Context}
          s3AddMetadata={this.props.s3AddMetadata}
          requiredFilesDone={this.requiredFilesDone()}
          cancelUpload={this.cancelUpload}
          onSubmit={this.handleFormSubmit}
          onCancel={this.handleFormCancel}
          allMoods={this.props.allMoods}
          allGenres={this.props.allGenres}
          isStripeUser={this.props.user.private.stripeUser}
        />
      )}
    </FullDropzone>
  );

  renderMusicUpload = () => (
    <FullDropzone
      canHaveMulti
      fileDropHandler={this.handleDrop}
      acceptableFiles="audio/mp3, audio/mpeg, audio/wav, audio/x-wav, application/zip, application/x-zip, application/x-zip-compressed, application/octet-stream, application/x-compress, application/x-compressed, multipart/x-zip"
      rejectText="Invalid file type. Please choose an MP3, WAV, or ZIP file."
    >
      {({ dropzoneRefClick, dropzoneRejectedFiles }) => (
        <MusicUpload
          errors={this.props.s3Context.errors}
          dropzoneRefClick={dropzoneRefClick}
          dropzoneRejectedFiles={dropzoneRejectedFiles}
        />
      )}
    </FullDropzone>
  );

  render() {
    const image = get(this.props.s3Context, 'metadata.image');
    const file = get(this.props.s3Context, 'metadata.file');
    const processing = get(this.props.s3Context, 'metadata.processing');
    const fileOrImageDropped = image || file || processing;
    const errors = get(this.props.s3Context, 'errors.length');
    return fileOrImageDropped && !errors
      ? this.renderTrackInfo()
      : this.renderMusicUpload();
  }
}

CreateTrack.propTypes = propTypes;

export default compose(
  withRouter,
  connect(
    null,
    { reset },
  ),
  withS3,
)(CreateTrack);
