import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import RelayRoute from 'components/Route/RelayRoute';
import Followers from 'components/Follow/Followers';

const followersQuery = graphql`
  query FollowersPageQuery($username: String!) {
    user: userByUsernameAndDeletedAndSkill(username: $username, deleted: false, skill: PRODUCER) {
      ...Followers_user
    }
  }
`;

const FollowersPage = props => (
  <RelayRoute
    {...props}
    component={Followers}
    query={followersQuery}
  />
);

export default FollowersPage;
