import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  textClassName: PropTypes.string,
  onClick: PropTypes.func,
  divStyle: PropTypes.object,
  svgStyle: PropTypes.object,
};

const defaultProps = {
  className: '',
  textClassName: '',
  onClick: () => {},
  divStyle: {},
  svgStyle: {},
};

const ExitButton = ({ id, className, textClassName, onClick, divStyle, svgStyle }) => (
  <div
    className={classnames(
      'border rounded-full h-6 w-6 flex-center',
      className,
      { 'border-gray-darker hover:border-black': !className },
    )}
    style={divStyle}
  >
    <svg
      id={id}
      className={classnames('fill-current h-6 w-6', textClassName, {
        'text-gray-darker hover:text-black': !textClassName,
      })}
      style={svgStyle}
      role="button"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      onClick={onClick}
    >
      <title>Close</title>
      <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
    </svg>
  </div>
);

ExitButton.propTypes = propTypes;
ExitButton.defaultProps = defaultProps;

export default ExitButton;
