import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import graphql from 'babel-plugin-relay/macro';
import createFragContainer from 'utils/create-frag-container';
import qs from 'query-string';
import CreateUserAlbumMutation from 'mutations/user/CreateUserAlbumMutation';
import CreateUserArtistMutation from 'mutations/user/CreateUserArtistMutation';
import CreateUserTalentMutation from 'mutations/user/CreateUserTalentMutation';
import DeleteUserAlbumMutation from 'mutations/user/DeleteUserAlbumMutation';
import DeleteUserArtistMutation from 'mutations/user/DeleteUserArtistMutation';
import DeleteUserTalentMutation from 'mutations/user/DeleteUserTalentMutation';
import OnboardingBody from './OnboardingBody';
import OnboardingInvites from './OnboardingInvites';
import OnboardingTalents from './OnboardingTalents';
import OnboardingAlbums from './OnboardingAlbums';
import OnboardingArtists from './OnboardingArtists';
import OnboardingOverview from './OnboardingOverview';

const onboardingFrag1 = graphql`
  fragment Onboarding_talents on TalentsConnection {
    edges {
      node {
        id
        publicId
        name
        imageUrl
      }
    }
  }
`;

const onboardingFrag2 = graphql`

  fragment Onboarding_albums on AlbumsConnection {
    edges {
      node {
        id
        publicId
        name
        artist
        genre
        imageUrl
      }
    }
  }
`;


const onboardingFrag3 = graphql`
  fragment Onboarding_artists on ArtistsConnection {
    edges {
      node {
        id
        publicId
        name
        imageUrl
      }
    }
  }
`;


const onboardingFrag4 = graphql`

  fragment Onboarding_userTalents on UserTalentsConnection {
    edges {
      node {
        userId
        talentId
      }
    }
  }
`;


const onboardingFrag5 = graphql`

  fragment Onboarding_userArtists on UserArtistsConnection {
    edges {
      node {
        userId
        artistId
      }
    }
  }

`;

const onboardingFrag6 = graphql`
  fragment Onboarding_userAlbums on UserAlbumsConnection {
    edges {
      node {
        userId
        albumId
      }
    }
  }
`;


const onboardingPages = {
  talents: {
    id: 'talents',
    slug: 'talents',
    title: 'I am...',
    nextSlug: '/onboarding/albums',
    nextText: 'Next',
    maxSelect: 0,
  },
  albums: {
    id: 'albums',
    slug: 'albums',
    title: 'I am inspired by...',
    nextSlug: '/onboarding/artists',
    previousSlug: '/onboarding/talents',
    nextText: 'Next',
    maxSelect: 0,
  },
  artists: {
    id: 'artists',
    slug: 'artists',
    title: 'I share a vision with...',
    nextSlug: '/onboarding/invites',
    previousSlug: '/onboarding/albums',
    nextText: 'Next',
    maxSelect: 0,
  },
  invites: {
    id: 'invites',
    slug: 'invites',
    title: 'I create my sound with...',
    nextSlug: '/onboarding/finish',
    previousSlug: '/onboarding/artists',
    nextText: 'Skip Invites',
  },
  finish: {
    id: 'finish',
    slug: 'finish',
    title: 'This is who I am.',
    nextSlug: '/',
    previousSlug: '/onboarding/invites',
    nextText: 'Done',
  },
};

const identifiers = {
  talents: 't',
  albums: 'al',
  artists: 'ar',
};

// Change to 'id' to use uuids instead of shorter, human-readable ids in the query string
// inb4 code readability
// more is more
const usingIdType = 'publicId';

class Onboarding extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    relay: PropTypes.object.isRequired,
    talents: PropTypes.object.isRequired, // All available talents to choose from
    albums: PropTypes.object.isRequired, // All available albums to choose from
    artists: PropTypes.object.isRequired, // All available artists to choose from
    // These props don't have to be here, but for readability they are included.
    // They are accessed dynamically and would generate an es-lint error since they aren't directly used
    userTalents: PropTypes.object.isRequired, // eslint-disable-line
    userAlbums: PropTypes.object.isRequired, // eslint-disable-line
    userArtists: PropTypes.object.isRequired, // eslint-disable-line
  };

  componentDidMount() {
    this.addPreviouslySelectedToUrl();
  }

  handleRedirect = redirectPath => () => {
    const { push, location: { search } } = this.props.history;
    push(`${redirectPath}${search}`);
  };

  handleSubmit = (talents, albums, artists) => {
    this.createNewRelationships();
    this.destroyOldRelationships();
    this.props.history.push('/');
  };

  handleInvites = (data) => {
    console.log('We should be sending out some invites now, ayy!');
    onboardingPages.invites.nextText = 'Next';
  };

  // eslint thinks this is confusing
  // eslint-disable-next-line
  mapPublicIds = type =>
    this.props[type]
      ? this.props[type].edges.reduce((acc, item) => {
        acc[`${item.node.id}`] = item.node.publicId;
        return acc;
      }, {})
      : {};

  addPreviouslySelectedToUrl = (idType = usingIdType) => {
    const { history: { push }, location: { search } } = this.props;

    let identifier;
    let previouslySelected;
    let queryString = '';

    Object.keys(identifiers).forEach((i) => {
      previouslySelected = this.previouslySelected(i, idType);
      identifier = identifiers[i];
      previouslySelected.forEach((s) => {
        if (!search.includes(s)) {
          queryString = queryString.includes('?')
            ? `${queryString}&${identifier}=${s}`
            : `?${queryString}${identifier}=${s}`;
        }
      });
    });

    // TODO: use history.replace instead?
    if (queryString.length > 1) {
      push(queryString);
    }
  };

  // Returns: the uuids, the full objects, or the publicIds which are selected in the URL
  // To reduce confusion: We can store ids OR public ids in the url, and this function
  // can return the results in different formats.
  urlSelected = (type, format, idType = usingIdType) => {
    const selected = qs.parse(this.props.location.search)[identifiers[type]];
    if (format === 'node') {
      return selected
        ? this.props[type].edges.reduce((acc, item) => {
          if (selected.includes(item.node[idType])) {
            return acc.concat(item.node);
          }
          return acc;
        }, [])
        : [];
    } else if (format === idType) {
      return selected
        ? typeof selected === 'object' ? selected : [selected]
        : [];
    } else if (format === 'id' && idType === 'publicId') {
      const publicIds = this.mapPublicIds(type);
      return (selected
        ? typeof selected === 'object' ? selected : [selected]
        : []
      ).map(publicId =>
        Object.keys(publicIds).find(key => publicIds[key] === publicId),
      );
    } else {
      return selected
        ? typeof selected === 'object' ? selected : [selected]
        : [];
    }
  };

  // This one is not as complex as urlSelected, since previously selected records are always
  // returned the same way from the api
  previouslySelected = (type, format) => {
    const ps = this.props[
      `user${type.charAt(0).toUpperCase() + type.slice(1)}`
    ];

    if (format === 'node') {
      return ps.edges;
    } else if (format === 'publicId') {
      const ids = ps
        ? ps.edges.reduce(
            (acc, item) => acc.concat(item.node[`${type.slice(0, -1)}Id`]),
            [],
          )
        : [];
      return ids.map(id => this.mapPublicIds(type)[id]);
    } else {
      // defaults to using 'id'
      // To reduce code, we're doing some risky key accessing. Assuming the key for id
      // is going to be 'talentId', 'albumId', or 'artistId', we chop off the last character
      // at the end of 'type', which should be an 's'
      // We also do this for props. Tricky bidnis, sir.
      return ps
        ? ps.edges.reduce(
            (acc, item) => acc.concat(item.node[`${type.slice(0, -1)}Id`]),
            [],
          )
        : [];
    }
  };

  // Combine previously- and currently-selected items
  // UNUSED
  // combinedSelected = (type, format) => {
  //   const s = new Set();
  //   const combinedResults = this.urlSelected(type, format)
  //     .map((c) => {
  //       s.add(c.a);
  //       return c;
  //     })
  //     .concat(this.previouslySelected(type, format).filter(p => !s.has(p.a)));
  //   return combinedResults;
  // };

  toggleSelected = (type, node, idType = usingIdType) => {
    const { history: { push }, location: { search } } = this.props;
    const identifier = identifiers[type];
    let newPush;
    let newSearch;

    if (search.includes(node[idType])) {
      if (search.includes(`&${identifier}=${node[idType]}`)) {
        newSearch = search.replace(`&${identifier}=${node[idType]}`, '');
      } else {
        newSearch = search.replace(`${identifier}=${node[idType]}`, '');
      }
      newPush = search.includes('?') ? `${newSearch}` : `?${newSearch}`;
    } else if (
      onboardingPages[type].maxSelect > 0 &&
      this.urlSelected(type, idType).length >
        onboardingPages[type].maxSelect - 1
    ) {
      return;
    } else {
      newPush = search.includes('?')
        ? `${search}&${identifier}=${node[idType]}`
        : `?${search}&${identifier}=${node[idType]}`;
    }

    push(newPush);
  };

  createNewRelationships = () => {
    Object.keys(identifiers).forEach((i) => {
      this.urlSelected(i, 'id').forEach((s) => {
        // Note we must get selected items by id for the mutations!
        if (s && !this.previouslySelected(i, 'id').includes(s)) {
          switch (i) {
          case 'talents':
            CreateUserTalentMutation(
                this.props.relay.environment,
                this.props.auth.userId,
                s,
              );
            break;
          case 'albums':
            CreateUserAlbumMutation(
                this.props.relay.environment,
                this.props.auth.userId,
                s,
              );
            break;
          case 'artists':
            CreateUserArtistMutation(
                this.props.relay.environment,
                this.props.auth.userId,
                s,
              );
            break;
          default:
            break;
          }
        }
      });
    });
  };

  destroyOldRelationships = () => {
    Object.keys(identifiers).forEach((i) => {
      this.previouslySelected(i, 'i').forEach((s) => {
        // Note we must get selected items by id for the mutations!
        if (!this.urlSelected(i, 'id').includes(s)) {
          switch (i) {
          case 'talents':
            DeleteUserTalentMutation(
                this.props.relay.environment,
                this.props.auth.userId,
                s,
              );
            break;
          case 'albums':
            DeleteUserAlbumMutation(
                this.props.relay.environment,
                this.props.auth.userId,
                s,
              );
            break;
          case 'artists':
            DeleteUserArtistMutation(
                this.props.relay.environment,
                this.props.auth.userId,
                s,
              );
            break;
          default:
            break;
          }
        }
      });
    });
  };

  renderOnboardingItems = (type) => {
    const { location: { search } } = this.props;

    switch (type) {
    case 'invites':
      return <OnboardingInvites handleSubmitInvites={this.handleInvites} />;
    case 'albums':
      return (
        <OnboardingAlbums
          albums={this.props.albums}
          toggleSelected={this.toggleSelected}
          selected={this.urlSelected('albums', usingIdType)}
          maxSelect={onboardingPages[type].maxSelect}
        />
      );
    case 'artists':
      return (
        <OnboardingArtists
          artists={this.props.artists}
          toggleSelected={this.toggleSelected}
          selected={this.urlSelected('artists', usingIdType)}
          maxSelect={onboardingPages[type].maxSelect}
        />
      );
    case 'finish':
      return (
        <OnboardingOverview
          selectedTalents={this.urlSelected('talents', 'node')}
          selectedArtists={this.urlSelected('artists', 'node')}
          selectedAlbums={this.urlSelected('albums', 'node')}
        />
      );
    default:
      return (
        <OnboardingTalents
          talents={this.props.talents}
          toggleSelected={this.toggleSelected}
          selected={this.urlSelected('talents', usingIdType)}
          maxSelect={onboardingPages[type].maxSelect}
        />
      );
    }
  };

  render() {
    const { match: { url }, history: { push } } = this.props;
    const t = this.props.location.pathname.split('/').pop();
    console.log(t, this.props);
    return (
      <div
        id="onboarding"
        className="w-screen h-screen fixed pin-t bg-pink z-40"
        style={{
          backgroundImage: `linear-gradient(to bottom right, ${
            t === 'talents'
              ? '#288bd0, purple'
              : t === 'albums'
                ? 'red, yellow'
                : t === 'invites' ? '#248de7, #52e552' : '#34c6c6, magenta'
          })`,
        }}
      >
        <Route
          path={`${url}/:type?`}
          render={({ match: { params: { type } } }) => {
            const onboardingItem = type
              ? onboardingPages[type]
              : onboardingPages.talents;
            return (
              <OnboardingBody
                title={onboardingItem.title}
                onPrevious={
                  onboardingItem.previousSlug &&
                  this.handleRedirect(onboardingItem.previousSlug)
                }
                onNext={
                  type !== 'finish'
                    ? this.handleRedirect(onboardingItem.nextSlug)
                    : null
                }
                onCancel={() => {
                  push('/');
                }}
                onSubmit={this.handleSubmit}
                nextText={onboardingItem.nextText}
              >
                {this.renderOnboardingItems(onboardingItem.id)}
              </OnboardingBody>
            );
          }}
        />
      </div>
    );
  }
}

export default createFragContainer([onboardingFrag1, onboardingFrag2, onboardingFrag3, onboardingFrag4, onboardingFrag5, onboardingFrag6], Onboarding);
