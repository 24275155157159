import React from 'react';
import PropTypes from 'prop-types';
import graphql from 'babel-plugin-relay/macro';
import ReactTable from 'react-table';
import capitalize from 'lodash/capitalize';
import createFragContainer from 'utils/create-frag-container';
import { displayTime } from 'utils/date';
import displayPrice from 'utils/display-price';
import 'react-table/react-table.css';

const beatsListFrag = graphql`
  fragment SoldBeatsList_beats on BeatsSoldsConnection {
    edges {
      node {
        productId
        licenseType
        trackName
        soldAt
        price
      }
    }
  }
`;

const propTypes = {
  beats: PropTypes.object.isRequired,
};

const SoldBeatsList = ({ beats }) => {
  const data = beats.edges.map(beat => beat.node);
  return (
    <ReactTable
      data={data}
      columns={[
        {
          Header: 'Track Name',
          accessor: 'trackName',
        },
        {
          Header: 'License',
          id: 'licenseType',
          accessor: d => capitalize(d.licenseType),
        },
        {
          Header: 'Price',
          id: 'price',
          accessor: d => displayPrice(d.price),
        },
        {
          Header: 'Time purchased',
          id: 'soldAt',
          accessor: d => displayTime(new Date(d.soldAt)),
        },
      ]}
      defaultPageSize={10}
      className="-striped -highlight"
    />
  );
};

SoldBeatsList.propTypes = propTypes;

export default createFragContainer([beatsListFrag])(SoldBeatsList);
