import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { moodImageUrl } from 'config';
import displayImage from 'utils/display-image';
import PlugImage from 'components/common/PlugImage';

const propTypes = {
  mood: PropTypes.object.isRequired,
};

const MoodCategory = ({ mood }) => (
  <div className="">
    <Link
      to={`/moods/${mood}`}
      key={`mood${mood}`}
      className="md:block md:text-left md:pb-2"
    >
      <div className="inline-flex flex-none rounded mr-3 p-3 align-center
      browse-mood-content browse-list-hover pointer landing-mood-color md:mr-0 md:pl-0
      md:bg-transparent"
      >
        <PlugImage
          imageSource={displayImage(moodImageUrl, mood)}
          alt="Mood"
          className="browse-mood-icons"
        />
        {/* <img
        src={displayImage(moodImageUrl, mood.mood)}
        alt="Mood"
        className="browse-mood-icons"
      /> */}
        <div className="browse-mood-text fm capitalize color-white">{mood}</div>
      </div>
    </Link>
  </div>
);

MoodCategory.propTypes = propTypes;

export default MoodCategory;
