import React from 'react';
import BasicLicense from 'components/Licenses/BasicLicense';

const BasicExamplePage = () => (
  <div id="basic-example-page" className="container">
    <BasicLicense />
  </div>
);

export default BasicExamplePage;
