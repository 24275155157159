import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import Immutable from 'immutable';
import LicenseTermsTable from 'components/LicenseTermsTable';
import ExitButton from 'components/common/ExitButton';

const propTypes = {
  data: PropTypes.instanceOf(Immutable.Map).isRequired,
  onClose: PropTypes.func.isRequired,
};

const LicenseModal = ({ data, onClose }) => (
  <div id="license-modal">
    <div className="flex justify-between">
      <h1 className="font-bold">{`${capitalize(data.get('type'))} License`}</h1>
      <ExitButton id="license-exit" onClick={onClose} />
    </div>
    <LicenseTermsTable licenseType={data.get('type')} />
  </div>
);

LicenseModal.propTypes = propTypes;

export default LicenseModal;
