import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import Dropdown from 'components/common/Dropdown';
import TextInput from 'components/common/TextInput';
import TextArea from 'components/common/TextArea';
import isReservedName from 'utils/is-reserved-name';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitPending: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  submitType: PropTypes.string.isRequired,
  waitingForFiles: PropTypes.bool.isRequired,
  allowEditTags: PropTypes.bool.isRequired,
  hasImage: PropTypes.bool,
  allGenres: PropTypes.object,
  allMoods: PropTypes.object,
  handleNextTab: PropTypes.func,
};

const defaultProps = {
  allowEditTags: true,
  waitingForFiles: false,
  submitPending: false,
  hasImage: true,
  allGenres: {},
  allMoods: {},
  handleNextTab: null,
};

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Required';
  } else if (values.name.length > 25) {
    errors.name = "That's a little long, buddy";
  } else if (isReservedName(values.name)) {
    errors.name = 'This name is reserved';
  }
  return errors;
};

const warn = (values) => {
  const warnings = {};
  if (values.tags < 1) {
    warnings.tags = 'You should add some tags!';
  }
  return warnings;
};

const TrackForm = ({
  handleSubmit,
  submitting,
  submitPending,
  onCancel,
  submitType,
  waitingForFiles,
  allowEditTags,
  hasImage,
  allGenres,
  allMoods,
  handleNextTab,
}) => (
  <form
    className="mx-6 pb-6"
    onSubmit={handleSubmit}
  >
    <Field
      name="name"
      label="Name"
      htmlFor="name"
      placeholder="Name"
      component={TextInput}
      disabled={submitPending}
    />
    <Field
      name="description"
      label="Description"
      placeholder="Description"
      htmlFor="description"
      component={TextArea}
      disabled={submitPending}
    />
    <Field
      name="genre"
      label="Genre"
      placeholder="Genre"
      htmlFor="genre"
      options={
        allGenres && allGenres.edges
          ? allGenres.edges.map(genre => ({
            value: genre.node.genre,
          }))
          : [{ value: 0, label: 'Loading...' }]
      }
      component={Dropdown}
      disabled={submitPending}
    />
    <Field
      name="mood"
      label="Mood"
      placeholder="Mood"
      htmlFor="mood"
      options={
        allMoods && allMoods.edges
          ? allMoods.edges.map(mood => ({
            value: mood.node.mood,
          }))
          : [{ value: 0, label: 'Loading...' }]
      }
      component={Dropdown}
      disabled={submitPending}
    />
    <Field
      name="bpm"
      label="BPM"
      htmlFor="bpm"
      component={TextInput}
      disabled={submitPending}
    />
    {allowEditTags && (
      <Field
        name="tags"
        label="Tags"
        htmlFor="tags"
        placeholder={'Separated by a space (e.g. track fire trap)'}
        component={TextInput}
        disabled={submitPending}
      />
    )}

    <div className="flex justify-end">
      <button
        className="btn btn-link btn-short px-3 py-2"
        onClick={onCancel}
        disabled={submitting}
      >
        Cancel
      </button>
      {/* Ordinarily we would have 'waitingForFiles' in 'disabled', but we want to be able to submit this without all the files being done. We'll keep the feature though, in case there ARE some files we end up wanting to wait for. */}

      {submitType !== 'Create' && (
        <button
          type="submit"
          className="btn btn-primary btn-short ml-3 py-2"
          disabled={submitting || submitPending || !hasImage}
        >
          {submitType}
        </button>
      )}

      {handleNextTab && (
        <button
          type="next"
          className="btn btn-primary btn-short ml-3"
          onClick={(e) => {
            e.preventDefault();
            handleNextTab();
          }}
        >
          Next
        </button>
      )}
    </div>
  </form>
);

TrackForm.propTypes = propTypes;
TrackForm.defaultProps = defaultProps;

export default createFragmentContainer(
  compose(
    withRouter,
    reduxForm({
      form: 'Track',
      validate,
      warn,
      destroyOnUnmount: false,
      enableReinitalize: true,
    }),
  )(TrackForm),
  {
    allGenres: graphql`
      fragment TrackForm_allGenres on GenresConnection {
        edges {
          node {
            genreId: id
            genre
          }
        }
      }
    `,
    allMoods: graphql`
      fragment TrackForm_allMoods on MoodsConnection {
        edges {
          node {
            mood
          }
        }
      }
    `,
  },
);
