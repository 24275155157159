import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { isDesktop, onDesktop } from 'utils/screentype';
import PlugImage from 'components/common/PlugImage';
import displayTrackImage from 'utils/display-track-image';

const propTypes = {
  currentTrack: PropTypes.object,
  history: PropTypes.object.isRequired,
};

const defaultProps = {
  currentTrack: {},
};

const TrackInfo = ({
  currentTrack: { username, userDisplayName, publicId, name, fileUrl, picture },
  history,
}) => {
  const usernameClick = onDesktop(() => history.push(`/${username}`));
  const trackClick = onDesktop(() => history.push(`/tracks/${publicId}`));
  const uxClass = isDesktop() ? 'hover:underline' : 'outline-none';
  return (
    <div
      className="inline-flex flex-none flex-shrink truncate"
      style={{ width: '190px' }}
    >
      <PlugImage
        imageSource={displayTrackImage(fileUrl, picture)}
        alt="Track"
        style={{
          height: '42px',
          width: '42px',
          flexShrink: 0,
          cursor: 'pointer',
        }}
        imageStyle={{
          height: '42px',
          width: '42px',
          objectFit: 'cover',
        }}
        once={false}
        onClick={trackClick}
      />
      <div className="ml-2 flex flex-col truncate justify-around">
        <div
          role="presentation"
          className={classnames(
            'fm text-gray-darkest text-sm truncate cursor-pointer',
            uxClass,
          )}
          onClick={trackClick}
        >
          {name}
        </div>
        <div
          role="presentation"
          className={classnames(
            'text-xs fm text-gray-dark cursor-pointer truncate',
            uxClass,
          )}
          onClick={usernameClick}
        >
          {userDisplayName}
        </div>
      </div>
    </div>
  );
};

TrackInfo.propTypes = propTypes;

TrackInfo.defaultProps = defaultProps;

export default compose(
  connect(state => ({
    currentTrack: state.soundbar.currentTrack,
  })),
  withRouter,
)(TrackInfo);
