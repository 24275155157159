export default [
  '/checkouts',
  '/checkout',
  '/contact-us',
  '/email',
  '/error',
  '/register',
  '/login',
  '/notifications',
  '/orders',
  '/projects',
  '/search',
  '/settings',
  '/stripe',
  '/tags',
  // '/tracks',
  '/validate',
  '/terms',
  '/privacy',
  '/about',
  '/licenses',
  '/cart',
  '/staffpicks',
  '/newestarrivals',
  '/genresandmoods',
  '/signup',
  '/onboarding',
  '/login',
];
