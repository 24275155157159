import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getDashboardLink } from 'rest/stripe';
import classnames from 'classnames';

class DashboardButton extends Component {
  static propTypes = {
    children: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    account: PropTypes.bool,
  };

  static defaultProps = {
    children: 'See payout details',
    className: '',
    style: null,
    account: false,
  };

  state = { loading: false };

  handleDashboardLink = () => {
    const { account } = this.props;
    this.setState({ loading: true });
    getDashboardLink().then((link) => {
      const linkUrl = account ? `${link.url}#/account` : link.url;
      window.location = linkUrl;
    });
  };

  render() {
    const { children, className, style } = this.props;
    if (this.state.loading) return <div>Loading ...</div>;
    return (
      <button
        className={classnames(className)}
        style={Object.assign({}, style)}
        onClick={this.handleDashboardLink}
      >
        {children}
      </button>
    );
  }
}

export default DashboardButton;
