/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type CommentList_track$ref = any;
type Hashtags_track$ref = any;
type TrackBody_track$ref = any;
type TrackButtons_track$ref = any;
import type { FragmentReference } from 'relay-runtime';
declare export opaque type TrackDesktop_track$ref: FragmentReference;
export type TrackDesktop_track = {|
  +id: any,
  +name: string,
  +picture: ?string,
  +fileUrl: ?string,
  +description: ?string,
  +publicId: ?string,
  +$fragmentRefs: (TrackBody_track$ref & TrackButtons_track$ref & Hashtags_track$ref & CommentList_track$ref),
  +$refType: TrackDesktop_track$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "TrackDesktop_track",
  "type": "Track",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "picture",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fileUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "publicId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "TrackBody_track",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "TrackButtons_track",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "Hashtags_track",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CommentList_track",
      "args": null
    }
  ]
};
(node/*: any*/).hash = '47d61d08e8567fec61d5a887abfcdf67';
module.exports = node;
