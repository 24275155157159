import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { isDesktop } from 'utils/screentype';
import { AudioWrapper } from 'utils/AudioControls';
// import FollowIconContainer from './SocialControls/FollowIconContainer';
import LikeIconContainer from './SocialControls/LikeIconContainer';
import VolumeSlider from './MusicControls/VolumeSlider';
import Previous from './MusicControls/Previous';
import Next from './MusicControls/Next';
import Play from './MusicControls/Play';
import Repeat from './MusicControls/Repeat';
// import Shuffle from './MusicControls/Shuffle';
import Playlist from './Playlist';
import AudioSliderSm from './MusicControls/AudioSliderSm';

const propTypes = {
  volumeBarShowing: PropTypes.bool.isRequired,
  toggleVolumeControl: PropTypes.func.isRequired,
  publicId: PropTypes.string.isRequired,
};

const Controls = ({ volumeBarShowing, toggleVolumeControl, publicId }) => (
  <AudioWrapper>
    {({ togglePlay, canPlayPrevious, previous, canPlayNext, next }) => (
      <div
        id="controls"
        className="controls flex flex-grow justify-end flex-no-shrink"
      >
        <Link
          to={`/tracks/${publicId}`}
          className="flex-no-shrink flex px-3"
          onClick={e => e.stopPropagation()}
        >
          <div className="inline-block border px-4 rounded-full text-sm border-blue-dark self-center py-1 text-white bg-blue font-bold">
            Buy
          </div>
        </Link>
        <div className="flex md:ml-none">
          {isDesktop() && <Previous previous={previous} />}
          <Play
            togglePlay={togglePlay}
            canPlayPrevious={canPlayPrevious}
            circularClassName={'circularprogress-small'}
          />
          <Next next={next} canPlayNext={canPlayNext} />
        </div>
        {isDesktop() && <AudioSliderSm />}
        {isDesktop() && (
          <VolumeSlider
            volumeBarShowing={volumeBarShowing}
            toggleVolumeControl={toggleVolumeControl}
          />
        )}
        {isDesktop() && (
          <div className="inline-flex items-center ml-auto md:ml-none">
            <Repeat />
            {/* <LikeIconContainer /> */}
            <Playlist />
          </div>
        )}
        {/* <Shuffle /> */}
        {/* <FollowIconContainer /> */}
      </div>
    )}
  </AudioWrapper>
);

Controls.propTypes = propTypes;

export default connect(state => ({
  publicId: state.soundbar.currentTrack.publicId,
}))(Controls);
