import graphql from 'babel-plugin-relay/macro';
import promiseMutation from 'utils/promise-mutation';

const mutation = graphql`
  mutation DeleteUserAlbumMutation(
    $input: DeleteUserAlbumByUserIdAndAlbumIdInput!
  ) {
    deleteUserAlbumByUserIdAndAlbumId(input: $input) {
      deletedUserAlbumId
    }
  }
`;

const commit = (environment, userId, albumId) =>
  promiseMutation(environment, {
    mutation,
    variables: { input: { userId, albumId } },
  });

export default commit;
