import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Checkbox from 'material-ui/Checkbox';
import Toggle from 'material-ui/Toggle';
import SearchExploreDropdown from 'components/Search/SearchExploreDropdown';
import UsersResults from './UsersResults';
import TracksResults from './TracksResults';
import TagsResults from './TagsResults';

const propTypes = {
  auth: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const defaultProps = {};

const validResultsFilters = ['users', 'tracks', 'tags'];
const validUserFilters = [
  'username',
  'displayname',
  'picture',
  'tracks',
  'follows',
];
const validTrackFilters = [
  'name',
  'picture',
  'mood',
  'length',
  'bpm',
  'genre',
  'author',
];
const validTagFilters = ['name'];

class Search extends Component {
  constructor(props) {
    super(props);
    const query = props.match.params.query;
    this.state = {
      resultsFilters: ['users', 'tags', 'tracks'],
      viewOptions: ['icons'],
      variables: { search: query || '' },
      advanced: false,
      userColumns: [...validUserFilters],
      trackColumns: [...validTrackFilters],
      tagColumns: [...validTagFilters],
    };
  }

  componentDidMount() {
    if (this.queryInput) {
      this.queryInput.focus();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.query !== this.state.variables.search) {
      this.setState({ variables: { search: nextProps.match.params.query } });
    }
  }

  onSelect = () => {
    // console.log('Selected something!');
  };

  toggleAdvancedMode = () => {
    this.setState({
      advanced: !this.state.advanced,
    });
  };

  toggleResultsFilter = (filter) => {
    const rf = this.state.resultsFilters;
    if (rf.includes(filter)) {
      this.setState({
        resultsFilters: rf.filter((_, index) => index !== rf.indexOf(filter)),
      });
    } else {
      this.setState({
        resultsFilters: rf.concat([filter]),
      });
    }
  };

  toggleViewOption = (filter) => {
    const vo = this.state.viewOptions;
    if (vo.includes(filter)) {
      this.setState({
        viewOptions: vo.filter((_, index) => index !== vo.indexOf(filter)),
      });
    } else {
      this.setState({
        viewOptions: vo.concat([filter]),
      });
    }
  };

  toggleColumnFilter = (result, filter) => {
    if (
      !result ||
      !filter ||
      !validResultsFilters.includes(result) ||
      (validTagFilters.includes(filter) &&
        validTrackFilters.includes(filter) &&
        validUserFilters.includes(filter))
    ) {
      console.error('Invalid result or column filter');
      return;
    }
    let cf;
    if (result === 'users') {
      cf = this.state.userColumns;
    } else if (result === 'tracks') {
      cf = this.state.trackColumns;
    } else if (result === 'tags') {
      cf = this.state.tagColumns;
    }

    if (cf.includes(filter)) {
      const filteredCf = cf.filter((_, index) => index !== cf.indexOf(filter));
      this.setState({
        userColumns: result === 'users' ? filteredCf : this.state.userColumns,
        trackColumns:
          result === 'tracks' ? filteredCf : this.state.trackColumns,
        tagColumns: result === 'tags' ? filteredCf : this.state.tagColumns,
      });
    } else {
      this.setState({
        userColumns:
          result === 'users' ? cf.concat([filter]) : this.state.userColumns,
        trackColumns:
          result === 'tracks' ? cf.concat([filter]) : this.state.trackColumns,
        tagColumns:
          result === 'tags' ? cf.concat([filter]) : this.state.tagColumns,
      });
    }
  };

  renderSearchResults = options => (
    <Paper>
      {this.state.resultsFilters.includes('users') && (
        <UsersResults
          simple={options.simple || true}
          auth={this.props.auth}
          variables={this.state.variables}
          showColumns={this.state.userColumns}
          onSelect={this.onSelect}
          options={this.state.viewOptions}
        />
      )}
      {this.state.resultsFilters.includes('tags') && (
        <TagsResults
          simple={options.simple || true}
          auth={this.props.auth}
          variables={this.state.variables}
          showColumns={this.state.tagColumns}
          onSelect={this.onSelect}
          options={this.state.viewOptions}
        />
      )}
      {this.state.resultsFilters.includes('tracks') && (
        <TracksResults
          simple={options.simple || true}
          auth={this.props.auth}
          variables={this.state.variables}
          showColumns={this.state.trackColumns}
          onSelect={this.onSelect}
          options={this.state.viewOptions}
        />
      )}
    </Paper>
  );

  renderDesktop = () => (
    <div className="container" style={{ paddingTop: 10 }}>
      <div
        className="box"
        style={{
          width: '80%',
          minWidth: '300px',
          margin: '0 auto',
        }}
      >
        <h1 className="title" style={{ fontFamily: 'Roboto', fontWeight: 400 }}>
          Search
        </h1>
        <Paper>
          <input
            type="text"
            ref={(input) => {
              this.queryInput = input;
            }}
            placeholder="what are you looking for?"
            value={this.state.variables.search || ''}
            style={{
              display: 'block',
              padding: 5,
              marginBottom: 10,
              width: '100%',
              border: 'none',
              textAlign: 'center',
              outline: 'none',
              fontWeight: 500,
              fontSize: 20,
              fontFamily: 'Roboto',
            }}
            onChange={(e) => {
              this.setState({ variables: { search: e.target.value } });
            }}
          />
        </Paper>
        <div id="controls" style={{ display: 'inline-block', width: '20%' }}>
          <Paper style={{ padding: 10 }}>
            <Typography type="headline" component="p">
              Filters
            </Typography>
            <Toggle
              label="Advanced"
              toggled={this.state.advanced}
              onToggle={this.toggleAdvancedMode}
            />
            <Checkbox
              label="Users"
              checked={this.state.resultsFilters.includes('users')}
              onClick={(e, checked) => {
                this.toggleResultsFilter('users');
              }}
            />
            <Checkbox
              label="Tracks"
              checked={this.state.resultsFilters.includes('tracks')}
              onClick={(e, checked) => {
                this.toggleResultsFilter('tracks');
              }}
            />
            <Checkbox
              label="Tags"
              checked={this.state.resultsFilters.includes('tags')}
              onClick={(e, checked) => {
                this.toggleResultsFilter('tags');
              }}
            />
          </Paper>
          <div
            id="advancedControls"
            style={{ display: this.state.advanced || 'none' }}
          >
            <Paper
              style={{
                padding: 10,
                marginTop: 5,
                display: this.state.resultsFilters.includes('users') || 'none',
              }}
            >
              <Typography type="subheading" component="p">
                Show Users Columns
              </Typography>
              <Checkbox
                checked={this.state.userColumns.includes('picture')}
                label="Avatar"
                onClick={() => {
                  this.toggleColumnFilter('users', 'picture');
                }}
              />
              <Checkbox
                checked={this.state.userColumns.includes('username')}
                label="Name"
                onClick={() => {
                  this.toggleColumnFilter('users', 'username');
                }}
              />
              <Checkbox
                checked={this.state.userColumns.includes('displayname')}
                label="Display Name"
                onClick={() => {
                  this.toggleColumnFilter('users', 'displayname');
                }}
              />
              <Checkbox
                checked={this.state.userColumns.includes('tracks')}
                label="Tracks"
                onClick={() => {
                  this.toggleColumnFilter('users', 'tracks');
                }}
              />
              <Checkbox
                checked={this.state.userColumns.includes('follows')}
                label="Follows"
                onClick={() => {
                  this.toggleColumnFilter('users', 'follows');
                }}
              />
            </Paper>
            <Paper
              style={{
                padding: 10,
                marginTop: 5,
                display: this.state.resultsFilters.includes('tracks') || 'none',
              }}
            >
              <Typography type="subheading" component="p">
                Show Tracks Columns
              </Typography>
              <Checkbox
                checked={this.state.trackColumns.includes('picture')}
                label="Image"
                onClick={() => {
                  this.toggleColumnFilter('tracks', 'picture');
                }}
              />
              <Checkbox
                checked={this.state.trackColumns.includes('name')}
                label="Name"
                onClick={() => {
                  this.toggleColumnFilter('tracks', 'name');
                }}
              />
              <Checkbox
                checked={this.state.trackColumns.includes('author')}
                label="Author"
                onClick={() => {
                  this.toggleColumnFilter('tracks', 'author');
                }}
              />
              <Checkbox
                checked={this.state.trackColumns.includes('length')}
                label="Length"
                onClick={() => {
                  this.toggleColumnFilter('tracks', 'length');
                }}
              />
              <Checkbox
                checked={this.state.trackColumns.includes('bpm')}
                label="BPM"
                onClick={() => {
                  this.toggleColumnFilter('tracks', 'bpm');
                }}
              />
              <Checkbox
                checked={this.state.trackColumns.includes('genre')}
                label="Genre"
                onClick={() => {
                  this.toggleColumnFilter('tracks', 'genre');
                }}
              />
              <Checkbox
                checked={this.state.trackColumns.includes('mood')}
                label="Mood"
                onClick={() => {
                  this.toggleColumnFilter('tracks', 'mood');
                }}
              />
            </Paper>
            <Paper
              style={{
                padding: 10,
                marginTop: 5,
                display: this.state.resultsFilters.includes('tags') || 'none',
              }}
            >
              <Typography type="subheading" component="p">
                Show Tags Columns
              </Typography>
              <Checkbox
                checked={this.state.tagColumns.includes('name')}
                label="Name"
              />
            </Paper>
            <Paper
              style={{
                padding: 10,
                marginTop: 5,
              }}
            >
              <Typography type="subheading" component="p">
                View
              </Typography>
              <Checkbox
                checked={this.state.viewOptions.includes('icons')}
                label="Result type icons"
                onClick={() => {
                  this.toggleViewOption('icons');
                }}
              />
            </Paper>
          </div>
        </div>
        <div
          id="resultsContainer"
          style={{
            display: 'inline-block',
            position: 'relative',
            left: 10,
            right: 10,
            verticalAlign: 'top',
            width: '80%',
          }}
        >
          {this.renderSearchResults({ simple: false })}
        </div>
      </div>
    </div>
  );

  /* eslint-disable react/jsx-boolean-value */
  renderSearchExploreDropdown = () => (
    <SearchExploreDropdown
      query={this.state.variables.search}
      showing={true}
      navBarSetShowing={null}
      containerStyle={{
        position: 'static',
        width: '100%',
        border: 'none',
        top: 0,
      }}
      usersResults={10}
      tracksResults={10}
      genresResults={10}
      moodsResults={10}
      tagsResults={10}
    />
  );
  /* eslint-enable react/jsx-boolean-value */

  renderMobile = () => (
    <div className="container" style={{ paddingTop: 0 }}>
      <div
        style={{
          minWidth: '300px',
          margin: '0 auto',
        }}
      >
        <Paper>
          <button
            className="absolute"
            style={{ padding: 8 }}
            onClick={() => {
              this.props.history.goBack();
            }}
          >
            <i className="fa fa-arrow-left" aria-hidden="true" />
          </button>
          <input
            type="text"
            ref={(input) => {
              this.queryInput = input;
            }}
            placeholder="what are you looking for?"
            value={this.state.variables.search || ''}
            style={{
              display: 'block',
              padding: 8,
              marginBottom: 10,
              width: '100%',
              border: 'none',
              textAlign: 'center',
              outline: 'none',
              fontWeight: 500,
              fontSize: 20,
              fontFamily: 'Roboto',
            }}
            onChange={(e) => {
              this.setState({ variables: { search: e.target.value } });
            }}
          />
        </Paper>
        {/* {this.renderSearchResults({ simple: true })} */}
        {this.renderSearchExploreDropdown()}
      </div>
    </div>
  );

  render() {
    return this.props.type === 'mobile'
      ? this.renderMobile()
      : this.renderDesktop();
  }
}

Search.propTypes = propTypes;
Search.defaultProps = defaultProps;

// export default Search;

export default connect(state => ({
  type: state.windowSession.type,
}))(Search);
