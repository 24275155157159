import React from 'react';
import { withRouter } from 'react-router-dom';
import FeatureFlagProvider from 'components/common/FeatureFlag/FeatureFlagProvider';
import MediaQueryProvider from 'components/common/MediaQuery/MediaQueryProvider';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import config from 'config';
import logoGreen from 'assets/Logo-green.png';
import { Helmet } from 'react-helmet';
import Navbar from 'components/Navbar';
import ScrollToTop from 'components/ScrollToTop';
import Main from 'components/Main';
import Footer from 'components/Footer';
import SoundbarContainer from 'components/Soundbar/SoundbarContainer';
import Feedback from 'components/Feedback';
import ModalPortal from 'components/ModalPortal';
import ToastMessages from 'components/ToastMessages';

const Layout = () => (
  <ScrollToTop>
    <MuiThemeProvider>
      <FeatureFlagProvider config={config}>
        <MediaQueryProvider>
          <Helmet>
            <title>Find beats, buy beats, sell beats - Plug Music</title>
            <meta name="theme-color" content="#000000" />
            <meta name="description" content="Revolutionizing music production, Plug Music is a social platform for independent music artists to buy and sell beats." />
            <link rel="canonical" href="https://plugmusic.co" />
            <meta name="og:title" content="Plug Music" />
            <meta name="og:description" content="Revolutionizing music production, Plug Music is a social platform for independent music artists to buy and sell beats." />
            <meta name="og:type" content="website" />
            <meta name="og:url" content="https://plugmusic.co" />
            <meta name="og:image" content={logoGreen} />
            <meta name="fb:app_id" content="207947733450087" />
          </Helmet>
          <div className="app-background min-h-full overflow-hidden">
            <Navbar />
            <Main />
            <ToastMessages />
            <SoundbarContainer />
            <Feedback />
            <Footer />
          </div>
          <ModalPortal />
        </MediaQueryProvider>
      </FeatureFlagProvider>
    </MuiThemeProvider>
  </ScrollToTop>
);

export default withRouter(Layout);
