import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import startsWith from 'lodash/startsWith';
import { withRouter } from 'react-router-dom';
import NavbarSignedIn from './NavbarSignedIn';
import NavbarVisitor from './NavbarVisitor';
// import NavbarDesktop from './NavbarDesktop';
// import NavbarMobile from './NavbarMobile';

const propTypes = {
  type: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  s3: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const s3ContextToWatch = 'CreateTrack';

const Navbar = ({ type, location: { pathname }, s3, auth: { isAuthenticated } }) => {
  const mobile = type === 'mobile';
  if (startsWith(pathname, '/projects/') && pathname.length > 10) {
    return <div />;
  }
  return isAuthenticated ? <NavbarSignedIn /> : <NavbarVisitor />;
};

Navbar.propTypes = propTypes;

export default compose(
  withRouter,
  connect(state => ({
    type: state.windowSession.type,
    s3: state.s3,
    auth: state.auth,
  })),
)(Navbar);
