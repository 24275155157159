import React from 'react';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import displayTrackImage from 'utils/display-track-image';
import TrackNotFound from 'components/Tracks/Track/TrackNotFound';
import TrackLikeList from './TrackLikeList';

const propTypes = {
  track: PropTypes.object.isRequired,
};

const TRACK_LIKES = gql`
  fragment TrackLikes_track on Track {
    id
    name
    fileUrl
    picture
    publicId
    userId
    user: userByUserId {
      username
      displayName
    }
    ...TrackLikeList_track
  }
  ${TrackLikeList.fragments.track}
`;

const TrackLikes = ({ track }) => {
  if (!track) return <TrackNotFound />;
  return (
    <div className="vh100 my-8 mx-4 lg:mx-8">
      <div className="flex-row items-center mb-6">
        <img
          alt="Track"
          className="cover br-4 mr-3"
          style={{
            height: '128px',
            width: '128px',
          }}
          src={displayTrackImage(track.fileUrl, track.picture)}
        />
        <div className="lg:ml-4">
          <div className="lg:flex items-center">
            <h2 className="title" style={{ marginBottom: 0 }}>
              {track.name}
            </h2>
            <div
              className="mx2 title hidden lg:block"
              style={{ marginBottom: 0 }}
            >
              -
            </div>
            <h2 className="title" style={{ marginBottom: 0 }}>
              <Link className="link" to={`/${track.user.username}`}>
                {track.user.displayName}
              </Link>
            </h2>
          </div>
        </div>
      </div>
      <TrackLikeList track={filter(TrackLikeList.fragments.track, track)} />
    </div>
  );
};

TrackLikes.propTypes = propTypes;
TrackLikes.fragments = { track: TRACK_LIKES };

export default TrackLikes;
