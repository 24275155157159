import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import Radium from 'radium';
import Project from './Project';

const style = {
  col: {
    fontSize: '14px',
    paddingBottom: '4px',
    borderBottom: '1px solid #d8d8d8',
    color: 'gray',
  },
  name: {
    width: '31%',
  },
  owner: {
    width: '23%',
  },
  users: {
    width: '23%',
  },
  date: {
    width: '23%',
  },
};

class ProjectList extends Component {
  static propTypes = {
    projects: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleDelete: PropTypes.func.isRequired,
    left: PropTypes.number,
    userId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    left: 0,
  };

  constructor(props) {
    super(props);
    this.state = { open: false, hoveredProj: null };
  }

  handleRightClick = (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    document
      .getElementById('projects')
      .addEventListener('click', this.handleRequestClose);
    document
      .getElementById('projects')
      .addEventListener('contextmenu', this.checkTarget);

    this.setState({
      open: true,
      left: e.clientX,
      top: e.clientY,
      hoveredProj: id,
    });
  };

  checkTarget = (e) => {
    if (e.target === this.clipArea) {
      this.setState({ left: e.clientX - this.props.left });
      document
        .getElementById('projects')
        .removeEventListener('click', this.handleRequestClose);
      document
        .getElementById('projects')
        .removeEventListener('click', this.checkTarget);
    } else {
      this.handleRequestClose();
    }
  };

  handleRequestClose = () => {
    this.setState({
      open: false,
      hoveredProj: null,
    });
    document
      .getElementById('projects')
      .removeEventListener('click', this.handleRequestClose);
    document
      .getElementById('projects')
      .removeEventListener('click', this.checkTarget);
  };

  handleDeleteClick = (e) => {
    e.stopPropagation();
    this.props.handleDelete(this.state.hoveredProj);
    this.handleRequestClose();
  }

  renderProjects = () => {
    const { projects } = this.props;
    // sort projects by date (later)
    const projectsMap = projects
      .sort(
        (a, b) =>
          ((a.updatedAt || a.createdAt) < (b.updatedAt || b.createdAt) ? -1 : 1),
      )
      .map(project =>
        (<Project
          key={project.id}
          {...project}
          userId={this.props.userId}
          rightClick={this.handleRightClick}
          hoveredProj={this.state.hoveredProj}
        />),
      );
    return (
      <div>
        <table style={{ width: '100%', textAlign: 'left' }}>
          <thead style={{ fontSize: '14px', marginBottom: '5px' }}>
            <tr>
              <th style={[style.col, style.name]} scope="col">
                Name
              </th>
              <th style={[style.col, style.owner]} scope="col">
                Creator
              </th>
              <th style={[style.col, style.users]} scope="col">
                Users
              </th>
              <th style={[style.col, style.date]} scope="col">
                Last Modified
              </th>
              <th style={style.col} scope="col" />
            </tr>
          </thead>
          <tbody>
            {projectsMap}
          </tbody>
        </table>

        <Popover
          open={this.state.open}
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
          targetOrigin={{ horizontal: 'left', vertical: 'top' }}
          onRequestClose={this.handleRequestClose}
          useLayerForClickAway={false}
          animated={false}
          style={{
            marginLeft: `${this.state.left}px`,
            marginTop: `${this.state.top}px`,
            transition: 'none',
          }}
        >
          <Menu width={150}>
            <MenuItem primaryText="Trash" onClick={this.handleDeleteClick} />
          </Menu>
        </Popover>
      </div>
    );
  }

  renderNoProjects = () => <div>No projects yet</div>

  render() {
    const { projects } = this.props;
    if (projects.length) return this.renderProjects();
    return this.renderNoProjects();
  }
}

export default Radium(ProjectList);
