import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import PropTypes from 'prop-types';
import Follower from './Follower';

const propTypes = {
  user: PropTypes.object.isRequired,
};

const FollowerList = ({ user }) => (
  <div>
    <div className="mb2 flex-row items-center">
      <h2 className="title mr2">Followers</h2>
      {'  '}
      <div className="subtitle">({user.followers.totalCount})</div>
    </div>
    <div className="flex-row flex-wrap">
      {user.followers.edges.map(edge => (
        <Follower key={edge.node.nodeId} follower={edge.node.follower} />
      ))}
    </div>
  </div>
);

FollowerList.propTypes = propTypes;

export default createFragmentContainer(FollowerList, {
  user: graphql`
    fragment FollowerList_user on User {
      followers: followsByFollowedId(
        first: 100,
        orderBy: CREATED_AT_DESC
      ) @connection(key: "FollowerList_followers") {
        totalCount,
        edges {
          node {
            nodeId,
            follower: userByFollowerId {
              ...Follower_follower
            }
          }
        }
      }
    }
  `,
});
