import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const propTypes = {
  error: PropTypes.string,
};

const defaultProps = {
  error: 'You don\'t have access to this page!',
};

const DeliveryError = ({ error }) => (
  <div id="delivery-error">
    <div className="title">Error deliverying your beat</div>
    <div className="subtitle">{error}</div>
  </div>
);

DeliveryError.propTypes = propTypes;
DeliveryError.defaultProps = defaultProps;

export default connect(state => ({ error: state.checkout.getIn(['errors', 'delivery']) }))(
  DeliveryError,
);
