import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Popover from '@material-ui/core/Popover';
import { soundbarShufflePlaylist } from 'actions/soundbar';
import PlaylistInfo from './PlaylistInfo';

const propTypes = {
  soundbarShufflePlaylist: PropTypes.func.isRequired,
};

class Playlist extends Component {
  state = { playlistOpen: false };
  togglePlaylist = () => {
    this.setState({
      playlistOpen: !this.state.playlistOpen,
    });
  };
  render() {
    return (
      <a
        role="button"
        tabIndex={0}
        className="music-icon-button pl-2 outline-none"
        style={{ userSelect: 'none' }}
        onClick={this.togglePlaylist}
        ref={(element) => {
          this.playlistAnchor = element;
        }}
      >
        <i
          className={
            this.state.playlistOpen
              ? 'material-icons selected-icon'
              : 'material-icons icon-color'
          }
        >
          playlist_play
        </i>
        {' '}
        <Popover
          open={this.state.playlistOpen}
          anchorEl={this.playlistAnchor}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          onClose={this.togglePlaylist}
        >
          <PlaylistInfo />
        </Popover>
      </a>
    );
  }
}

Playlist.propTypes = propTypes;

export default connect(null, { soundbarShufflePlaylist })(Playlist);
