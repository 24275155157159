import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import gql from 'graphql-tag';
import FollowButton from 'components/common/ApolloFollowButton';

const buttonClasses = {
  sm: 'text-xs',
  md: 'text-sm',
};

const propTypes = {
  myself: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  className: PropTypes.string,
  settingsClassName: PropTypes.string,
  followClassName: PropTypes.string,
  followingClassName: PropTypes.string,
  buttonSize: PropTypes.string,
};

const defaultProps = {
  className: '',
  settingsClassName: '',
  followClassName: '',
  followingClassName: '',
  buttonSize: 'sm',
};

const SIDE_BUTTON_FRAG = gql`
  fragment SideButton_user on User {
    ...FollowButton_user
  }
  ${FollowButton.fragments.user}
`;

const SideButton = ({
  myself,
  user,
  className,
  settingsClassName,
  followClassName,
  followingClassName,
  buttonSize,
}) => {
  const renderSettingsBtn = () => (
    <Link
      className={classnames(
        'btn btn-normal text-gray-darkest font-semibold',
        settingsClassName,
        buttonClasses[buttonSize],
      )}
      to="/settings"
    >
      Settings
    </Link>
  );

  const renderFollowBtn = () => (
    <FollowButton
      user={user}
      followClass={classnames(
        'FollowButton',
        followClassName,
        `${buttonClasses[buttonSize]}`,
      )}
      followingClass={classnames(
        'bg-grayish-blue pointer outline-none',
        followingClassName,
        `${buttonClasses[buttonSize]}`,
      )}
    />
  );

  return (
    <div className={className}>
      {myself ? renderSettingsBtn() : renderFollowBtn()}
    </div>
  );
};

SideButton.propTypes = propTypes;
SideButton.defaultProps = defaultProps;
SideButton.fragments = { user: SIDE_BUTTON_FRAG };

export default SideButton;
