import React from 'react';
import About from 'components/Static/About';

const AboutPage = () => (
  <div id="about-page" className="container">
    <About />
  </div>
);

export default AboutPage;
