export const includeAction = [
  'TRASH_TRACK_CLIENT',
  'UPDATE_VOLUME',
  'UPDATE_NAME',
  'TOGGLE_MUTE',
  'TOGGLE_SOLO',
  'TOGGLE_ARM',
  'ADD_CLIP_CLIENT',
  'UPLOAD_CLIP',
  'TRASH_CLIP_CLIENT',
  'DELETE_CLIPS_BY_TRACK',
  'SPLIT_CLIPS',
  'TOGGLE_RECORD',
  'ADD_RECORDED_CLIP',
  'UPDATE_LOOP_REGION',
  'CHANGE_LOOP',
  'UPDATE_DELAY_TIME',
  'UPDATE_CLIP_LENGTH',
  'MOVE_TO_TRACK',
  'PASTE_CLIP',
  'BATCHING_REDUCER.BATCH',
];

export default module.exports;
