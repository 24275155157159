import graphql from 'babel-plugin-relay/macro';
import promiseMutation from 'utils/promise-mutation';

const mutation = graphql`
  mutation DeleteUserTalentMutation(
    $input: DeleteUserTalentByUserIdAndTalentIdInput!
  ) {
    deleteUserTalentByUserIdAndTalentId(input: $input) {
      deletedUserTalentId
    }
  }
`;

const commit = (environment, userId, talentId) =>
  promiseMutation(environment, {
    mutation,
    variables: { input: { userId, talentId } },
  });

export default commit;
