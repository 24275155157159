import React from 'react';
import PropTypes from 'prop-types';
import graphql from 'babel-plugin-relay/macro';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as authActions from 'actions/auth';
import createFragContainer from 'utils/create-frag-container';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import PurchasedTracks from './PurchasedTracks';
import LikedTracks from './LikedTracks';

const propTypes = {
  user: PropTypes.object.isRequired,
  signOut: PropTypes.func.isRequired,
};

const vocalFrag = graphql`
  fragment VocalDashboard_user on User {
    ...PurchasedTracks_user
    ...LikedTracks_user
  }
`;

const VocalDashboard = ({ user, signOut }) => (
  <div id="vocal-dashboard" className="mt-3 w-desk flex">
    <div className="flex-1 w-1 cont block py-4 sm:border border-0">
      <div className="flex sm:px-6 mb-2 justify-end items-center">
        <div>
          <button className="btn" onClick={signOut}>Sign Out</button>
        </div>
      </div>
      <Tabs className="sm:pb-6 sm:px-6">
        <TabList>
          <Tab>Purchases</Tab>
          <Tab>Collection</Tab>
        </TabList>

        <TabPanel>
          <PurchasedTracks user={user} />
        </TabPanel>
        <TabPanel>
          <LikedTracks user={user} />
        </TabPanel>
      </Tabs>
    </div>
  </div>
);

VocalDashboard.propTypes = propTypes;

export default compose(
  connect(null, authActions),
  createFragContainer([vocalFrag]),
)(VocalDashboard);
