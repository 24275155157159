import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ExitButton from 'components/common/ExitButton';
import PlugImage from 'components/common/PlugImage';
import displayTrackImage from 'utils/display-track-image';
import SoundbarContent from './SoundbarContent';

const propTypes = {
  currentTrack: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

const defaultProps = {
  currentTrack: {},
};

const SoundbarModal = ({
  currentTrack: { fileUrl, picture },
  onClose,
}) => (
  <div className="flex flex-col">
    <PlugImage
      imageSource={displayTrackImage(fileUrl, picture)}
      alt="Track"
      className="w-full cover"
      imageStyle={{ height: '100%' }}
      style={{ height: '60vh' }}
      once={false}
    />
    <ExitButton
      id="feedback-exit"
      className="absolute pin-r"
      onClick={onClose}
    />
    <SoundbarContent onClose={onClose} />
  </div>
);

SoundbarModal.propTypes = propTypes;
SoundbarModal.defaultProps = defaultProps;

export default connect(state => ({
  currentTrack: state.soundbar.currentTrack,
}))(SoundbarModal);
