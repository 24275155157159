import React from 'react';
import classnames from 'classnames';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { Mutation } from 'react-apollo';
import PropTypes from 'prop-types';

const propTypes = {
  track: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  onUnlikeClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  textClassName: PropTypes.string,
};

const defaultProps = {
  className: '',
  iconClassName: '',
  textClassName: '',
};

const likesFrag = gql`
  fragment LikesLink_track on Track {
    id
    publicId
    likes: likesByTrackId(first: 10000) @connection(key: "LikesLink_likes") {
      totalCount
      edges {
        node {
          userId
        }
      }
    }
  }
`;

const CREATE_LIKE = gql`
  mutation CreateLikeMutation($input: CreateLikeInput!) {
    createLike(input: $input) {
      track: trackByTrackId {
        ...LikesLink_track
      }
    }
  }
  ${likesFrag}
`;

const DELETE_LIKE = gql`
  mutation DeleteLikeByUserIdAndTrackIdMutation(
    $input: DeleteLikeByUserIdAndTrackIdInput!
  ) {
    deleteLike: deleteLikeByUserIdAndTrackId(input: $input) {
      track: trackByTrackId {
        ...LikesLink_track
      }
    }
  }
  ${likesFrag}
`;

const LikesLink = ({
  track,
  className,
  iconClassName,
  textClassName,
  userId,
  onLikeClick,
  onUnlikeClick,
  auth: { isAuthenticated },
}) => {
  const hasLiked = !!(
    track.likes.edges.length
    && track.likes.edges
      .map(like => like.node.userId)
      .find(item => item === userId)
  );
  const disabled = !isAuthenticated;

  return (
    <Mutation
      mutation={CREATE_LIKE}
    >
      {createLike => (
        <Mutation
          mutation={DELETE_LIKE}
        >
          {deleteLike => (
            <div id="likes-link" className={classnames(className)}>
              <a
                className={classnames('link', { disabled })}
                role="button"
                tabIndex={0}
                onClick={(e) => {
                  e.stopPropagation();
                  if (hasLiked) {
                    deleteLike({
                      variables: {
                        input: { userId, trackId: track.id },
                      },
                    });
                  } else {
                    createLike({
                      variables: {
                        input: { like: { userId, trackId: track.id } },
                      },
                    });
                  }
                }}
                disabled={disabled}
              >
                <i
                  className={classnames(
                    'fa',
                    iconClassName,
                    { 'fa-heart-o': !hasLiked },
                    { 'fa-heart red': !disabled && hasLiked },
                  )}
                />
                <span className={textClassName}>{track.likes.totalCount}</span>
              </a>
            </div>
          )}
        </Mutation>
      )}
    </Mutation>
  );
};

LikesLink.propTypes = propTypes;
LikesLink.defaultProps = defaultProps;
LikesLink.fragments = {
  track: likesFrag,
};

export default connect(state => ({ auth: state.auth }))(LikesLink);
