import React from 'react';
import { Link } from 'react-router-dom';
import SignUpBlock from './SignUpBlock';

const SignUp = () => (
  <div className="container mt-8 pt-8 text-center">
    <SignUpBlock />
    <div
      className="flex justify-center"
    >
      <div className="lu-w30 mu-w50 w90 mt-12 text-gray-dark">
        Plug Music is in <span className="font-bold">beta</span>. We will email sign ups when we are out of beta.
      </div>
    </div>
    <div className="mt-4 text-gray-dark">
      Already have an account?&nbsp;
      <Link to="/login" className="font-semibold hover:text-gray-darker">
        Sign in
      </Link>
      .
    </div>
  </div>
);

export default SignUp;
