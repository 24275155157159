import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from 'components/QueryRenderer';
import s3Actions from 'actions/s3';
import EditTrackModal from './EditTrackModal';

const editTrackQuery = graphql`
  query EditTrackModalContainerQuery($trackId: UUID!, $genreCondition: GenreCondition!) {
    track: trackById(id: $trackId) {
      ...EditTrackModal_track
    },
    allGenres: allGenres(condition: $genreCondition){
      ...TrackForm_allGenres,
    },
    allMoods: allMoods{
      ...TrackForm_allMoods,
    }
    allGenres: allGenres(condition: $genreCondition){
      ...TrackForm_allGenres,
    },
    allMoods: allMoods{
      ...TrackForm_allMoods,
    }
    user: currentUser {
      ...EditTrackModal_user
    }
  }
`;

const propTypes = {
  auth: PropTypes.object.isRequired,
  modals: PropTypes.object.isRequired,
};

const EditTrackModalContainer = ({ auth, modals, ...props }) => {
  const trackId = modals.getIn(['data', 'trackId']);
  return (
    <QueryRenderer
      auth={auth}
      query={editTrackQuery}
      variables={{ trackId, genreCondition: { parent: 1 } }}
      component={EditTrackModal}
      {...props}
    />
  );
};

EditTrackModalContainer.propTypes = propTypes;

export default connect(state => ({ auth: state.auth }), s3Actions)(
  EditTrackModalContainer,
);
