import {
  ADD_CART_ITEM,
  REMOVE_CART_ITEM,
  UPDATE_CART_ITEM,
  SWAP_CART_ITEM,
  REFRESH_CART,
  LOAD_CART_ITEMS,
} from 'types/cart';

export const addCartItem = (itemId, price, trackId) => ({
  type: ADD_CART_ITEM,
  payload: { id: itemId, price, trackId },
});

export const removeCartItem = itemId => ({
  type: REMOVE_CART_ITEM,
  payload: { id: itemId },
});

export const refreshCart = () => ({
  type: REFRESH_CART,
});

export const updateCartItem = update => ({
  type: UPDATE_CART_ITEM,
  payload: update,
});

export const swapCartItem = update => ({
  type: SWAP_CART_ITEM,
  payload: update,
});

export const loadCartItems = items => ({
  type: LOAD_CART_ITEMS,
  payload: items,
});
