import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import gql from 'graphql-tag';
import displayTrackImage from 'utils/display-track-image';

const propTypes = {
  track: PropTypes.object.isRequired,
  togglePlay: PropTypes.func.isRequired,
  soundbarTrack: PropTypes.object,
  soundbarIsPlaying: PropTypes.bool.isRequired,
  imageClassName: PropTypes.string.isRequired,
  showPlayByDefault: PropTypes.bool.isRequired,
  circlePlayIcon: PropTypes.bool.isRequired,
};

const defaultProps = {
  soundbarTrack: null,
  removeOtherTracksOnPlay: false,
};

const imagePlayFrag = gql`
  fragment ImagePlay_track on Track {
    id
    fileUrl
    picture
    publicId
  }
`;

const ImagePlay = ({
  track,
  imageClassName,
  showPlayByDefault,
  soundbarIsPlaying,
  soundbarTrack,
  togglePlay,
  circlePlayIcon,
}) => {
  const trackIsPlaying = soundbarIsPlaying && track.id === soundbarTrack.id;

  return (
    <div
      role="presentation"
      className="inline-block relative outline-none flex flex-1 overflow-hidden"
      key={track.id}
      onClick={togglePlay}
    >
      <img
        src={displayTrackImage(track.fileUrl, track.picture)}
        alt="Track"
        className={classnames(
          'play-pause-img br-4 cover flex-1',
          imageClassName,
        )}
      />
      <i
        className={classnames(
          'fa fa-pause absolute transform-center pointer play-pause-btn pause-btn',
          { 'block hover-show': trackIsPlaying, hidden: !trackIsPlaying },
        )}
        aria-hidden="true"
      />
      <i
        className={classnames(
          'fa absolute transform-center pointer play-pause-btn',
          {
            'fa-play-circle-o text-5xl': circlePlayIcon,
            'fa-play text-4xl': !circlePlayIcon,
            hidden: trackIsPlaying || !showPlayByDefault,
            'hover-show': !trackIsPlaying,
          },
        )}
        aria-hidden="true"
      />
    </div>
  );
};

ImagePlay.propTypes = propTypes;
ImagePlay.defaultProps = defaultProps;
ImagePlay.fragments = {
  track: imagePlayFrag,
};

export default compose(
  withRouter,
  connect(state => ({
    soundbarTrack: state.soundbar.currentTrack,
    soundbarIsPlaying: state.soundbar.playing,
  })),
)(ImagePlay);
