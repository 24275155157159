import * as types from 'types';

console.log('all types', types);

const initialState = {
  size: null,
  type: null,
};

export default function windowSession(state = initialState, { type, payload }) {
  switch (type) {
  case types.CHANGE_WINDOW_SETTINGS:{
    console.log('trackplayer payload', payload);
    
    return Object.assign({}, state, payload);
}
  default:
    return state;
  }
}
