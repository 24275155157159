import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { QueryRenderer, graphql } from 'react-relay';
import SearchStatePreserver from './SearchStatePreserver';
import { standardizeResults } from '../../helpers/standardizeRelayResults';


const propTypes = {
  auth: PropTypes.object.isRequired,
  tags: PropTypes.object,
  tracks: PropTypes.object,
  users: PropTypes.object,
  genres: PropTypes.object,
  moods: PropTypes.object,
  queryString: PropTypes.string.isRequired,
  handleHide: PropTypes.func.isRequired,
  usersResults: PropTypes.number,
  tracksResults: PropTypes.number,
  genresResults: PropTypes.number,
  moodsResults: PropTypes.number,
  tagsResults: PropTypes.number,
};

const defaultProps = {
  tags: {},
  tracks: {},
  users: {},
  genres: {},
  moods: {},
  usersResults: 3,
  tracksResults: 3,
  genresResults: 3,
  moodsResults: 3,
  tagsResults: 3,
};

const SearchResultsRelay = ({ auth, queryString, handleHide, usersResults, tracksResults, genresResults, moodsResults, tagsResults }) => (
  <QueryRenderer
    query={graphql`
          query SearchResultsRelayQuery($queryString: String!, $usersResults: Int, $tagsResults: Int, $tracksResults: Int, $genresResults: Int, $moodsResults: Int){
            users: searchUsers(search:$queryString, first: $usersResults){
              edges {
                cursor
                node {
                  id
                  displayName
                  username
                  picture
                  tracksByUserId{
                    totalCount
                  }
                  followsByFollowerId{
                    totalCount
                  }
                }
              }
            },
            tracks: searchTracks(search:$queryString, first: $tracksResults){
              edges {
                cursor
                node {
                  id
                  name
                  publicId
                  length
                  bpm
                  mood
                  genre
                  picture
                  fileUrl
                  userByUserId {
                    id
                    username
                    displayName
                  }
                }
              }
            },
            genres: searchGenres(search:$queryString, first: $genresResults){
              edges {
                node {
                  genre
                }
              }
            },
            moods: searchMoods(search:$queryString, first: $moodsResults){
              edges {
                node {
                  mood
                }
              }
            },
            tags: searchTags(search: $queryString, first: $tagsResults){
              edges {
                cursor
                node {
                  id
                  tag
                }
              }
            },
          }`}
    variables={{ queryString, usersResults, tracksResults, genresResults, moodsResults, tagsResults }}
    environment={auth.environment}
    render={({ error, props }) => {
      if (error) {
        return <p>We have an error</p>;
      }
      if (props) {
        const { tags, tracks, users, genres, moods } = props;
        return (
          <SearchStatePreserver
            tags={standardizeResults(tags)}
            tracks={standardizeResults(tracks)}
            users={standardizeResults(users)}
            genres={standardizeResults(genres)}
            moods={standardizeResults(moods)}
            handleHide={handleHide}
          />
        );
      }
      return (
        <SearchStatePreserver
          loading
          handleHide={handleHide}
        />
      );
    }}
  />
);

SearchResultsRelay.propTypes = propTypes;
SearchResultsRelay.defaultProps = defaultProps;


export default connect(state => ({ auth: state.auth }))(SearchResultsRelay);
