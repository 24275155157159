import React from 'react';
import Notifications from 'components/Notifications';

const NotificationsPage = () => (
  <div
    id="notifications-page"
    className="vw50 mx-auto mt-3 pcont"
    style={{ padding: '20px 16px', minWidth: '500px' }}
  >
    <h1 className="h1p">Notifications</h1>
    <Notifications />
  </div>
);

export default NotificationsPage;
