import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
};

const defaultProps = {
  id: '',
  className: '',
  style: null,
};

const TableData = ({ id, className, style, children }) => (
  <div id={id} className={classnames('TableData', className)} style={style}>
    {children}
  </div>
);

TableData.propTypes = propTypes;
TableData.defaultProps = defaultProps;

export default TableData;
