import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement,
} from 'react-stripe-elements';
import { connect } from 'react-redux';
import * as checkoutActions from 'actions/checkout';

const createOptions = fontSize => ({
  style: {
    base: {
      fontSize,
      color: '#424770',
      letterSpacing: '0.025em',
      fontFamily: 'Open Sans, Helvetica Neue, sans-serif',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
});

class CardSection extends Component {
  static propTypes = {
    checkout: PropTypes.object.isRequired,
    checkoutError: PropTypes.func.isRequired,
  };

  handleChange = (input, change) => {
    const { checkout, checkoutError } = this.props;
    const inputError = checkout.getIn(['errors', input]);
    if (change.error) {
      checkoutError({ [input]: change.error.message });
    } else if (inputError && change.complete) {
      checkoutError({ [input]: '' });
    }
  };

  render() {
    const { checkout } = this.props;
    const cardNumberError = checkout.getIn(['errors', 'cardNumber']);
    const cardExpiryError = checkout.getIn(['errors', 'cardExpiry']);
    const cardCvcError = checkout.getIn(['errors', 'cardCvc']);
    const postalCodeError = checkout.getIn(['errors', 'postalCode']);

    return (
      <div id="card-section">
        <label
          htmlFor="card-number"
          className={classnames({ 'text-red': cardNumberError })}
        >
          <div className="font-semibold">Card number</div>
          <CardNumberElement
            className={classnames(
              'my-2',
              { 'mb-6': !cardNumberError },
              {
                'text-red-dark': cardNumberError,
              },
            )}
            onChange={change => this.handleChange('cardNumber', change)}
            {...createOptions('16px')}
          />
          {cardNumberError && <div className="mb-6">{cardNumberError}</div>}
        </label>

        <div className="flex flex-wrap">
          <label
            htmlFor="card-expiry"
            className={classnames('w-1/2 md:w-1/3 pr-4', {
              'text-red': cardExpiryError,
            })}
          >
            <div className="font-semibold">Expiration date</div>
            <CardExpiryElement
              className={classnames(
                'my-2',
                { 'mb-6': !cardExpiryError },
                {
                  'text-red-dark': cardExpiryError,
                },
              )}
              onChange={change => this.handleChange('cardExpiry', change)}
              {...createOptions('16px')}
            />
            {cardExpiryError && <div className="mb-6">{cardExpiryError}</div>}
          </label>

          <label
            htmlFor="card-cvc"
            className={classnames('w-1/2 md:w-1/3 pr-4', {
              'text-red': cardCvcError,
            })}
          >
            <div className="font-semibold">Security code</div>
            <CardCVCElement
              className={classnames(
                'my-2',
                { 'mb-6': !cardCvcError },
                {
                  'text-red-dark': cardCvcError,
                },
              )}
              onChange={change => this.handleChange('cardCvc', change)}
              {...createOptions('16px')}
            />
            {cardCvcError && <div className="mb-6">{cardCvcError}</div>}
          </label>

          <label
            htmlFor="postal-code"
            className={classnames('w-1/3 md:w-1/4 pr-4', {
              'text-red': postalCodeError,
            })}
          >
            <div className="font-semibold">Postal code</div>
            <PostalCodeElement
              className={classnames(
                'my-2',
                { 'mb-8': !postalCodeError },
                {
                  'text-red-dark': postalCodeError,
                },
              )}
              onChange={change => this.handleChange('postalCode', change)}
              {...createOptions('16px')}
            />
            {postalCodeError && <div className="mb-8">{postalCodeError}</div>}
          </label>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({ checkout: state.checkout }),
  checkoutActions,
)(CardSection);
