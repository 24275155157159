import {
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAILURE,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
  SAVE,
  CHANGE_PROJECT_STATUS,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAILURE,
  CHANGE_BPM,
  RESET_PROJECTS,
} from 'types/projects';
import {
  fetchProjectsApi,
  fetchProjectApi,
  addProjectApi,
  deleteProjectApi,
  updateProjectApi,
} from 'rest/projects';
import { requestWrapper } from './helper';

export const fetchProjectAsync = requestWrapper(
  'FETCH_PROJECT',
  fetchProjectApi,
);

export const fetchProjectsAsync = requestWrapper(
  'FETCH_PROJECTS',
  fetchProjectsApi,
);

export function addProjectSuccess(payload) {
  return {
    type: ADD_PROJECT_SUCCESS,
    payload,
  };
}

export function addProjectFailure(payload) {
  return {
    type: ADD_PROJECT_FAILURE,
    payload,
  };
}

export const addProjectAsync = requestWrapper('ADD_PROJECT', addProjectApi);

export function deleteProjectSuccess(payload) {
  return {
    type: DELETE_PROJECT_SUCCESS,
    payload,
  };
}

export function deleteProjectFailure(payload) {
  return {
    DELETE_PROJECT_FAILURE,
    payload,
  };
}

export function save(payload) {
  return {
    type: SAVE,
    payload,
  };
}

export function changeProjectStatus(payload) {
  return {
    type: CHANGE_PROJECT_STATUS,
    payload,
  };
}

export const deleteProjectAsync = requestWrapper(
  'DELETE_PROJECT',
  deleteProjectApi,
);

export function updateProjectSuccess(payload) {
  return {
    type: UPDATE_PROJECT_SUCCESS,
    payload,
  };
}

export function updateProjectFailure(error) {
  return {
    type: UPDATE_PROJECT_FAILURE,
    error,
  };
}

export function changeBpm(id, bpm) {
  return {
    type: CHANGE_BPM,
    payload: {
      property: 'bpm',
      value: bpm,
      id,
    },
  };
}

export const updateProjectAsync = requestWrapper(
  'UPDATE_PROJECT',
  updateProjectApi,
);

export const resetProjects = () => ({ type: RESET_PROJECTS });

export default module.exports;
