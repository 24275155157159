/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type Buy_track$ref = any;
type DownloadFree_track$ref = any;
type Download_track$ref = any;
type LikesLink_track$ref = any;
import type { FragmentReference } from 'relay-runtime';
declare export opaque type TrackButtons_track$ref: FragmentReference;
export type TrackButtons_track = {|
  +id: any,
  +genre: ?string,
  +mood: ?string,
  +free: boolean,
  +comments: {|
    +totalCount: ?number,
  |},
  +$fragmentRefs: (LikesLink_track$ref & Download_track$ref & DownloadFree_track$ref & Buy_track$ref),
  +$refType: TrackButtons_track$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "TrackButtons_track",
  "type": "Track",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "genre",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "mood",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "free",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": "comments",
      "name": "commentsByTrackId",
      "storageKey": "commentsByTrackId(first:100,orderBy:\"CREATED_AT_ASC\")",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 100,
          "type": "Int"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "CREATED_AT_ASC",
          "type": "[CommentsOrderBy!]"
        }
      ],
      "concreteType": "CommentsConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "LikesLink_track",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "Download_track",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "DownloadFree_track",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "Buy_track",
      "args": null
    }
  ]
};
(node/*: any*/).hash = 'bea0f4e488e10e4a7ce033d28fb02c7f';
module.exports = node;
