import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { AudioSlider } from 'utils/AudioControls';

const propTypes = {
  size: PropTypes.string.isRequired,
};

const AudioSliderLg = ({ size }) => {
  const showSlider = size === 'default' || size === 'sm';

  return showSlider ? (
    <div className="w-full relative">
      <div>
        <AudioSlider rootClass={'soundbar-slider-root'} />
      </div>
    </div>
  ) : null;
};

AudioSliderLg.propTypes = propTypes;

export default compose(
  connect(state => ({
    size: state.windowSession.size,
  })),
)(AudioSliderLg);
