import { fetch, authHeader } from 'utils/fetch';

export function fetchClipsApi(trackId) {
  return fetch(`/api/tracks/${trackId}/clips`, {
    headers: authHeader(),
  });
}

export function addClipApi(clip) {
  return fetch('/api/clips/new', {
    method: 'POST',
    body: JSON.stringify(clip),
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
  });
}

export function deleteClipApi(id) {
  return fetch(`/api/clips/${id}`, {
    method: 'DELETE',
    headers: authHeader(),
  });
}

export function updateClipApi(update) {
  return fetch(`/api/clips/${update.id}`, {
    method: 'PUT',
    body: JSON.stringify(update),
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
  });
}

export default module.exports;
