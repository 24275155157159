import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import PropTypes from 'prop-types';
import Followed from './Followed';

const propTypes = {
  user: PropTypes.object.isRequired,
};

const FollowedList = ({ user }) => (
  <div>
    <div className="mb2 flex-row items-center">
      <h2 className="title mr2">Following</h2>
      {'  '}
      <div className="subtitle">({user.following.totalCount})</div>
    </div>
    <div className="flex-row flex-wrap">
      {user.following.edges.map(edge => (
        <Followed key={edge.node.nodeId} followed={edge.node.followed} />
      ))}
    </div>
  </div>
);

FollowedList.propTypes = propTypes;

export default createFragmentContainer(FollowedList, {
  user: graphql`
  fragment FollowedList_user on User {
    following: followsByFollowerId(
      first: 100,
      orderBy: CREATED_AT_DESC
    ) @connection(key: "FollowedList_following") {
      totalCount,
      edges {
        node {
          nodeId,
          followed: userByFollowedId {
            ...Followed_followed
          }
        }
      }
    }
  }
  `,
});
