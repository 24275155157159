import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import { Query } from 'react-apollo';
import CircularProgress from '@material-ui/core/CircularProgress';
import MoodListContainer from 'components/GenresAndMoods/MoodListContainer';

const MOOD_QUERY = gql`
  query MoodPageQuery($mood: TrackCondition!) {
    moodTracks: allTracks(condition: $mood) {
      ...MoodListContainer_moodTracks
    }
  }
  ${MoodListContainer.fragments.moodTracks}
`;

const propTypes = {
  match: PropTypes.object.isRequired,
};

const MoodPage = ({ match: { params: { mood } } }) => (
  <Query query={MOOD_QUERY} variables={{ mood: { mood, deleted: false } }}>
    {({ loading, error, data }) => {
      if (loading) {
        return (
          <div className="mt-12 ml-16">
            <CircularProgress size={50} style={{ color: '#2a585d' }} />
          </div>
        );
      }
      if (error) return <div>Error</div>;

      return <MoodListContainer moodTracks={filter(MoodListContainer.fragments.moodTracks, data.moodTracks)} />;
    }}
  </Query>
);

MoodPage.propTypes = propTypes;

export default MoodPage;
