import React, { Component } from 'react';
import PropTypes from 'prop-types';
import animate from 'utils/animate';
import minMax from 'utils/min-max';
import { classnames } from 'utils/screentype';
import Slides from './Slides';

const propTypes = {
  rows: PropTypes.number.isRequired,
  marginBottom: PropTypes.number.isRequired,
  lazyLoad: PropTypes.bool.isRequired,
  max: PropTypes.number.isRequired,
  lazyLoadedIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  columns: PropTypes.number.isRequired,
  totalColumns: PropTypes.number.isRequired,
  children: PropTypes.array.isRequired,
  slide: PropTypes.bool.isRequired,
  paddingLeft: PropTypes.number.isRequired,
  marginRight: PropTypes.number.isRequired,
  move: PropTypes.string.isRequired,
  arrowSlide: PropTypes.func.isRequired,
  extraSlide: PropTypes.bool.isRequired,
};

class SlideTrack extends Component {
  state = { columnWidth: 0 };

  componentDidMount() {
    this.setTrackWidth();
    this.createResizeHandler();
  }

  componentDidUpdate(prevState) {
    if (
      prevState.columns !== this.props.columns ||
      prevState.slide !== this.props.slide
    ) {
      this.setTrackWidth();
    }
    if (this.props.slide) {
      if (this.props.move) {
        const transform = this.slideTrack.style.transform;
        const translateX = parseFloat(transform.replace(/[^\d.]/g, '') || 0);
        const newTrans =
          this.props.move === 'right'
            ? -(translateX + this.carousel.clientWidth + this.props.marginRight)
            : -(
                translateX -
                this.carousel.clientWidth -
                this.props.marginRight
              );
        this.translateX = minMax(newTrans, -this.state.maxTranslateX, 0);
        this.slideTrack.style.transform = `translateX(${this.translateX}px)`;
        this.props.arrowSlide(null);
      }
      // needs work
      if (prevState.slideTrackWidth !== this.state.slideTrackWidth) {
        const newTrans = -(
          (this.translateX / prevState.slideTrackWidth) *
          this.state.slideTrackWidth
        );
        this.translateX = minMax(newTrans, -this.state.maxTranslateX, 0);
        this.slideTrack.style.transform = `translateX(${this.translateX}px)`;
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.animatedTrackWidth);
  }

  setTrackWidth = () => {
    let columnWidth;
    let amtOfPadding;
    const {
      paddingLeft,
      marginRight,
      slide,
      columns,
      totalColumns,
      extraSlide,
    } = this.props;
    if (slide) {
      const amtOfExtraSlide = extraSlide ? 0.25 : 0;
      if (paddingLeft.indexOf('%') > 0) {
        const paddingProportion = parseFloat(paddingLeft) / 100.0;
        amtOfPadding = paddingProportion * this.carousel.clientWidth;
      } else if (paddingLeft.indexOf('px') > 0) {
        amtOfPadding = parseFloat(paddingLeft);
      } else if (!isNaN(paddingLeft)) {
        amtOfPadding = paddingLeft;
      } else {
        throw Error('paddingLeft should be percentage or pixel');
      }
      // amtOfPadding = paddingLeft * this.carousel.clientWidth;
      const marginSpace = (columns - 1) * marginRight;
      const availableSpace =
        this.carousel.clientWidth - amtOfPadding - marginSpace;
      const totalColumnsPerRow = this.props.columns + amtOfExtraSlide;
      columnWidth = availableSpace / totalColumnsPerRow;
    } else {
      const marginSpace = (columns - 1) * marginRight;
      columnWidth = (this.carousel.clientWidth - marginSpace) / columns;
    }
    const totalMarginSpace = marginRight * (totalColumns - 1);
    const slideTrackWidth = totalMarginSpace + (columnWidth * totalColumns);
    this.setState({
      slideTrackWidth,
      columnWidth,
      maxTranslateX: slideTrackWidth - this.carousel.clientWidth,
    });
  };

  animatedTrackWidth = animate(this.setTrackWidth);

  createResizeHandler = () => {
    window.addEventListener('resize', this.animatedTrackWidth);
  };

  translateX;

  render() {
    const {
      rows,
      marginBottom,
      lazyLoad,
      max,
      lazyLoadedIndex,
      index,
      totalColumns,
      slide,
      children,
      paddingLeft,
      marginRight,
    } = this.props;
    const { columnWidth, slideTrackWidth } = this.state;
    const numberOfColumnsScrolled = index / rows;
    const columnFraction = 1 / totalColumns;
    let transform;
    if (!slide) {
      const marginScroll = marginRight * numberOfColumnsScrolled;
      const columnScroll = numberOfColumnsScrolled * columnWidth;
      transform = {
        transform: `translateX(-${marginScroll + columnScroll}px)`,
      };
    }
    return (
      <div
        className="overflow-hidden carousel-list h100"
        ref={(carousel) => {
          this.carousel = carousel;
        }}
      >
        <div
          style={{ paddingLeft }}
          className={classnames(
            'h100',
            slide
              ? 'overflow-x-scroll overflow-y-hidden scroll-invisible'
              : 'overflow-hidden',
          )}
        >
          <div
            className="carousel-track transition-carousel h100"
            style={{
              ...transform,
              willChange: 'transform',
              width: slideTrackWidth,
            }}
            ref={(slideTrack) => {
              this.slideTrack = slideTrack;
            }}
          >
            <Slides
              rows={rows}
              marginBottom={marginBottom}
              marginRight={marginRight}
              lazyLoad={lazyLoad}
              max={max}
              lazyLoadedIndex={lazyLoadedIndex}
              index={index}
              totalColumns={totalColumns}
              columnPercentage={columnFraction * 100}
            >
              {children}
            </Slides>
          </div>
        </div>
      </div>
    );
  }
}

SlideTrack.propTypes = propTypes;

export default SlideTrack;
