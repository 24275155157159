import React from 'react';
import PropTypes from 'prop-types';
import ExitButton from 'components/common/ExitButton';
import { isDesktop } from 'utils/screentype';

const propTypes = {
  title: PropTypes.string.isRequired,
  onNext: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  nextText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onPrevious: PropTypes.func,
  onSubmit: PropTypes.func,
};

const defaultProps = {
  onPrevious: null,
  onSubmit: null,
};

const OnboardingBody = ({
  title,
  children,
  onNext,
  onCancel,
  nextText,
  onPrevious,
  onSubmit,
}) => (
  <div id="onboarding-body" className="container h-screen overflow-auto pt-10">
    <div
      className={`fixed mt-2 w-full pr-20 ${isDesktop() || 'z-10'}`}
      style={{ left: 15, top: 0 }}
    >
      {onPrevious && (
        <button
          className={`p2 pl-3 pr-3 color-white noFocusedOutline float-left ${isDesktop() ||
            'fixed'}`}
          style={{
            borderRadius: 8,
            background: 'rgba(61, 72, 82, 0.5)',
            bottom: isDesktop() || 30,
          }}
          onClick={onPrevious}
        >
          {'< Back'}
        </button>
      )}
      <ExitButton
        className={`color-white noFocusedOutline float-right ${isDesktop() ||
          'absolute'}`}
        divStyle={{ top: isDesktop() || 10, right: isDesktop() || 30 }}
        textClassName="color-white"
        onClick={onCancel}
      />
    </div>
    <h1 className="color-white fixed ml-10">{title}</h1>
    {children}
    {/* Eventually get a nice stepped indicator here with next/back buttons and navigation for maxx sexx */}
    {(onNext || onSubmit) && (
      <div
        className={`center ${isDesktop() ? 'relative' : 'fixed'}`}
        style={{
          left: 0,
          right: 0,
          top: isDesktop() && 50,
          bottom: isDesktop() || 30,
        }}
      >
        {onNext ? (
          <button
            className="btn w-1/6 p-2 bg-blue "
            style={{ minWidth: 180 }}
            onClick={onNext}
          >
            {nextText}
          </button>
        ) : (
          onSubmit && (
            <button className="btn w-1/6 p-2 bg-green mt-2" onClick={onSubmit}>
              {'Done'}
            </button>
          )
        )}
      </div>
    )}
  </div>
);

OnboardingBody.propTypes = propTypes;
OnboardingBody.defaultProps = defaultProps;

export default OnboardingBody;
