/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type FollowedList_user$ref = any;
import type { FragmentReference } from 'relay-runtime';
declare export opaque type Following_user$ref: FragmentReference;
export type Following_user = {|
  +id: any,
  +displayName: string,
  +picture: ?string,
  +$fragmentRefs: FollowedList_user$ref,
  +$refType: Following_user$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "Following_user",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "displayName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "picture",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "FollowedList_user",
      "args": null
    }
  ]
};
(node/*: any*/).hash = 'f655cb3942833870b4e3770d27cb04ed';
module.exports = node;
