import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import Immutable from 'immutable';
import { Redirect } from 'react-router-dom';
import CheckoutDetail from '../CheckoutDetail';

const propTypes = {
  cartItems: PropTypes.instanceOf(Immutable.List).isRequired,
};

const CART_CHECKOUT_DETAILS = gql`
  query CartCheckoutDetailsQuery($productId: UUID!) {
    product: productById(id: $productId) {
      ...CheckoutDetail_product
    }
  }
  ${CheckoutDetail.fragments.product}
`;

const CartCheckoutDetails = ({ cartItems }) => (
  <div id="cart-checkout-details">
    {cartItems.map(item => (
      <Query
        key={item.get('id')}
        variables={{ productId: item.get('id') }}
        query={CART_CHECKOUT_DETAILS}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return <div>Loading ...</div>;
          } else if (error) {
            return <Redirect to="/error" />;
          }
          return <CheckoutDetail product={data.product} />;
        }}
      </Query>
    ))}
  </div>
  );

CartCheckoutDetails.propTypes = propTypes;

export default connect(state => ({
  cartItems: state.cart.get('items', Immutable.List()),
}))(CartCheckoutDetails);
