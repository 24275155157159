import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

const propTypes = {
  username: PropTypes.string.isRequired,
  toolConfig: PropTypes.object.isRequired,
};

const Profile = ({ username, toolConfig }) => (
  <Link
    to={`/${username}`}
    className="flex justify-center items-center mlr-8 ml-3"
    data-tip
    data-for={'profile'}
  >
    <i className="material-icons color-bg fs-24 icon-opacity">person</i>
    <ReactTooltip
      id={'profile'}
      type={toolConfig.type}
      effect={toolConfig.effect}
      delayShow={toolConfig.delayShow}
      className="tooltip"
    >
      <span>Profile</span>
    </ReactTooltip>
  </Link>
);

Profile.propTypes = propTypes;

export default Profile;
