import { createFragmentContainer } from 'react-relay';
import curry from 'lodash/curry';

// const createFragContainer = curry((fragSpec, Component) => createFragmentContainer(Component, fragSpec));
const createFragContainer = curry((fragments, Component) => {
  const fragmentObj = Object.values(fragments).reduce((acc, curr) => ({
    ...acc,
    [curr.name.split('_').pop()]: curr
  }), {});
  return createFragmentContainer(Component, fragmentObj);
});


export default createFragContainer;


