import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  index: PropTypes.number.isRequired,
  elementsPerCycle: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  leftArrowStyle: PropTypes.object.isRequired,
  rightArrowStyle: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  hasArrows: PropTypes.bool.isRequired,
  arrowSlide: PropTypes.func.isRequired,
  slide: PropTypes.bool.isRequired,
  changeIndex: PropTypes.func.isRequired,
};

const styles = {
  arrow: {
    top: '40% ',
  },
};

const Arrows = ({
  index,
  elementsPerCycle,
  max,
  children,
  leftArrowStyle,
  rightArrowStyle,
  arrowSlide,
  slide,
  changeIndex,
  hasArrows,
}) => {
  const renderLeftArrow = () => (
    <i
      style={{
        left: '-30px',
        fontSize: '40px',
        ...styles.arrow,
        ...leftArrowStyle,
      }}
      className="fa fa-angle-left absolute inline-block cursor-pointer browse-arrows"
      aria-hidden="true"
      onClick={() => //eslint-disable-line
        slide
          ? arrowSlide('left')
          : changeIndex(Math.max(0, index - elementsPerCycle))
      }
    />
  );
  const renderRightArrow = () => (
    <i
      style={{
        right: '-30px',
        fontSize: '40px',
        ...styles.arrow,
        ...rightArrowStyle,
      }}
      className="fa fa-angle-right absolute inline-block cursor-pointer browse-arrows"
      aria-hidden="true"
      onClick={() => //eslint-disable-line
        slide
          ? arrowSlide('right')
          : changeIndex(
              Math.min(index + elementsPerCycle, max - elementsPerCycle),
            )
      }
    />
  );
  // );
  const showLeftArrow = hasArrows && (slide || index - elementsPerCycle > -elementsPerCycle);
  const showRightArrow = hasArrows && (slide || index + elementsPerCycle < max);
  return (
    <div className="min-h-0 flex-1 relative">
      {showLeftArrow && renderLeftArrow()}
      {showRightArrow && renderRightArrow()}
      <div className="carousel h100">{children}</div>
    </div>
  );
};

Arrows.propTypes = propTypes;

export default Arrows;
