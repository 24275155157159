import React from 'react';
// import exclusiveExample from '../../markdown/exclusive-example.html';

const ExclusiveLicense = () => (
  <div />
  // <div
  //   className="markdown"
  //   dangerouslySetInnerHTML={{ __html: exclusiveExample }}
  // />
);

export default ExclusiveLicense;
