import React from 'react';
import PropTypes from 'prop-types';
import { ScaleLoader } from 'react-spinners';

const propTypes = {
  rejectText: PropTypes.string.isRequired,
  isDragReject: PropTypes.bool.isRequired,
};

const acceptText = 'Drop Files Anywhere';

const FullDropzoneView = ({ rejectText, isDragReject }) => (
  <span
    id="test"
    className="fixed h-screen w-screen flex z-50 pin-t pin-r pin-b pin-l
                 text-center justify-center align-center"
    style={{
      backgroundColor: 'rgba(255, 255, 255, 0.57)',
    }}
  >
    <div
      className="inline-block bg-white rounded border-2 border-gray-dark text-gray-darker"
      style={{
        height: '160px',
        width: '380px',
      }}
    >
      {isDragReject ? (
        <div className="h-full flex flex-col p-4 justify-around">
          <div className="text-sm leading-normal">{rejectText}</div>
          <div style={{ fontSize: '70px' }}>X</div>
        </div>
      ) : (
        <div className="h-full flex flex-col p-4 justify-around">
          <div className="font-semibold text-2xl">{acceptText}</div>
          <div className="music-upload-halogen">
            <ScaleLoader loading />
          </div>
        </div>
      )}
    </div>
  </span>
);
FullDropzoneView.propTypes = propTypes;

export default FullDropzoneView;
