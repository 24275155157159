import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import TrackDesktop from './TrackDesktop';
import TrackMobile from './TrackMobile';

const propTypes = {
  type: PropTypes.string.isRequired,
  track: PropTypes.object.isRequired,
  tracks: PropTypes.array.isRequired,
};

const TRACK_PLAYER_TRACK = gql`
  fragment TrackPlayer_track on Track {
    ...TrackDesktop_track
    ...TrackMobile_track
  }
  ${TrackDesktop.fragments.track}
  ${TrackMobile.fragments.track}
`;

const TRACK_PLAYER_USER = gql`
  fragment TrackPlayer_user on User {
    ...TrackDesktop_user
    ...TrackMobile_user
  }
  ${TrackDesktop.fragments.user}
  ${TrackMobile.fragments.user}
`;

const TrackPlayer = ({
  type,
  ...props
}) => {
  const desktop = type === 'desktop';
  console.log('trackplayer desktop', type);
  return desktop ? <TrackDesktop {...props} /> : <TrackMobile {...props} />;
};

TrackPlayer.propTypes = propTypes;
TrackPlayer.fragments = { user: TRACK_PLAYER_USER, track: TRACK_PLAYER_TRACK };

export default connect(state => ({ type: state.windowSession.type }))(TrackPlayer);
