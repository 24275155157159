/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type Buy_track$ref = any;
type Download_track$ref = any;
type Hashtags_track$ref = any;
type TrackButtons_track$ref = any;
import type { FragmentReference } from 'relay-runtime';
declare export opaque type TrackMobile_track$ref: FragmentReference;
export type TrackMobile_track = {|
  +id: any,
  +name: string,
  +picture: ?string,
  +fileUrl: ?string,
  +description: ?string,
  +publicId: ?string,
  +mood: ?string,
  +genre: ?string,
  +mp3Loaded: boolean,
  +$fragmentRefs: (Download_track$ref & Buy_track$ref & TrackButtons_track$ref & Hashtags_track$ref),
  +$refType: TrackMobile_track$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "TrackMobile_track",
  "type": "Track",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "picture",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fileUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "publicId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "mood",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "genre",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "mp3Loaded",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "Download_track",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "Buy_track",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "TrackButtons_track",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "Hashtags_track",
      "args": null
    }
  ]
};
(node/*: any*/).hash = 'a68aba408d2510ce0a58128b114159b8';
module.exports = node;
