import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import SuggestedProducer from './SuggestedProducer';

const propTypes = {
  users: PropTypes.object.isRequired,
};

const SUGGESTED_PRODUCERS_FRAG = gql`
  fragment SuggestedProducers_users on UsersConnection {
    edges {
      node {
        id
        ...SuggestedProducer_user
      }
    }
  }
  ${SuggestedProducer.fragments.user}
`;

const SuggestedProducers = ({ users }) => (
  <div className="cont mt-3 py-4 px-6">
    <div className="relative mb-4">
      <div className="text-sm font-bold inline-block text-gray-dark">
        Suggested Producers
      </div>
      <i
        className="fa fa-refresh inline-block absolute text-gray-dark text-base pin-r"
        style={{ marginTop: '3px' }}
        aria-hidden="true"
      />
    </div>
    <div>
      {users.edges.map(user => (
        <SuggestedProducer key={user.node.id} user={user.node} />
      ))}
    </div>
  </div>
);

SuggestedProducers.propTypes = propTypes;
SuggestedProducers.fragments = { users: SUGGESTED_PRODUCERS_FRAG };

export default SuggestedProducers;
