import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import CircularProgress from '@material-ui/core/CircularProgress';
import GenreListContainer from 'components/GenresAndMoods/GenreListContainer';

const GENRE_QUERY = gql`
  query GenrePageQuery($genre: TrackCondition!) {
    genreTracks: allTracks(condition: $genre) {
      ...GenreListContainer_genreTracks
    }
  }
  ${GenreListContainer.fragments.genreTracks}
`;

const propTypes = {
  match: PropTypes.object.isRequired,
};

const GenrePage = ({
  match: {
    params: { genre },
  },
}) => (
  <Query query={GENRE_QUERY} variables={{ genre: { genre, deleted: false } }}>
    {({ loading, error, data }) => {
      if (loading) {
        return (
          <div className="mt-12 ml-16">
            <CircularProgress size={50} style={{ color: '#2a585d' }} />
          </div>
        );
      }
      if (error) return <div>Error</div>;

      return (
        <GenreListContainer
          genreTracks={filter(
            GenreListContainer.fragments.genreTracks,
            data.genreTracks,
          )}
        />
      );
    }}
  </Query>
);

GenrePage.propTypes = propTypes;

export default GenrePage;
