import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { isDesktop } from 'utils/screentype';

const propTypes = {
  talents: PropTypes.object.isRequired,
  toggleSelected: PropTypes.func,
  selected: PropTypes.array,
  maxSelect: PropTypes.number,
  usingId: PropTypes.string,
};

const defaultProps = {
  selected: [],
  toggleSelected: () => {},
  maxSelect: 0,
  usingId: 'publicId',
};

const OnboardingTalents = ({
  talents,
  selected,
  toggleSelected,
  maxSelect,
  usingId,
}) => (
  <div
    className={`overflow-auto relative pl-10 pr-10 noScroll ${
      isDesktop() ? 'flex justify-center' : 'h-4/5'
    }`}
    style={{
      webkitMaskImage:
        'linear-gradient(90deg, rgba(0, 0, 0, 0) 3%, rgb(0, 0, 0) 9%), linear-gradient(90deg, rgba(0, 0, 0,0) 91%, rgb(0, 0, 0) 97%)',
      webkitMaskComposite: 'source-out',
      height: isDesktop() && 360,
      top: 50,
    }}
  >
    {talents &&
      talents.edges.map(({ node }) => (
        <div
          className={`p-2 justify-center ${isDesktop() || 'center-childp'}`}
          key={node[usingId]}
        >
          <Card
            className="w-48"
            style={{
              boxShadow: selected.includes(node[usingId])
                ? '#195899 0px 0px 1pt 5pt'
                : 'none',
            }}
          >
            <CardActionArea
              onClick={() => {
                toggleSelected('talents', node);
              }}
              style={{
                outline: 'none',
              }}
            >
              <CardMedia
                component="img"
                alt="Talent"
                className="cover"
                height="140"
                image={node.imageUrl}
                title={node.name}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {node.name}
                </Typography>
              </CardContent>
            </CardActionArea>
            {selected.includes(node[usingId]) && (
              <CardActions>
                <Button
                  disabled
                  size="small"
                  color="primary"
                  style={{ margin: 'auto' }}
                >
                  This is who I am
                </Button>
              </CardActions>
            )}
          </Card>
        </div>
      ))}
  </div>
);

OnboardingTalents.propTypes = propTypes;
OnboardingTalents.defaultProps = defaultProps;

export default OnboardingTalents;
