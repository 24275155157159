import React from 'react';

const TrackNotFound = () => (
  <div id="track-not-found" className="my-8 pt-8 px-8">
    <div className="title text-center">Track Not Found</div>
    <div className="subtitle text-center">Sorry, we can&#39;t find that track.</div>
  </div>
);

export default TrackNotFound;
