import React from 'react';
import PropTypes from 'prop-types';
import LandingStaffPicks from './LandingStaffPicks';
import photo from './abra.jpg';

const propTypes = {
  staffPicksTracks: PropTypes.object.isRequired,
  soundbarIsVisible: PropTypes.bool.isRequired,
  soundbarHeight: PropTypes.string.isRequired,
  removeOtherTracksOnPlay: PropTypes.bool,
};

const defaultProps = {
  removeOtherTracksOnPlay: false,
};

const Panda = ({
  staffPicksTracks,
  soundbarIsVisible,
  soundbarHeight,
  removeOtherTracksOnPlay,
}) => (
  <div
    id="page-1"
    className="h-screen"
    // style={soundbarIsVisible ? { height: `calc(100% - ${soundbarHeight})` } : null}
  >
    <div
      className="bg-black relative flex"
      style={{
        height: '75%',
      }}
    >
      <img
        className="absolute cover h-full w-full"
        style={{ objectPosition: '50% 0' }}
        alt="banner"
        src={photo}
      />
      <div className="relative w-desk flex flex-col h-full justify-center items-center">
        <div className="px-4 py-2" style={{ backgroundColor: '#28193e' }}>
          <p className="font-semibold text-5xl text-white pb-1">Plug Music</p>
          <p className="subtitle font-medium subtitle text-white">
            Discover dope beats
          </p>
        </div>
      </div>
    </div>
    <div
      style={{ height: '25%', backgroundColor: '#0f3452' }}
      className="bg-white"
    >
      <LandingStaffPicks
        tracks={staffPicksTracks}
        removeOtherTracksOnPlay={removeOtherTracksOnPlay}
      />
    </div>
  </div>
);

Panda.propTypes = propTypes;
Panda.defaultProps = defaultProps;

export default Panda;
