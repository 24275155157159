import React from 'react';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { avatarImageUrl } from 'config';
import displayImage from 'utils/display-image';

const propTypes = {
  like: PropTypes.object.isRequired,
};

const TRACK_LIKE = gql`
  fragment TrackLike_like on Like {
    user: userByUserId {
      username
      displayName,
      picture
    }
  }
`;

const TrackLike = ({ like: { user: { username, displayName, picture } } }) => (
  <div className="has-text-centered p-2">
    <Link to={`/${username}`} style={{ display: 'block' }} className="sat-contrast-hover mb1">
      <img
        alt="this is fake"
        className="circle"
        style={{ objectFit: 'cover', height: 124, width: 124 }}
        src={displayImage(avatarImageUrl, picture)}
      />
    </Link>
    <Link to={`/${username}`} className="link">{displayName}</Link>
  </div>
);

TrackLike.propTypes = propTypes;
TrackLike.fragments = { like: TRACK_LIKE };

export default TrackLike;
