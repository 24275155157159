// If we are only wrapping auth around the route (possible default)
import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const propTypes = {
  auth: PropTypes.object.isRequired,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

const AuthRoute = ({ auth, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => <Component {...props} auth={auth} />}
  />
);

AuthRoute.propTypes = propTypes;

export default compose(withRouter, connect(state => ({ auth: state.auth })))(
  AuthRoute,
);
