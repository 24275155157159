import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import TextInput from 'components/common/TextInput';

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  return errors;
};

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
};

const defaultProps = {
  error: '',
};

const SellBeatsModalForm = ({ handleSubmit, submitting, error }) => (
  <form className="md:mr-8 md:pr-8 text-left" onSubmit={handleSubmit}>
    <Field
      className="w-full my-6"
      name="email"
      placeholder="Email"
      htmlFor="email"
      type="email"
      component={TextInput}
    />

    {error && <div className="error">{error}</div>}

    <button
      type="submit"
      className="btn w-full md:w-32 mt-4"
      disabled={submitting}
    >
      Submit
    </button>
  </form>
);

SellBeatsModalForm.propTypes = propTypes;
SellBeatsModalForm.defaultProps = defaultProps;

export default reduxForm({
  form: 'SellBeats',
  validate,
})(SellBeatsModalForm);
