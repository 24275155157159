import TextField from '@material-ui/core/TextField';
import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  setRef: PropTypes.func,
};

const defaultProps = {
  input: '',
  type: '',
  label: '',
  setRef: () => {},
};

const RFTextField = ({
  input,
  type,
  label,
  setRef,
  meta: { touched, error },
  ...custom
}) =>
  (<TextField
    hintText={label}
    type={type}
    floatingLabelText={label}
    errorText={touched && error}
    ref={setRef}
    {...input}
    {...custom}
  />);

RFTextField.propTypes = propTypes;
RFTextField.defaultProps = defaultProps;

export default RFTextField;
