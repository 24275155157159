import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import { avatarImageUrl } from 'config';
import FollowButton from 'components/common/ApolloFollowButton';
import PlugImage from 'components/common/PlugImage';
import displayImage from 'utils/display-image';

const propTypes = {
  user: PropTypes.object.isRequired,
};

const SUGGESTED_PRODUCER = gql`
  fragment SuggestedProducer_user on User {
    id
    username
    picture
    displayName
    ...FollowButton_user
  }
  ${FollowButton.fragments.user}
`;

const SuggestedProducer = ({ user }) => (
  <div key={user.id} className="dashboard-producers-cont pb-4 relative flex">
    <div
      className="inline-block relative overflow-hidden rounded-full mr-px"
      style={{
        width: '60px',
        height: '60px',
      }}
    >
      <Link to={`/${user.username}`}>
        <PlugImage
          imageSource={displayImage(avatarImageUrl, user.picture)}
          alt="tr808"
          className="w-full h-full cover"
          style={{ height: 60, width: 60 }}
        />
        {/* <img
          src={displayImage(avatarImageUrl, user.picture)}
          alt="tr808"
          className="w-full h-full cover"
        /> */}
      </Link>
    </div>
    <div
      className="text-ellipsis text-xs relative self-center ml-3"
      style={{
        width: '106px',
      }}
    >
      <Link
        className="font-semibold leading-normal pb-px whitespace-no-wrap text-gray-dark"
        to={`/${user.username}`}
      >
        {user.displayName}
      </Link>
    </div>
    <FollowButton
      followClass="btn btn-pill text-xxs font-semibold self-center absolute pin-r"
      followingClass="btn btn-pill text-xxs font-semibold self-center absolute pin-r"
      user={user}
    />
  </div>
);

SuggestedProducer.propTypes = propTypes;
SuggestedProducer.fragments = { user: SUGGESTED_PRODUCER };

export default SuggestedProducer;
