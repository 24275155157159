import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import SideButton from '../SideButton';
import AddDescription from './AddDescription';

const propTypes = {
  user: PropTypes.object.isRequired,
  myself: PropTypes.bool.isRequired,
  currentUser: PropTypes.string.isRequired,
};

const PROFILE_SUMMARY_FRAG = gql`
  fragment ProfileSummaryDesktop_user on User {
    id
    description
    displayName
    username
    tracks: tracksByUserId(
      first: 100
      orderBy: CREATED_AT_DESC
      condition: $trackCondition
    ) {
      totalCount
    }
    followers: followsByFollowedId(first: 1) {
      totalCount
    }
    following: followsByFollowerId(first: 1) {
      totalCount
    }
    ...SideButton_user
  }
  ${SideButton.fragments.user}
`;

const ProfileSummaryDesktop = ({ user, myself, currentUser }) =>
   (
     <div
       className="leading-normal"
       style={{ width: '300px', minWidth: '300px' }}
     >
       {/* Width of side button container should be same as banner image so it is centered */}
       <div className="text-center" style={{ width: '200px' }}>
         <SideButton
           user={user}
           myself={myself}
           currentUser={currentUser}
           followClassName="profile-follow-btn-desk"
           followingClassName="profile-follow-btn-desk"
           buttonSize="md"
         />
       </div>
       <div className="mt-3">
         {!user.description && myself ? (
           <AddDescription user={user} onSubmit={() => console.log('submit new description')} />
        ) : (
          user.description
        )}
         <div className="mt-2 flex">
           <div className="flex flex-col mr-6 items-center">
             <p className="text-gray-darker font-semibold text-sm">Tracks</p>
             <p className="font-semibold">
               {user.tracks && user.tracks.totalCount}
             </p>
           </div>
           <div className="flex flex-col mr-6 items-center">
             <p className="font-semibold text-sm">Followers</p>
             <p className="font-semibold">
               {user.followers && user.followers.totalCount}
             </p>
           </div>
           <div className="flex flex-col items-center">
             <p className="font-semibold text-sm">Following</p>
             <p className="font-semibold">
               {user.following && user.following.totalCount}
             </p>
           </div>
         </div>
       </div>
     </div>
  );

ProfileSummaryDesktop.propTypes = propTypes;
ProfileSummaryDesktop.fragments = { user: PROFILE_SUMMARY_FRAG };

export default ProfileSummaryDesktop;
