import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import Projects from 'components/Projects';
import RelayRoute from 'components/Route/RelayRoute';

const projectsQuery = graphql`
  query ProjectsPageQuery {
    coolProjects: getAllProjects {
      edges {
        node {
          id
          name
          updatedAt
          createdAt
          createdBy
          userProjectsByProjectId {
            edges {
              node {
                userByUserId {
                  id
                  username
                  displayName
                  picture
                }
              }
            }
          }
        }
      }
    }
    beats: getBeatsSold {
      ...SoldBeats_beats
    }
    query {
      ...SoldBeats_query
    }
  }
`;

const ProjectsPage = props => (
  <RelayRoute
    {...props}
    privacy="PRIVATE"
    query={projectsQuery}
    component={Projects}
  />
);

export default ProjectsPage;
