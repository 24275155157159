import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { filter } from "graphql-anywhere";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import ls from "local-storage";
import qs from "query-string";
import decode from "jwt-decode";
import pluralize from "pluralize";
import * as checkoutActions from "actions/checkout";
import * as modalActions from "actions/modals";
import DeliveryOptions from "./DeliveryOptions";
import DeliveryError from "./DeliveryError";

const DELIVER_BEAT_ORDER = gql`
  fragment DeliverBeat_order on Order {
    id
    guest
    purchaserId
    createdAt
    products {
      totalCount
      edges {
        node {
          id
          type
          track: trackByTrackId {
            name
            user: userByUserId {
              displayName
            }
          }
          ...DeliveryOptions_product
        }
      }
    }
  }
  ${DeliveryOptions.fragments.product}
`;

class DeliverBeat extends Component {
  static fragments = { order: DELIVER_BEAT_ORDER };

  static propTypes = {
    order: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
    showModal: PropTypes.func.isRequired,
  };

  state = { status: "loading", error: "" };

  componentDidMount() {
    const {
      history: { push },
      location: { search },
    } = this.props;
    const query = qs.parse(search);
    const token = JSON.parse(ls("otoken")) || {};
    if (query.token) {
      this.handleQueryToken(query);
    } else if (token.orderId) {
      this.handleLocalToken(token);
    } else {
      push("/cart");
    }
  }

  getSubTitle = () => {
    const { status } = this.state;
    const {
      order: {
        products: { totalCount },
      },
    } = this.props;
    const desktopSubTitle = status === "local"
      ? `Download the ${pluralize(
        "beat",
        totalCount,
      )} below. We also sent you an email to download at a later time.`
      : `Download the ${pluralize("beat", totalCount)} below.`;
    return desktopSubTitle;
  };

  handleQueryToken = ({ token, email }) => {
    const qToken = decodeURIComponent(
      Buffer.from(token, "base64").toString("ascii"),
    );
    const { id } = decode(qToken);
    const {
      order: { id: orderId, guest, purchaserId },
      userId,
    } = this.props;
    const isCorrectUser = purchaserId === userId || guest === email;
    if (isCorrectUser && id === orderId) {
      this.setState({ status: "email" });
    } else {
      this.setState({ error: "You have no access to this page!" });
    }
  };

  handleLocalToken = ({ orderId, guest: orderGuest }) => {
    const {
      order: {
        id, guest, purchaserId, createdAt,
      },
      userId,
    } = this.props;
    const then = new Date(createdAt);
    const now = new Date();
    const valid = then / 1000 + 60 * 60 * 24 >= now / 1000;
    const isCorrectUser = purchaserId === userId || guest === orderGuest;
    if (isCorrectUser && id === orderId && valid) {
      this.setState({ status: "local" });
    } else {
      this.setState({ error: "Your access to this page has expired!" });
    }
  };

  render() {
    const {
      order: { products },
      showModal,
      userId,
    } = this.props;
    const { error, status } = this.state;
    const totalCount = products.length;

    if (error) return <DeliveryError error={error} />;
    if (status === "loading") return <div>Loading ...</div>;
    const subTitle = this.getSubTitle();
    return (
      <div
        id="deliver-beat"
        className="text-center page-width mx-auto my-8 pt-8"
      >
        <div className="title">
          Thank you for purchasing
          {' '}
          {totalCount}
          {' '}
          {pluralize("beat", totalCount)}
          !
        </div>
        <div className="subtitle" style={{ marginTop: "1.5rem" }}>
          {subTitle}
        </div>
        {!userId && (
          <div>
            <a role="presentation" onClick={() => showModal("RegisterModal")}>
              Sign up
            </a>
            {" "}
            to access the beat at a later time.
          </div>
        )}
        {products.map((product) => (
          <>
            <div className="text-2xl mt-8 font-semibold">
              {product.track.name}
              {' '}
              {product.type}
              {' '}
              by
              {" "}
              {product.track.user.displayName}
            </div>
            <DeliveryOptions key={product.id} product={product} />
          </>
        ))}
      </div>
    );
  }
}

export default compose(
  connect(
    (state) => ({
      checkout: state.checkout,
      userId: state.auth.userId,
    }),
    { ...checkoutActions, ...modalActions },
  ),
  withRouter,
)(DeliverBeat);
