import { fetch, authHeader } from 'utils/fetch';

export function fetchTracksApi(projectId) {
  return fetch(`/api/projects/${projectId}/tracks`, {
    headers: authHeader(),
  });
}

export function addTrackApi(track) {
  return fetch('/api/tracks/new', {
    method: 'POST',
    body: JSON.stringify(track),
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
  });
}

export function deleteTrackApi(id) {
  return fetch(`/api/tracks/${id}`, {
    method: 'DELETE',
    headers: authHeader(),
  });
}

export function updateTrackApi(id, update) {
  return fetch(`/api/tracks/${id}`, {
    method: 'PUT',
    body: JSON.stringify(update),
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
  });
}

export default module.exports;
