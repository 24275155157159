/**
 * @flow
 * @relayHash 4638e065308b8d7f28457f1eb645f647
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateUserTalentMutationVariables = {|
  input: {
    clientMutationId?: ?string,
    userTalent: {
      userId: any,
      talentId: string,
      createdAt?: ?any,
    },
  },
|};
export type CreateUserTalentMutationResponse = {|
  +createUserTalent: ?{|
    +userTalent: ?{|
      +userId: any,
      +talentId: string,
    |},
  |},
|};
*/


/*
mutation CreateUserTalentMutation(
  $input: CreateUserTalentInput!
) {
  createUserTalent(input: $input) {
    userTalent {
      userId
      talentId
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateUserTalentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createUserTalent",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "CreateUserTalentInput!"
      }
    ],
    "concreteType": "CreateUserTalentPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userTalent",
        "storageKey": null,
        "args": null,
        "concreteType": "UserTalent",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "userId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "talentId",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CreateUserTalentMutation",
  "id": null,
  "text": "mutation CreateUserTalentMutation(\n  $input: CreateUserTalentInput!\n) {\n  createUserTalent(input: $input) {\n    userTalent {\n      userId\n      talentId\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateUserTalentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateUserTalentMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node/*: any*/).hash = '251efb373cd99ee103606d9a39d70e5a';
module.exports = node;
