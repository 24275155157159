import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  htmlFor: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
};

const defaultProps = {
  label: '',
  htmlFor: '',
  placeholder: '',
  defaultValue: '',
  disabled: false,
};

const TextArea = ({
  input,
  label,
  placeholder,
  defaultValue,
  htmlFor,
  disabled,
  meta: { touched, error, warning },
}) => (
  <div className="field">
    <label htmlFor={htmlFor} className="label">{label}</label>
    <div className="control">
      <textarea
        className={classnames(
          'textarea',
          'no-bs',
          { 'is-danger': touched && error },
          { 'is-warning': touched && warning },
        )}
        placeholder={placeholder}
        defaultValue={defaultValue}
        disabled={disabled}
        {...input}
      />
      {touched &&
        ((error && <p className="help is-danger">{error}</p>) ||
          (warning && <p className="help is-warning">{warning}</p>))}
    </div>
  </div>
);

TextArea.propTypes = propTypes;
TextArea.defaultProps = defaultProps;

export default TextArea;
