import types from 'types/posts';
import { fetchPostsApi, addPostApi } from 'rest/posts';
import { requestWrapper } from './helper';

export function addPostSuccess(payload) {
  return {
    type: types.ADD_POST_SUCCESS,
    payload,
  };
}

export const fetchPostsAsync = requestWrapper('FETCH_POSTS', fetchPostsApi);

export const addPostAsync = requestWrapper('ADD_POST', addPostApi);

export const resetPosts = () => ({ type: types.RESET_POSTS });

export default module.exports;
