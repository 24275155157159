import graphql from 'babel-plugin-relay/macro';
import promiseMutation from 'utils/promise-mutation';

const mutation = graphql`
  mutation CreateUserArtistMutation($input: CreateUserArtistInput!) {
    createUserArtist(input: $input) {
      userArtist {
        userId
        artistId
      }
    }
  }
`;

const commit = (environment, userId, artistId) =>
  promiseMutation(environment, {
    mutation,
    variables: { input: { userArtist: { userId, artistId } } },
  });

export default commit;
