import React from 'react';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';
import TextInput from 'components/common/TextInput';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
};

const CreateComment = ({ handleSubmit, disabled }) => (
  <form onSubmit={handleSubmit}>
    <Field
      name="comment"
      placeholder="Write a comment..."
      htmlFor="comment"
      component={TextInput}
      disabled={disabled}
    />
  </form>
);

CreateComment.propTypes = propTypes;
CreateComment.defaultProps = defaultProps;

export default reduxForm({
  form: 'CreateComment',
})(CreateComment);
