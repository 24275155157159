import { fetch, authHeader } from 'utils/fetch';

export function fetchInvitesApi() {
  return fetch('/api/invites', {
    headers: authHeader(),
  });
}

export function fetchInvitesSentApi() {
  return fetch('/api/invites/sender', {
    headers: authHeader(),
  });
}

export function fetchInvitesReceivedApi() {
  return fetch('/api/invites/receiver', {
    headers: authHeader(),
  });
}

export function addInviteApi(invite) {
  return fetch('/api/invites/new', {
    method: 'POST',
    body: JSON.stringify(invite),
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
  });
}

export function updateInviteApi(invite) {
  return fetch(`/api/invites/${invite.id}`, {
    method: 'PUT',
    body: JSON.stringify(invite),
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
  });
}

export default module.exports;
