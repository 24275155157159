import React, { Fragment } from 'react';
import { Table, TableHead, TableRow, TableData } from 'components/common/Table';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import licensesData from 'config/licenses.json';

const propTypes = {
  licenseType: PropTypes.string.isRequired,
};

const LicenseTermsTable = ({ licenseType }) => {
  const license = licensesData[licenseType.toLowerCase()];
  if (!license) return <Redirect to="/error" />;
  const { fileTypes, licenses } = license;
  return (
    <Fragment>
      <div id="file-types" className="my-4">
        <h2 className="text-2xl font-semibold my-4">File Types</h2>
        <Table id="file-types-table">
          <TableHead>
            <div className="w-1/2">Type</div>
            <div className="w-1/2">Availability</div>
          </TableHead>
          {fileTypes.map(({ id, title, availability }) => (
            <TableRow key={id}>
              <TableData className="w-1/2">{title}</TableData>
              <TableData className="w-1/2">
                {availability === 'check' ? (
                  <i className="fa fa-check text-green" aria-hidden="true" />
                ) : (
                  <i className="fa fa-times text-red" aria-hidden="true" />
                )}
              </TableData>
            </TableRow>
          ))}
        </Table>
      </div>
      <div id="license-terms" className="my-4">
        <h2 className="text-2xl font-semibold my-4">License Terms</h2>
        <Table id="license-terms-table">
          <TableHead>
            <div className="w-1/2">Type</div>
            <div className="w-1/2">Allowance</div>
          </TableHead>
          {licenses.map(({ id, title, availability }) => (
            <TableRow key={id}>
              <TableData className="w-1/2">{title}</TableData>
              <TableData className="w-1/2">{availability}</TableData>
            </TableRow>
          ))}
        </Table>
      </div>
    </Fragment>
  );
};

LicenseTermsTable.propTypes = propTypes;

export default LicenseTermsTable;
