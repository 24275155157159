import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as modalActions from 'actions/modals';
import capitalize from 'lodash/capitalize';
import displayPrice from 'utils/display-price';

const propTypes = {
  product: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
};

const CHECKOUT_DETAIL_PRODUCT = gql`
  fragment CheckoutDetail_product on Product {
    track: trackByTrackId {
      id
      name
      user: userByUserId {
        id
        displayName
      }
    }
    type
    price
  }
`;

const CheckoutDetail = ({ product, showModal }) => (
  <div id="checkout-detail" className="rounded-sm mt-4 p-4 border min-w-2/3">
    <div className="flex mb-2 items-center">
      <div className="uppercase font-semibold w-1/3 lg:w-1/4">Track</div>
      <div className="w-2/3 lg:w-3/4">
        {product.track.name} by {product.track.user.displayName}
      </div>
    </div>
    <div className="flex mb-2 items-center">
      <div className="uppercase font-semibold w-1/3 lg:w-1/4">License</div>
      <div
        role="presentation"
        className="w-2/3 lg:w-3/4 link"
        onClick={() => showModal('LicenseModal', { type: product.type })}
      >
        {capitalize(product.type)}
      </div>
    </div>
    <div className="flex items-center">
      <div className="uppercase font-semibold w-1/3 lg:w-1/4">Total amount</div>
      <div className="w-2/3 lg:w-3/4">{displayPrice(product.price)}</div>
    </div>
  </div>
);

CheckoutDetail.propTypes = propTypes;
CheckoutDetail.fragments = { product: CHECKOUT_DETAIL_PRODUCT };

export default compose(
  connect(
    null,
    modalActions,
  ),
)(CheckoutDetail);
