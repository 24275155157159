import { fromJS } from 'immutable';
import { TOASTS_SHOW_MESSAGE, TOASTS_DISMISS_MESSAGE } from 'types/toasts';

const initialState = fromJS({
  error: '',
  data: {},
});

export default function toasts(state = initialState, { type, payload }) {
  switch (type) {
  case TOASTS_SHOW_MESSAGE:
    return state.update('data', data =>
        data.set(payload.id, fromJS(payload)),
      );
  case TOASTS_DISMISS_MESSAGE:
    return state.update('data', data =>
        data.filter((val, key) => key !== payload),
      );
  default:
    return state;
  }
}
