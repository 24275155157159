import React from 'react';
import { avatarImageUrl } from 'config';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import SideButton from '../SideButton';
import '../ProfileBanner.css';

const propTypes = {
  user: PropTypes.object.isRequired,
  currentUser: PropTypes.string.isRequired,
  myself: PropTypes.bool.isRequired,
  image: PropTypes.string.isRequired,
};

const PROFILE_BANNER_FRAG = gql`
  fragment ProfileMobileBanner_user on User {
    ...SideButton_user
  }
  ${SideButton.fragments.user}
`;

const ProfileBanner = ({
  user,
  currentUser,
  myself,
  image,
}) => (
  <div>
    <section
      className="h-1/4 sm:h-1/3 banner-profile-default flex bg-cover bg-center w-full"
      style={{ backgroundImage: image, minHeight: '180px' }}
    />
    <div className="relative mx-auto h-1/4 sm:h-1/3 w-9/10">
      <div className="relative flex w-full">
        <div className="inline-block leading-none">
          <div style={{ marginTop: '-40%' }}>
            <div
              className="banner-avatar bg-cover bg-center"
              style={{
                backgroundImage: image || `${avatarImageUrl}placeholder.png`,
              }}
              alt="User Avatar"
            />
            <div className="img_description_layer">
              <p className="img_description">Change Avatar in Settings</p>
            </div>
          </div>
        </div>
        <SideButton
          user={filter(SideButton.fragments.user, user)}
          myself={myself}
          currentUser={currentUser}
          className="self-end ml-3 profile-side-btn"
          followClassName="profile-follow-btn"
          followingClassName="profile-follow-btn"
          settingsClassName="flex"
        />
      </div>
    </div>
  </div>
);

ProfileBanner.propTypes = propTypes;
ProfileBanner.fragments = { user: PROFILE_BANNER_FRAG };

export default ProfileBanner;
