import React from 'react';
import PropTypes from 'prop-types';
import { avatarImageUrl } from 'config';
import classnames from 'classnames';
import 'styles/fade.css';

const propTypes = {
  imageSource: PropTypes.string, // The image we want to load
  placeholder: PropTypes.string, // A placeholder we want to display by default
  fallbacks: PropTypes.array, // Other urls to try if our first choice isn't there
  className: PropTypes.string, // Optional for styling
  alt: PropTypes.string, // The image alt property
  style: PropTypes.object, // Style of the containing div
  imageStyle: PropTypes.object, // Style of the <img> element
  once: PropTypes.bool, // Will not attempt to reload the image once one has successfully loaded, even if we supply new sources to the component
  showControls: PropTypes.bool, // Renders an overlay with play/pause buttons. Needs 'playing' prop hooked up to work
  playing: PropTypes.bool, // Determines whether the play or pause icon is shown if showControls is true
  circlePlayIcon: PropTypes.bool, // Determines whether to use a circular or plain play icon if showControls is true
  hoverEffect: PropTypes.bool, // Darkens the component on hover
  showPlayByDefault: PropTypes.bool, // Will show the play icon without needing to hover if showControls is true
  smallerPlayIcon: PropTypes.bool, // Makes play icon slightly smaller, for mobile
  onClick: PropTypes.func, // Function to fire when component is clicked
};

const defaultProps = {
  imageSource: null,
  placeholder: `${avatarImageUrl}placeholder.png`,
  fallbacks: [],
  className: null,
  alt: 'None',
  style: {},
  imageStyle: {},
  once: true,
  showControls: false,
  playing: false,
  circlePlayIcon: false,
  showPlayByDefault: false,
  hoverEffect: false,
  smallerPlayIcon: false,
  onClick: null,
};

class PlugImage extends React.Component {
  constructor(props) {
    super(props);
    // console.log('constructor');
    this.state = {
      source: this.props.imageSource,
      fallbacks: this.props.fallbacks ? this.props.fallbacks.slice() : null,
      loaded: false,
      failed: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    // If we are swapping the image source out on the fly,
    // we need to reset state and go through our fallbacks (if any) all over again

    // To clarify the logic here -
    // If we have our "once" prop and we already have a successfully loaded image, skip the update
    // Iff we haven't loaded anything successfully yet, regardless of the once flag, attempt the load BUT ...
    // ... ONLY if the following conditions are true:
    // 1. We have a different, untried image source
    // 2. The fallbacks don't include our last-tried source, because obviously THAT didn't work
    // 3. We haven't already tried (and failed) to load any of the newly supplied imageSource and/or fallbacks
    if (
      (!this.props.once || !this.state.loaded) &&
      nextProps.imageSource &&
      nextProps.imageSource !== this.state.source &&
      (nextProps.fallbacks &&
        !nextProps.fallbacks.includes(this.state.source)) &&
      !this.state.failed.includes(nextProps.imageSource) &&
      (!nextProps.fallbacks.length ||
        this.state.failed.some(src => nextProps.fallbacks.includes(src)))
    ) {
      this.setState({
        source: nextProps.imageSource,
        fallbacks: nextProps.fallbacks ? nextProps.fallbacks.slice() : null,
        loaded: false,
      });
    }
  }

  componentWillUnmount() {
    // console.log('unmount');
  }

  initial = () => {
    this.setState({
      source: this.props.imageSource,
    });
  };

  fallback = () => {
    this.setState({
      failed: this.state.failed.concat(this.state.source),
      source: this.state.fallbacks.shift(),
    });
  };

  placeholder = () => {
    this.setState({
      failed: this.state.failed.concat(this.state.source),
      source: this.props.placeholder,
    });
  };

  none = () => {
    this.setState({
      failed: this.state.failed.concat(this.state.source),
      source: 'none',
    });
  };

  handleLoad = (event) => {
    this.setState({
      loaded: true,
    });
  };

  handleError = (event) => {
    if (!this.state.source) {
      this.initial();
      return;
    }

    console.warn(
      'PlugImage, source ',
      this.state.source,
      ' was not found, using fallback source(s)',
    );

    if (this.state.fallbacks && this.state.fallbacks.length) {
      // As long as we have fallbacks, use the next one
      this.fallback();
    } else if (this.props.placeholder) {
      // If we don't have fallbacks, use the placeholder
      this.placeholder();
    } else {
      // Throw hands up in despair if we have no sources, no fallbacks, and no placeholder
      this.none();
    }
  };

  /* eslint-disable no-sequences */
  renderPlayPause = () => (
    <div className={classnames('absolute h-full w-full')}>
      <i
        className={classnames(
          'fa fa-pause absolute transform-center pointer z-10 play-pause-btn',
          this.props.playing
            ? { block: true, 'hover-show': true }
            : { hidden: true },
        )}
        aria-hidden="true"
      />
      <i
        className={classnames(
          'fa absolute transform-center pointer z-10 play-pause-btn',
          this.props.circlePlayIcon
            ? `fa-play-circle-o ${
                this.props.smallerPlayIcon ? 'text-4xl' : 'text-5xl'
              }`
            : 'fa-play text-4xl',
          { hidden: this.props.playing || !this.props.showPlayByDefault },
          { 'hover-show': !this.props.playing },
        )}
        aria-hidden="true"
      />
    </div>
  );

  render() {
    if (this.state.source === 'none') {
      return <div>Error</div>;
    }

    // cleanup: might be ok to remove this if-block
    if (this.state.loaded) {
      return (
        <div
          style={{ ...this.props.style }}
          className={this.props.hoverEffect && 'play-pause-hover relative'}
          onClick={this.props.onClick}
          role="presentation"
        >
          {this.props.showControls && this.renderPlayPause()}
          <img
            src={this.state.source}
            className={`${this.props.className} animated fadeIn`}
            alt={this.props.alt}
            style={
              !this.state.loaded
                ? { display: 'none', ...this.props.imageStyle }
                : this.props.imageStyle
            }
          />
        </div>
      );
    }

    return (
      <div
        style={{ ...this.props.style }}
        className={this.props.hoverEffect && 'play-pause-hover relative'}
        onClick={this.props.onClick}
        role="presentation"
      >
        {this.props.showControls && this.renderPlayPause()}
        <img
          onError={this.handleError}
          onLoad={this.handleLoad}
          src={this.state.source}
          className={`${this.props.className} animated fadeIn`}
          alt={this.props.alt}
          style={
            !this.state.loaded
              ? { display: 'none', ...this.props.imageStyle }
              : this.props.imageStyle
          }
        />
        {this.state.loaded || (
          <img
            src={this.props.placeholder}
            style={this.props.imageStyle}
            className={this.props.className}
            alt={this.props.alt}
          />
        )}
      </div>
    );
  }
}

PlugImage.propTypes = propTypes;
PlugImage.defaultProps = defaultProps;

export default PlugImage;
