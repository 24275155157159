import React from 'react';
import PropTypes from 'prop-types';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { Link } from 'react-router-dom';
import CreateLikeMutation from 'mutations/like/CreateLikeMutation';
import DeleteLikeByUserIdAndTrackIdMutation from 'mutations/like/DeleteLikeByUserIdAndTrackIdMutation';
import LikesLink from './LikesLink';

const propTypes = {
  track: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  relay: PropTypes.object.isRequired,
  toggleComments: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

const TrackButtons = ({
  track,
  userId,
  relay: { environment },
  toggleComments,
  onClick,
}) => {
  const handleLikeClick = () => {
    const { id: trackId } = track;
    CreateLikeMutation(environment, { userId, trackId });
  };

  const handleUnlikeClick = () => {
    const { id: trackId } = track;
    DeleteLikeByUserIdAndTrackIdMutation(environment, { userId, trackId });
  };
  return (
    <div className="pro-trkplayer-icons-cont fs1p">
      <LikesLink
        track={track}
        userId={userId}
        className="naked-button pro-trkplayer-buttons fct"
        iconClassName="is1 pro-trkplayer-icons"
        textClassName="vab"
        onLikeClick={handleLikeClick}
        onUnlikeClick={handleUnlikeClick}
      />
      <span
        tabIndex="-1"
        className="naked-button pro-trkplayer-buttons fct"
        role="button"
        onClick={toggleComments}
      >
        <i
          className="fa fa-comment-o is1 pro-trkplayer-icons"
          aria-hidden="true"
        />
        <span className="vab">{track.comments.totalCount}</span>
      </span>
      <div className="flex">
        {track.genre && (
          <Link
            className="flex text-xs font-semibold pr-4 italic text-gray-darker"
            onClick={e => e.stopPropagation()}
            to={`/genres/${track.genre}`}
          >
            <p>Genre:&nbsp;</p>
            <p className="italic">{track.genre}</p>
          </Link>
        )}
        {track.mood && (
          <Link
            className="flex text-xs font-semibold italic text-gray-darker"
            onClick={e => e.stopPropagation()}
            to={`/moods/${track.mood}`}
          >
            <p>Mood:&nbsp;</p>
            <p className="italic">{track.mood}</p>
          </Link>
        )}
      </div>
      <span className="outline-none ml-auto">
        <button className="cursor-pointer" onClick={onClick}>
          <i
            className="fa fa-ellipsis-h text-sm text-gray-dark"
            aria-hidden="true"
          />
        </button>
      </span>
    </div>
  );
};

TrackButtons.propTypes = propTypes;

export default createFragmentContainer(
  TrackButtons,
  {track: graphql`
    fragment TrackButtons_track on Track {
      id
      genre
      mood
      free
      comments: commentsByTrackId(first: 100, orderBy: CREATED_AT_ASC) {
        totalCount
      }
      ...LikesLink_track
      ...Download_track
      ...DownloadFree_track
      ...Buy_track
    }
  `,}
);
