import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
};

const EmailInput = ({ input, disabled, meta: { touched, error } }) => (
  <div id="checkout-email-input">
    <label
      htmlFor="checkout-email-input"
      className={classnames({ 'text-red': touched && error })}
    >
      <div className="font-semibold">Email address</div>
      <input
        type="email"
        name="email"
        placeholder="Your email address"
        className={classnames(
          'my-2 email-input',
          {
            'email-input-error': touched && error,
          },
          {
            'mb-4': !(touched && error),
          },
        )}
        {...input}
        disabled={disabled}
      />
      {(touched && error) && <div className="mb-4">{error}</div>}
    </label>
  </div>
);

EmailInput.propTypes = propTypes;
EmailInput.defaultProps = defaultProps;

export default EmailInput;
