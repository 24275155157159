import GoogleAnalyticsGtag, { trackPageView, trackEvent } from '@redux-beacon/google-analytics-gtag';
import { createMiddleware } from 'redux-beacon';
import { LOCATION_CHANGE } from 'react-router-redux';
import logger from '@redux-beacon/logger';
import { GATrackId } from 'config';

const ga = GoogleAnalyticsGtag(GATrackId);

const eventsMap = {
  [LOCATION_CHANGE]: trackPageView(action => ({
    path: action.payload.pathname,
  })),
  // SOUNDBAR_SET_TRACK: trackEvent(action => ({
  //   category: 'Tracks',
  //   action: 'Set',
  //   label: action.track.id,
  // })),
  SOUNDBAR_SET_PLAYING: trackEvent((action, prevState) => ({
    category: 'Tracks',
    action: action.playing ? 'Play' : 'Pause',
    label: prevState.soundbar.currentTrack.id,
  })),
  SOUNDBAR_NEXT_TRACK: trackEvent((action, prevState, nextState) => ({
    category: 'Tracks',
    action: 'Next',
    label: `${prevState.soundbar.currentTrack.id} -> ${nextState.soundbar.currentTrack.id}`,
  })),
  SOUNDBAR_PREVIOUS_TRACK: trackEvent((action, prevState, nextState) => ({
    category: 'Tracks',
    action: 'Previous',
    label: `${prevState.soundbar.currentTrack.id} -> ${nextState.soundbar.currentTrack.id}`,
  })),
  SOUNDBAR_SET_STALLED: trackEvent((action, prevState) => ({
    category: 'Tracks',
    action: 'Stalled',
    label: prevState.soundbar.currentTrack.id,
  })),
  MODAL_SHOW: trackEvent(action => ({
    category: 'Modal',
    action: 'Open',
    label: action.payload.name,
  })),
  ADD_CART_ITEM: trackEvent(action => ({
    category: 'Cart',
    action: 'Add',
    label: action.payload.id,
  })),
  REMOVE_CART_ITEM: trackEvent(action => ({
    category: 'Cart',
    action: 'Remove',
    label: action.payload.id,
  })),
};

export default createMiddleware(eventsMap, ga, { logger });
