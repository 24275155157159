import React from 'react';
import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Redirect } from 'react-router-dom';
import { Query } from 'react-apollo';
import StaffPicksCategory from 'components/Categories/StaffPicksCategory';

const STAFF_PICKS_QUERY = gql`
  query StaffPicksPageQuery($maxTracks: Int!) {
    tracks: tracksByLikeCount(top: $maxTracks) {
      ...StaffPicksCategory_tracks
    }
  }
  ${StaffPicksCategory.fragments.tracks}
`;

const StaffPicksPage = () => (
  <Query query={STAFF_PICKS_QUERY} variables={{ maxTracks: 30 }}>
    {({ loading, error, data }) => {
      if (loading) {
        return (
          <div className="mt-12 ml-16">
            <CircularProgress size={50} style={{ color: '#2a585d' }} />
          </div>
        );
      }
      if (error) {
        return <Redirect to="/error" />;
      }
      return <StaffPicksCategory title="Staff Picks" tracks={data.tracks} />;
    }}
  </Query>
);

export default StaffPicksPage;
