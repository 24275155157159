import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import Carousel from 'utils/Carousel';
import * as soundbarActions from 'actions/soundbar';
import Tile from 'components/common/ApolloImagePlay/Tile';

const propTypes = {
  tracks: PropTypes.object.isRequired,
  removeOtherTracksOnPlay: PropTypes.bool,
  soundbarAddTracks: PropTypes.func.isRequired,
  soundbarSetTrack: PropTypes.func.isRequired,
};

const defaultProps = {
  removeOtherTracksOnPlay: false,
};

const STAFF_FRAG = gql`
  fragment LandingStaffPicks_tracks on TracksConnection {
    edges {
      node {
        id
        name
        picture
        fileUrl
        publicId
        description
        userByUserId {
          id
          username
          displayName
        }
        ...Tile_track
      }
    }
  }
  ${Tile.fragments.track}
`;

class LandingStaffPicks extends Component {
  componentDidMount() {
    const { tracks, soundbarAddTracks, soundbarSetTrack } = this.props;
    console.log('tracks', tracks)
    const sanitizedTracks = this.sanitizeTracks(tracks.edges);
    const trackIndex = Math.floor(Math.random() * sanitizedTracks.length);
    const trackToSet = sanitizedTracks[trackIndex];
    soundbarAddTracks(sanitizedTracks);
    soundbarSetTrack(trackToSet, 0);
  }

  sanitizeTracks = tracks =>
    tracks.map((trak) => {
      const track = trak.node || trak;
      const user = track.user || track.userByUserId;
      return {
        id: track.id,
        name: track.name,
        picture: track.picture,
        fileUrl: track.fileUrl,
        publicId: track.publicId,
        userId: user.id,
        username: user.username,
        userDisplayName: user.displayName,
      };
    });

  render() {
    console.log('actions', soundbarActions);
    const { tracks, removeOtherTracksOnPlay } = this.props;
    return (
      <div className="h100 flex flex-col pt-3 pb-4 md:w-desk">
        {/* <Link to="/staffpicks" className="font-bold text-lg pb-3 text-white">Staff Picks</Link> */}
        <p to="/staffpicks" className="font-bold text-lg pb-3 text-white">
          Staff Picks
        </p>
        <Carousel
          settings={{
            columns: 2,
            paddingLeft: '5%',
            marginRight: 15,
            slide: true,
            hasArrows: false,
            extraSlide: true,
          }}
          breakpoints={[
            {
              breakpoint: 768,
              columns: 4,
              slide: false,
              hasArrows: true,
              paddingLeft: 0,
              extraSlide: false,
            },
            {
              breakpoint: 998,
              columns: 5,
            },
          ]}
        >
          {tracks.edges.map(track => (
            <div key={`staff-${track.node.id}`} className="h-full">
              <Tile
                track={filter(Tile.fragments.track, track.node)}
                tracks={tracks.edges}
                hasText={false}
                imageClassName="rounded-none"
                className="w-full"
                removeOtherTracksOnPlay={removeOtherTracksOnPlay}
              />
            </div>
          ))}
        </Carousel>
      </div>
    );
  }
}

LandingStaffPicks.propTypes = propTypes;
LandingStaffPicks.defaultProps = defaultProps;
LandingStaffPicks.fragments = { tracks: STAFF_FRAG };

export default connect(
  null,
  soundbarActions,
)(LandingStaffPicks);
