import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  htmlFor: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  setRef: PropTypes.func,
  validateOn: PropTypes.string,
  className: PropTypes.string,
};

const defaultProps = {
  label: '',
  placeholder: '',
  htmlFor: '',
  type: 'text',
  disabled: false,
  setRef: () => {},
  validateOn: '',
  className: '',
};

const TextInput = ({
  input,
  label,
  placeholder,
  type,
  htmlFor,
  disabled,
  setRef,
  meta: { touched, error, warning, dirty },
  validateOn,
  className,
}) => {
  const validated = validateOn === 'change' ? (dirty || touched) : touched;
  return (
    <div className={classnames('field', className)}>
      <label htmlFor={htmlFor} className="label">
        {label}
      </label>
      <div className="control">
        <input
          type={type}
          className={classnames(
            'input',
            'no-bs',
            { 'is-danger': validated && error },
            { 'is-warning': validated && warning },
          )}
          placeholder={placeholder}
          {...input}
          disabled={disabled}
          ref={setRef}
        />
        {validated &&
          ((error && <p className="help is-danger">{error}</p>) ||
            (warning && <p className="help is-warning">{warning}</p>))}
      </div>
    </div>
  );
};

TextInput.propTypes = propTypes;
TextInput.defaultProps = defaultProps;

export default TextInput;
