import React from 'react';
import LandingExplore from './LandingExplore';

const Landing = () => (
  <div id="landing" className="center">
    <LandingExplore />
  </div>
);

export default Landing;
