import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { primary } from 'css/tailwind/colors';
import Logo from 'components/common/Logo';
import Badge from 'material-ui/Badge';
import Cart from 'components/common/Cart';
import SearchDesktop from '../Buttons/SearchDesktop';

const propTypes = {
  type: PropTypes.string.isRequired,
  isTrackPage: PropTypes.bool.isRequired,
  cartCount: PropTypes.number.isRequired,
};

class Desktop extends Component {
  state = {
    searching: false,
  };

  toggleSearching = () => {
    this.setState({ searching: !this.state.searching });
  };

  render() {
    const { isTrackPage, cartCount, type } = this.props;
    return (
      <div className={'w-desk flex items-center'}>
        <div className="flex">
          <Link className="pr-2" to="/">
            <Logo color={isTrackPage ? primary : undefined} />
          </Link>
        </div>
        {/* <SearchDesktop
          containerClass={
            isTrackPage
              ? 'search-container-transparent-darker'
              : 'search-container-transparent'
          }
          inputClass={classnames(
            'bg-transparent font-semibold',
            isTrackPage
              ? 'search-input-transparent-darker'
              : 'search-input-transparent',
          )}
          buttonClass="search-button-transparent"
          iconClass="search-icon-transparent"
          onFocus={this.toggleSearching}
          onBlur={this.toggleSearching}
          clearOnBoth
        /> */}
        {((type === 'mobile' && !this.state.searching) ||
          type !== 'mobile') && (
          <div
            className={classnames(
              'flex ml-auto',
              isTrackPage ? 'text-gray-darker' : 'color-bg',
            )}
          >
            <Link
              to="/login"
              className="flex justify-center items-center nowrap hover:text-white pr-2"
            >
              <div className="text-sm pl-2 font-semibold align-center">
                Sign In
              </div>
            </Link>
            <Link
              to="/cart"
              className="flex justify-center items-center nowrap cursor-pointer text-sm font-semibold pl-1 sm:pl-2"
            >
              {type !== 'mobile' && <p className="pr-1 hover:text-white">Cart</p>}
              {cartCount ? (
                <Badge
                  badgeContent={cartCount}
                  primary
                  badgeStyle={{
                    height: 20,
                    width: 20,
                    top: '-4px',
                    right: '-8px',
                  }}
                  style={{ padding: 0, paddingTop: 4 }}
                >
                  <div className="pt-1" style={{ width: '24px' }}>
                    <Cart style={{ color: 'white' }} />
                  </div>
                </Badge>
              ) : (
                <div className="pt-1 color-bg" style={{ width: '24px' }}>
                  <Cart style={{ color: 'white' }} />
                </div>
              )}
            </Link>
          </div>
        )}
        {this.state.searching &&
          type === 'mobile' && (
            <div className="flex ml-auto">
              <button
                onClick={() => {
                  this.toggleSearching();
                }}
              >
                <i className="fa fa-times-circle" style={{ color: 'white' }} />
              </button>
            </div>
          )}
      </div>
    );
  }
}

Desktop.propTypes = propTypes;

export default connect(state => ({
  cartCount: state.cart.get('items').size,
}))(Desktop);
