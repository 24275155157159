/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type SoldBeatsList_beats$ref = any;
import type { FragmentReference } from 'relay-runtime';
declare export opaque type SoldBeats_beats$ref: FragmentReference;
export type SoldBeats_beats = {|
  +totalCount: ?number,
  +$fragmentRefs: SoldBeatsList_beats$ref,
  +$refType: SoldBeats_beats$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "SoldBeats_beats",
  "type": "BeatsSoldsConnection",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "SoldBeatsList_beats",
      "args": null
    }
  ]
};
(node/*: any*/).hash = '0e83414e30af558f3099a70a096687eb';
module.exports = node;
