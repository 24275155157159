import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { filter } from 'graphql-anywhere';
import CircularProgress from '@material-ui/core/CircularProgress';
import TrackHashtags from 'components/Hashtag';

const propTypes = {
  match: PropTypes.object.isRequired,
};

const HASH_QUERY = gql`
  query HashtagPageQuery($tag: HashtagCondition!) {
    hashtags: allHashtags(first: 100, condition: $tag) {
      ...TrackHashtags_hashtags
    }
  }
  ${TrackHashtags.fragments.hashtags}
`;

const HashtagPage = ({
  match: {
    params: { tag },
  },
}) => (
  <Query query={HASH_QUERY} variables={{ tag: { tag } }}>
    {({ loading, error, data }) => {
      if (loading) {
        return (
          <div className="mt-12 ml-16">
            <CircularProgress size={50} style={{ color: '#2a585d' }} />
          </div>
        );
      }
      if (error) return <div>Error</div>;

      return (
        <TrackHashtags
          hashtags={filter(TrackHashtags.fragments.hashtags, data.hashtags)}
        />
      );
    }}
  </Query>
);

HashtagPage.propTypes = propTypes;

export default HashtagPage;
