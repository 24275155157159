import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Redirect } from 'react-router-dom';
import NewestArrivalsCategory from 'components/Categories/NewestArrivalsCategory';

const NEWEST_ARRIVALS_QUERY = gql`
  query NewestArrivalsPageQuery($maxTracks: Int!) {
    tracks: tracksByRecentlyCreated(top: $maxTracks) {
      ...NewestArrivalsCategory_tracks
    }
  }
  ${NewestArrivalsCategory.fragments.tracks}
`;

const NewestArrivalsPage = () => (
  <Query query={NEWEST_ARRIVALS_QUERY} variables={{ maxTracks: 30 }}>
    {({ loading, error, data }) => {
      if (loading) {
        return (
          <div className="mt-12 ml-16">
            <CircularProgress size={50} style={{ color: '#2a585d' }} />
          </div>
        );
      }
      if (error) {
        return <Redirect to="/error" />;
      }
      return <NewestArrivalsCategory tracks={data.tracks} />;
    }}
  </Query>
);

export default NewestArrivalsPage;
