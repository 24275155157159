import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import * as authActions from 'actions/auth';
import Logo from 'components/common/Logo';
import ExploreDesktop from '../Buttons/ExploreDesktop';
import SearchDesktop from '../Buttons/SearchDesktop';
import Notifications from '../Buttons/Notifications';
import Profile from '../Buttons/Profile';
import MyMusic from '../Buttons/MyMusic';
import Upload from '../Buttons/Upload';
import NavMenu from '../Buttons/NavMenu';

const propTypes = {
  auth: PropTypes.object.isRequired,
  s3ContextToWatch: PropTypes.object,
  signOut: PropTypes.func.isRequired,
};

const defaultProps = {
  s3ContextToWatch: {},
};

const toolConfig = {
  type: 'dark',
  effect: 'solid',
  delayShow: 400,
  place: 'bottom',
};

const NavbarDesktop = ({
  auth,
  s3ContextToWatch,
  s3ContextToWatch: { uploads },
  signOut,
}) => {
  const { username } = auth;
  const requiredFiles = get(s3ContextToWatch, 'metadata.requiredFiles');
  return (
    <div className="w-desk flex items-center">
      <div className="flex justify-center items-center">
        <Link
          className="flex justify-center items-center"
          style={{ padding: '10px', paddingLeft: '0px' }}
          to="/"
        >
          <Logo />
        </Link>
      </div>
      <ExploreDesktop />
      {/* <SearchDesktop
        formClass="justify-center"
        containerClass="bg-white bg-gray-lighter"
        buttonClass="p-0 search-button-white"
        iconClass="text-gray"
      /> */}
      <div id="icon-div-desk" className="flex-row items-center">
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center">
            <Upload uploads={uploads} requiredFiles={requiredFiles} />
            <Profile toolConfig={toolConfig} username={username} />
            <Notifications toolConfig={toolConfig} />
            <MyMusic toolConfig={toolConfig} />
            <NavMenu auth={auth} signOut={signOut} />
          </div>
        </div>
      </div>
    </div>
  );
};

NavbarDesktop.propTypes = propTypes;
NavbarDesktop.defaultProps = defaultProps;

export default connect(({ auth }) => ({ auth }), authActions)(NavbarDesktop);
