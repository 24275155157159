import React from 'react';
import PropTypes from 'prop-types';
import graphql from 'babel-plugin-relay/macro';
import createFragContainer from 'utils/create-frag-container';
import DashboardButton from 'components/common/DashboardButton';
import displayPrice from 'utils/display-price';
import SoldBeatsList from './SoldBeatsList';

const soldBeatsFrag1 = graphql`
  fragment SoldBeats_beats on BeatsSoldsConnection {
    totalCount
    ...SoldBeatsList_beats
  }
`;

const soldBeatsFrag2 = graphql`

  fragment SoldBeats_query on Query {
    totalDollarsSold
  }
`;

const propTypes = {
  beats: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
};

const SoldBeats = ({ beats, query: { totalDollarsSold } }) => (
  <div id="sold-beats">
    <div className="flex justify-between items-center mt-3">
      <h1 className="title" style={{ marginBottom: 0 }}>
        Beats sold
      </h1>
      <DashboardButton
        className="b4p rounded-full"
        style={{
          backgroundColor: 'rgb(100, 185, 147)',
          color: 'white',
        }}
      >
        See payout details
      </DashboardButton>
    </div>
    {totalDollarsSold > 0 ? (
      <div className="mb-4 font-light">
        Total value:{' '}
        {displayPrice(totalDollarsSold)}
      </div>
    ) : (
      <div className="mb-6" />
    )}
    <SoldBeatsList beats={beats} />
  </div>
);

SoldBeats.propTypes = propTypes;

export default createFragContainer([soldBeatsFrag1, soldBeatsFrag2])(SoldBeats);
