import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import * as soundbarActions from 'actions/soundbar';
import FeatureFlag from 'components/common/FeatureFlag/FeatureFlag';
import './Soundbar.css';
import Audio from './Audio';
import MiniSoundbar from './MiniSoundbar';
import Content from './Content';
import AudioSliderLg from './Controls/MusicControls/AudioSliderLg';

const propTypes = {
  playing: PropTypes.bool.isRequired,
  progress: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  soundbarSetVisible: PropTypes.func.isRequired,
  soundbarSetPlaying: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const defaultProps = {
  follow: null,
  like: null,
  currentTrack: {},
  muted: false,
};

// The soundbar appears only when there are tracks in our playlist to play.
// Following a user will only be an option if the user is logged in and is
// playing a track that was created by a different user

class Soundbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: props.progress,
      liked: false,
      volumeBarShowing: false,
    };

    this.audio = <audio />;
  }

  componentWillReceiveProps(nextProps) {
    const enteringGridPage =
      nextProps.location && nextProps.location.pathname.includes('projects/');

    const leavingGridPage =
      this.props.location &&
      !this.props.location.pathname.includes('projects/') &&
      (nextProps.location && nextProps.location.pathname.includes('projects/'));

    if (enteringGridPage) {
      if (this.props.playing) {
        this.togglePlay();
      }
      if (this.props.visible) {
        this.toggleBar();
      }
    } else if (leavingGridPage) {
      this.toggleBar();
    }
  }

  toggleBar = () => {
    this.props.soundbarSetVisible(!this.props.visible);
  };

  togglePlay = () => {
    this.props.soundbarSetPlaying(!this.props.playing);
  };

  toggleVolumeControl = () => {
    this.setState({ volumeBarShowing: !this.state.volumeBarShowing });
  };

  render() {
    const onGrid = this.props.location.pathname.includes('projects/');

    const soundbarContainer = ['soundbarContainer'];
    if (this.props.visible) {
      soundbarContainer.push('visible');
    }

    return (
      <div
        id="soundbar"
        className="fixed z-10"
        style={{
          display: onGrid ? 'none' : 'block',
          bottom: this.props.visible ? 0 : 10,
          right: this.props.visible ? 0 : 10,
          width: this.props.visible ? '100%' : 'auto',
        }}
        onMouseLeave={() => {
          if (this.state.volumeBarShowing) {
            this.toggleVolumeControl();
          }
        }}
      >
        <FeatureFlag
          name="SOUNDBAR.MINI"
          render={({ condition }) => (
            <Fragment>
              {condition && <MiniSoundbar location={this.props.location} />}
              <div
                id="soundbar-container"
                className={classnames(...soundbarContainer, 'border-t', 'flex')}
              >
                <AudioSliderLg />
                <Audio />
                <Content
                  volumeBarShowing={this.state.volumeBarShowing}
                  toggleVolumeControl={this.toggleVolumeControl}
                />
              </div>
            </Fragment>
          )}
        />
      </div>
    );
  }
}

Soundbar.propTypes = propTypes;
Soundbar.defaultProps = defaultProps;

export default compose(
  connect(
    state => ({
      visible: state.soundbar.visible,
      playing: state.soundbar.playing,
      currentTrack: state.soundbar.currentTrack,
      progress: state.soundbar.progress,
      seeking: state.soundbar.seeking,
    }),
    soundbarActions,
  ),
  withRouter,
)(Soundbar);
