export const updateProjectId = payload => ({
  type: 'UPDATE_PROJECT_ID',
  payload,
});

export function reset() {
  const reducers = [
    'clips',
    'invites',
    'posts',
    'projects',
    'session',
    'tracks',
    'users',
  ];

  return (dispatch) => {
    for (let i = 0; i < reducers.length; i++) {
      const type = `RESET_${reducers[i].toUpperCase()}`;
      dispatch({ type });
    }
  };
}

export default module.exports;
