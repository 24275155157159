import React from 'react';
import PropTypes from 'prop-types';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { avatarImageUrl } from 'config';
import displayImage from 'utils/display-image';
import ProfileNotFound from 'components/Profile/ProfileNotFound';
import { Link } from 'react-router-dom';
import FollowerList from './FollowerList';

const propTypes = {
  user: PropTypes.object.isRequired,
};

const Followers = ({ user }) => {
  if (!user) return <ProfileNotFound />;
  return (
    <div className="my-8 mx-4 lg:mx-8">
      <div className="flex-row items-center mb-6">
        <img
          alt="Profile"
          className="circle cover h-32 w-32 mr-3"
          src={displayImage(avatarImageUrl, user.picture)}
        />
        <div className="lg:ml-4">
          <Link to={`/${user.username}`}>
            <h1 className="title">{user.displayName}</h1>
          </Link>
        </div>
      </div>
      <FollowerList user={user} />
    </div>
  );
};

Followers.propTypes = propTypes;

export default createFragmentContainer(Followers, {
  user: graphql`
    fragment Followers_user on User {
      id
      displayName
      username
      picture
      ...FollowerList_user
    }
  `,
});
