import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import CircularProgress from '@material-ui/core/CircularProgress';
import Likes from 'components/Likes';

const propTypes = {
  match: PropTypes.object.isRequired,
};

const LIKES_QUERY = gql`
  query LikesPageQuery($publicId: String!) {
    track: trackByPublicIdAndDeleted(publicId: $publicId, deleted: false) {
      ...TrackLikes_track
    }
  }
  ${Likes.fragments.track}
`;

const LikesPage = ({ match: { params: { publicId } } }) => (
  <Query query={LIKES_QUERY} variables={{ publicId }}>
    {({ loading, error, data }) => {
      if (loading) {
        return (
          <div className="mt-12 ml-16">
            <CircularProgress size={50} style={{ color: '#2a585d' }} />
          </div>
        );
      }
      if (error) return <div>Error</div>;

      return <Likes track={data.track} />;
    }}
  </Query>
);

LikesPage.propTypes = propTypes;

export default LikesPage;
