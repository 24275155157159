/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type FollowerList_user$ref = any;
import type { FragmentReference } from 'relay-runtime';
declare export opaque type Followers_user$ref: FragmentReference;
export type Followers_user = {|
  +id: any,
  +displayName: string,
  +username: ?string,
  +picture: ?string,
  +$fragmentRefs: FollowerList_user$ref,
  +$refType: Followers_user$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "Followers_user",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "displayName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "username",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "picture",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "FollowerList_user",
      "args": null
    }
  ]
};
(node/*: any*/).hash = 'f97f5098b52ee4bd435d6e18f17cc203';
module.exports = node;
