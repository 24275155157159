export {default as classnames} from 'classnames';

let screenType = null;

export const setScreenType = (type) => { screenType = type; };

const screenTest = (testType, arg) => (screenType === testType ? arg : null);

export const isDesktop = () => screenType === 'desktop';

export const onMobile = arg => screenTest('mobile', arg);

export const onDesktop = arg => screenTest('desktop', arg);

export default module.exports;
