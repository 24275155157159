import React from 'react';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { connect } from 'react-redux';
import * as modalActions from 'actions/modals';

const propTypes = {
  auth: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  userId: PropTypes.string,
  currentUser: PropTypes.string,
  followClass: PropTypes.string,
  followingClass: PropTypes.string,
  buttonStyle: PropTypes.object,
  override: PropTypes.string,
  overrideHandleFollow: PropTypes.func,
  overrideHandleUnfollow: PropTypes.func,
  onClick: PropTypes.func,
};

const defaultProps = {
  user: null,
  userId: '',
  currentUser: '',
  classProp: null,
  followClass: null,
  followingClass: null,
  buttonStyle: { outline: 'none' },
  override: null,
  overrideHandleFollow: null,
  overrideHandleUnfollow: null,
  onClick: null,
};

const FOLLOW_BUTTON_FRAG = gql`
  fragment FollowButton_user on User {
    id
    isFollow
  }
`;

const FOLLOW_MUTATION = gql`
  mutation CreateFollowMutation($input: CreateFollowInput!) {
    createFollow(input: $input) {
      user: userByFollowedId {
        ...FollowButton_user
      }
    }
  }
  ${FOLLOW_BUTTON_FRAG}
`;

const UNFOLLOW_MUTATION = gql`
  mutation DeleteFollowByFollowerIdAndFollowedIdMutation(
    $input: DeleteFollowByFollowerIdAndFollowedIdInput!
  ) {
    deleteFollowByFollowerIdAndFollowedId(input: $input) {
      deletedFollowId
      user: userByFollowedId {
        ...FollowButton_user
      }
    }
  }
  ${FOLLOW_BUTTON_FRAG}
`;

const FollowButton = ({
  user: { id, isFollow },
  auth: { isAuthenticated, userId },
  showModal,
  followClass,
  followingClass,
  buttonStyle,
}) => {
  const renderFollowing = () => (
    <Mutation mutation={UNFOLLOW_MUTATION}>
      {deleteFollow => (
        <button
          className={followingClass}
          onClick={() =>
            deleteFollow({
              variables: { input: { followerId: userId, followedId: id } },
            })
          }
          style={buttonStyle}
          disabled={!isAuthenticated}
        >
          Following
        </button>
      )}
    </Mutation>
  );

  const renderFollow = () => (
    <Mutation mutation={FOLLOW_MUTATION}>
      {createFollow => (
        <button
          className={followClass}
          onClick={() => {
            if (isAuthenticated) {
              return createFollow({
                variables: { input: { follow: { followerId: userId, followedId: id } } },
              });
            }
            return showModal('SignUpModal');
          }}
          style={buttonStyle}
        >
          Follow
        </button>
      )}
    </Mutation>
  );

  return isFollow ? renderFollowing() : renderFollow();
};

FollowButton.propTypes = propTypes;
FollowButton.defaultProps = defaultProps;
FollowButton.fragments = { user: FOLLOW_BUTTON_FRAG };

export default connect(
  ({ auth }) => ({ auth }),
  modalActions,
)(FollowButton);
