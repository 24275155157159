import React from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { stripePublic } from 'config';
import stripe2x from 'assets/powered_by_stripe@2x.png';
import displayPrice from 'utils/display-price';
import CheckoutForm from '../CheckoutForm';
import CartCheckoutDetails from './CartCheckoutDetails';

const CART_CHECKOUT_USER = gql`
  fragment CartCheckout_user on User {
    username
    private {
      email
    }
  }
`;

const propTypes = {
  user: PropTypes.object.isRequired,
  productIds: PropTypes.instanceOf(Immutable.List).isRequired,
  totalPrice: PropTypes.number.isRequired,
};

const CartCheckout = ({ user, productIds, totalPrice }) => {
  const email = get(user, 'private.email', '');
  if (productIds.length === 0) return <Redirect to="/" />;
  return (
    <StripeProvider apiKey={stripePublic}>
      <div
        id="cart-checkout"
        className="lg:w-1/2 mx-3 lg:mx-auto my-3 pcont p-4"
      >
        <div className="flex items-center">
          <h1 className="h1p mr-6 font-semibold text-3xl">Checkout</h1>
          <a href="https://stripe.com/">
            <img
              alt="Powered by Stripe"
              src={stripe2x}
              style={{ width: 149, height: 41 }}
            />
          </a>
        </div>
        <div className="font-semibold my-2">
          Order total:{' '}
          <span className="font-bold text-black">
            {displayPrice(totalPrice)}
          </span>
        </div>
        <CartCheckoutDetails />
        <div>
          <div className="font-semibold mt-8 mb-2">Delivery Methods</div>
          <ul style={{ listStyleType: 'disc' }}>
            <li className="ml-8">Email</li>
            <li className="ml-8">On screen</li>
          </ul>
        </div>
        <Elements>
          <CheckoutForm
            productIds={productIds.toJS()}
            initialValues={{ email }}
          />
        </Elements>
        <div className="mt-4 text-sm font-light">
          By purchasing this beat, you agree to Plug Music&#39;s{' '}
          <Link to="/terms">Terms of Use</Link> and{' '}
          <Link to="/privacy">Privacy Policy</Link>.
        </div>
      </div>
    </StripeProvider>
  );
};

CartCheckout.propTypes = propTypes;
CartCheckout.fragments = { user: CART_CHECKOUT_USER };

export default connect(state => ({
  productIds: state.cart
    .get('items', Immutable.List())
    .map(item => item.get('id')),
  totalPrice: state.cart
    .get('items', [])
    .reduce((sum, item) => sum + item.get('price'), 0),
}))(CartCheckout);
