import React from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';
import get from 'lodash/get';
import { avatarImageUrl } from 'config';
import displayTrackImage, {
  displaySmallImage,
} from 'utils/display-track-image';
import PlugImage from 'components/common/PlugImage';
import endsWith from 'lodash/endsWith';

const propTypes = {
  s3Context: PropTypes.object.isRequired,
  dropzoneRefClick: PropTypes.func.isRequired,
  fileUrl: PropTypes.string,
  picture: PropTypes.string,
};

const defaultProps = {
  fileUrl: '',
  picture: '',
};

const ImageField = ({
  s3Context, dropzoneRefClick, fileUrl, picture,
}) => {
  const image = get(s3Context, 'metadata.image');
  const upload = image && s3Context.uploads.find(u => endsWith(u.id, '.jpg'));
  const progress = get(upload, 'progress', 0);
  const opacity = image ? progress : 1;

  return (
    <div
      className="relative br-4 flex-no-shrink mx-auto mb-6 md:mr-8"
      style={{
        width: '230px',
        height: '230px',
      }}
    >
      {image ? (
        <img
          className="w-full h-full img-transition cover absolute br-4"
          src={image.preview}
          style={{
            opacity: `${opacity}`,
            background: image && `url(${image.preview})`,
            backgroundSize: 'cover',
          }}
          alt="Track Avatar"
        />
      ) : (
        <PlugImage
          imageSource={displaySmallImage(fileUrl, picture)}
          fallbacks={[displayTrackImage(fileUrl, picture)]}
          placeholder={`${avatarImageUrl}placeholder.png`}
          className="absolute br-4 w-full h-full"
        />
      )}
      <div
        role="button"
        tabIndex={0}
        className="absolute z-10 px-6 py-2 font-bold text-sm mx-auto br-4 cursor-pointer text-center inline-flex transform-center-x nowrap outline-none text-white"
        style={{
          bottom: 12,
          backgroundColor: `${
            get(s3Context, 'metadata.submitWhenDone')
              ? 'rgb(186, 190, 193)'
              : '#0b749e'
          }`,
        }}
        onClick={() => {
          if (get(s3Context, 'metadata.submitWhenDone')) {
            alert('You must wait for the current submission to complete');
          } else {
            dropzoneRefClick();
          }
        }}
      >
        <i className="material-icons">photo_camera</i>
        <div style={{ lineHeight: '24px', marginLeft: '6px' }}>
          Add Track Image
        </div>
      </div>
      {image
        && progress < 1 && (
        <div id="imageLoader" style={{ marginTop: '30px' }}>
          <ClipLoader color="white" loading />
        </div>
      )}
    </div>
  );
};

ImageField.propTypes = propTypes;
ImageField.defaultProps = defaultProps;

export default ImageField;
