import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Link } from 'react-router-dom';
import TextInput from 'components/common/TextInput';
import LicenseLink from './LicenseLink';

const propTypes = {
  basicCheck: PropTypes.bool,
  premiumCheck: PropTypes.bool,
  exclusiveCheck: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  submitType: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isStripeUser: PropTypes.bool,
  submitting: PropTypes.bool,
  waitingForFiles: PropTypes.bool,
  hasImage: PropTypes.bool,
  hasStems: PropTypes.bool,
  submitPending: PropTypes.bool,
  handlePreviousTab: PropTypes.func.isRequired,
};

const defaultProps = {
  submitting: false,
  waitingForFiles: false,
  isStripeUser: false,
  hasImage: true,
  hasStems: false,
  basicCheck: false,
  premiumCheck: false,
  exclusiveCheck: false,
  submitPending: false,
};

const validate = (values) => {
  const errors = {};

  if (values.basicCheck && !values.basic) {
    errors.basic = 'Please tell us an amount.';
  } else if (values.basicCheck && parseFloat(values.basic) < 10) {
    errors.basic = 'Please tell us an amount greater than 10.';
  } else if (values.basicCheck && !/^[0-9]+(\.[0-9]{2})?$/.test(values.basic)) {
    errors.basic =
      'Please tell us an amount using only numbers and decimal point.';
  }

  if (values.premiumCheck && !values.premium) {
    errors.premium = 'Please tell us an amount.';
  } else if (values.premiumCheck && parseFloat(values.premium) < 10) {
    errors.premium = 'Please tell us an amount greater than 10.';
  } else if (
    values.premiumCheck &&
    !/^[0-9]+(\.[0-9]{2})?$/.test(values.premium)
  ) {
    errors.premium =
      'Please tell us an amount using only numbers and decimal point.';
  }

  if (values.exclusiveCheck && !values.exclusive) {
    errors.exclusive = 'Please tell us an amount.';
  } else if (values.exclusiveCheck && parseFloat(values.exclusive) < 10) {
    errors.exclusive = 'Please tell us an amount greater than 10.';
  } else if (
    values.exclusiveCheck &&
    !/^[0-9]+(\.[0-9]{2})?$/.test(values.exclusive)
  ) {
    errors.exclusive =
      'Please tell us an amount using only numbers and decimal point.';
  }

  return errors;
};

const normalizeCheck = value => !!value;

const TrackPricingForm = ({
  basicCheck,
  premiumCheck,
  exclusiveCheck,
  submitting,
  submitPending,
  onCancel,
  handleSubmit,
  waitingForFiles,
  hasImage,
  hasStems,
  submitType,
  isStripeUser,
  handlePreviousTab,
}) => (
  <form id="track-pricing-form" onSubmit={handleSubmit} className="mx-6 mb-8">
    {!isStripeUser && (
      <div className="bg-orange-lightest border border-orange-light text-orange-dark px-4 py-3 mb-8 rounded relative">
        To sell your music, you must{' '}
        <Link
          to="/stripe/connect"
          className="link text-orange hover:text-orange-lighter font-semibold"
          target="_blank"
        >
          sign up
        </Link>{' '}
        for payment processing with Stripe.
      </div>
    )}
    <div className="flex my-3">
      <Field
        name="freeCheck"
        type="checkbox"
        component="input"
        style={{ marginTop: 2 }}
        normalize={normalizeCheck}
        disabled={submitPending}
      />
      <div className="ml-3">
        <div className="flex items-center">
          <h2 className="font-black mr-3">Free</h2>
          <div className="font-light">Tagged MP3</div>
        </div>
        <LicenseLink submitType={submitType} productType="basic">
          License terms
        </LicenseLink>
        <div className="h-8" />
      </div>
    </div>
    <div className="flex my-3">
      <Field
        name="basicCheck"
        type="checkbox"
        component="input"
        style={{ marginTop: 2 }}
        normalize={normalizeCheck}
        disabled={submitPending}
      />
      <div className="ml-3">
        <div className="flex items-center">
          <h2 className="font-black mr-3">Basic</h2>
          <div className="font-light">MP3 / WAV</div>
        </div>
        <LicenseLink submitType={submitType} productType="basic">
          License terms
        </LicenseLink>
        <div className={classnames('flex', { invisible: !basicCheck })}>
          <div className="font-semibold mr-2 mt-4 text-lg">$</div>
          <Field
            name="basic"
            component={TextInput}
            placeholder="10.00"
            className="w-32"
            disabled={submitPending}
          />
        </div>
      </div>
    </div>
    <div className="flex my-3">
      <Field
        name="premiumCheck"
        type="checkbox"
        component="input"
        disabled={!hasStems || submitPending}
        style={{ marginTop: 2 }}
        className={classnames({ 'cursor-not-allowed': !hasStems })}
        normalize={normalizeCheck}
      />
      <div className={classnames('ml-3', { 'text-gray': !hasStems })}>
        <div className="flex items-center">
          <h2 className="font-black mr-3">Premium</h2>
          <div className="font-light">MP3 / WAV / Stems</div>
        </div>
        <LicenseLink submitType={submitType} productType="premium">
          License terms
        </LicenseLink>
        <div className={classnames('flex', { invisible: !premiumCheck })}>
          <div className="font-semibold mr-2 mt-4 text-lg">$</div>
          <Field
            name="premium"
            component={TextInput}
            placeholder="20.00"
            className="w-32"
            disabled={submitPending}
          />
        </div>
      </div>
    </div>
    <div className="flex my-3">
      <Field
        name="exclusiveCheck"
        type="checkbox"
        component="input"
        disabled={!hasStems}
        style={{ marginTop: 2 }}
        className={classnames({ 'cursor-not-allowed': !hasStems })}
        normalize={normalizeCheck}
      />
      <div className={classnames('ml-3', { 'text-gray': !hasStems })}>
        <div className="flex items-center">
          <h2 className="font-black mr-3">Exclusive</h2>
          <div className="font-light">MP3 / WAV / Stems</div>
        </div>
        <LicenseLink submitType={submitType} productType="exclusive">
          License terms
        </LicenseLink>
        <div className={classnames('flex', { invisible: !exclusiveCheck })}>
          <div className="font-semibold mr-2 mt-4 text-lg">$</div>
          <Field
            name="exclusive"
            component={TextInput}
            placeholder="30.00"
            className="w-32"
          />
        </div>
      </div>
    </div>
    <div className="flex justify-end mt-6">
      <button
        className="btn btn-link btn-short px-3 py-2"
        onClick={onCancel}
        disabled={submitting}
      >
        Cancel
      </button>
      {handlePreviousTab && (
        <button
          type="previous"
          className="btn btn-inverse px-3 py-2 ml-3"
          onClick={(e) => {
            e.preventDefault();
            handlePreviousTab();
          }}
        >
          Previous
        </button>
      )}
      <button
        type="submit"
        className="btn btn-primary btn-short ml-3"
        disabled={submitting || submitPending || !hasImage}
      >
        {submitType}
      </button>
    </div>
  </form>
);

TrackPricingForm.propTypes = propTypes;
TrackPricingForm.defaultProps = defaultProps;

const selector = formValueSelector('Track');

export default compose(
  reduxForm({
    form: 'Track',
    validate,
    destroyOnUnmount: false,
    enableReinitalize: true,
  }),
  connect(state => ({
    basicCheck: selector(state, 'basicCheck'),
    premiumCheck: selector(state, 'premiumCheck'),
    exclusiveCheck: selector(state, 'exclusiveCheck'),
  })),
)(TrackPricingForm);
