import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { filter } from 'graphql-anywhere';
import { connect } from 'react-redux';
import * as s3Actions from 'actions/s3';
import DeleteTrackModal from './DeleteTrackModal';

const DELETE_TRACK_QUERY = gql`
  query DeleteTrackModalContainerQuery($trackId: UUID!) {
    track: trackById(id: $trackId) {
      ...DeleteTrackModal_track
    }
  }
  ${DeleteTrackModal.fragments.track}
`;

const propTypes = {
  auth: PropTypes.object.isRequired,
  modals: PropTypes.object.isRequired,
};

const DeleteTrackModalContainer = ({ auth, modals, ...props }) => {
  const trackId = modals.getIn(['data', 'trackId']);
  return (
    <Query query={DELETE_TRACK_QUERY} variables={{ trackId }}>
      {({ loading, error, data }) => {
        if (loading) return <div>Loading ...</div>;
        if (error) return <div>Error</div>;

        return (
          <DeleteTrackModal
            track={filter(DeleteTrackModal.fragments.track, data.track)}
            {...props}
          />
        );
      }}
    </Query>
  );
};

DeleteTrackModalContainer.propTypes = propTypes;

export default connect(
  state => ({ auth: state.auth }),
  s3Actions,
)(DeleteTrackModalContainer);
