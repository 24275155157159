const dayOfWeek = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

// const months = {
//   0: 'January',
//   1: 'February',
//   2: 'March',
//   3: 'April',
//   4: 'May',
//   5: 'June',
//   6: 'July',
//   7: 'August',
//   8: 'September',
//   9: 'October',
//   10: 'November',
//   11: 'December',
// };

export function timeDiff(date) {
  const current = new Date();
  return (
    ((current.getFullYear() - date.getFullYear()) * 365) +
    ((current.getMonth() - date.getMonth()) * 30) +
    (current.getDate() - date.getDate())
  );
}

export function displayTime(time) {
  if (!(time instanceof Date)) time = new Date(time); // eslint-disable-line
  const diff = timeDiff(time);
  if (diff >= 1) {
    return time.toDateString();
  }
  if (diff === 1) return 'Yesterday';
  if (diff > 0) return `${dayOfWeek[time.getDay()]}`;
  if (diff >= 0) return 'Today';
  console.error(new Error('created in the future')); // eslint-disable-line
  return 'created in the future';
}

export default module.exports;
