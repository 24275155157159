/* eslint-disable */
import uuid from 'uuid';

let queue = [];
let loopWorker;
let recBuffersL = [], recLength = 0, recClip;
let loops = [];
let scriptNode;
let mediaStream;

export let audioCtx;
export let offlineCtx;
export let startTime = 0;
export let contextTime;

export function setupLoopWorker () {
  loopWorker = new Worker("/workers/looper.js");
}

export function setupContext() {
  if (!audioCtx) audioCtx = new (window.AudioContext || window.webkitAudioContext)();
  if (!offlineCtx) offlineCtx = new (window.OfflineAudioContext || window.webkitAudioContext)(1,2,44100);
}

export function openStream() {
  if (!navigator.getUserMedia) navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

  return new Promise((resolve, reject) => {
    navigator.getUserMedia(
      {
          "audio": {
              "mandatory": {
                  // "googEchoCancellation": "false",
                  // "googAutoGainControl": "false",
                  // "googNoiseSuppression": "false",
                  // "googHighpassFilter": "false"
              },
              "optional": []
          },
      },
      (navStream) => resolve(mediaStream = navStream),

      (e) => {alert('Error getting audio'); console.log(e)}
    )
  })
}

export function stopStream() {
  mediaStream.getAudioTracks()[0].stop();
  if (scriptNode) scriptNode.onaudioprocess = () => null;

}

export function createSource (trackBuffer) {
  return new Promise(function(resolve, reject) {
    let reader = new FileReader();
    reader.addEventListener('load', function (e) {
      offlineCtx.decodeAudioData(e.target.result, function(data) {
        let decodedData = data;
        resolve(decodedData);
      });
    });
    reader.readAsArrayBuffer(trackBuffer);
  })
}

//make 2048 a variable
export function recordClip(newState, loopRegion) {
  let micStream = audioCtx.createMediaStreamSource(mediaStream);
  scriptNode = micStream.context.createScriptProcessor(2048, 1, 1);
  micStream.connect(scriptNode);
  scriptNode.connect(scriptNode.context.destination);
  scriptNode.onaudioprocess = (e) => {
    let inputBufferL = e.inputBuffer.getChannelData(0);
    recBuffersL.push([...inputBufferL]);
    recLength += inputBufferL.length;
  }
  let recDelayTime = loopRegion ? loopRegion.start : startTime + (audioCtx.currentTime - contextTime);
  recClip = {delayTime: recDelayTime, startTime: audioCtx.currentTime}
}

// change 44100 to variable
export function addRecordedClip(currentTime) {
  scriptNode.onaudioprocess = (e) => null;
  let result = new Float32Array(recLength);
  let offset = 0;
  for (var i = 0; i < recBuffersL.length; i++){
    result.set(recBuffersL[i], offset);
    offset += recBuffersL[i].length;
  }
  let recClipLength = currentTime - recClip.startTime;
  let sourceBuffer = audioCtx.createBuffer(1, 44100 * recClipLength, 44100);
  sourceBuffer.copyToChannel(result, 0, 0);

  let clipData = {
    id: uuid.v4(),
    sourceBuffer: sourceBuffer,
    delayTime: recClip.delayTime,
    length: {start: 0, end: sourceBuffer.duration}
  }
  recBuffersL = [];
  recLength = 0;
  recClip = null;
  return clipData;
}

export function calculateStartTime(newState) {
  let totalElapsedTime = audioCtx.currentTime - contextTime;
  if (newState.isLooping) {
    if (startTime + totalElapsedTime > newState.loopRegion.end) {
      let difference = startTime - newState.loopRegion.start;
      let cycleElapsedTime = (difference + totalElapsedTime) % (newState.loopRegion.end - newState.loopRegion.start);
      startTime = newState.loopRegion.start + cycleElapsedTime;
    }
    else startTime = startTime + totalElapsedTime;
  }
  else startTime = startTime + totalElapsedTime;
}

export function checkLoop (newState, changeStartTime) {
  if (changeStartTime) calculateStartTime(newState);
  if (newState.loop && startTime < newState.loopRegion.end) return true;
  else return false;
}

export function play(newState) {
  contextTime = audioCtx.currentTime;
  stopAudio();
  if (newState.isLooping) loopPlay(newState)
  else singlePlay(newState)
}


export function singlePlay(newState) {
  let currentTime = audioCtx.currentTime;
  if (newState.isRecording) recordClip(newState);

  let singlePlay = newState.items.map((clip) => {
    if (clip.status === 'perishing') return;
    const source = audioCtx.createBufferSource();
    source.buffer = newState.decodedData.find(decodedData => decodedData.id === clip.decodedId).data;
    source.connect(clip.gainNode);

    let realClipStartTime = startTime >= clip.delayTime ? startTime - clip.delayTime + clip.length.start : clip.length.start;
    let realDelayTime = clip.delayTime >= startTime ? clip.delayTime - startTime : 0;

    if (!(realClipStartTime > clip.length.end) ) {
      source.start(realDelayTime + currentTime, realClipStartTime);
      source.stop((realDelayTime + currentTime) + (clip.length.end - realClipStartTime))
      clip.source = source;
      return clip;
    }
    else return null;
  }).filter(clip => clip);

  queue.push(singlePlay)
}

export function seek(isPlaying, newPosition) {
  if (isPlaying) stopAudio();
  if (startTime == newPosition) {
    startTime = newPosition + .00000001;
  }
  else startTime = newPosition;
}


export function pause(newState) {
  calculateStartTime(Object.assign({}, newState));
  stopAudio();
}

export function stop() {
  startTime = 0;
  stopAudio();
}

function stopAudio() {
  queue.forEach(index => index.forEach(clip => clip.source.stop(0)));
  queue = [];
  loopWorker.postMessage({command: "stop"});
}




function loopPlay(newState) {
  const lookAhead = 20.0;
  const scheduleAheadTime = .5;
  const gracePeriod = 0;

  let nextStartTime = audioCtx.currentTime + (newState.loopRegion.end - startTime);
  let oldStartTime = nextStartTime;
  let currentTime = audioCtx.currentTime;


  if (newState.isRecording) recordClip(newState, currentTime);
  scheduleLoop(currentTime, startTime);
  scheduler();
  setupWorker();

  function setupWorker() {
    loopWorker.onmessage = function(e) {
      e.data == "tick" ? scheduler() : console.log("message: " + e.data);
    }
    loopWorker.postMessage({command: "interval", interval: lookAhead});
    loopWorker.postMessage({command:"start"});
  }

  function scheduleLoop(nextStartTime, start) {
    let currentTime = audioCtx.currentTime;
    let loopStart = start ? start : newState.loopRegion.start;
    let loopPlay = newState.items.map((clip) => {

      if (clip.status === 'perishing') return;

      const source = audioCtx.createBufferSource();
      source.buffer = newState.decodedData.find(decodedData => decodedData.id === clip.decodedId).data;
      source.connect(clip.gainNode);

      let realClipStartTime = loopStart >= clip.delayTime ? loopStart - clip.delayTime + clip.length.start : clip.length.start;
      let realDelayTime = clip.delayTime >= loopStart? clip.delayTime - loopStart: 0;
      let realClipStopTime = Math.min(nextStartTime + realDelayTime + clip.length.end - realClipStartTime, nextStartTime + newState.loopRegion.end - loopStart);

      if (!(realClipStartTime > clip.length.end) ) {
        source.start(nextStartTime + realDelayTime, realClipStartTime);
        source.stop(realClipStopTime)
        clip.source = source;
        return clip;
      }
      else return null;
    }).filter(clip=>clip);
    queue.push(loopPlay);
  }

  function scheduler() {
    if (nextStartTime < audioCtx.currentTime + scheduleAheadTime ) {
        scheduleLoop( nextStartTime);
        oldStartTime = nextStartTime;
        nextStartTime += newState.loopRegion.end - newState.loopRegion.start;
    }
    if (oldStartTime < audioCtx.currentTime) {
      if (newState.isRecording) {
        recBuffersL = [];
        recLength = 0;
        recClip = null;
        scriptNode.onaudioprocess = (e) => null;
        recordClip(newState, newState.loopRegion);
      }
      queue.shift();
      oldStartTime = nextStartTime;
    }
  }
}



export default module.exports;
/* eslint-enable */
