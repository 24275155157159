import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import PropTypes from 'prop-types';
import { avatarImageUrl } from 'config';
import { Link } from 'react-router-dom';
import displayImage from 'utils/display-image';

const propTypes = {
  follower: PropTypes.object.isRequired,
};

const Follower = ({ follower }) => (
  <div className="has-text-centered p-2">
    <Link to={`/${follower.username}`}>
      <img
        alt="Follower user"
        className="circle cover"
        style={{ height: 124, width: 124 }}
        src={displayImage(avatarImageUrl, follower.picture)}
      />
      <div>{follower.displayName}</div>
    </Link>
  </div>
);

Follower.propTypes = propTypes;

export default createFragmentContainer(Follower, {
  follower: graphql`
    fragment Follower_follower on User {
      username
      displayName
      picture
    }
  `,
});
