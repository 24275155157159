import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { avatarImageUrl } from 'config';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import startsWith from 'lodash/startsWith';
import TextInput from 'components/common/TextInput';
import DashboardButton from 'components/common/DashboardButton';
import isReservedName from 'utils/is-reserved-name';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  username: PropTypes.string.isRequired,
  error: PropTypes.bool,
  uploading: PropTypes.bool,
  stripeUser: PropTypes.bool,
};

const defaultProps = {
  error: false,
  stripeUser: false,
};

const validate = (values) => {
  const errors = {};
  if (!values.displayName) {
    errors.displayName = 'Required';
  }

  if (!values.username) {
    errors.username = 'Required';
  } else if (isReservedName(values.username)) {
    errors.username = 'This name is reserved';
  }

  return errors;
};

const renderDropzoneInput = field => (
  <div className="my3">
    {/* We can also pass e, the event, to onDrop in case we must interact with it */}
    <Dropzone
      name={field.name}
      onDrop={(filesToUpload) => {
        console.log('files to upload', filesToUpload);
        field.input.onChange(filesToUpload[0].preview);
      }}
      multiple={false}
      accept="image/jpeg, image/png"
      style={{
        width: '13rem',
        height: '13rem',
        margin: 'auto',
        border: '1px solid black',
        borderRadius: '128px',
        textAlign: 'center',
        background:
          field.input.value &&
          startsWith(field.input.value, 'blob') ?
          `url(${
            field.input.value
          }) no-repeat center center` :
          `url(${avatarImageUrl}${
            field.input.value
          }) no-repeat center center`,
        backgroundSize: 'cover',
      }}
      activeStyle={{
        backgroundColor: 'white',
      }}
      rejectStyle={{
        backgroundColor: 'rgb(234, 201, 201)',
      }}
    >
      {({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
        console.log('args', isDragActive, isDragReject, acceptedFiles, rejectedFiles);
        if (isDragActive) {
          return (
            <div
              style={{
                width: '13rem',
                height: '13rem',
                lineHeight: '80px',
              }}
            >
              Upload!
            </div>
          );
        }
        if (isDragReject) {
          return (
            <div
              style={{
                width: '13rem',
                height: '13rem',
                lineHeight: '50px',
                fontSize: '8px',
              }}
            >
              Please choose a jpeg or png file.
            </div>
          );
        }
        if (acceptedFiles.length) {
          return (
            <div
              style={{
                width: '13rem',
                height: '13rem',
                background: `url(${
                  acceptedFiles[0].preview
                }) center center no-repeat`,
                backgroundSize: 'cover',
                display: 'inline-block',
                margin: 'auto',
                border: '1px solid black',
                borderRadius: '128px',
              }}
            />
          );
        }
        return rejectedFiles.length ? (
          <span
            style={{
              lineHeight: '80px',
            }}
          >
            Valid files: jpg/png
          </span>
        ) : (
          <div
            style={{
              lineHeight: '13rem',
              color: 'white',
              borderRadius: '50%',
              cursor: 'pointer',
            }}
          >
            Change
            {'   '}
            <span
              style={{
                border: 'solid white',
                borderWidth: '0 3px 3px 0',
                display: 'inline-block',
                padding: '3px',
                transform: 'rotate(-135deg)',
                WebkitTransform: 'rotate(-135deg)',
              }}
            />
          </div>
        );
      }}
    </Dropzone>

    {field.meta.touched &&
      field.meta.error && <span className="error">{field.meta.error}</span>}
  </div>
);

const SettingsForm = ({
  handleSubmit,
  submitting,
  submitSucceeded,
  error,
  uploading,
  history,
  stripeUser,
  username,
}) => (
  <form onSubmit={handleSubmit}>
    <div className="lg:flex justify-center items-center">
      <Field name="picture" component={renderDropzoneInput} />
      <div className="text-center mb-4 lg:ml-8 lg:pl-4 lg:mb-0 lg:text-left">
        {stripeUser ? (
          <DashboardButton className="btn rounded-full" account>
            Stripe Settings
          </DashboardButton>
        ) : (
          <Link
            to="/stripe/connect"
            style={{ display: 'inline-block' }}
            className="btn rounded-full"
          >
            Connect to Stripe
          </Link>
        )}
      </div>
    </div>

    <Field
      name="displayName"
      htmlFor="display-name"
      label="Display Name"
      placeholder="Display Name"
      component={TextInput}
    />

    <Field
      name="username"
      htmlFor="username"
      label="Username"
      placeholder="Username"
      component={TextInput}
    />

    <Field
      name="description"
      htmlFor="description"
      label="Description"
      placeholder="Description"
      component={TextInput}
    />

    {submitSucceeded && (
      <p className="help is-success">
        Your user info has been updated successfully!
      </p>
    )}

    <div className="field is-grouped">
      <p className="control">
        <button
          type="submit"
          className={`btn btn-primary btn-short ${uploading && 'is-loading'}`}
          disabled={submitting || error || uploading}
        >
          Submit
        </button>
      </p>
      <p className="control">
        <button
          className="btn btn-link btn-short"
          onClick={() => history.push(`/${username}`)}
          disabled={submitting}
        >
          Cancel
        </button>
      </p>
    </div>
  </form>
);

SettingsForm.propTypes = propTypes;
SettingsForm.defaultProps = defaultProps;

export default compose(
  reduxForm({
    form: 'UserSettings',
    validate,
  }),
  withRouter,
)(SettingsForm);
