import React from 'react';

const ProfileNotFound = () => (
  <div id="profile-not-found" className="my-8 pt-8 px-8">
    <div className="title text-center">User Not Found</div>
    <div className="subtitle text-center">Sorry, we can&#39;t find that user.</div>
  </div>
);

export default ProfileNotFound;
