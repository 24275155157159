// eslint-disable
import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

const defaultProps = {
  className: '',
  color: '#FFFFFF',
};

const Logo = ({ className, color }) => (
  <svg className={className} width="40px" height="30px" viewBox="0 0 40 30" version="1.1">
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group" fill={color}>
        <path
          d="M12.85,-5.55111512e-15 C5.33556086,2.23144088 0,7.87736699 0,14.4897281 C0,23.0558147 8.954305,30 20,30 C31.045695,30 40,23.0558147 40,14.4897281 C40,7.87736699 34.6644391,2.23144088 27.15,-1.88737914e-15 L27.15,7.80391593 C29.6243501,9.39713198 31.2,11.8007306 31.2,14.4897281 C31.2,19.2867366 26.1855892,23.1754803 20,23.1754803 C13.8144108,23.1754803 8.8,19.2867366 8.8,14.4897281 C8.8,11.8007306 10.3756499,9.39713198 12.85,7.80391593 L12.85,-4.88498131e-15 Z"
          id="Combined-Shape"
        />
        <g id="Waves" transform="translate(14.545455, 0.000000)">
          <rect
            id="Rectangle-3"
            x="1.42108547e-14"
            y="1"
            width="1"
            height="5.45021186"
            rx="0.5"
          />
          <rect
            id="Rectangle-3-Copy"
            x="2.45454545"
            y="2"
            width="1"
            height="3.67055085"
            rx="0.5"
          />
          <rect
            id="Rectangle-3-Copy-2"
            x="5"
            y="0"
            width="1"
            height="6.5625"
            rx="0.5"
          />
          <rect
            id="Rectangle-3-Copy-3"
            x="7.45454545"
            y="2"
            width="1"
            height="3.67055085"
            rx="0.5"
          />
          <rect
            id="Rectangle-3-Copy-4"
            x="10"
            y="1"
            width="1"
            height="5.45021186"
            rx="0.5"
          />
        </g>
      </g>
    </g>
  </svg>
);

Logo.propTypes = propTypes;

Logo.defaultProps = defaultProps;

export default Logo;
