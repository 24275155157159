import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import * as soundbarActions from 'actions/soundbar';

const propTypes = {
  children: PropTypes.func.isRequired,
  track: PropTypes.object,
  tracks: PropTypes.array,
  soundbarIsPlaying: PropTypes.bool.isRequired,
  soundbarSetTrack: PropTypes.func.isRequired,
  soundbarSetVisible: PropTypes.func.isRequired,
  soundbarAddTracks: PropTypes.func.isRequired,
  soundbarRemoveAllTracks: PropTypes.func.isRequired,
  soundbarNextTrack: PropTypes.func.isRequired,
  soundbarPreviousTrack: PropTypes.func.isRequired,
  soundbarTrack: PropTypes.object,
  removeOtherTracksOnPlay: PropTypes.bool,
  soundbarTracks: PropTypes.object,
  soundbarCurrentTrack: PropTypes.object,
  soundbarProgress: PropTypes.number,
  soundbarDuration: PropTypes.number,
  soundbarUpdate: PropTypes.func.isRequired,
};

const defaultProps = {
  track: {},
  tracks: [],
  soundbarTrack: {},
  soundbarCurrentTrack: {},
  soundbarTracks: [],
  removeOtherTracksOnPlay: true,
  soundbarProgress: 0,
  soundbarDuration: 0,
};

const sanitizeTracks = tracks =>
  tracks.map((trak) => {
    const track = trak.node || trak;
    const user = track.user || track.userByUserId;
    return {
      id: track.id,
      name: track.name,
      picture: track.picture,
      fileUrl: track.fileUrl,
      publicId: track.publicId,
      userId: user.id,
      username: user.username,
      userDisplayName: user.displayName,
    };
  });

const AudioWrapper = ({
  children,
  track,
  tracks,
  soundbarIsPlaying,
  soundbarSetTrack,
  soundbarAddTracks,
  soundbarRemoveAllTracks,
  soundbarTrack,
  soundbarTracks,
  removeOtherTracksOnPlay,
  soundbarCurrentTrack,
  soundbarProgress,
  soundbarNextTrack,
  soundbarPreviousTrack,
  soundbarDuration,
  soundbarUpdate,
}) => {
  const canPlay = soundbarTrack;
  let currentIndex = 0;

  if (canPlay) {
    currentIndex = soundbarTracks.findIndex(
      trak => trak.id === soundbarCurrentTrack.id,
    );
  }
  const hasPrevious = currentIndex > 0;
  const canPlayPrevious = canPlay;
  const canPlayNext = soundbarTracks.length > currentIndex + 1;

  const previous = () => {
    const currentTime = soundbarDuration * soundbarProgress;
    if (canPlay) {
      if (hasPrevious && currentTime < 4) {
        soundbarPreviousTrack();
      } else {
        soundbarUpdate({
          update: true,
          updatedTime: 0,
        });
      }
    }
  };

  const next = (e) => {
    e.stopPropagation();
    if (canPlayNext) {
      soundbarNextTrack();
    }
  };

  const play = () => {
    console.log('playing...', track);
    if (!isEmpty(track)) {
      const sanitizedTracks = sanitizeTracks(tracks);
      const trackToSet = sanitizedTracks.find(trak => trak.id === track.id);
      if (removeOtherTracksOnPlay) {
        soundbarRemoveAllTracks(true);
      }
      const progress =
        soundbarCurrentTrack.id === trackToSet.id ? soundbarProgress : 0;
      console.log('track to set', trackToSet);
      soundbarAddTracks(sanitizedTracks);
      soundbarSetTrack(trackToSet, progress);
    }
    soundbarUpdate({
      visible: true,
      playing: true,
    });
  };

  const pause = () => {
    soundbarUpdate({
      playing: false,
    });
  };

  const togglePlay = (e) => {
    e.stopPropagation();
    console.log('can play', canPlay);
    if (canPlay) {
      if (soundbarIsPlaying && soundbarTrack.id === track.id) {
        pause();
      } else if (soundbarIsPlaying && isEmpty(track)) {
        pause();
      } else play();
    }
  };

  const newChild = children({
    togglePlay,
    next,
    canPlayNext,
    previous,
    canPlayPrevious,
  });

  return newChild;
};

AudioWrapper.propTypes = propTypes;
AudioWrapper.defaultProps = defaultProps;

export default connect(
  state => ({
    soundbarTrack: state.soundbar.currentTrack,
    soundbarIsPlaying: state.soundbar.playing,
    soundbarTracks: state.soundbar.tracks,
    soundbarCurrentTrack: state.soundbar.currentTrack,
    soundbarProgress: state.soundbar.progress,
    soundbarDuration: state.soundbar.duration,
  }),
  soundbarActions,
)(AudioWrapper);
