import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import PropTypes from 'prop-types';
import Comment from './Comment';

const propTypes = {
  track: PropTypes.object.isRequired,
  mine: PropTypes.bool,
  currentUser: PropTypes.string,
  myStyle: PropTypes.object,
};

const defaultProps = {
  mine: false,
  currentUser: '',
  myStyle: null,
};

const CommentList = ({ track, mine, currentUser, myStyle }) => (
  track.comments.edges.length > 0 ?
    <ul className="mt-4 pt-2 border-t border-gray-light" style={myStyle}>
      {track.comments.edges.map(comment => (
        <Comment
          key={comment.node.id}
          comment={comment.node}
          deleteable={mine || comment.node.author.id === currentUser}
        />
      ))}
    </ul>
    :
    null
);

CommentList.propTypes = propTypes;
CommentList.defaultProps = defaultProps;

export default createFragmentContainer(CommentList, {
  track: graphql`
    fragment CommentList_track on Track {
      comments: commentsByTrackId(first: 100, orderBy: CREATED_AT_ASC) @connection(key: "CommentList_comments") {
        edges {
          node {
            id
            author: userByUserId {
              id
            }
            ...Comment_comment
          }
        }
      }
    }
  `,
});
