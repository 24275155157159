import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import MainContent from './MainContent';
import Sidebar from './Sidebar';
import VocalDashboard from './VocalDashboard';

const propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const Dashboard = ({ auth, history }) => {
  const skill = get(auth, 'skill', '').toLowerCase();
  if (skill === 'vocalist') return <VocalDashboard />;
  return (
    <div className="mt-3 w-desk flex">
      <MainContent />
      <Sidebar auth={auth} history={history} />
    </div>
  );
};

Dashboard.propTypes = propTypes;

export default connect(({ auth }) => ({ auth }))(Dashboard);
