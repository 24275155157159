import React from 'react';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { genreImageUrl } from 'config';
import TrackPlayer from 'components/common/ApolloTrackPlayer';
import GenreAndMoodDisplay from './GenreAndMoodDisplay';

const propTypes = {
  match: PropTypes.object.isRequired,
  genreTracks: PropTypes.object.isRequired,
};

const GENRE_LIST_TRACKS = gql`
  fragment GenreListContainer_genreTracks on TracksConnection {
    totalCount
    tracks: edges {
      node {
        id
        name
        picture
        fileUrl
        publicId
        userId
        ...TrackPlayer_track
        user: userByUserId {
          ...TrackPlayer_user
          id
          displayName
          username
        }
      }
    }
  }
  ${TrackPlayer.fragments.track}
  ${TrackPlayer.fragments.user}
`;

const GenreListContainer = ({
  match: {
    params: { genre },
  },
  genreTracks,
}) => (
  <GenreAndMoodDisplay
    tracks={genreTracks.tracks}
    name={genre}
    url={genreImageUrl}
    totalCount={genreTracks.totalCount}
  />
);

GenreListContainer.propTypes = propTypes;
GenreListContainer.fragments = { genreTracks: GENRE_LIST_TRACKS };

export default withRouter(GenreListContainer);
