import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import displayImage from 'utils/display-image';
import get from 'lodash/get';
import { ClipLoader } from 'react-spinners';
import CreateTrackProgress from './CreateTrackProgress';
import TrackForm from '../TrackForm';
import TrackPricingForm from '../TrackPricingForm';
import ImageField from '../ImageField';

const propTypes = {
  requiredFilesDone: PropTypes.bool.isRequired,
  cancelUpload: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dropzoneRefClick: PropTypes.func,
  allGenres: PropTypes.object,
  allMoods: PropTypes.object,
  s3Context: PropTypes.object.isRequired,
  s3AddMetadata: PropTypes.func.isRequired,
  isStripeUser: PropTypes.bool,
};

const defaultProps = {
  dropzoneRefClick: () => {},
  allGenres: {},
  allMoods: {},
  isStripeUser: false,
};

const CreateTrackInfo = ({
  requiredFilesDone,
  cancelUpload,
  onSubmit,
  onCancel,
  dropzoneRefClick,
  s3Context,
  s3AddMetadata,
  allGenres,
  allMoods,
  isStripeUser,
}) => {
  const hasStems = !!get(s3Context, 'metadata.requiredFiles.stems');

  const handleNextTab = () => {
    const currentIndex = get(s3Context, 'metadata.formActiveTabIndex') || 0;
    if (currentIndex < 1) {
      s3AddMetadata({ formActiveTabIndex: currentIndex + 1 });
    }
  };

  const handlePreviousTab = () => {
    const currentIndex = get(s3Context, 'metadata.formActiveTabIndex');
    if (currentIndex >= 1) {
      s3AddMetadata({ formActiveTabIndex: currentIndex - 1 });
    } // Pop back to index 0 if there is no previous index?
  };

  return (
    <div className="w-desk pnavMt mt-3">
      <div className="pcont">
        <div className="w-9/10 mx-auto" style={{ minWidth: '300px', maxWidth: '780px' }}>
          <div className="upload-title">Upload A Beat</div>
          <CreateTrackProgress
            s3Context={s3Context}
            cancelUpload={cancelUpload}
          />
          <Tabs
            selectedIndex={get(s3Context, 'metadata.formActiveTabIndex') || 0}
            onSelect={(index, lastIndex, event) => {
              s3AddMetadata({ formActiveTabIndex: index });
            }}
          >
            <TabList>
              <Tab>Basic info</Tab>
              <Tab>Pricing options</Tab>
            </TabList>

            <div className="flex flex-col md:flex-row">
              <ImageField s3Context={s3Context} dropzoneRefClick={dropzoneRefClick} />
              <TabPanel>
                <TrackForm
                  s3Context={s3Context}
                  hasImage={s3Context.imageId !== ''}
                  onSubmit={onSubmit}
                  onCancel={onCancel}
                  waitingForFiles={!requiredFilesDone}
                  allGenres={allGenres}
                  allMoods={allMoods}
                  initialValues={{ mood: 'chill', genre: 'rap' }}
                  submitType="Create"
                  showImageUpload={false}
                  submitPending={!!get(s3Context, 'metadata.submitWhenDone')}
                  handleNextTab={handleNextTab}
                />
              </TabPanel>
              <TabPanel>
                <TrackPricingForm
                  hasImage={s3Context.imageId !== ''}
                  onSubmit={onSubmit}
                  onCancel={onCancel}
                  hasStems={hasStems}
                  waitingForFiles={!requiredFilesDone}
                  submitType="Create"
                  showImageUpload={false}
                  isStripeUser={isStripeUser}
                  submitPending={!!get(s3Context, 'metadata.submitWhenDone')}
                  handlePreviousTab={handlePreviousTab}
                />
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

CreateTrackInfo.propTypes = propTypes;
CreateTrackInfo.defaultProps = defaultProps;

export default CreateTrackInfo;
