import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import CartItem from './CartItem';

const propTypes = {
  id: PropTypes.string.isRequired,
};

const CART_ITEM_QUERY = gql`
  query CartItemContainerQuery($productId: UUID!) {
    cartProduct: productById(id: $productId) {
      id
      type
      price
      track: trackByTrackId {
        id
        name
        picture
        fileUrl
        publicId
        trackUser: userByUserId {
          id
          username
          displayName
        }
        products: productsByTrackId(first: 3, orderBy: PRICE_ASC) {
          edges {
            node {
              id
              type
              price
            }
          }
        }
      }
    }
  }
`;

const CartItemContainer = ({ id }) => (
  <Query
    query={CART_ITEM_QUERY}
    variables={{ productId: id }}
  >
    {({ loading, error, data }) => {
      console.log('error', error);
      if (loading) {
        return <div>Loading ...</div>;
      }
      if (error) {
        return <Redirect to="/error" />;
      }
      return <CartItem product={data.cartProduct} />;
    }}
  </Query>
);

CartItemContainer.propTypes = propTypes;

export default CartItemContainer;
