import React, { Component } from 'react';
import { connect } from 'react-redux';
import hoistStatics from 'hoist-non-react-statics';
import PropTypes from 'prop-types';
import * as s3Actions from 'actions/s3';


export default function (WrappedComponent) {
  class S3ContextWrapper extends Component {
    static propTypes = {
      s3: PropTypes.object.isRequired,
      s3CreateContext: PropTypes.func.isRequired,
      s3DestroyContext: PropTypes.func.isRequired,
      s3ResetFile: PropTypes.func.isRequired,
      s3AddMetadata: PropTypes.func.isRequired,
      s3DeleteMetadata: PropTypes.func.isRequired,
      s3Upload: PropTypes.func.isRequired,
      s3Abort: PropTypes.func.isRequired,
      s3Delete: PropTypes.func.isRequired,
    };

    componentDidMount() {
      console.log('Wrapped Component Name: ', WrappedComponent.name);
      console.log('S3Context Wrapper props :', this.props.s3);
      this.props.s3CreateContext(WrappedComponent.name);
    }

    wrappedS3DestroyContext = () => {
      this.props.s3DestroyContext(WrappedComponent.name);
    };

    wrappedS3ResetFile = (id) => {
      this.props.s3ResetFile(WrappedComponent.name, id);
    };

    wrappedS3AddMetadata = (metadata) => {
      this.props.s3AddMetadata(WrappedComponent.name, metadata);
    };

    wrappedS3DeleteMetadata = (keys) => {
      this.props.s3DeleteMetadata(WrappedComponent.name, keys);
    };

    // Note on the fourth parameter, "rules" - This was implemented to allow a component to have some control
    // over the action's dispatching, specifically for preventing uploads that might trigger that we don't want
    // *ehem*, web workers, I'm looking at you. Hacky and stupid, but it'll work for the time being.
    // Only the "match" parameter is implemented. See actions creators.
    wrappedS3Upload = (fileData, fileId, completion, rules) => {
      console.log('args', fileData, fileId, completion, rules);
      this.props.s3Upload(
        WrappedComponent.name,
        fileData,
        fileId,
        completion,
        rules,
      );
    };

    wrappedS3Abort = (request, completion) => {
      this.props.s3Abort(WrappedComponent.name, request, completion);
    };

    wrappedS3Delete = (fileId, completion) => {
      this.props.s3Delete(WrappedComponent.name, fileId, completion);
    };

    render() {
      const { s3 } = this.props;
      return (
        <WrappedComponent
          {...this.props}
          s3Context={s3[WrappedComponent.name] || {}}
          s3DestroyContext={this.wrappedS3DestroyContext}
          s3ResetFile={this.wrappedS3ResetFile}
          s3AddMetadata={this.wrappedS3AddMetadata}
          s3DeleteMetadata={this.wrappedS3DeleteMetadata}
          s3Upload={this.wrappedS3Upload}
          s3Abort={this.wrappedS3Abort}
          s3Delete={this.wrappedS3Delete}
        />
      );
    }
  }

  const WrappedS3 = connect(
    state => {
      return ({ s3: state.s3 })},
    { ...s3Actions },
  )(S3ContextWrapper);

  console.log('wrapped s3', WrappedS3);

  return hoistStatics(WrappedS3, WrappedComponent);
}
