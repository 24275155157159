import React from 'react';
import classnames from 'classnames';
import createFragContainer from 'utils/create-frag-container';
import { compose } from 'redux';
import graphql from 'babel-plugin-relay/macro';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const propTypes = {
  track: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  onUnlikeClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  textClassName: PropTypes.string,
};

const defaultProps = {
  className: '',
  iconClassName: '',
  textClassName: '',
};

const likesFrag = graphql`
  fragment LikesLink_track on Track {
    id
    publicId
    likes: likesByTrackId(first: 10000) @connection(key: "LikesLink_likes") {
      totalCount
      edges {
        node {
          userId
        }
      }
    }
  }
`;

const LikesLink = ({
  track,
  className,
  iconClassName,
  textClassName,
  userId,
  onLikeClick,
  onUnlikeClick,
  auth: { isAuthenticated },
}) => {
  const hasLiked = !!(
    track.likes.edges.length &&
    track.likes.edges
      .map(like => like.node.userId)
      .find(item => item === userId)
  );
  const disabled = !isAuthenticated;

  return (
    <div id="likes-link" className={classnames(className)}>
      <a
        className={classnames('link', { disabled })}
        role="button"
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation();
          if (hasLiked) onUnlikeClick();
          else onLikeClick();
        }}
        disabled={disabled}
      >
        <i
          className={classnames(
            'fa',
            iconClassName,
            { 'fa-heart-o': !hasLiked },
            { 'fa-heart red': !disabled && hasLiked },
          )}
        />
        <span className={textClassName}>
          {track.likes.totalCount}
        </span>
      </a>
    </div>
  );
};

LikesLink.propTypes = propTypes;
LikesLink.defaultProps = defaultProps;

export default compose(
  connect(state => ({ auth: state.auth })),
  createFragContainer([likesFrag]),
)(LikesLink);
