import { Map, fromJS } from 'immutable';
import Cookies from 'js-cookie';
import * as types from 'types';

const initialState = fromJS({
  initialLoad: false,
  currentUser: {},
  projectId: '',
  isLoading: false,
  items: {},
  error: '',
  validationMessage: '',
});

export default function users(state = initialState, { type, payload }) {
  if (!(state instanceof Map)) state = fromJS(state); // eslint-disable-line

  switch (type) {
  case types.RESET_USERS:
    return initialState;

  case types.UPDATE_PROJECT_ID:
    return state.set('projectId', payload);

  case types.LOGIN_USER:
  case types.SIGNUP_SUCCESS:
    return state.withMutations((map) => {
      map
          .set('currentUser', fromJS(payload.user))
          .set('initialLoad', true)
          .set('isLoading', false)
          .set('error', '');
    });

  case types.VALIDATE_USER_REQUEST:
  case types.FETCH_USER_REQUEST:
  case types.SIGNUP_REQUEST:
  case types.REFRESH_USER_REQUEST:
  case types.FETCH_USERS_REQUEST:
    return state.withMutations((map) => {
      map.set('isLoading', true).set('error', '');
    });

  case types.FETCH_USER_SUCCESS:
    return state.withMutations((map) => {
      map
          .set('currentUser', fromJS(payload))
          .set('initialLoad', true)
          .set('isLoading', false)
          .set('error', '');
    });

  case types.FETCH_USERS_SUCCESS: {
    const arrayPayload = Array.isArray(payload) ? fromJS(payload) : fromJS([payload]);
    const items = arrayPayload.reduce(
        (curr, next) => curr.set(next.get('id'), next),
        Map(),
      );
    return state.withMutations((map) => {
      map.set('items', items).set('isLoading', false).set('error', '');
    });
  }

  case types.FETCH_USER_FAILURE:
    Cookies.remove('token');
    window.location = '/';
    return state;

  case types.ADD_USER_MESSAGE:
    return state.withMutations((map) => {
      map.set('isLoading', false).set('error', payload);
    });

  case types.VALIDATE_USER_SUCCESS:
    return state.withMutations((map) => {
      map
        .setIn(['currentUser', 'valid'], true)
        .set('validationMessage', payload.message)
        .set('initialLoad', true)
        .set('isLoading', false)
        .set('error', '');
    });

  case types.UPDATE_USER_SUCCESS:
    if (payload.id === state.getIn(['currentUser', 'id'])) {
      return state.withMutations((map) => {
        map
            .set('currentUser', fromJS(payload))
            .set('isLoading', false)
            .set('error', '');
      });
    }
    return state;

  case types.VALIDATE_USER_FAILURE:
    return state.withMutations((map) => {
      map.set('isLoading', false).set('validationMessage', payload.message);
    });

  case types.SIGNUP_FAILURE:
  case types.REFRESH_USER_FAILURE:
  case types.UPDATE_USER_FAILURE:
  case types.FETCH_USERS_FAILURE:
    return state.withMutations((map) => {
      map
          .set('isLoading', false)
          .set('initialLoad', true)
          .set('error', payload);
    });

  case types.USERS_INITIALIZE:
    return state.withMutations((map) => {
      map
          .set('isLoading', false)
          .set('initialLoad', true)
          .set('error', false);
    });

  default:
    return state;
  }
}
