import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import startsWith from 'lodash/startsWith';
import * as notificationActions from 'actions/notifications';
import get from 'lodash/get';
import Desktop from './Desktop';
import Mobile from './Mobile';
import VocalNavbar from './VocalNavbar';

const propTypes = {
  type: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  s3: PropTypes.object.isRequired,
  fetchUnreadNotifications: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const s3ContextToWatch = 'CreateTrack';

class NavbarSignedIn extends Component {
  componentDidMount() {
    window.addEventListener('beforeunload', this.handleWindowClose);
    this.props.fetchUnreadNotifications();
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleWindowClose);
  }

  getContents = () => {
    const { type, s3, auth } = this.props;
    const mobile = type === 'mobile';
    const skill = get(auth, 'skill', '').toLowerCase();
    console.log('skill', skill);
    if (skill === 'vocalist') {
      return <VocalNavbar />;
    } else {
      if (mobile) {
        return <Mobile />;
      }
      return <Desktop s3ContextToWatch={s3[s3ContextToWatch]} />;
    }
  };

  // eslint-disable-next-line no-confusing-arrow
  requiredFilesDone = () => get(this.props.s3[s3ContextToWatch], 'metadata.requiredFiles')
    ? Object.keys(
      this.props.s3[s3ContextToWatch].metadata.requiredFiles,
    ).every(
      key => this.props.s3[s3ContextToWatch].metadata.requiredFiles[key]
            === 'done',
    )
    : true;

  handleWindowClose = (e) => {
    /* eslint-disable no-return-assign */
    const metadata = get(this.props.s3.s3ContextToWatch, 'metadata');
    if (
      !this.requiredFilesDone()
      || (metadata && Object.keys(metadata).length)
    ) {
      return (e.returnValue = 'You have some files that are still processing or uploading! If you choose to leave now, you will lose your currently uploading track. Do you still want to leave this page?');
    } else if (get(this.props.s3[s3ContextToWatch], 'uploads.length')) {
      return (e.returnValue = 'You may have uploaded some files, but have not yet submitted your track. If you choose to leave now, you will be required to upload your files again. Do you still want to leave this page?');
    }
    return null;
    /* eslint-enable no-return-assign */
  };

  render() {
    const {
      location: { pathname },
    } = this.props;
    if (startsWith(pathname, '/projects/') && pathname.length > 10) {
      return <div />;
    }
    return (
      <nav className="pnav bgcolor-primary fixed pin-t w-full z-40">
        {this.getContents()}
      </nav>
    );
  }
}

NavbarSignedIn.propTypes = propTypes;

export default compose(
  connect(
    state => ({
      type: state.windowSession.type,
      s3: state.s3,
      auth: state.auth,
    }),
    notificationActions,
  ),
  withRouter,
)(NavbarSignedIn);
