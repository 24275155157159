/**
 * @flow
 * @relayHash 8f30080389c7134df56504a4b3174f38
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type GenresAndMoodsCategory_genres$ref = any;
type GenresAndMoodsCategory_moods$ref = any;
export type GenresAndMoodsPageQueryVariables = {|
  genreCondition: {
    id?: ?number,
    genre?: ?string,
    parent?: ?number,
  },
|};
export type GenresAndMoodsPageQueryResponse = {|
  +genres: ?{|
    +$fragmentRefs: GenresAndMoodsCategory_genres$ref,
  |},
  +moods: ?{|
    +$fragmentRefs: GenresAndMoodsCategory_moods$ref,
  |},
|};
*/


/*
query GenresAndMoodsPageQuery(
  $genreCondition: GenreCondition!
) {
  genres: allGenres(condition: $genreCondition) {
    ...GenresAndMoodsCategory_genres
  }
  moods: allMoods {
    ...GenresAndMoodsCategory_moods
  }
}

fragment GenresAndMoodsCategory_genres on GenresConnection {
  edges {
    node {
      genreId: id
      genre
    }
  }
}

fragment GenresAndMoodsCategory_moods on MoodsConnection {
  edges {
    node {
      mood
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "genreCondition",
    "type": "GenreCondition!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "condition",
    "variableName": "genreCondition",
    "type": "GenreCondition"
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "GenresAndMoodsPageQuery",
  "id": null,
  "text": "query GenresAndMoodsPageQuery(\n  $genreCondition: GenreCondition!\n) {\n  genres: allGenres(condition: $genreCondition) {\n    ...GenresAndMoodsCategory_genres\n  }\n  moods: allMoods {\n    ...GenresAndMoodsCategory_moods\n  }\n}\n\nfragment GenresAndMoodsCategory_genres on GenresConnection {\n  edges {\n    node {\n      genreId: id\n      genre\n    }\n  }\n}\n\nfragment GenresAndMoodsCategory_moods on MoodsConnection {\n  edges {\n    node {\n      mood\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "GenresAndMoodsPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "genres",
        "name": "allGenres",
        "storageKey": null,
        "args": v1,
        "concreteType": "GenresConnection",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "GenresAndMoodsCategory_genres",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "moods",
        "name": "allMoods",
        "storageKey": null,
        "args": null,
        "concreteType": "MoodsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "GenresAndMoodsCategory_moods",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "GenresAndMoodsPageQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "genres",
        "name": "allGenres",
        "storageKey": null,
        "args": v1,
        "concreteType": "GenresConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "GenresEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Genre",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": "genreId",
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "genre",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "moods",
        "name": "allMoods",
        "storageKey": null,
        "args": null,
        "concreteType": "MoodsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "MoodsEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Mood",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "mood",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
(node/*: any*/).hash = '3b906220d37475d2a05e5e7d8e3f35e9';
module.exports = node;
