import Immutable from 'immutable';
import { MODAL_SHOW, MODAL_HIDE, MODAL_UPDATE_STYLES } from 'types/modals';

const initialState = Immutable.fromJS({
  open: false,
  name: '',
  contentLabel: '',
  data: {},
  styles: {},
});

export default function modals(state = initialState, { type, payload }) {
  switch (type) {
  case MODAL_SHOW:
    return state.set('open', true).merge(Immutable.fromJS(payload));

  case MODAL_HIDE:
    return state.merge({ open: false, name: '', contentLabel: '', data: null });

  case MODAL_UPDATE_STYLES:
    return state.update('styles', styles => styles.merge(Immutable.fromJS(payload)));

  default:
    return state;
  }
}
