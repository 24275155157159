import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
};

const PasswordInput = ({ input, disabled, meta: { touched, error, warning } }) => (
  <div className="field">
    <p className="control has-icons-left">
      <input
        type="password"
        className={classnames(
          'input',
          'no-bs',
          { 'is-danger': touched && error },
          { 'is-warning': touched && warning },
        )}
        placeholder="Password"
        {...input}
        disabled={disabled}
      />
      {touched &&
        ((error && <p className="help is-danger">{error}</p>) ||
          (warning && <p className="help is-warning">{warning}</p>))}
      <span className="icon is-small is-left">
        <i className="fa fa-lock" />
      </span>
    </p>
  </div>
);

PasswordInput.propTypes = propTypes;
PasswordInput.defaultProps = defaultProps;

export default PasswordInput;
