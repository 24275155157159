import { createStore, combineReducers, applyMiddleware } from 'redux';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { reducer as formReducer } from 'redux-form';
// import { composeWithDevTools } from 'redux-devtools-extension';
import Immutable from 'immutable';
import serverCheck from 'utils/server-check';
import gaMiddleware from './redux-beacon-middleware';
import * as reducers from '../reducers';
import storageMiddleware from './storage-middleware';

export default () => {
  const isServer = serverCheck();

  const middlewares = [gaMiddleware, thunkMiddleware, storageMiddleware];

  if (process.env.REACT_APP_ENV === 'development') {
    const mapToJS = (state) => {
      if (state === null || typeof state !== 'object') return state;
      const newState = {};

      for (const i of Object.keys(state)) { // eslint-disable-line
        if (Immutable.Iterable.isIterable(state[i])) {
          newState[i] = state[i].toJS();
        } else {
          newState[i] = state[i];
        }
      }

      return newState;
    };

    const loggerMiddleware = createLogger({
      logger: console,
      predicate: () => typeof window !== 'undefined',
      collapsed: true,
      stateTransformer: mapToJS,
    });

    middlewares.push(loggerMiddleware);
  }

  // Do we have preloaded state available? Great, save it.
  const initialState = !isServer ? window.__PRELOADED_STATE__ : {};

  // Delete it once we have it stored in a variable
  if (!isServer) {
    delete window.__PRELOADED_STATE__;
  }

  const store = createStore(
    combineReducers({
      ...reducers,
      router: routerReducer,
      form: formReducer.plugin({
        Track: (state, action) => {
          switch (action.type) {
          case '@@redux-form/RESET':
            return undefined;
          default:
            return state;
          }
        },
      }),
    }),
    initialState,
    applyMiddleware(...middlewares),
  );

  return store;
};
