import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as soundbarActions from 'actions/soundbar';
import { trackUrl } from 'config';

const propTypes = {
  tracks: PropTypes.array.isRequired,
  currentTrackIndex: PropTypes.number.isRequired,
  playing: PropTypes.bool.isRequired,
  repeat: PropTypes.bool.isRequired,
  autoplay: PropTypes.bool.isRequired,
  soundbarNextTrack: PropTypes.func.isRequired,
  soundbarUpdate: PropTypes.func.isRequired,
  currentTrack: PropTypes.object,
  stalled: PropTypes.bool.isRequired,
  waiting: PropTypes.bool.isRequired,
  update: PropTypes.bool.isRequired,
  updatedTime: PropTypes.number.isRequired, //eslint-disable-line
  volume: PropTypes.number.isRequired,
  mute: PropTypes.bool.isRequired,
};

const defaultProps = {
  currentTrack: {},
};

class Audio extends Component {
  constructor(props) {
    super(props);
    this.audioElement = React.createRef();
  }
  componentDidMount() {
    this.setupTimeUpdateListener();
    this.setupDurationChangeListener();
    this.setupEndedListener();
    this.audioElement.current.volume = this.props.volume;
  }

  componentWillReceiveProps(nextProps) {
    const playHasChanged = this.props.playing !== nextProps.playing;
    const fileHasChanged =
      this.props.currentTrack.fileUrl !== nextProps.currentTrack.fileUrl;
    const timeHasChanged = nextProps.update;
    const volumeHasChange =
      this.props.volume !== nextProps.volume ||
      this.props.mute !== nextProps.mute;
    if (playHasChanged) this.handlePlayChange(this.props, nextProps);
    if (fileHasChanged) this.handleFileChange();
    if (timeHasChanged) this.handleTimeChange(nextProps);
    if (volumeHasChange) this.handleVolumeChange(nextProps);
  }

  setupTimeUpdateListener = () => {
    this.audioElement.current.addEventListener('timeupdate', (e) => {
      e.preventDefault();
      const progress =
        this.audioElement.current.currentTime / this.audioElement.current.duration;
      this.props.soundbarUpdate({ progress });
      this.updateWaitingOrStalledIfNeeded();
    });
  };

  setupDurationChangeListener = () => {
    console.log('first audioElement', this.audioElement.current)
    console.log('this first audioElement', this)
    this.audioElement.current.addEventListener('durationchange', (e) => {
      console.log('second audioElement', this.audioElement.current)
      console.log('this fisecondrst audioElement', this)
      e.preventDefault();
      this.props.soundbarUpdate({ duration: this.audioElement.current && this.audioElement.current.duration });
    });
  };

  setupEndedListener = () => {
    const {
      repeat,
      playing,
      soundbarUpdate,
      autoplay,
      tracks,
      currentTrackIndex,
      soundbarNextTrack,
    } = this.props;
    this.audioElement.current.addEventListener('ended', (e) => {
      e.preventDefault();
      if (repeat) {
        if (playing) {
          this.audioElement.current.play();
          soundbarUpdate({ playing: true });
        }
      } else if (autoplay) {
        if (tracks.length > currentTrackIndex + 1) {
          if (playing) {
            soundbarNextTrack();
            this.audioElement.current.play();
          }
        } else {
          this.audioElement.current.pause();
          soundbarUpdate({ playing: false });
        }
      } else {
        this.audioElement.current.pause();
        soundbarUpdate({ playing: false });
      }
    });
  };

  handlePlayChange = (oldProps, newProps) => {
    if (oldProps.playing && !newProps.playing) {
      this.audioElement.current.pause();
    } else if (!oldProps.playing && newProps.playing) {
      this.audioElement.current.play();
    }
  };

  handleFileChange = () => {
    if (this.audioElement.current) {
      this.props.soundbarUpdate({ duration: 0 });
      this.audioElement.current.load();
    }
  };

  handleTimeChange = (newProps) => {
    const { currentTrack, updatedTime, soundbarUpdate } = newProps;
    if (currentTrack && this.audioElement.current.duration) {
      this.audioElement.current.currentTime = updatedTime * this.audioElement.current.duration;
      soundbarUpdate({
        progress: updatedTime,
        update: false,
      });
    }
  };

  handleVolumeChange = (newProps) => {
    this.audioElement.current.volume = newProps.mute ? 0 : newProps.volume;
  };

  updateWaitingOrStalledIfNeeded = () => {
    const { soundbarUpdate } = this.props;
    if (this.props.stalled) {
      soundbarUpdate({ stalled: false });
    }
    if (this.props.waiting) {
      soundbarUpdate({ waiting: false });
    }
  };

  progressHandler = () => {
    const { soundbarUpdate } = this.props;
    const progress = this.audioElement.current.currentTime / this.audioElement.current.duration;

    if (!isNaN(progress)) {
      soundbarUpdate({ progress });
    } else {
      console.warn(
        'Soundbar progress is NaN. This is normal so long as it is happening only when updating the current track',
      );
    }
    this.updateWaitingOrStalledIfNeeded();
  };

  stalledHandler = () => {
    const { soundbarUpdate, stalled } = this.props;
    if (!stalled) {
      soundbarUpdate({ stalled: true });
    }
  };

  waitingHandler = () => {
    const { soundbarUpdate } = this.props;
    if (!this.props.waiting) {
      soundbarUpdate({ waiting: true });
      // If we are still waiting after a while, assume we have stalled regardless of
      // what our audio element says
      setTimeout(() => {
        if (this.props.waiting) {
          soundbarUpdate({ stalled: true });
        }
      }, 3000);
    }
  };

  render() {
    const { playing, currentTrack } = this.props;
    return (
      <audio
        autoPlay={playing}
        onProgress={this.progressHandler}
        onStalled={this.stalledHandler}
        onWaiting={this.waitingHandler}
        ref={this.audioElement}
      >
        <source
          src={`${trackUrl}${currentTrack.fileUrl}/tagged.mp3`}
          type="audio/mpeg"
        />{' '}
        {/* And how exactly are we handling 404's? */}
      </audio>
    );
  }
}

Audio.propTypes = propTypes;

Audio.defaultProps = defaultProps;

export default connect(
  state => ({
    playing: state.soundbar.playing,
    currentTrack: state.soundbar.currentTrack,
    tracks: state.soundbar.tracks,
    currentTrackIndex: state.soundbar.currentTrackIndex,
    repeat: state.soundbar.repeat,
    autoplay: state.soundbar.autoplay,
    progress: state.soundbar.progress,
    seeking: state.soundbar.seeking,
    stalled: state.soundbar.stalled,
    waiting: state.soundbar.waiting,
    update: state.soundbar.update,
    updatedTime: state.soundbar.updatedTime,
    volume: state.soundbar.volume,
    mute: state.soundbar.mute,
  }),
  soundbarActions,
)(Audio);
