import React, { Component } from 'react';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import { avatarImageUrl } from 'config';
import PropTypes from 'prop-types';
import withS3 from 'utils/S3';
import get from 'lodash/get';
import ProfileMobileTracklist from './ProfileMobileTracklist';
import ProfileMobileSummary from './ProfileMobileSummary';
import ProfileMobileBanner from './ProfileMobileBanner';
import '../ProfileBanner.css';

const propTypes = {
  user: PropTypes.object.isRequired,
  currentUser: PropTypes.string.isRequired,
  myself: PropTypes.bool.isRequired,
  s3Context: PropTypes.object,
  s3DestroyContext: PropTypes.func.isRequired,
  removeOtherTracksOnPlay: PropTypes.bool,
  addOtherTracksOnPlay: PropTypes.bool,
};

const defaultProps = {
  removeOtherTracksOnPlay: false,
  addOtherTracksOnPlay: false,
  s3Context: {},
};

const PROFILE_FRAGMENT = gql`
  fragment ProfileMobile_user on User {
    id
    picture
    displayName
    description
    ...ProfileMobileSummary_user
    ...ProfileMobileTracklist_user
    ...ProfileMobileBanner_user
  }
  ${ProfileMobileBanner.fragments.user}
  ${ProfileMobileTracklist.fragments.user}
  ${ProfileMobileSummary.fragments.user}
`;

class ProfileMobile extends Component {
  componentWillUnmount() {
    this.props.s3DestroyContext();
  }

  getImageUrl = () => {
    const { user, s3Context } = this.props;
    if (get(user, 'picture')) {
      return `url(${avatarImageUrl}${user.picture})`;
    } else if (s3Context.uploads) {
      const imageUploaded = s3Context.uploads.filter(
        u =>
          u.id === `avatars/${s3Context.metadata.imageId}` &&
          u.status === 'uploaded',
      )[0];
      return imageUploaded && `url(${s3Context.metadata.image.preview})`;
    } else {
      return null;
    }
  };

  render() {
    const {
      user,
      currentUser,
      myself,
      removeOtherTracksOnPlay,
      addOtherTracksOnPlay,
    } = this.props;

    const image = this.getImageUrl();

    return (
      <div className="relative overflow-auto">
        <ProfileMobileBanner
          user={filter(ProfileMobileBanner.fragments.user, user)}
          currentUser={currentUser}
          myself={myself}
          image={image}
        />
        <div className="mx-auto flex mt-0 w-9/10 md:w-full flex-col md:flex-row max-w-full-desk">
          <ProfileMobileSummary
            user={filter(ProfileMobileSummary.fragments.user, user)}
            myself={myself}
            currentUser={currentUser}
          />
          <ProfileMobileTracklist
            user={filter(ProfileMobileTracklist.fragments.user, user)}
            currentUser={currentUser}
            myself={myself}
            removeOtherTracksOnPlay={removeOtherTracksOnPlay}
            addOtherTracksOnPlay={addOtherTracksOnPlay}
          />
        </div>
      </div>
    );
  }
}

ProfileMobile.propTypes = propTypes;
ProfileMobile.defaultProps = defaultProps;
ProfileMobile.fragments = { user: PROFILE_FRAGMENT };

export default withS3(ProfileMobile);
