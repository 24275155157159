import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Menu, { MenuItem } from 'material-ui/Menu';
import Popover from '@material-ui/core/Popover';
import AuthButton from './AuthButton';

class NavMenu extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    signOut: PropTypes.func.isRequired,
  };

  state = { open: false };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  // handleTouchTap = (event) => {
  //   // This prevents ghost click.
  //   event.preventDefault();
  //   this.setState({
  //     open: true,
  //   });
  // };

  render() {
    return (
      <div
        ref={(div) => {
          this.anchor = div;
        }}
      >
        <i
          className="material-icons color-bg fs-24 pointer icon-opacity cursor-pointer"
          onClick={(e) => {
          // this.handleTouchTap(e);
          }}
          role="button"
          tabIndex={0}
          style={{
            paddingRight: '10px',
            paddingLeft: '4px',
            marginRight: 0,
            width: '18px',
            userSelect: 'none',
            outline: 'none',
          }}
        >
          more_vert
        </i>
        <Popover
          open={this.state.open}
          anchorEl={this.anchor}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          onClose={this.handleClose}
        >
          <Menu>
            <MenuItem>
              <AuthButton
                auth={this.props.auth}
                signOut={this.props.signOut}
                onRequestClose={this.handleClose}
              />
            </MenuItem>
          </Menu>
        </Popover>
      </div>
    );
  }
}

export default NavMenu;
