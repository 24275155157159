import React from 'react';
import PropTypes from 'prop-types';
import SignUpForm from 'components/SignUp/SignUpForm';
import ExitButton from 'components/common/ExitButton';

const propTypes = {
  onClose: PropTypes.func.isRequired,
};

const SignUpModal = ({ onClose }) => (
  <div id="sign-up-modal">
    <div className="flex justify-between mb-6">
      <h1 className="title" style={{ marginBottom: 0 }}>
        Sign Up
      </h1>
      <ExitButton id="delete-track-exit" onClick={onClose} />
    </div>
    <div className="my-6">
      <h2 className="font-normal md:w-4/5 text-xl">
        Plug Music is in <span className="font-bold">closed beta</span>. We will email sign ups when we are out of beta.
      </h2>
    </div>
    <div style={{ maxWidth: 450 }}>
      <SignUpForm onAfterSubmit={onClose} />
    </div>
  </div>
);

SignUpModal.propTypes = propTypes;

export default SignUpModal;
