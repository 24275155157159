import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as authActions from 'actions/auth';
import pick from 'lodash/pick';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import TextInput from 'components/common/TextInput';
import isReservedName from 'utils/is-reserved-name';

const validate = (values) => {
  const errors = {};
  if (!values.displayName) {
    errors.displayName = 'Required';
  } else if (isReservedName(values.displayName)) {
    errors.displayName = 'This name is reserved';
  }

  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.password) {
    errors.password = 'Required';
  } else if (values.password.length < 8) {
    errors.password = 'Password must be at least 8 characters';
  }

  return errors;
};

class RegisterForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    registerUser: PropTypes.func.isRequired,
    logIn: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    onAfterSubmit: PropTypes.func,
    error: PropTypes.string,
    vocalist: PropTypes.bool,
  };

  static defaultProps = {
    error: '',
    vocalist: false,
    onAfterSubmit: () => {},
  };

  handleSubmit = (data) => {
    const { registerUser, logIn, history, vocalist, onAfterSubmit } = this.props;
    const user = pick(data, ['displayName', 'email', 'password']);
    const authUser = pick(data, ['email', 'password']);
    if (vocalist) user.skill = 'vocalist';
    return registerUser(user)
      .then(() => logIn(authUser))
      .then(() => history.push('/'))
      .then(() => onAfterSubmit())
      .catch((err) => {
        throw new SubmissionError({
          _error: err.message,
        });
      });
  };

  render() {
    const { handleSubmit, submitting, error } = this.props;
    return (
      <form onSubmit={handleSubmit(this.handleSubmit)}>
        <Field
          name="displayName"
          htmlFor="display-name"
          placeholder="Display Name"
          component={TextInput}
        />

        <Field
          name="email"
          placeholder="Email"
          htmlFor="email"
          type="email"
          component={TextInput}
        />

        <Field
          name="password"
          htmlFor="password"
          placeholder="Password"
          type="password"
          component={TextInput}
          validateOn="change"
        />

        {error && <div className="error">{error}</div>}
        <div className="field is-grouped">
          <div className="control">
            <button
              type="submit"
              className="btn btn-primary btn-short"
              disabled={submitting}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default compose(
  withRouter,
  connect(state => ({ auth: state.auth }), authActions),
  reduxForm({
    form: 'UserRegister',
    validate,
  }),
)(RegisterForm);
