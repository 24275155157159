import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { avatarImageUrl } from 'config';
import { displayTime } from 'utils/date';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import displayImage from 'utils/display-image';
import PlugImage from 'components/common/PlugImage';

const style = {
  row: {
    fontSize: 20,
    fontWeight: 300,
    cursor: 'pointer',
    padding: 2,
    userSelect: 'none',
    height: '42px',
    borderBottom: '1px solid #d8d8d8',
  },
};

class Project extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    rightClick: PropTypes.func.isRequired,
    handleDelete: PropTypes.func,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    updatedAt: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    createdBy: PropTypes.object.isRequired,
    hoveredProj: PropTypes.string,
    users: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  static defaultProps = {
    hoveredProj: '',
    handleDelete: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      hoverColor: { color: '#ed8064' },
    };
  }

  hoverEnter = () => {
    if (!this.props.hoveredProj) this.setState({ hover: true });
  };

  hoverExit = () => {
    this.setState({ hover: false });
  };

  handleRowClick = () => {
    const push = this.props.history.push;
    push(`/projects/${this.props.id}`);
  };

  handleDeleteClick = (e) => {
    e.stopPropagation();
    this.props.handleDelete(this.props.id);
  };

  handleRightClick = e => this.props.rightClick(this.props.id, e);

  renderUserImages() {
    if (this.props.users.length > 0) {
      return (
        <td className="proj-table-td" style={{}}>
          {this.props.users.map(user => (
            <div
              key={user.picture}
              className="proj-avatar-cont"
              style={{ display: 'inline-block' }}
            >
              <PlugImage
                imageSource={displayImage(avatarImageUrl, user.picture)}
                className="project-avatar"
                alt="User Avatar"
              />
              {/* <img
                className="project-avatar"
                src={displayImage(avatarImageUrl, user.picture)}
                alt="User Avatar"
              /> */}
            </div>
          ))}
        </td>
      );
    }
    return (
      <td className="proj-table-td" style={{}}>
        <div className="italic">Solo Project</div>
      </td>
    );
  }

  render() {
    const date = new Date(this.props.updatedAt || this.props.createdAt);
    return (
      <tr
        style={[
          style.row,
          this.props.hoveredProj === this.props.id || this.state.hover
            ? this.state.hoverColor
            : null,
        ]}
        onClick={this.handleRowClick}
        onContextMenu={this.handleRightClick}
        onMouseEnter={this.hoverEnter}
        onMouseLeave={this.hoverExit}
      >
        <td className="proj-table-td" style={{}}>
          {this.props.name}
        </td>
        <td className="proj-table-td">
          <div>
            {this.props.createdBy.id === this.props.userId
              ? 'Me'
              : this.props.createdBy.displayName}
          </div>
        </td>
        {this.renderUserImages.bind(this)()}
        <td className="proj-table-td">{displayTime(date)}</td>
      </tr>
    );
  }
}

export default compose(withRouter, Radium)(Project);
