import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import RelayRoute from 'components/Route/RelayRoute';
import GenresAndMoodsCategory from 'components/Categories/GenresAndMoodsCategory';

const genresAndMoodsQuery = graphql`
  query GenresAndMoodsPageQuery($genreCondition: GenreCondition!) {
    genres: allGenres(condition: $genreCondition) {
      ...GenresAndMoodsCategory_genres
    }
    moods: allMoods {
      ...GenresAndMoodsCategory_moods
    }
  }
`;

const GenresAndMoodsPage = props => (
  <RelayRoute
    query={genresAndMoodsQuery}
    setVariables={() => ({ genreCondition: { parent: 1 } })}
    component={GenresAndMoodsCategory}
    {...props}
  />
);

export default GenresAndMoodsPage;
