import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { s3Bucket } from 'config';

const s3 = require('helpers/s3').s3;

const bytesToSize = (bytes) => {
  /* eslint-disable */
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return '0 B';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
  /* eslint-enable */
};

const listObjects = track =>
  new Promise((resolve, reject) => {
    const params = {
      Bucket: s3Bucket,
      Prefix: `track/${track.fileUrl}`,
    };

    s3.listObjects(params, (err, data) => {
      if (err) return reject(err);
      return resolve(data.Contents);
    });
  });

// These params and signedURL code, aside from the key and content disposition
// are repeat-code and should be stuffed away into a utility or something
const stemsClick = (track) => {
  const params = {
    Bucket: s3Bucket,
    Key: `track/${track.fileUrl}/stems.zip`,
    Expires: 900, // this is the default - 15 minutes
    ResponseContentDisposition: `attachment;filename=${track.name}.zip`, // This allows us to set a custom filename for the download
  };

  s3.getSignedUrl('getObject', params, (err, url) => {
    if (err) {
      // window.gtag('event', 'Download', {
      //   event_category: 'Delivery Error',
      //   event_label: track.id,
      // });
      console.error(err);
    } else {
      // window.gtag('event', 'Download', {
      //   event_category: 'Delivery',
      //   event_label: track.id,
      // });
      window.location.href = url;
    }
  });
};

const wavClick = (track) => {
  const params = {
    Bucket: s3Bucket,
    Key: `track/${track.fileUrl}/clean.wav`,
    Expires: 900, // this is the default - 15 minutes
    ResponseContentDisposition: `attachment;filename=${track.name}.wav`, // This allows us to set a custom filename for the download
  };

  s3.getSignedUrl('getObject', params, (err, url) => {
    if (err) {
      // window.gtag('event', 'Download', {
      //   event_category: 'Delivery Error',
      //   event_label: track.id,
      // });
      console.error(err);
    } else {
      // window.gtag('event', 'Download', {
      //   event_category: 'Delivery',
      //   event_label: track.id,
      // });
      window.location.href = url;
    }
  });
};

const mp3Click = (track) => {
  const params = {
    Bucket: s3Bucket,
    Key: `track/${track.fileUrl}/clean.mp3`,
    Expires: 900, // this is the default - 15 minutes
    ResponseContentDisposition: `attachment;filename=${track.name}.mp3`, // This allows us to set a custom filename for the download
  };

  s3.getSignedUrl('getObject', params, (err, url) => {
    if (err) {
      // window.gtag('event', 'Download', {
      //   event_category: 'Delivery Error',
      //   event_label: track.id,
      // });
      console.error(err);
    } else {
      // window.gtag('event', 'Download', {
      //   event_category: 'Delivery',
      //   event_label: track.id,
      // });
      window.location.href = url;
    }
  });
};

const downloadTypes = [
  { id: 'mp3', name: 'mp3', fileName: 'clean.mp3', onClick: mp3Click },
  { id: 'wav', name: 'wav', fileName: 'clean.wav', onClick: wavClick },
  { id: 'stems', name: 'stems', fileName: 'stems.zip', onClick: stemsClick },
];

const DELIVERY_OPTIONS_PRODUCT = gql`
  fragment DeliveryOptions_product on Product {
    type
    track: trackByTrackId {
      name
      id
      fileUrl
    }
  }
`;

class DeliveryOptions extends Component {
  static propTypes = {
    product: PropTypes.object.isRequired,
  };

  state = { s3Objects: [] };

  componentDidMount() {
    const { track } = this.props.product;
    listObjects(track).then(data => this.setState({ s3Objects: data }));
  }

  renderDeliveryOption = (optionType) => {
    const {
      product: { track },
    } = this.props;
    const productObject = this.state.s3Objects.find(item => item.Key === `track/${track.fileUrl}/${optionType.fileName}`);
    const productSize = productObject && bytesToSize(productObject.Size);
    return (
      <a
        role="button"
        tabIndex={0}
        className="flex items-center text-lg font-semibold my-4 link"
        onClick={() => optionType.onClick(track)}
      >
        <i className="fa fa-download mr-3" aria-hidden="true" />
        <div className="mr-3">
          Download {optionType.name}
        </div>
        <div className="font-thin">{productSize}</div>
      </a>
    );
  };

  render() {
    const {
      product: { type },
    } = this.props;
    const removeStems = type.toLowerCase() === 'basic';
    const filteredTypes = removeStems
      ? downloadTypes.filter(dType => dType.id !== 'stems')
      : downloadTypes;
    return (
      <div id="delivery-options" className="mb-8 flex justify-center">
        <div className="text-left">
          {filteredTypes.map(this.renderDeliveryOption)}
        </div>
      </div>
    );
  }
}

DeliveryOptions.fragments = { product: DELIVERY_OPTIONS_PRODUCT };

export default DeliveryOptions;
