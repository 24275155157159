const initialState = [];

export default function following(state = initialState, action) {
  switch (action.type) {
  case 'FOLLOW_SET_FOLLOWING':
    if (action.follow) {
      return Object.assign([], state, [action.followedId]);
    }
    return state.reduce((accumulator, currentValue) => {
      if (currentValue !== action.followedId) {
        return Object.assign([], accumulator, [currentValue]);
      }
      return accumulator;
    }, []);
  default:
    return state;
  }
}
