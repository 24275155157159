import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  htmlFor: PropTypes.string,
  disabled: PropTypes.bool,
};

const defaultProps = {
  meta: {},
  label: '',
  placeholder: '',
  htmlFor: '',
  disabled: false,
  options: [{ value: 1, label: 'Option' }],
};

const Dropdown = ({
  input,
  label,
  htmlFor,
  disabled,
  options,
  meta: { touched, error, warning },
}) => (
  <div className="field">
    <label htmlFor={htmlFor} className="label">
      {label}
    </label>
    <div className="control">
      <select {...input} disabled={disabled}>
        {options &&
          options.map(option => (
            <option key={option.value || option.label} value={option.value}>
              {option.label || option.value}
            </option>
          ))}
      </select>
      {touched &&
        ((error && <p className="help is-danger">{error}</p>) ||
          (warning && <p className="help is-warning">{warning}</p>))}
    </div>
  </div>
);

Dropdown.propTypes = propTypes;
Dropdown.defaultProps = defaultProps;

export default Dropdown;
