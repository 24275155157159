import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import get from 'lodash/get';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { Link, withRouter } from 'react-router-dom';
import { avatarImageUrl } from 'config';
import PlugImage from 'components/common/PlugImage';
import displayTrackImage, {
  displaySmallImage,
} from 'utils/display-track-image';
import * as soundbarActions from 'actions/soundbar';
import * as modalActions from 'actions/modals';
import { AudioWrapper } from 'utils/AudioControls';
import Download from './TrackButtons/Download';
import Buy from './TrackButtons/Buy';
import DownloadFree from './TrackButtons/DownloadFree';

const TRACK_MOBILE_TRACK = gql`
  fragment TrackMobile_track on Track {
    id
    name
    picture
    fileUrl
    description
    publicId
    mood
    genre
    mp3Loaded
    ...Download_track
    ...DownloadFree_track
    ...Buy_track
  }
  ${Download.fragments.track}
  ${DownloadFree.fragments.track}
  ${Buy.fragments.track}
`;

const TRACK_MOBILE_USER = gql`
  fragment TrackMobile_user on User {
    id
    username
    displayName
  }
`;

class TrackMobile extends Component {
  static fragments = { track: TRACK_MOBILE_TRACK, user: TRACK_MOBILE_USER };

  static propTypes = {
    track: PropTypes.object.isRequired,
    tracks: PropTypes.object.isRequired,
    className: PropTypes.string,
    purchasedType: PropTypes.string,
    user: PropTypes.object.isRequired,
    showModal: PropTypes.func.isRequired,
    soundbar: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  static contextTypes = {
    myself: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    currentUser: '',
    className: 'pt-4 pb-2',
    purchasedType: '',
  };

  state = {
    showTags: false,
    contextMenu: false,
    contextPosition: null,
    otherTracksRemoved: false,
    otherTracksAdded: false,
  };


  handleRequestClose = () => {
    this.setState({
      contextMenu: false,
    });
  };

  handleDeleteClick = () => {
    const {
      track: { id },
      showModal,
    } = this.props;
    this.handleRequestClose();
    showModal('DeleteTrackModal', { trackId: id });
  };

  renderPurgatoryInfo = () => (
    <Link to="/stripe/connect" className="ml-1 link">
      (
      <span data-tip data-for="track-purgatory">
        *
      </span>
      )
      <ReactTooltip
        id="track-purgatory"
        type="warning"
        effect="solid"
        place="top"
      >
        <span>To sell this beat, you must sign up with Stripe.</span>
      </ReactTooltip>
    </Link>
  );

  render() {
    const {
      track,
      tracks,
      user,
      className,
      soundbar,
      history: { push },
      purchasedType,
    } = this.props;
    const { myself } = this.context;
    const currentTrackId = get(soundbar, 'currentTrack.id');
    const isCurrentTrack = currentTrackId === track.id;
    const isPlaying = isCurrentTrack && soundbar.playing;
    const downloadable = myself || purchasedType;
    return (
      <AudioWrapper track={track} tracks={tracks}>
        {({ togglePlay }) => (
          <li
            className={classnames('border-b first-child:border-t', className)}
            role="presentation"
            onClick={togglePlay}
            ref={(playerElement) => {
              this.playerElement = playerElement;
            }}
          >
            <div className="bg-white">
              <article className="flex w100" style={{ position: 'relative' }}>
                <PlugImage
                  imageSource={displaySmallImage(track.fileUrl, track.picture)}
                  fallbacks={[displayTrackImage(track.fileUrl, track.picture)]}
                  placeholder={`${avatarImageUrl}placeholder.png`}
                  className={'profile-trackplayer-img-desk'}
                  showControls
                  showPlayByDefault={isCurrentTrack}
                  circlePlayIcon
                  smallerPlayIcon
                  hoverEffect
                  playing={isPlaying}
                />
                <div className="w-full flex flex-col ml-3 md:ml-4">
                  <div className="flex items-center justify-between h-full">
                    <div id="track-player-content" className="h-full flex-grow">
                      <div className="inline-block">
                        <p className="pr-1 text-sm font-semibold text-black md:text-sm">
                          {track.name}{' '}
                          {purchasedType && <span className="font-bold">{purchasedType}</span>}
                        </p>
                        {myself &&
                          track.purgatory &&
                          this.renderPurgatoryInfo()}
                      </div>
                      <div className="flex pt-1">
                        <p className="text-xs md:text-sm text-gray-dark pb-px block">
                          {user.displayName}
                        </p>
                      </div>
                      <div className="text-xs pt-1">
                        <p className="inline-block">
                          {`${track.genre},`}&nbsp;
                        </p>
                        <p className="inline-block">{`${track.mood}`}</p>
                      </div>
                      <div className="pt-1">
                        <i className="fa fa-ellipsis-h" />
                      </div>
                    </div>
                    {!downloadable ? (
                      <div
                        role="presentation"
                        id="track-player-buy"
                        className="flex align-center h-full pl-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          push(`/tracks/${track.publicId}#buy-track`);
                        }}
                      >
                        <Buy
                          track={filter(Buy.fragments.track, track)}
                          className="border rounded border-blue-light text-sm font-bold px-3 py-1 tracking-wide text-blue-dark"
                          shouldLink={false}
                        />
                      </div>
                    ) : (
                      <div
                        id="track-player-download"
                        className="flex align-center h-full pl-2"
                      >
                        <Download
                          className="border rounded border-blue-light text-sm font-bold py-1 tracking-wide text-blue-dark inline-block"
                          track={filter(Download.fragments.track, track)}
                          purchasedType={purchasedType}
                        />
                      </div>
                    )}
                  </div>

                  <div className="pro-trkplayer-icons-cont fs1p">
                    {!myself && track.free && <DownloadFree track={filter(Download.fragments.track, track)} />}
                  </div>
                </div>
              </article>
            </div>
          </li>
        )}
      </AudioWrapper>
    );
  }
}

export default compose(
  connect(
    state => ({ soundbar: state.soundbar }),
    Object.assign({}, soundbarActions, modalActions),
  ),
  withRouter,
)(TrackMobile);
