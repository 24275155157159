import React from 'react';
import ReactDOM from 'react-dom';
import 'bulma/css/bulma.css'
// import './index.css';
import './generated.css';
import App from './components/App';


ReactDOM.render(
  <App />,
  document.getElementById('root'),
);
