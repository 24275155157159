import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import { connect } from 'react-redux';
import { isDesktop } from 'utils/screentype';
import ProfileMobile from './ProfileMobile';
import ProfileDesktop from './ProfileDesktop';
import ProfileNotFound from './ProfileNotFound';

const PROFILE_FRAGMENT = gql`
  fragment ProfileContainer_user on User {
    id
    ...ProfileDesktop_user
    ...ProfileMobile_user
  }
  ${ProfileDesktop.fragments.user}
  ${ProfileMobile.fragments.user}
`;

class ProfileContainer extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
  };

  static childContextTypes = { myself: PropTypes.bool.isRequired };

  static fragments = { user: PROFILE_FRAGMENT };

  static defaultProps = {
    currentUser: '',
  };

  getChildContext() {
    const {
      auth: { userId },
      user,
    } = this.props;
    if (user) return { myself: userId === user.id };
    return { myself: false };
  }

  render() {
    const {
      user,
      auth: { userId },
    } = this.props;
    if (!user) return <ProfileNotFound />;

    const myself = userId === user.id;
    return (
      <div className="h-full">
        {isDesktop() ? (
          <ProfileDesktop
            removeOtherTracksOnPlay // Will remove all but currently-playing track from the playlist on play
            addOtherTracksOnPlay // Will add all currently-showing tracks on the page to the playlist on play
            user={filter(ProfileDesktop.fragments.user, user)}
            myself={myself}
            currentUser={userId}
          />
        ) : (
          <ProfileMobile
            removeOtherTracksOnPlay // Will remove all but currently-playing track from the playlist on play
            addOtherTracksOnPlay // Will add all currently-showing tracks on the page to the playlist on play
            user={filter(ProfileMobile.fragments.user, user)}
            myself={myself}
            currentUser={userId}
          />
        )}
      </div>
    );
  }
}

export default connect(({ auth }) => ({ auth }))(ProfileContainer);
