import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import TrackPlayer from 'components/common/ApolloTrackPlayer';
import TrackLayout from './TrackLayout';

const STAFF_PICKS_FRAG = gql`
  fragment StaffPicksCategory_tracks on TracksConnection {
    edges {
      node {
        id
        name
        picture
        fileUrl
        publicId
        description
        ...TrackPlayer_track
        user: userByUserId {
          id
          username
          displayName
          ...TrackPlayer_user
        }
      }
    }
  }
  ${TrackPlayer.fragments.track}
  ${TrackPlayer.fragments.user}
`;

const propTypes = {
  tracks: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

const StaffPicksCategory = ({ tracks, title }) => (
  <TrackLayout tracks={tracks} title="Staff Picks" />
);

StaffPicksCategory.propTypes = propTypes;
StaffPicksCategory.fragments = { tracks: STAFF_PICKS_FRAG };

export default StaffPicksCategory;
