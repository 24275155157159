import React from 'react';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import { Mutation } from 'react-apollo';
import PropTypes from 'prop-types';
import { reset } from 'redux-form';
import { connect } from 'react-redux';
import CommentList from 'components/common/CommentList';
import CreateComment from './CreateComment';

const propTypes = {
  track: PropTypes.object.isRequired,
  resetForm: PropTypes.func.isRequired,
  mine: PropTypes.bool,
  disabled: PropTypes.bool,
  userId: PropTypes.string,
};

const defaultProps = {
  mine: false,
  disabled: false,
  userId: '',
};

const commentsSectionFrag = gql`
  fragment CommentsSection_track on Track {
    id
    comments: commentsByTrackId(first: 100, orderBy: CREATED_AT_ASC)
      @connection(key: "CommentList_comments") {
      ...CommentList_comments
    }
  }
  ${CommentList.fragments.comments}
`;

const CREATE_COMMENT = gql`
  mutation CreateComment($input: CreateCommentInput!) {
    createComment(input: $input) {
      track: trackByTrackId {
        ...CommentsSection_track
      }
    }
  }
  ${commentsSectionFrag}
`;

const DELETE_COMMENT = gql`
  mutation DeleteCommentByIdMutation($input: DeleteCommentByIdInput!) {
    deleteCommentById(input: $input) {
      track: trackByTrackId {
        ...CommentsSection_track
      }
    }
  }
  ${commentsSectionFrag}
`;

const CommentsSection = ({
  track, mine, disabled, userId, resetForm,
}) => (
  <Mutation
    mutation={CREATE_COMMENT}
    onCompleted={() => {
      resetForm('CreateComment');
    }}
  >
    {createComment => (
      <Mutation
        mutation={DELETE_COMMENT}
      >
        {deleteComment => (
          <div className="w-9/10 mx-auto md:w-desk">
            <p className="font-semibold">Comments</p>
            <CommentList
              comments={filter(CommentList.fragments.comments, track.comments)}
              userId={userId}
              onDelete={commentId => deleteComment({ variables: { input: { id: commentId } } })}
              mine={mine}
              myStyle={{ marginTop: '.5rem', border: 'none' }}
            />
            <CreateComment
              onSubmit={data => createComment({
                variables: {
                  input: {
                    comment: { userId, trackId: track.id, body: data.comment },
                  },
                },
              })}
              disabled={disabled}
            />
          </div>
        )}
      </Mutation>
    )}
  </Mutation>
);

CommentsSection.propTypes = propTypes;
CommentsSection.defaultProps = defaultProps;
CommentsSection.fragments = {
  track: commentsSectionFrag,
};

export default connect(null, { resetForm: reset })(CommentsSection);
