import { fetch, authHeader } from 'utils/fetch';

export function getToken(code) {
  return fetch(`/api/stripe/token?code=${code}`, {
    headers: authHeader(),
  });
}

export function createCharge(charge) {
  return fetch('/api/stripe/charge', {
    method: 'POST',
    body: JSON.stringify(charge),
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
  });
}

export function getDashboardLink() {
  return fetch('/api/stripe/account', {
    headers: authHeader(),
  });
}

export function getOrder(id) {
  return fetch(`/api/stripe/order/${id}`, {
    headers: authHeader(),
  });
}

export default getToken;
