import graphql from 'babel-plugin-relay/macro';
import promiseMutation from 'utils/promise-mutation';

const mutation = graphql`
  mutation CreateTrackMutation($input: CreateTrackInput!) {
    createTrack(input: $input) {
      track {
        id
      }
    }
  }
`;

const commit = (environment, track, cb) =>
  promiseMutation(environment, {
    mutation,
    variables: { input: { track } },
    // @cntrlz: here's the onCompleted function called after mutation completes,
    // We call cb (parameter for a callback function)
    onCompleted: response => cb(response),
  });

export default commit;
