import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import SearchExploreDropdown from 'components/Search/SearchExploreDropdown';
import search from 'assets/search.svg';
import { withRouter } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';
import classnames from 'classnames';

const searchPlaceholderSm = 'Search...';
const searchPlaceholderLg = 'Search by artist, genre, or track';

class SearchDesktop extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    size: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    formClass: PropTypes.string,
    containerClass: PropTypes.string,
    inputClass: PropTypes.string,
    buttonClass: PropTypes.string,
    iconClass: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    clearOnBlur: PropTypes.bool,
    clearOnFocus: PropTypes.bool,
    clearOnBoth: PropTypes.bool,
  };

  static defaultProps = {
    formClass: null,
    containerClass: null,
    inputClass: null,
    buttonClass: null,
    iconClass: null,
    onFocus: null,
    onBlur: null,
    clearOnBlur: false,
    clearOnFocus: false,
    clearOnBoth: false,
  };

  state = { dropDownShowing: false, query: '' };

  componentDidMount() {
    this.inputField.addEventListener('keypress', (e) => {
      this.handleSearchEnter(e);
    });
  }

  componentWillUnmount() {
    document.removeEventListener(
      'click',
      this.handleClickOutside.bind(this),
      true,
    );
    this.inputField.removeEventListener('keypress', (e) => {
      this.handleSearchEnter(e);
    });
  }

  onFocus = () => {
    if (this.props.clearOnFocus || this.props.clearOnBoth) {
      this.inputField.value = '';
    }
  };

  onBlur = () => {
    if (this.props.clearOnBlur || this.props.clearOnBoth) {
      this.inputField.value = '';
    }
  };

  handleClickOutside = (event) => {
    if (event.type === 'mousedown') {
      this.setState({ dropDownShowing: false });
    }
  };

  // There is some weird funk where the state of the explore dropdown component
  // is not showing, but the parent component thinks it still is, so now we
  // have this coolbean function we pass to the child!
  dropdownSetShowing = (showing) => {
    this.setState({ dropDownShowing: showing });
  };

  handleSearchEnter = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.props.history.push(`/search/${event.target.value}`);
      this.setState({ dropDownShowing: false });
      this.inputField.value = '';
      this.inputField.blur();
    }
  };

  handleSearchButton = (e) => {
    e.preventDefault();
    if (this.state.query && this.state.query !== '') {
      this.props.history.push(`/search/${this.state.query}`);
      this.setState({ query: '', dropDownShowing: false });
      this.inputField.value = '';
      this.inputField.blur();
    }
  };

  render() {
    const {
      size,
      formClass,
      containerClass,
      inputClass,
      buttonClass,
      iconClass,
      type,
    } = this.props;
    return (
      <form
        className={classnames(
          'flex flex-1 h-8 px-4 scrolling-touch',
          formClass,
        )}
      >
        <SearchExploreDropdown
          query={this.state.query}
          showing={this.state.dropDownShowing}
          navBarSetShowing={this.dropdownSetShowing}
          containerStyle={
            type === 'mobile'
              ? {
                width: '100%',
                left: 0,
                backgroundColor: '#e5e5e5fc',
                border: 'none',
                borderRadius: 0,
                overflow: 'hidden',
                overflowY: 'scroll',
                height: 'calc(100vh - 45px)', // This offsets the top 45px spacing
                paddingBottom: 80, // This is to prevent clipping with the floating feedback button
              }
              : {}
          }
        />
        <div
          className={classnames(
            'border-r-0 px-2 flex-1 search-container search-container-partial-border',
            containerClass,
          )}
          style={{ overflow: 'hidden' }}
        >
          <input
            type="text"
            placeholder={
              size === 'default' || size === 'sm'
                ? searchPlaceholderSm
                : searchPlaceholderLg
            }
            ref={(element) => {
              this.inputField = element;
            }}
            className={classnames(
              'h100 border-0 w-full outline-none ml-px text-sm',
              inputClass,
            )}
            onMouseDown={() => {
              // hack to prevent ios zoom... needs to be 16px
              this.inputField.style.fontSize = '16px';
            }}
            onChange={(e) => {
              e.preventDefault();
              this.setState({ query: e.target.value });
            }}
            onFocus={(e) => {
              this.setState({ dropDownShowing: true, query: e.target.value });
              if (this.props.onFocus) {
                this.props.onFocus();
              }
              this.onFocus();
            }}
            onBlur={(e) => {
              // hack for ios zoom
              this.inputField.style.fontSize = '';
              if (this.props.onBlur) {
                this.props.onBlur();
              }
              this.onBlur();
            }}
          />
        </div>
        {type === 'mobile' || (
          <button
            className={classnames('flex-center px-2', buttonClass)}
            onClick={this.handleSearchButton}
          >
            <i className={classnames('material-icons', iconClass)}>search</i>
          </button>
        )}
      </form>
    );
  }
}

export default compose(
  withRouter,
  connect(state => ({
    size: state.windowSession.size,
    type: state.windowSession.type,
  })),
  onClickOutside,
)(SearchDesktop);
