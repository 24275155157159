/**
 * @flow
 * @relayHash d026704cc293d54376398fa12f4e35aa
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EditTrackModal_track$ref = any;
type EditTrackModal_user$ref = any;
type TrackForm_allGenres$ref = any;
type TrackForm_allMoods$ref = any;
export type EditTrackModalContainerQueryVariables = {|
  trackId: any,
  genreCondition: {
    id?: ?number,
    genre?: ?string,
    parent?: ?number,
  },
|};
export type EditTrackModalContainerQueryResponse = {|
  +track: ?{|
    +$fragmentRefs: EditTrackModal_track$ref,
  |},
  +allGenres: ?{|
    +$fragmentRefs: TrackForm_allGenres$ref,
  |},
  +allMoods: ?{|
    +$fragmentRefs: TrackForm_allMoods$ref,
  |},
  +user: ?{|
    +$fragmentRefs: EditTrackModal_user$ref,
  |},
|};
*/


/*
query EditTrackModalContainerQuery(
  $trackId: UUID!
  $genreCondition: GenreCondition!
) {
  track: trackById(id: $trackId) {
    ...EditTrackModal_track
  }
  allGenres: allGenres(condition: $genreCondition) {
    ...TrackForm_allGenres
  }
  allMoods: allMoods {
    ...TrackForm_allMoods
  }
  user: currentUser {
    ...EditTrackModal_user
  }
}

fragment EditTrackModal_track on Track {
  id
  name
  description
  picture
  bpm
  stems
  free
  fileUrl
  genre
  mood
  products: productsByTrackId(first: 100) {
    edges {
      node {
        id
        type
        price
      }
    }
  }
}

fragment TrackForm_allGenres on GenresConnection {
  edges {
    node {
      genreId: id
      genre
    }
  }
}

fragment TrackForm_allMoods on MoodsConnection {
  edges {
    node {
      mood
    }
  }
}

fragment EditTrackModal_user on User {
  private {
    stripeUser
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "trackId",
    "type": "UUID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "genreCondition",
    "type": "GenreCondition!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "trackId",
    "type": "UUID!"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "condition",
    "variableName": "genreCondition",
    "type": "GenreCondition"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "genre",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "mood",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EditTrackModalContainerQuery",
  "id": null,
  "text": "query EditTrackModalContainerQuery(\n  $trackId: UUID!\n  $genreCondition: GenreCondition!\n) {\n  track: trackById(id: $trackId) {\n    ...EditTrackModal_track\n  }\n  allGenres: allGenres(condition: $genreCondition) {\n    ...TrackForm_allGenres\n  }\n  allMoods: allMoods {\n    ...TrackForm_allMoods\n  }\n  user: currentUser {\n    ...EditTrackModal_user\n  }\n}\n\nfragment EditTrackModal_track on Track {\n  id\n  name\n  description\n  picture\n  bpm\n  stems\n  free\n  fileUrl\n  genre\n  mood\n  products: productsByTrackId(first: 100) {\n    edges {\n      node {\n        id\n        type\n        price\n      }\n    }\n  }\n}\n\nfragment TrackForm_allGenres on GenresConnection {\n  edges {\n    node {\n      genreId: id\n      genre\n    }\n  }\n}\n\nfragment TrackForm_allMoods on MoodsConnection {\n  edges {\n    node {\n      mood\n    }\n  }\n}\n\nfragment EditTrackModal_user on User {\n  private {\n    stripeUser\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EditTrackModalContainerQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "track",
        "name": "trackById",
        "storageKey": null,
        "args": v1,
        "concreteType": "Track",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "EditTrackModal_track",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "allGenres",
        "name": "allGenres",
        "storageKey": null,
        "args": v2,
        "concreteType": "GenresConnection",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "TrackForm_allGenres",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "allMoods",
        "name": "allMoods",
        "storageKey": null,
        "args": null,
        "concreteType": "MoodsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "TrackForm_allMoods",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "user",
        "name": "currentUser",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "EditTrackModal_user",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EditTrackModalContainerQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "track",
        "name": "trackById",
        "storageKey": null,
        "args": v1,
        "concreteType": "Track",
        "plural": false,
        "selections": [
          v3,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "picture",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "bpm",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "stems",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "free",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fileUrl",
            "args": null,
            "storageKey": null
          },
          v4,
          v5,
          {
            "kind": "LinkedField",
            "alias": "products",
            "name": "productsByTrackId",
            "storageKey": "productsByTrackId(first:100)",
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 100,
                "type": "Int"
              }
            ],
            "concreteType": "ProductsConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ProductsEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Product",
                    "plural": false,
                    "selections": [
                      v3,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "type",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "price",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "allGenres",
        "name": "allGenres",
        "storageKey": null,
        "args": v2,
        "concreteType": "GenresConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "GenresEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Genre",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": "genreId",
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  },
                  v4
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "allMoods",
        "name": "allMoods",
        "storageKey": null,
        "args": null,
        "concreteType": "MoodsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "MoodsEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Mood",
                "plural": false,
                "selections": [
                  v5
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "user",
        "name": "currentUser",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "private",
            "storageKey": null,
            "args": null,
            "concreteType": "UserPrivate",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "stripeUser",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
(node/*: any*/).hash = '4ec364e8154c65b16d27190b3e8b2879';
module.exports = node;
