import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as modalActions from 'actions/modals';
import FeedbackButton from './FeedbackButton';

const soundbarHeight = 59;

const propTypes = {
  soundbar: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  isShowing: PropTypes.bool.isRequired,
};

const Feedback = ({ showModal, soundbar, isShowing }) => {
  const bottom = soundbar.visible ? soundbarHeight + 20 : 20;
  if (isShowing) return <div />;
  return (
    <div className="fixed z-10" style={{ bottom, left: 20 }}>
      <FeedbackButton onClick={() => showModal('FeedbackModal')} />
    </div>
  );
};

Feedback.propTypes = propTypes;

export default connect(
  state => ({
    soundbar: state.soundbar,
    isShowing: state.modals.get('name') === 'FeedbackModal',
  }),
  modalActions,
)(Feedback);
