import React from 'react';
import PropTypes from 'prop-types';
import licensesData from 'config/licenses.json';

const propTypes = {
  type: PropTypes.string.isRequired,
};

const LicenseContent = ({ type }) => {
  const license = licensesData[type];
  return (
    <div id="license-content" className="mt-5 mb-8">
      <div key="file-types" className="border-t border-gray-light font-serif py-3 text-sm">
        {license.fileTypes.filter(file => file.availability === 'check').map(file => file.title).join(' & ')}
      </div>
      {license.licenses.map(spec => (
        <div key={spec.id} className="border-t border-gray-light py-3 text-sm font-serif license-spec-padding">
          <p className="inline-block text-capitalize">{spec.availability}&nbsp;</p>
          <p className="inline-block">{spec.details}</p>
        </div>
      ))}
    </div>
  );
};

LicenseContent.propTypes = propTypes;

export default LicenseContent;
