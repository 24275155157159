// Set up utility functions
/* eslint no-param-reassign: ["error", { "props": false }] */
import hasIn from 'lodash/hasIn';
import get from 'lodash/get';
import merge from 'lodash/merge';

const objectUpdate = (object, values) => Object.assign({}, object, values);

const objectRemoveKeys = (object, keys) =>
  Object.keys(object).reduce((result, key) => {
    if (!keys.includes(key)) {
      result[key] = object[key];
    }
    return result;
  }, {});

const arrayUpdateItemById = (array, itemIds, patch) =>
  array.map((item) => {
    if (!itemIds.includes(item.id)) {
      return item;
    } else {
      const updatedItem = objectUpdate(item, patch);
      return updatedItem;
    }
  });

const arrayRemoveItemsByIds = (array, itemIds) =>
  array.filter(item => !itemIds.includes(item.id));

const initialState = {};
const initialContextState = { uploads: [], metadata: {} };

// Simply aggregates our errors for convenience
function errorsReducer(state = [], action) {
  switch (action.type) {
  case 'S3_UPLOAD_STATUS':
    if (action.status.error) {
      const id = action.status.response.request.params.Key;
      return state.concat({ id, error: action.status.error });
    }
    return state;
  default:
    return state;
  }
}

function metadataReducer(state = {}, action) {
  switch (action.type) {
  case 'S3_UPDATE_METADATA': // Would be nice to have deep-clone, for greater updating utility!
    return merge({}, state, action.metadata);
  case 'S3_REMOVE_METADATA_KEYS':
    return objectRemoveKeys(state, action.keys);
  default:
    return state;
  }
}

function uploadsReducer(state = [], action) {
  switch (action.type) {
  case 'S3_RESET_FILE':
    return arrayRemoveItemsByIds(state, action.id);
    /* eslint-disable no-case-declarations */
  case 'S3_UPLOAD_START':
    const newState = state.concat([
      {
        id: action.request.params.Key,
        name: action.request.params.Body.name,
        data: action.request.params.Body,
        request: action.request,
        status: 'uploading',
      },
    ]);
    return newState;
    /* eslint-enable no-case-declarations */
  case 'S3_UPLOAD_STATUS':
      // If our file is "reset" during upload, status will not be processed. It should be re-added.
    if (action.status.progress) {
      return arrayUpdateItemById(
          state,
          [action.status.response.request.params.Key],
        {
          progress:
              action.status.progress.loaded / action.status.progress.total,
          status: action.status.status,
          loaded: action.status.progress.loaded,
          size: action.status.progress.total,
        },
        );
    }

    if (action.status.error) {
      return arrayUpdateItemById(
          state,
          [action.status.response.request.params.Key],
        {
          error: action.status.error,
          response: action.status.response,
          status: action.status.status,
        },
        );
    }

    if (action.status.response) {
      return arrayUpdateItemById(
          state,
          [action.status.response.request.params.Key],
        {
          status: action.status.status,
        },
        );
    } else {
      return state;
    }

  default:
    return state;
  }
}

function contextReducer(state, action) {
  return {
    uploads: state[action.context].uploads
      ? uploadsReducer(state[action.context].uploads, action)
      : initialContextState.uploads,
    metadata: state[action.context].metadata
      ? metadataReducer(state[action.context].metadata, action)
      : initialContextState.metadata,
    errors: errorsReducer(state[action.context].errors, action),
    name: action.context,
  };
}

/* eslint-disable no-confusing-arrow */
export default function s3(state = initialState, action) {
  // If the action doesn't have a context, don't worry about it!
  // this also takes care of mr. initial action
  if (!action.context) {
    return state;
  }
  switch (action.type) {
  case 'S3_CREATE_CONTEXT': {
    if (!state[action.context]) {
      return objectUpdate(state, {
        [action.context]: Object.assign(initialContextState, {
          name: action.context,
        }),
      });
    } else return state;
  }
  case 'S3_DESTROY_CONTEXT':
    return objectRemoveKeys(state, action.context);
  default:
    if (!state[action.context]) {
      console.warn(
          'S3 Reducer: An action was dispatched to a context that does not exist or has been destroyed.',
        );
      return state;
    } else {
      return Object.assign({}, state, {
        [action.context]: contextReducer(state, action),
      });
    }
  }
}
/* eslint-enable no-confusing-arrow */
