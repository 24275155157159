import isomorphicFetch from 'isomorphic-fetch';
import Cookies from 'js-cookie';
import { apiUrl } from 'config';

const returnJSON = response =>
  response.json().then(json => (response.ok ? json : Promise.reject(json)));

export const fetch = (partialUrl, ...options) => {
  const fullUrl = `${apiUrl}${partialUrl}`;
  return isomorphicFetch(fullUrl, ...options).then(returnJSON);
};

export const authHeader = () => ({
  Authorization: `bearer ${Cookies.get('token')}`,
});

export default fetch;
