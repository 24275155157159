export const FETCH_CLIPS_REQUEST = 'FETCH_CLIPS_REQUEST';
export const FETCH_CLIPS_FAILURE = 'FETCH_CLIPS_FAILURE';
export const FETCH_CLIPS_SUCCESS = 'FETCH_CLIPS_SUCCESS';

export const ADD_CLIP_REQUEST = 'ADD_CLIP_REQUEST';
export const ADD_CLIP_FAILURE = 'ADD_CLIP_FAILURE';
export const ADD_CLIP_SUCCESS = 'ADD_CLIP_SUCCESS';

export const ADD_CLIP_CLIENT = 'ADD_CLIP_CLIENT';

export const UPLOAD_CLIP = 'UPLOAD_CLIP';

export const TRASH_CLIP_CLIENT = 'TRASH_CLIP_CLIENT';

export const DELETE_CLIP_REQUEST = 'DELETE_CLIP_REQUEST';
export const DELETE_CLIP_FAILURE = 'DELETE_CLIP_FAILURE';
export const DELETE_CLIP_SUCCESS = 'DELETE_CLIP_SUCCESS';

export const DELETE_CLIPS_BY_TRACK = 'DELETE_CLIPS_BY_TRACK';

export const UPDATE_CLIP_REQUEST = 'UPDATE_CLIP_REQUEST';
export const UPDATE_CLIP_FAILURE = 'UPDATE_CLIP_FAILURE';
export const UPDATE_CLIP_SUCCESS = 'UPDATE_CLIP_SUCCESS';

export const SPLIT_CLIPS = 'SPLIT_CLIPS';

export const SETUP_AUDIOCTX = 'SETUP_AUDIOCTX';

export const TOGGLE_RECORD = 'TOGGLE_RECORD';
export const ADD_RECORDED_CLIP = 'ADD_RECORDED_CLIP';
export const PLAY = 'PLAY';
export const PAUSE = 'PAUSE';
export const STOP = 'STOP';
export const SEEK = 'SEEK';
export const UPDATE_LOOP_REGION = 'UPDATE_LOOP_REGION';
export const CHANGE_LOOP = 'CHANGE_LOOP';

export const UPDATE_DELAY_TIME = 'UPDATE_DELAY_TIME';
export const UPDATE_CLIP_LENGTH = 'UPDATE_CLIP_LENGTH';

export const MOVE_TO_TRACK = 'MOVE_TO_TRACK';

export const COPY_CLIP = 'COPY_CLIP';
export const PASTE_CLIP = 'PASTE_CLIP';

export const SOLO = 'SOLO';
export const UNSOLO = 'UN_SOLO';

export const ADD_IMAGE = 'ADD_IMAGE';

export const RESET_CLIPS = 'RESET_CLIPS';

export const CHANGE_UPLOAD_STATUS = 'CHANGE_UPLOAD_STATUS';

export const CLIP = {
  CLIP: 'clip',
};

export default module.exports;
