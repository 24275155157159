import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import RelayRoute from 'components/Route/RelayRoute';
import Onboarding from 'components/Onboarding';

const onboardingQuery = graphql`
  query OnboardingPageQuery(
    $userTalent: UserTalentCondition!
    $userArtist: UserArtistCondition!
    $userAlbum: UserAlbumCondition!
  ) {
    talents: allTalents {
      ...Onboarding_talents
    }
    albums: allAlbums {
      ...Onboarding_albums
    }
    artists: allArtists {
      ...Onboarding_artists
    }
    userTalents: allUserTalents(condition: $userTalent) {
      ...Onboarding_userTalents
    }
    userAlbums: allUserAlbums(condition: $userAlbum) {
      ...Onboarding_userAlbums
    }
    userArtists: allUserArtists(condition: $userArtist) {
      ...Onboarding_userArtists
    }
  }
`;

const OnboardingPage = props => (
  <RelayRoute
    query={onboardingQuery}
    setVariables={({ userId }) => ({
      userTalent: { userId },
      userArtist: { userId },
      userAlbum: { userId },
    })}
    component={Onboarding}
    {...props}
  />
);

export default OnboardingPage;
