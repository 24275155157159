import React from 'react';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import TrackPlayer from 'components/common/ApolloTrackPlayer';
import { isDesktop } from 'utils/screentype';

const propTypes = {
  hashtags: PropTypes.object.isRequired,
};

const TRACK_HASHTAG_LIST = gql`
  fragment TrackHashtagList_hashtags on HashtagsConnection {
    edges {
      node {
        id,
        tag,
        track: trackByTrackId {
          ...TrackPlayer_track
          user: userByUserId {
            id,
            displayName
            ...TrackPlayer_user
          }
        }
      }
    }
  }
  ${TrackPlayer.fragments.track}
  ${TrackPlayer.fragments.user}
`;

const TrackHashtagList = ({ hashtags }) =>
  (<ul style={{ marginBottom: 20 }} className="mx-4">
    {hashtags.edges.map(tag =>
      (<TrackPlayer
        className={classnames('md:mb-4', { 'track-li': !isDesktop() })}
        key={tag.node.id}
        track={filter(TrackPlayer.fragments.track, tag.node.track)}
        user={filter(TrackPlayer.fragments.user, tag.node.track.user)}
        listStyle={{ marginBottom: 10 }}
      />),
    )}
  </ul>);

TrackHashtagList.propTypes = propTypes;
TrackHashtagList.fragments = { hashtags: TRACK_HASHTAG_LIST };

export default TrackHashtagList;
