import React from 'react';
import PropTypes from 'prop-types';
import DropZone from 'react-dropzone';
import UpdateUserByIdMutation from 'mutations/UpdateUserByIdMutation';
import { ClipLoader } from 'react-spinners';

const uuidV4 = require('uuid/v4');

const propTypes = {
  onUpdateUser: PropTypes.func.isRequired,
  s3Upload: PropTypes.func.isRequired,
  s3Context: PropTypes.object.isRequired,
  s3AddMetadata: PropTypes.func.isRequired,
};

const ImageDropzone = ({
  onUpdateUser,
  s3Upload,
  s3Context,
  s3AddMetadata,
}) => {
  const handleImageDrop = (accepted, rejected) => {
    if (accepted.length) {
      accepted.forEach((image) => {
        const imageId = uuidV4();
        s3AddMetadata({ image, imageId });

        s3Upload(image, `avatars/${imageId}`, () => {
          onUpdateUser({ picture: imageId });
        });
      });
    }
    if (rejected.length) {
      console.log('Rejected files: ', rejected);
    }
  };

  return (
    <DropZone
      accept="image/jpeg, image/png"
      multiple={false}
      onDrop={handleImageDrop}
      disablePreview={false}
      className="bg-gray-80 flex"
      style={{
        width: 200,
        margin: 'auto',
        height: 200,
        borderRadius: '128px',
      }}
      activeStyle={{
        backgroundColor: 'white',
      }}
      rejectStyle={{
        backgroundColor: 'rgb(234, 201, 201)',
      }}
    >
      {({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
        if (isDragActive) {
          return (
            <div
              style={{
                lineHeight: '80px',
              }}
            >
              Upload!
            </div>
          );
        }
        if (isDragReject) {
          return (
            <div
              style={{
                lineHeight: '50px',
                fontSize: '8px',
              }}
            >
              Please choose a jpg or png file.
            </div>
          );
        }
        if (acceptedFiles.length) {
          const image = s3Context.metadata.image;
          const upload = s3Context.uploads.filter(
            u => u.id === `avatars/${s3Context.metadata.imageId}`,
          )[0];
          const opacity = image ? upload.progress : 1;

          return (
            <div
              style={{
                background: image && `url(${image.preview})`,
                backgroundSize: 'cover',
                width: '200px',
                height: '200px',
                opacity: `${opacity}`,
                transition: 'opacity .25s ease-in-out',
                MozTransition: 'opacity .25s ease-in-out',
                WebkitTransition: 'opacity .25s ease-in-out',
                borderRadius: '128px',
                WebkitBorderRadius: '128px',
                MozBorderRadius: '128px',
                display: 'inline-block',
                border: '1px solid black',
              }}
            >
              {image &&
                upload.progress < 1 && (
                  <div id="imageLoader" style={{ marginTop: '60px' }}>
                    <ClipLoader color="white" loading />
                  </div>
                )}
            </div>
          );
        }
        return rejectedFiles.length ? (
          <span
            style={{
              lineHeight: '80px',
            }}
          >
            Valid files: jpg/png
          </span>
        ) : (
          <span className="inline-flex self-center mx-auto border border-transparent border-solid bg-white rounded-sm btn-upload_img cursor-pointer hover:border-gray-dark">
            <i className="material-icons text-xl">photo_camera</i>
            <div className="text-xs font-semibold flex self-center ml-1">
              Add Profile Image
            </div>
          </span>
        );
      }}
    </DropZone>
  );
};

ImageDropzone.propTypes = propTypes;

export default ImageDropzone;
