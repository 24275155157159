import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field, FieldArray, SubmissionError } from 'redux-form';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'components/common/forms/TextField';
import invitesActions from 'actions/invites';
import { isDesktop } from 'utils/screentype';

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
};

class OnboardingInvites extends Component {
  static propTypes = {
    addInviteAsync: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleSubmitInvites: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
  };

  handleInvite = (data) => {
    const { addInviteAsync, reset, projectId, onRequestClose } = this.props;
    const invite = {
      projectId,
      ...data,
    };
    return addInviteAsync(invite)
      .then(() => {
        reset();
        onRequestClose();
      })
      .catch((err) => {
        throw new SubmissionError({
          _error: err.message,
        });
      });
  };

  handleThings = (data) => {
    console.log(data);
  };

  renderField = ({ input, label, type, meta: { touched, error } }) => (
    <div>
      <label htmlFor={label} />
      <div>
        <input
          className="p1 noFocusedOutline w-full"
          style={{ borderRadius: 8 }}
          {...input}
          type={type}
          placeholder={label}
        />
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );

  renderVisionaries = ({ fields, meta: { touched, error } }) => {
    if (!fields.length) {
      fields.push({});
    }
    return (
      <div
        className="overflow-auto relative"
        style={{
          height: 300,
          top: isDesktop() || 50,
        }}
      >
        <ul>
          {fields.map((visionary, index) => (
            <li key={Number(index.toString())}>
              <h4 className="p-2 color-white">
                {`Visionary ${index + 1} - `}
                <button
                  type="button"
                  className="noFocusedOutline"
                  style={{
                    color: 'white',
                    height: 20,
                    width: 20,
                  }}
                  title="Remove Visionary"
                  onClick={() => fields.remove(index)}
                >
                  X
                </button>
              </h4>
              <Field
                name={`${visionary}.email`}
                label="Email address"
                type="email"
                component={this.renderField}
              />
            </li>
          ))}
          <li>
            <button
              className="mt-3 noFocusedOutline bg-green"
              style={{
                fontSize: 'larger',
                borderRadius: '50%',
                height: 30,
                width: 30,
                color: 'white',
                border: '2px solid white',
              }}
              type="button"
              onClick={() => fields.push({})}
            >
              +
            </button>
            {touched && error && <span>{error}</span>}
          </li>
        </ul>
      </div>
    );
  };

  render() {
    const {
      pristine,
      submitting,
      error,
      handleSubmit,
      handleSubmitInvites,
    } = this.props;

    return (
      <div
        id="onboarding-invites"
        className="flex justify-center"
        style={{ height: 360 }}
      >
        <div
          className="center overflow-auto relative w-screen"
          style={{ top: 50 }}
        >
          <form
            className="w-5/6"
            style={{ margin: 'auto', maxWidth: 500 }}
            onSubmit={handleSubmit(handleSubmitInvites)}
          >
            <FieldArray name="visionaries" component={this.renderVisionaries} />
            <div
              className={`${isDesktop() ? 'absolute mt-2' : 'fixed mt-20'}`}
              style={{ left: 0, right: 0 }}
            >
              <button
                className="btn pl-5 pr-5 pt-2 pb-2"
                type="submit"
                disabled={submitting}
                onClick={() => {
                  alert('Unfortunately, the invite feature is not yet ready!');
                }}
              >
                Invite
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default compose(
  reduxForm({
    form: 'invites',
    asyncBlurFields: ['email'],
    validate,
    destroyOnUnmount: false,
  }),
  connect(state => ({ invite: state.invite }), invitesActions),
)(OnboardingInvites);
