import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';

const propTypes = {
  user: PropTypes.object.isRequired,
};

const PROFILE_SUMMARY_FRAG = gql`
  fragment ProfileMobileSummary_user on User {
    id
    description
    link
    displayName
    username
    tracks: tracksByUserId(first: 100, orderBy: CREATED_AT_DESC, condition: $trackCondition) {
      totalCount
    }
    followers: followsByFollowedId(first: 1) {
      totalCount
    }
    following: followsByFollowerId(first: 1) {
      totalCount
    }
  }
`;

const ProfileSummary = ({ user }) => (
  <div className="profile-summary">
    <div className="banner-name pt-2 font-bold text-base text-gray-darker">
      {user.displayName}
    </div>
    <div className="mt-1">{user.description}</div>
    {user.link && <a className="block text-blue-dark truncate" href={`https://${user.link}`}>{user.link}</a>}
    <div className="flex my-2">
      <div className="flex mr-6">
        <p className="mr-1 font-bold text-sm text-gray-darker">{user.tracks && user.tracks.totalCount}</p>
        <p className="font-medium text-sm text-gray-dark">Tracks</p>
      </div>
      <div className="flex">
        <p className="mr-1 font-bold text-sm text-gray-darker">
          {user.followers && user.followers.totalCount}
        </p>
        <p className="font-medium text-sm">
          Followers
        </p>
      </div>
    </div>
  </div>
);

ProfileSummary.propTypes = propTypes;
ProfileSummary.fragments = { user: PROFILE_SUMMARY_FRAG };

export default ProfileSummary;
