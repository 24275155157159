import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import Profile from 'components/Profile';
import { filter } from 'graphql-anywhere';
import { Helmet } from 'react-helmet';
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiUrl, avatarImageUrl } from 'config';
import displayImage from 'utils/display-image';

const propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const PROFILE_PAGE = gql`
  query ProfilePageQuery($username: String!, $trackCondition: TrackCondition!) {
    user: userByUsernameAndDeletedAndSkill(
      username: $username
      deleted: false
      skill: PRODUCER
    ) {
      username
      displayName
      description
      picture
      ...ProfileContainer_user
    }
  }
  ${Profile.fragments.user}
`;

const ProfilePage = ({
  match: {
    params: { username },
  },
  location: { pathname },
}) => (
  <Query
    query={PROFILE_PAGE}
    variables={{ username, trackCondition: { deleted: false } }}
  >
    {({ loading, error, data }) => {
      if (loading) {
        return (
          <div className="mt-12 ml-16">
            <CircularProgress size={50} style={{ color: '#2a585d' }} />
          </div>
        );
      }
      if (error) return <div>Error</div>;

      return (
        <div id="profile-page">
          <Helmet>
            <title>{data.user.displayName}</title>
            <meta
              property="description"
              content={data.user.description || data.user.username}
            />
            <meta property="og:title" content={data.user.displayName} />
            <meta
              property="og:description"
              content={data.user.description || data.user.username}
            />
            <meta
              property="og:type"
              content={`profile:${data.user.username}`}
            />
            <meta property="og:url" content={`${apiUrl}${pathname}`} />
            <meta
              property="og:image"
              content={displayImage(avatarImageUrl, data.user.picture)}
            />
            <meta property="og:image:type" content="image/jpeg" />
            <meta property="og:image:width" content="128" />
            <meta property="og:image:height" content="128" />
            <link rel="canonical" href={`${apiUrl}${pathname}`} />
          </Helmet>
          <Profile user={filter(Profile.fragments.user, data.user)} />
        </div>
      );
    }}
  </Query>
);

ProfilePage.propTypes = propTypes;

export default ProfilePage;
