import getImagePalette from 'image-palette-core';
import rgbToHex from 'utils/rgb-hex';

const pica = require('pica/dist/pica')();


const uploadImage = (image, fileUrl, imageId, s3AddMetadata, s3Upload, requiredFiles = {}) => {
  console.log('required', requiredFiles, s3AddMetadata, s3Upload);
  s3AddMetadata({
    requiredFiles: Object.assign(requiredFiles, {
      image: true,
    }),
    processing: true,
    image,
    imageId,
  });

  const fr = new FileReader();
  fr.onload = () => {
    const img = new Image();
    img.onload = () => {
      if (image.size > 100000) {
        const targetSize = 50000;
        s3AddMetadata({
          requiredFiles: Object.assign(requiredFiles, { imagesm: true }),
        });

        const pixels = img.width * img.height;
        const pixelBits = pixels / image.size;
        const hwRatio = img.height / img.width;

        /* eslint-disable no-mixed-operators */ const newWidth = Math.sqrt(
          targetSize * pixelBits / hwRatio,
        );
        /* eslint-enable no-mixed-operators */
        const newHeight = newWidth * hwRatio;

        const picaCanvas = document.createElement('canvas');
        picaCanvas.width = img.width;
        picaCanvas.height = img.height;

        const dest = document.createElement('canvas');
        dest.width = newWidth;
        dest.height = newHeight;

        const ctx = picaCanvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        pica
          .resize(picaCanvas, dest)
          .then(result => pica.toBlob(result, 'image/jpeg', 0.9))
          .then((blob) => {
            const imageSm = new File([blob], 'image_sm', {
              type: 'image/jpeg',
            });
            // saveAs(blob, `${image.name.split('.')[0]}_resized.jpg`);

            s3Upload(
              imageSm,
              `track/${fileUrl}/${imageId}_sm.jpg`, // We need to validate extensions!
              () => {
                s3AddMetadata({
                  requiredFiles: Object.assign(requiredFiles, {
                    imagesm: 'done',
                  }),
                });
              },
              null, // Add uploadRules
            );
          });
      }

      const palette = getImagePalette(img);
      // };
      if (palette) {
        s3AddMetadata({
          dominantColor: rgbToHex(palette.backgroundColor),
          overlayColor: palette.color,
          alternativeColor: palette.alternativeColor,
          // imageHex: color,
        });
      }
    };
    img.src = fr.result;
  }; // onload fires after reading is complete
  fr.readAsDataURL(image);
  console.log('reqqqq', requiredFiles, image);

  // Let's use our resizy thing above for now
  s3Upload(
    image,
    `track/${fileUrl}/${imageId}.jpg`, // We need to validate extensions!
    () => {
      s3AddMetadata({
        requiredFiles: Object.assign(requiredFiles, { image: 'done' }),
      });
    },
    null, // Add uploadRules
  );
};


export default uploadImage;
