import React from 'react';
import SignUp from 'components/SignUp';

const SignUpPage = () => (
  <div id="sign-up-page">
    <SignUp />
  </div>
);

export default SignUpPage;
