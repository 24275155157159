import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  divClassName: PropTypes.string,
  svgClassName: PropTypes.string,
};

const defaultProps = {
  divClassName: '',
  svgClassName: '',
};

const MpcIcon = ({ divClassName, svgClassName }) => (
  <div className={classnames('svg-default', divClassName)}>
    <svg
      className={svgClassName}
      version="1.1"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="m93.445 11.594h-86.867c-3.6211 0-6.5781 2.9531-6.5781 6.5742v63.664c0 3.6211 2.957 6.5742 6.5742 6.5742h86.848c3.6211 0 6.5742-2.957 6.5742-6.5742l0.003906-63.664c0.019531-3.6211-2.9375-6.5742-6.5547-6.5742zm-86.871 4.0195h86.867c1.3867 0 2.5547 1.168 2.5547 2.5547l0.003906 13.535h-91.977v-13.535c0-1.3867 1.1641-2.5547 2.5508-2.5547zm86.871 68.773h-86.867c-1.3867 0-2.5547-1.168-2.5547-2.5547v-46.109h91.977v46.109c0 1.3867-1.168 2.5547-2.5547 2.5547z" />
        <path d="m30.805 58.004h11.945c1.6289 0 2.9766-1.3281 2.9766-2.9766v-12.246c0-1.6289-1.3281-2.9766-2.9766-2.9766h-11.945c-1.6289 0-2.9766 1.3281-2.9766 2.9766v12.246c0.003906 1.6484 1.3281 2.9766 2.9766 2.9766z" />
        <path d="m42.75 62.086h-11.945c-1.6289 0-2.9766 1.3281-2.9766 2.9766v12.246c0 1.6289 1.3281 2.9766 2.9766 2.9766h11.945c1.6289 0 2.9766-1.3281 2.9766-2.9766v-12.246c0-1.6484-1.3281-2.9766-2.9766-2.9766zm-1.043 14.156h-9.832v-10.133h9.832z" />
        <path d="m64.711 39.824h-11.945c-1.6289 0-2.9766 1.3281-2.9766 2.9766v12.246c0 1.6289 1.3281 2.9766 2.9766 2.9766h11.945c1.6289 0 2.9766-1.3281 2.9766-2.9766v-12.246c-0.023438-1.6484-1.3477-2.9766-2.9766-2.9766zm-1.0664 14.156h-9.832v-10.133h9.832z" />
        <path d="m64.711 62.086h-11.945c-1.6289 0-2.9766 1.3281-2.9766 2.9766v12.246c0 1.6289 1.3281 2.9766 2.9766 2.9766h11.945c1.6289 0 2.9766-1.3281 2.9766-2.9766v-12.246c-0.023438-1.6484-1.3477-2.9766-2.9766-2.9766z" />
        <path d="m86.648 39.824h-11.945c-1.6289 0-2.9766 1.3281-2.9766 2.9766v12.246c0 1.6289 1.3281 2.9766 2.9766 2.9766h11.945c1.6289 0 2.9766-1.3281 2.9766-2.9766v-12.246c0-1.6484-1.3477-2.9766-2.9766-2.9766zm-1.0469 14.156h-9.832v-10.133h9.832z" />
        <path d="m86.648 62.086h-11.945c-1.6289 0-2.9766 1.3281-2.9766 2.9766v12.246c0 1.6289 1.3281 2.9766 2.9766 2.9766h11.945c1.6289 0 2.9766-1.3281 2.9766-2.9766v-12.246c0-1.6484-1.3477-2.9766-2.9766-2.9766z" />
        <path d="m45.727 23.82c0 2.9727-4.4648 2.9727-4.4648 0 0-2.9766 4.4648-2.9766 4.4648 0" />
        <path d="m74.984 23.82c0 2.9727-4.4648 2.9727-4.4648 0 0-2.9766 4.4648-2.9766 4.4648 0" />
        <path d="m60.348 23.82c0 2.9727-4.4648 2.9727-4.4648 0 0-2.9766 4.4648-2.9766 4.4648 0" />
        <path d="m89.625 23.82c0 2.9727-4.4648 2.9727-4.4648 0 0-2.9766 4.4648-2.9766 4.4648 0" />
        <path d="m20.512 45.094c0 5.9531-8.9297 5.9531-8.9297 0s8.9297-5.9531 8.9297 0" />
        <path d="m20.512 60.055c0 5.9531-8.9297 5.9531-8.9297 0 0-5.9531 8.9297-5.9531 8.9297 0" />
        <path d="m20.512 74.996c0 5.9492-8.9297 5.9492-8.9297 0 0-5.9531 8.9297-5.9531 8.9297 0" />
        <path d="m12.145 27.195h20.391c1.168 0 2.1328-0.94531 2.1328-2.1328v-2.5117c0-1.168-0.94531-2.1328-2.1328-2.1328l-20.391 0.003906c-1.168 0-2.1328 0.94531-2.1328 2.1328l0.003906 2.5117c0 1.1641 0.94531 2.1289 2.1289 2.1289z" />
      </g>
    </svg>
  </div>
);

MpcIcon.propTypes = propTypes;

MpcIcon.defaultProps = defaultProps;

export default MpcIcon;
