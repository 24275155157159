/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type TrackDesktop_track$ref = any;
type TrackMobile_track$ref = any;
import type { FragmentReference } from 'relay-runtime';
declare export opaque type TrackPlayer_track$ref: FragmentReference;
export type TrackPlayer_track = {|
  +$fragmentRefs: (TrackDesktop_track$ref & TrackMobile_track$ref),
  +$refType: TrackPlayer_track$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "TrackPlayer_track",
  "type": "Track",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "TrackDesktop_track",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "TrackMobile_track",
      "args": null
    }
  ]
};
(node/*: any*/).hash = 'd957b9f613633ad83152881f27454fc9';
module.exports = node;
