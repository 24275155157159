import isNil from "lodash/isNil";
import { avatarImageUrl, trackUrl } from "config";

const production = process.env.REACT_APP_ENV === 'production';

/* eslint-disable no-confusing-arrow */
export default (fileUrl, picture) => {
  if (isNil(fileUrl) || isNil(picture)) {
    return `${avatarImageUrl}placeholder.png`;
  }
  return production
    ? `${trackUrl}${fileUrl}/${picture}.jpg`
    : `${trackUrl}${fileUrl}/image.jpg`;
};

export const displaySmallImage = (fileUrl, picture) =>
  isNil(fileUrl) || isNil(picture)
    ? `${avatarImageUrl}placeholder.png`
    : `${trackUrl}${fileUrl}/${picture}_sm.jpg`;
