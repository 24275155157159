import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as checkoutActions from 'actions/checkout';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import TextInput from 'components/common/TextInput';

const validate = (values) => {
  const errors = {};
  if (!values.guest) {
    errors.guest = 'Your email must be present';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.guest)) {
    errors.guest = 'Your email must be a valid address';
  }

  return errors;
};

class GuestForm extends Component {
  static propTypes = {
    addGuestEmail: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    next: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  handleSubmit = (data) => {
    const { addGuestEmail, history: { push }, next } = this.props;
    const { from } = next || { from: { pathname: '/', hash: '' } };
    addGuestEmail(data.guest);
    push(from);
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit(this.handleSubmit)}>
        <Field
          name="guest"
          label="Email"
          placeholder="Email address"
          component={TextInput}
        />
        <button className="btn">Continue</button>
      </form>
    );
  }
}

export default compose(
  connect(null, checkoutActions),
  reduxForm({
    form: 'GuestForm',
    validate,
  }),
  withRouter,
)(GuestForm);
