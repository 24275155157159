import React from 'react';
// import basicExample from '../../markdown/basic-example.html';

const BasicLicenseExample = () => (
  <div />
  // <div
  //   className="markdown"
  //   dangerouslySetInnerHTML={{ __html: basicExample }}
  // />
);

export default BasicLicenseExample;
