import React from 'react';
import graphql from 'babel-plugin-relay/macro'
import { createFragmentContainer } from 'react-relay';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  track: PropTypes.object.isRequired,
  number: PropTypes.number,
  style: PropTypes.object,
  containerStyle: PropTypes.object,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  showNoTags: PropTypes.bool,
};

const defaultProps = {
  number: 0,
  style: null,
  containerStyle: null,
  className: '',
  containerClassName: '',
  showNoTags: false,
};

const Hashtags = ({
  track,
  number,
  style,
  containerStyle,
  containerClassName,
  className,
  showNoTags,
}) => (
  <div style={containerStyle} className={containerClassName}>
    {track.hashtags.edges.length
      ? number
        ? track.hashtags.edges.slice(0, number).map(hash => (
          <div
            key={hash.node.tag}
            className="text-xs border border-gray-dark rounded-full ml-2 px-2"
          >
            <Link
              className={classnames(className, 'link')}
              style={style}
              onClick={e => e.stopPropagation()}
              to={`/tags/${hash.node.tag}`}
            >
              {`#${hash.node.tag}`}
            </Link>
          </div>
          ))
        : track.hashtags.edges.map(hash => (
          <Link
            key={hash.node.tag}
            style={style}
            className={classnames(className, 'mr-2 link')}
            to={`/tags/${hash.node.tag}`}
          >
            {`#${hash.node.tag}`}
          </Link>
          ))
      : showNoTags && <span>This track has no tags</span>}
  </div>
);

Hashtags.propTypes = propTypes;
Hashtags.defaultProps = defaultProps;

export default createFragmentContainer(Hashtags, {
  track: graphql`
    fragment Hashtags_track on Track {
      id
      hashtags: hashtagsByTrackId(first: 100)
        @connection(key: "Hashtags_hashtags") {
        edges {
          node {
            nodeId
            tag
            trackId
            createdAt
          }
        }
      }
    }
  `,
});
