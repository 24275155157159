import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';

const propTypes = {
  s3Context: PropTypes.object.isRequired,
};

const CreateTrackProgress = ({ s3Context }) => {
  const displayedFiles = () => {
    const files = s3Context.uploads.reduce((result, upload) => {
      if (upload.id.search('tracks/images') > -1) {
        return result;
      } else if (
        upload.status === 'uploading' ||
        upload.status === 'uploaded'
      ) {
        result.push({
          name: upload.name,
          id: upload.id,
          progress: upload.progress,
          size: upload.size,
          loaded: upload.loaded,
        });
      }

      return result;
    }, []);

    if (!files.length) {
      return [];
    } else {
      const stemExists = files.filter(file => file.id.indexOf('_stems') > -1);
      if (stemExists.length) {
        const stemsReducer = (acc, cv) => [
          {
            name: acc.name,
            id: acc.id,
            progress: (acc.loaded + cv.loaded) / (acc.size + cv.size),
          },
        ];

        return files.reduce(stemsReducer);
      } else {
        return files;
      }
    }
  };

  const aggregateProgress = () => {
    const n = Object.keys(get(s3Context, 'metadata.requiredFiles', {})).length;
    if (n) {
      let weightedTotal = 0;
      s3Context.uploads.forEach((u) => {
        weightedTotal += (u.progress || 0) / n;
      });
      return Math.round(weightedTotal * 100) / 100;
    } else {
      return 0;
    }
  };

  const allFilesDone = () =>
    displayedFiles().length &&
    displayedFiles().every(file => file.progress === 1);

  const renderUploading = (uploadStage, value) => (
    <div>
      <div className="pb-1">
        <p className="inline-block">{uploadStage}</p>
        {value >= 100 && (
          <i
            className="fa fa-check text-green inline-block pl-2"
            style={{ lineHeight: '16px' }}
          />
        )}
      </div>
      <LinearProgress
        variant={value === undefined ? 'indeterminate' : 'determinate'}
        value={value}
        style={{
          padding: 4,
          margin: '5px 0px',
          borderRadius: 5,
        }}
      />
    </div>
  );

  const UploadProgress = () => {
    if (!get(s3Context, 'uploads.length')) {
      return renderUploading('Merging Audio...');
    }
    const progress = aggregateProgress();
    const uploadStage = progress < 1 ? 'Uploading...' : 'Upload Complete';
    return renderUploading(uploadStage, progress * 100);
  };

  return (
    <div className="mb-6">
      <div className="text-center">
        {get(s3Context, 'metadata.preview') && (
          <div className="mb-4">
            <p className="pb-2">Preview Your Track:</p>
            <audio controls controlsList="nodownload" className="w-4/5">
              <source src={get(s3Context, 'metadata.preview')} />
            </audio>
            {/* Pause soundbar when you play this shit */}
          </div>
        )}
        {UploadProgress()}
      </div>
    </div>
  );
};

CreateTrackProgress.propTypes = propTypes;

export default CreateTrackProgress;
