import React from 'react';
import SignUpSuccess from 'components/SignUp/SignUpSuccess';

const SignUpSuccessPage = () => (
  <div id="sign-up-success-page">
    <SignUpSuccess />
  </div>
);

export default SignUpSuccessPage;
