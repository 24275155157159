import React from 'react';
import PreCheckout from 'components/Checkout/PreCheckout';

const PreCheckoutPage = () => (
  <div id="pre-checkout-page">
    <PreCheckout />
  </div>
);

export default PreCheckoutPage;
