/**
 * @flow
 * @relayHash ec610bccff106c25b9dec653d7a4fced
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Onboarding_albums$ref = any;
type Onboarding_artists$ref = any;
type Onboarding_talents$ref = any;
type Onboarding_userAlbums$ref = any;
type Onboarding_userArtists$ref = any;
type Onboarding_userTalents$ref = any;
export type OnboardingPageQueryVariables = {|
  userTalent: {
    userId?: ?any,
    talentId?: ?string,
    createdAt?: ?any,
  },
  userArtist: {
    publicId?: ?any,
    userId?: ?any,
    artistId?: ?string,
    createdAt?: ?any,
  },
  userAlbum: {
    userId?: ?any,
    albumId?: ?string,
    createdAt?: ?any,
  },
|};
export type OnboardingPageQueryResponse = {|
  +talents: ?{|
    +$fragmentRefs: Onboarding_talents$ref,
  |},
  +albums: ?{|
    +$fragmentRefs: Onboarding_albums$ref,
  |},
  +artists: ?{|
    +$fragmentRefs: Onboarding_artists$ref,
  |},
  +userTalents: ?{|
    +$fragmentRefs: Onboarding_userTalents$ref,
  |},
  +userAlbums: ?{|
    +$fragmentRefs: Onboarding_userAlbums$ref,
  |},
  +userArtists: ?{|
    +$fragmentRefs: Onboarding_userArtists$ref,
  |},
|};
*/


/*
query OnboardingPageQuery(
  $userTalent: UserTalentCondition!
  $userArtist: UserArtistCondition!
  $userAlbum: UserAlbumCondition!
) {
  talents: allTalents {
    ...Onboarding_talents
  }
  albums: allAlbums {
    ...Onboarding_albums
  }
  artists: allArtists {
    ...Onboarding_artists
  }
  userTalents: allUserTalents(condition: $userTalent) {
    ...Onboarding_userTalents
  }
  userAlbums: allUserAlbums(condition: $userAlbum) {
    ...Onboarding_userAlbums
  }
  userArtists: allUserArtists(condition: $userArtist) {
    ...Onboarding_userArtists
  }
}

fragment Onboarding_talents on TalentsConnection {
  edges {
    node {
      id
      publicId
      name
      imageUrl
    }
  }
}

fragment Onboarding_albums on AlbumsConnection {
  edges {
    node {
      id
      publicId
      name
      artist
      genre
      imageUrl
    }
  }
}

fragment Onboarding_artists on ArtistsConnection {
  edges {
    node {
      id
      publicId
      name
      imageUrl
    }
  }
}

fragment Onboarding_userTalents on UserTalentsConnection {
  edges {
    node {
      userId
      talentId
    }
  }
}

fragment Onboarding_userAlbums on UserAlbumsConnection {
  edges {
    node {
      userId
      albumId
    }
  }
}

fragment Onboarding_userArtists on UserArtistsConnection {
  edges {
    node {
      userId
      artistId
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "userTalent",
    "type": "UserTalentCondition!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "userArtist",
    "type": "UserArtistCondition!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "userAlbum",
    "type": "UserAlbumCondition!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "condition",
    "variableName": "userTalent",
    "type": "UserTalentCondition"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "condition",
    "variableName": "userAlbum",
    "type": "UserAlbumCondition"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "condition",
    "variableName": "userArtist",
    "type": "UserArtistCondition"
  }
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publicId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "imageUrl",
  "args": null,
  "storageKey": null
},
v8 = [
  v4,
  v5,
  v6,
  v7
],
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "userId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "OnboardingPageQuery",
  "id": null,
  "text": "query OnboardingPageQuery(\n  $userTalent: UserTalentCondition!\n  $userArtist: UserArtistCondition!\n  $userAlbum: UserAlbumCondition!\n) {\n  talents: allTalents {\n    ...Onboarding_talents\n  }\n  albums: allAlbums {\n    ...Onboarding_albums\n  }\n  artists: allArtists {\n    ...Onboarding_artists\n  }\n  userTalents: allUserTalents(condition: $userTalent) {\n    ...Onboarding_userTalents\n  }\n  userAlbums: allUserAlbums(condition: $userAlbum) {\n    ...Onboarding_userAlbums\n  }\n  userArtists: allUserArtists(condition: $userArtist) {\n    ...Onboarding_userArtists\n  }\n}\n\nfragment Onboarding_talents on TalentsConnection {\n  edges {\n    node {\n      id\n      publicId\n      name\n      imageUrl\n    }\n  }\n}\n\nfragment Onboarding_albums on AlbumsConnection {\n  edges {\n    node {\n      id\n      publicId\n      name\n      artist\n      genre\n      imageUrl\n    }\n  }\n}\n\nfragment Onboarding_artists on ArtistsConnection {\n  edges {\n    node {\n      id\n      publicId\n      name\n      imageUrl\n    }\n  }\n}\n\nfragment Onboarding_userTalents on UserTalentsConnection {\n  edges {\n    node {\n      userId\n      talentId\n    }\n  }\n}\n\nfragment Onboarding_userAlbums on UserAlbumsConnection {\n  edges {\n    node {\n      userId\n      albumId\n    }\n  }\n}\n\nfragment Onboarding_userArtists on UserArtistsConnection {\n  edges {\n    node {\n      userId\n      artistId\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "OnboardingPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "talents",
        "name": "allTalents",
        "storageKey": null,
        "args": null,
        "concreteType": "TalentsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "Onboarding_talents",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "albums",
        "name": "allAlbums",
        "storageKey": null,
        "args": null,
        "concreteType": "AlbumsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "Onboarding_albums",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "artists",
        "name": "allArtists",
        "storageKey": null,
        "args": null,
        "concreteType": "ArtistsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "Onboarding_artists",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "userTalents",
        "name": "allUserTalents",
        "storageKey": null,
        "args": v1,
        "concreteType": "UserTalentsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "Onboarding_userTalents",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "userAlbums",
        "name": "allUserAlbums",
        "storageKey": null,
        "args": v2,
        "concreteType": "UserAlbumsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "Onboarding_userAlbums",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "userArtists",
        "name": "allUserArtists",
        "storageKey": null,
        "args": v3,
        "concreteType": "UserArtistsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "Onboarding_userArtists",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OnboardingPageQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "talents",
        "name": "allTalents",
        "storageKey": null,
        "args": null,
        "concreteType": "TalentsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "TalentsEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Talent",
                "plural": false,
                "selections": v8
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "albums",
        "name": "allAlbums",
        "storageKey": null,
        "args": null,
        "concreteType": "AlbumsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "AlbumsEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Album",
                "plural": false,
                "selections": [
                  v4,
                  v5,
                  v6,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "artist",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "genre",
                    "args": null,
                    "storageKey": null
                  },
                  v7
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "artists",
        "name": "allArtists",
        "storageKey": null,
        "args": null,
        "concreteType": "ArtistsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ArtistsEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Artist",
                "plural": false,
                "selections": v8
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "userTalents",
        "name": "allUserTalents",
        "storageKey": null,
        "args": v1,
        "concreteType": "UserTalentsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "UserTalentsEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "UserTalent",
                "plural": false,
                "selections": [
                  v9,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "talentId",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "userAlbums",
        "name": "allUserAlbums",
        "storageKey": null,
        "args": v2,
        "concreteType": "UserAlbumsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "UserAlbumsEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "UserAlbum",
                "plural": false,
                "selections": [
                  v9,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "albumId",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "userArtists",
        "name": "allUserArtists",
        "storageKey": null,
        "args": v3,
        "concreteType": "UserArtistsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "UserArtistsEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "UserArtist",
                "plural": false,
                "selections": [
                  v9,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "artistId",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
(node/*: any*/).hash = 'fab7c3ddf0f9567bd6fc68bebf173e5b';
module.exports = node;
