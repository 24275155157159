import React from 'react';
import PropTypes from 'prop-types';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import Download from 'components/common/TrackPlayer/TrackButtons/Download';
import Buy from 'components/common/TrackPlayer/TrackButtons/Buy';

const propTypes = {
  track: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  togglePlay: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  purchasedType: PropTypes.string,
  mine: PropTypes.bool,
  isPlaying: PropTypes.bool,
};

const defaultProps = {
  purchasedType: '',
  mine: false,
  isPlaying: false,
};

const renderPurgatoryInfo = (
  <Link to="/stripe/connect" className="ml-1 link">
    (
    <span data-tip data-for="track-purgatory">
      *
    </span>
    )
    <ReactTooltip
      id="track-purgatory"
      type="warning"
      effect="solid"
      place="top"
    >
      <span>To sell this beat, you must sign up with Stripe.</span>
    </ReactTooltip>
  </Link>
);

const TrackBody = ({
  track,
  user,
  mine,
  isPlaying,
  togglePlay,
  onEdit,
  purchasedType,
}) => {
  const downloadable = mine || purchasedType;
  const editSection =
    mine &&
    (track.locked ? (
      <span>
        (
        <span data-tip data-for="track-locked">
          *
        </span>
        )
        <ReactTooltip id="track-locked" type="dark" effect="solid" place="top">
          <span>Purchased tracks cannot be edited</span>
        </ReactTooltip>
      </span>
    ) : (
      <span>
        <span
          role="button"
          tabIndex={0}
          onClick={onEdit}
          className="cursor-pointer inline-flex"
        >
          <i className="fa fa-pencil text-sm" aria-hidden="true" />
        </span>
      </span>
    ));
  return (
    <div className="flex">
      <button
        className="outline-none mr-2 cursor-pointer rounded-full noFocusedOutline"
        style={{
          width: '34px',
          height: '34px',
          border: '1px solid #15a992',
          backgroundColor: '#03d1b2',
        }}
        onClick={togglePlay}
      >
        <i
          className={classnames(
            'fa mx-auto text-white',
            { 'fa-pause': isPlaying },
            { 'fa-play': !isPlaying },
          )}
          aria-hidden="true"
          style={{
            fontSize: isPlaying ? '16px' : '20px',
            marginLeft: isPlaying ? 'auto' : '3px',
          }}
        />
      </button>
      <div role="presentation" onClick={e => e.stopPropagation()}>
        <div className="inline-block">
          <Link
            to={`/tracks/${track.publicId}`}
            className="pr-1 pb-1 text-sm inline-block"
          >
            <span className="font-semibold text-black">
              {track.name}{' '}
              {purchasedType && (
                <span className="font-bold">{purchasedType}</span>
              )}
            </span>
          </Link>
          {editSection}
          {mine && track.purgatory && renderPurgatoryInfo}
        </div>
        <div className="flex">
          <Link
            className="text-xs font-semibold text-gray-darker pb-1 block"
            to={`/${user.username}`}
          >
            {user.displayName}
          </Link>
          {!track.mp3Loaded && (
            <div className="font-light ml-4">Processing ...</div>
          )}
        </div>
        {/* <Hashtags
          containerClassName="pro-trkplayer-hashtags"
          track={track}
          number={3}
        /> */}
      </div>
      <div className="ml-auto cursor-pointer">
        {downloadable ? (
          <Download
            className="border rounded border-blue-light text-sm font-bold px-3 py-1 tracking-wide text-blue-dark inline-block"
            track={track}
            purchasedType={purchasedType}
          />
        ) : (
          <Buy
            className="border rounded bg-blue border-blue text-sm font-bold px-3 py-1 tracking-wide text-white inline-block"
            track={track}
          />
        )}
      </div>
    </div>
  );
};

TrackBody.propTypes = propTypes;
TrackBody.defaultProps = defaultProps;

export default createFragmentContainer(
  TrackBody,
{  track: graphql`
    fragment TrackBody_track on Track {
      id
      name
      locked
      publicId
      purgatory
      mp3Loaded
      ...Hashtags_track
      ...Download_track
      ...Buy_track
    }
  `,
  user: graphql`

  fragment TrackBody_user on User {
    username
    displayName
  }
  `,}
);
