import socketClient from 'socket.io-client';
import { apiUrl } from 'config';

export default function setupRealtime(dispatch, actions, token) {
  const io = socketClient(apiUrl, { path: '/ws', query: `token=${token}` });

  io.on('projects-changed', (change) => {
    console.log('change client:', change);
    if (!change.old_val) {
      dispatch(actions.addProjectSuccess(change.new_val));
    } else if (!change.new_val) {
      dispatch(actions.deleteProjectSuccess(change.old_val.id));
    } else {
      dispatch(actions.updateProjectSuccess(change.new_val));
    }
  });

  io.on('tracks-changed', (change) => {
    console.log('change client:', change);

    if (!change.old_val) {
      dispatch(actions.addTrackSuccess(change.new_val));
    } else if (!change.new_val) {
      dispatch(actions.deleteTrackSuccess(change.old_val.id));
    } else {
      dispatch(actions.updateTrackSuccess(change.new_val));
    }
  });

  io.on('clips-changed', (change) => {
    console.log('change client:', change);

    if (!change.old_val) {
      dispatch(actions.addClipSuccess(change.new_val));
    } else if (!change.new_val) {
      dispatch(actions.deleteClipSuccess(change.old_val.id));
    } else {
      dispatch(actions.updateClipSuccess(change.new_val));
    }
  });

  io.on('posts-changed', (change) => {
    console.log('change client:', change);

    if (!change.old_val) {
      dispatch(actions.addPostSuccess(change.new_val));
    }
  });

  io.on('received-changed', (change) => {
    console.log('change client:', change);

    if (!change.old_val) {
      dispatch(actions.addInviteReceivedSuccess(change.new_val));
    } else {
      dispatch(actions.updateInviteSuccess(change.new_val));
    }
  });

  return io;
}
