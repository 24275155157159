import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { AudioSlider, AudioTime } from 'utils/AudioControls';

const propTypes = {
  size: PropTypes.string.isRequired,
};

const AudioSliderSm = ({ size }) => {
  const showSlider = size !== 'default' && size !== 'sm';

  return showSlider ? (
    <div
      className="inline-flex items-center flex-1"
      style={{ flexBasis: '80%' }}
    >
      <div className="flex-1 mx-2">
        <AudioSlider />
      </div>
      <AudioTime type="current-time" />
      <p className="text-sm ml-1">/</p>
      <AudioTime type="duration" />
    </div>
  ) : null;
};

AudioSliderSm.propTypes = propTypes;

export default compose(
  connect(state => ({
    size: state.windowSession.size,
  })),
)(AudioSliderSm);
