import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

const propTypes = {
  toolConfig: PropTypes.object.isRequired,
};

const MyMusic = ({ toolConfig }) => (
  <Link
    to="/projects"
    className="flex justify-center items-center nowrap mlr-8"
    data-tip
    data-for={'myMusic'}
  >
    <i className="material-icons color-bg fs-24 icon-opacity">
      library_music
    </i>
    <ReactTooltip
      id={'myMusic'}
      type={toolConfig.type}
      effect={toolConfig.effect}
      delayShow={toolConfig.delayShow}
      className="tooltip"
    >
      <span>My Music</span>
    </ReactTooltip>
  </Link>
);

MyMusic.propTypes = propTypes;

export default MyMusic;
