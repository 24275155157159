import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';
import actionCreators from 'actions/users';

class Validation extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    validateUserAsync: PropTypes.func.isRequired,
  };

  componentWillMount() {
    const {
      location: { search },
      history: { push },
      validateUserAsync,
    } = this.props;
    const query = qs.parse(search);
    const expired = new Date(parseInt(query.exp)); // eslint-disable-line
    const now = new Date();
    if (!query.token) window.location = '/';
    const token = decodeURIComponent(
      Buffer.from(query.token, 'base64').toString('ascii'),
    );
    if (expired > now) {
      const body = Object.assign({}, query, { token });
      validateUserAsync(body)
        .then(() => {
          push('/validate/success');
        })
        .catch(() => {
          push('/validate/error');
        });
    } else {
      push('/validate/error');
    }
  }

  render() {
    return (
      <div>
        <h2>Verifying email...</h2>
      </div>
    );
  }
}

export default compose(
  connect(state => ({ users: state.users }), actionCreators),
  withRouter,
)(Validation);
