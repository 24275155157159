import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';

const propTypes = {
  location: PropTypes.object.isRequired,
  soundbarVisible: PropTypes.bool.isRequired,
  auth: PropTypes.object.isRequired,
};

const footerItems = [
  // { id: 'about', link: '/about', title: 'About' },
  { id: 'support', link: '/contact-us', title: 'Support' },
  { id: 'privacy', link: '/privacy', title: 'Privacy' },
  { id: 'terms', link: '/terms', title: 'Terms' },
  { id: 'sellbeats', link: '/sellbeats', title: 'Sell Beats' },
];

const soundbarHeight = 58;

const Footer = ({ location: { pathname }, soundbarVisible, auth: { isAuthenticated } }) => {
  const isLanding = pathname === '/' && !isAuthenticated;
  console.log('pathname', pathname);
  return (
    <footer
      id="footer"
      className={classnames('p-4', { 'gorilla-bg': isLanding })}
      style={{ marginBottom: soundbarVisible && soundbarHeight }}
    >
      <div className="container flex flex-wrap md:w-4/5 mx-auto md:justify-start justify-center items-end">
        <ul className="flex flex-wrap -mx-4">
          {footerItems.map(({ id, link, title }) => (
            <li key={id} className="mx-4 my-3 inline-block">
              <Link className={classnames('text-sm', { 'text-white': isLanding })} to={link}>
                {title}
              </Link>
            </li>
          ))}
        </ul>
        <div className={classnames('my-3 mx-4 text-sm', { 'text-white': isLanding })}>
          © 2018 Plug Music
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = propTypes;

export default compose(
  withRouter,
  connect(state => ({
    soundbarVisible: state.soundbar.visible,
    auth: state.auth,
  })),
)(Footer);
