import React from 'react';
import PropTypes from 'prop-types';
import createFragContainer from 'utils/create-frag-container';
import graphql from 'babel-plugin-relay/macro';
// import graphql from 'babel-plugin-relay/macro';
import MoodCategory from './MoodCategory';

const genreMoodFrag = graphql`
  fragment GenresAndMoodsCategory_genres on GenresConnection {
    edges {
      node {
        genreId: id
        genre
      }
    }
  }
`;

const otherFrag = graphql`

  fragment GenresAndMoodsCategory_moods on MoodsConnection {
    edges {
      node {
        mood
      }
    }
  }
`;


const propTypes = {
  moods: PropTypes.object.isRequired,
  genres: PropTypes.object.isRequired,
};

const GenresAndMoodsCategory = ({ moods, genres }) => (
  <div className="mt-5 w-9/10 mx-auto md:w-desk">
    <div>Moods</div>
    {moods.edges.map(mood => <MoodCategory mood={mood.node.mood} />)}
  </div>
  );

GenresAndMoodsCategory.propTypes = propTypes;

export default createFragContainer([genreMoodFrag, otherFrag])(GenresAndMoodsCategory);
