import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'components/common/forms/TextField';

const fields = ['password'];
const validate = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = 'Required';
  }
  if (!values.displayName) {
    errors.displayName = 'Required';
  }
  return errors;
};

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
};

const PasswordForm = ({
  handleSubmit,
  pristine,
  submitting,
  error,
}) => (
  <Paper
    zDepth={1}
    rounded={false}
    style={{
      backgroundColor: '#fff',
      zIndex: 4,
      padding: 15,
      textAlign: 'center',
      width: 300,
    }}
  >
    <h3 style={{ fontSize: '21px', fontWeight: 300 }}>Add your password</h3>
    <form onSubmit={handleSubmit}>
      <Field
        name="displayName"
        label="display name"
        type="text"
        component={TextField}
      />
      <Field
        name="password"
        label="password"
        type="password"
        component={TextField}
      />
      <RaisedButton
        type="submit"
        style={{ marginTop: 10 }}
        label="Submit"
        disabled={pristine || submitting || error}
        backgroundColor="#D7343B"
      />
    </form>
  </Paper>
);

PasswordForm.propTypes = propTypes;

export default reduxForm({
  form: 'password',
  fields,
  validate,
})(PasswordForm);
