import graphql from 'babel-plugin-relay/macro';
import { commitMutation } from 'react-relay';

const mutation = graphql`
  mutation CreateLikeMutation($input: CreateLikeInput!) {
    createLike(input: $input) {
      track: trackByTrackId {
        ...LikesLink_track
      }
    }
  }
`;

const commit = (environment, like, cb) =>
  commitMutation(environment, {
    mutation,
    variables: { input: { like } },
    onCompleted: (response) => {
      if (cb) cb(response);
    },
  });

export default commit;
