import React from 'react';
import PropTypes from 'prop-types';
import graphql from 'babel-plugin-relay/macro';
import createFragContainer from 'utils/create-frag-container';
import TrackPlayer from 'components/common/TrackPlayer';

const purchasedFrag = graphql`
  fragment PurchasedTracks_user on User {
    id
    products: purchasedProducts(first: 100) @connection(key: "PurchasedTracks_products") {
      totalCount
      edges {
        node {
          price
          type
          track: trackByTrackId {
            id
            name
            picture
            fileUrl
            description
            publicId
            userId
            user: userByUserId {
              id
              username
              displayName
              ...TrackPlayer_user
            }
            ...TrackPlayer_track
          }
        }
      }
    }
  }
`;

const propTypes = {
  user: PropTypes.object.isRequired,
};

const PurchasedTracks = ({ user }) => (
  <div id="purchased-tracks">
    <div className="subtitle">Purchases</div>
    <ul className="sm:mx-6 my-4">
      {user.products.edges.map(productEdge => (
        <TrackPlayer
          key={productEdge.node.id}
          className="pt-4 pb-2 sm:pb-4 sm:pt-0"
          track={productEdge.node.track}
          tracks={user.products.edges.map(edge => edge.node.track)}
          currentUser={user.id}
          user={productEdge.node.track.user}
          purchasedType={productEdge.node.type}
        />
      ))}
    </ul>
  </div>
);

PurchasedTracks.propTypes = propTypes;

export default createFragContainer([purchasedFrag], PurchasedTracks);
