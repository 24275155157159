import { CHANGE_WINDOW_SETTINGS } from "types/windowSession";

export const changeWindowSettings = (payload) => {
  console.log("window settings", payload);
  return {
    type: CHANGE_WINDOW_SETTINGS,
    payload,
  };
};

export default module.exports;
