import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { injectStripe } from 'react-stripe-elements';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import ls from 'local-storage';
import { createCharge } from 'rest/stripe';
import * as checkoutActions from 'actions/checkout';
import * as toastActions from 'actions/toasts';
import * as cartActions from 'actions/cart';
import { addNotification } from 'utils/notifications';
import EmailInput from './EmailInput';
import CardSection from './CardSection';

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Your email address is required.';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Your email address is invalid.';
  }

  return errors;
};

class CheckoutForm extends Component {
  static propTypes = {
    productIds: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    stripe: PropTypes.object.isRequired,
    checkout: PropTypes.object.isRequired,
    checkoutLoading: PropTypes.func.isRequired,
    checkoutSuccess: PropTypes.func.isRequired,
    checkoutFailure: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    refreshCart: PropTypes.func.isRequired,
  };

  state = { error: '', loading: false };

  handleSubmit = (data) => {
    const {
      stripe: { createToken },
      productIds,
      history: { push },
      checkoutLoading,
      checkoutSuccess,
      checkoutFailure,
      showMessage,
      refreshCart,
    } = this.props;
    createToken().then(({ token, error }) => {
      if (token) {
        const chargeData = {
          tokenId: token.id,
          products: productIds.join(','),
          guest: data.email,
        };
        checkoutLoading();
        createCharge(chargeData)
          .then((order) => {
            // add notification
            // order.products.forEach((prod) => {
            //   addNotification({
            //     userId: prod.userId,
            //     notification: {
            //       sender: { type: 'guest', name: data.email },
            //       action: 'purchased your beat',
            //       object: {
            //         slug: `/tracks/${prod.publicId}`,
            //         name: `${data.email} -- ${prod.productType}`,
            //       },
            //     },
            //   });
            // });
            checkoutSuccess();
            refreshCart();
            const orderToken = JSON.stringify({
              orderId: order.id,
              guest: data.email,
            });
            ls('otoken', orderToken);

            // window.gtag('event', 'Purchase', {
            //   event_category: 'Checkout',
            //   event_label: order.id,
            // });
            window.location.replace(`${window.location.origin}/orders/${order.id}/delivery`);
            // push(`/orders/${order.id}/delivery`);
          })
          .catch((err) => {
            showMessage({ text: err.message, type: 'error' });
            checkoutFailure();
          });
      } else if (error) {
        showMessage({
          text: 'Something happened on our end. We are looking into it!',
          type: 'error',
        });
        checkoutFailure();
        this.setState({ error: 'We have an error!' });
      }
    });
  };

  render() {
    const { error } = this.state;
    const { checkout, handleSubmit } = this.props;
    if (checkout.get('loading')) return <div>Loading ...</div>;
    return (
      <form
        id="checkout-form"
        className="mt-8 mb-3"
        onSubmit={handleSubmit(this.handleSubmit)}
      >
        <Field
          name="email"
          type="email"
          label="Email address"
          style={{ maxWidth: '500px' }}
          component={EmailInput}
        />
        <CardSection error={error} />
        <button className="btn w-full">Confirm</button>
      </form>
    );
  }
}

export default compose(
  injectStripe,
  withRouter,
  connect(
    state => ({ checkout: state.checkout }),
    { ...checkoutActions, ...toastActions, ...cartActions },
  ),
  reduxForm({
    form: 'CheckoutForm',
    validate,
  }),
)(CheckoutForm);
