import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const propTypes = {
  selectedTalents: PropTypes.array,
  selectedAlbums: PropTypes.array,
  selectedArtists: PropTypes.array,
};

const defaultProps = {
  selectedTalents: [],
  selectedAlbums: [],
  selectedArtists: [],
};

const OnboardingOverview = ({
  selectedTalents,
  selectedAlbums,
  selectedArtists,
}) => (
  <div className="overflow-auto relative" style={{ height: '80%', top: 50 }}>
    {selectedTalents.length > 0 ? (
      <div
        className="mt-3 overflow-auto p-4 flex flex-wrap space-around"
        id="talents"
      >
        <Paper className="center" style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}>
          {selectedTalents.map(node => (
            <div className="inline-flex p-4 justify-center" key={node.id}>
              <Card className="w-48">
                <CardActionArea
                  style={{
                    outline: 'none',
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="Artist"
                    className="cover"
                    height="140"
                    image={node.imageUrl}
                    title={node.name}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {node.name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          ))}
        </Paper>
      </div>
    ) : (
      <div
        className="mt-3 overflow-auto p-4 flex flex-wrap space-around"
        id="talents"
        style={{ color: 'white' }}
      >
        <Typography gutterBottom variant="h5" component="h2" color="inherit">
          {selectedArtists.length || selectedAlbums.length
            ? 'You have not chosen any talents you possess.'
            : 'You have no talent.'}
        </Typography>
      </div>
    )}
    {selectedAlbums.length ? (
      <div className="mt-3 overflow-auto p-4 flex flex-wrap space-around">
        <Paper
          className="center"
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}
        >
          {selectedAlbums.map(node => (
            <div className="inline-flex p-4" key={node.id}>
              <Card className="w-48">
                <CardActionArea
                  style={{
                    outline: 'none',
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="Artist"
                    className="cover"
                    height="140"
                    image={node.imageUrl}
                    title={node.name}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {node.name}
                    </Typography>
                    <Typography component="p">{node.artist}</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          ))}
        </Paper>
      </div>
    ) : (
      <div
        className="mt-3 overflow-auto p-4 flex flex-wrap space-around"
        id="talents"
        style={{ color: 'white' }}
      >
        <Typography gutterBottom variant="h5" component="h2" color="inherit">
          {selectedArtists.length || selectedTalents.length
            ? 'You have not chosen any albums that inspire you.'
            : 'You have no inspiration.'}
        </Typography>
      </div>
    )}
    {selectedArtists.length ? (
      <div className="mt-3 overflow-auto p-4 flex flex-wrap space-around">
        <Paper
          className="center"
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}
        >
          {selectedArtists.map(node => (
            <div className="inline-flex p-4" key={node.id}>
              <Card className="w-48">
                <CardActionArea
                  style={{
                    outline: 'none',
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="Artist"
                    className="cover"
                    height="140"
                    image={node.imageUrl}
                    title={node.name}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {node.name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          ))}
        </Paper>
      </div>
    ) : (
      <div
        className="mt-3 overflow-auto p-4 flex flex-wrap space-around"
        id="talents"
        style={{ color: 'white' }}
      >
        <Typography gutterBottom variant="h5" component="h2" color="inherit">
          {selectedAlbums.length || selectedTalents.length
            ? 'You have not chosen any artists that feed your vision.'
            : 'You have no vision.'}
        </Typography>
      </div>
    )}
    {!selectedAlbums.length &&
      !selectedArtists.length &&
      !selectedTalents.length && (
        <div
          className="mt-3 overflow-auto p-4 flex flex-wrap space-around"
          id="talents"
        >
          <Typography gutterBottom variant="h5" component="h2">
            {'You are nothing. What are you doing with your life?'}
          </Typography>
        </div>
      )}
  </div>
);

OnboardingOverview.propTypes = propTypes;
OnboardingOverview.defaultProps = defaultProps;

export default OnboardingOverview;
