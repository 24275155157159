import types from 'types/session';

export function zoomIn() {
  return { type: types.ZOOM_IN };
}

export function zoomOut() {
  return { type: types.ZOOM_OUT };
}

export function changeTimeGrid(bpm) {
  return {
    type: types.CHANGE_TIMEGRID,
    payload: {
      property: 'bpm',
      value: bpm,
    },
  };
}

export function snapToGrid(snap) {
  return {
    type: types.SNAP_TO_GRID,
    payload: {
      property: 'snap',
      value: snap,
    },
  };
}

export const resetSession = () => ({ type: types.RESET_SESSION });

export default module.exports;
