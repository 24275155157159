import React from 'react';
import PropTypes from 'prop-types';
import { isDesktop } from 'utils/screentype';
import classnames from 'classnames';
import TrackPlayer from 'components/common/ApolloTrackPlayer';

const propTypes = {
  tracks: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

const TrackCategory = ({ tracks, title }) => (
  <div>
    <div className="ml-4 py-8">
      <h1 className="title">{title}</h1>
    </div>
    <ul style={{ marginBottom: 20 }} className="mx-4">
      {tracks.edges.map((trackNode) => {
        const track = trackNode.node;
        return (
          <TrackPlayer
            key={track.id}
            className={classnames('md:mb-4', { 'track-li': !isDesktop() })}
            track={track}
            user={track.user}
            listStyle={{ marginBottom: 10 }}
            tracks={tracks}
          />
        );
      })}
    </ul>
  </div>
);

TrackCategory.propTypes = propTypes;

export default TrackCategory;
