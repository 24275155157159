import React from 'react';
import Terms from 'components/Static/Terms';

const TermsPage = () => (
  <div id="terms-page" className="container">
    <Terms />
  </div>
);

export default TermsPage;
