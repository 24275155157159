import React from 'react';
import PropTypes from 'prop-types';
import Collaboration from './Collaboration';
import SuggestedProducers from './SuggestedProducers';

const propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const Sidebar = ({ auth, history }) => (
  <div className="ml-4 hidden md:block" style={{ width: '290px' }}>
    <Collaboration history={history} />
    <SuggestedProducers auth={auth} />
  </div>
);

Sidebar.propTypes = propTypes;

export default Sidebar;
