import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import TrackPlayer from 'components/common/ApolloTrackPlayer';
import TrackLayout from './TrackLayout';

const NEWEST_FRAG = gql`
  fragment NewestArrivalsCategory_tracks on TracksConnection {
    edges {
      node {
        id
        name
        picture
        fileUrl
        publicId
        description
        ...TrackPlayer_track
        user: userByUserId {
          id
          username
          displayName
          ...TrackPlayer_user
        }
      }
    }
  }
  ${TrackPlayer.fragments.track}
  ${TrackPlayer.fragments.user}
`;

const propTypes = {
  tracks: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

const NewestArrivalsCategory = ({ tracks, title }) => (
  <TrackLayout tracks={tracks} title="Newest Arrivals" />
);

NewestArrivalsCategory.propTypes = propTypes;
NewestArrivalsCategory.fragments = { tracks: NEWEST_FRAG };

export default NewestArrivalsCategory;
