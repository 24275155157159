import React from 'react';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MpcIcon from 'components/common/SvgIcons/MpcIcon';
import TrackPlayer from 'components/common/ApolloTrackPlayer';
import * as soundbarActions from 'actions/soundbar';

const propTypes = {
  user: PropTypes.object.isRequired,
  myself: PropTypes.bool.isRequired,
  currentUser: PropTypes.string,
};

const defaultProps = {
  currentUser: '',
};

const PROFILE_TRACKLIST_FRAG = gql`
  fragment ProfileTracklistDesktop_user on User {
    id
    displayName
    displayTracks: tracksByUserId(
      first: 100
      orderBy: CREATED_AT_DESC
      condition: $trackCondition
    ) @connection(key: "ProfileTracklistDesktop_displayTracks") {
      edges {
        node {
          id
          id
          name
          picture
          fileUrl
          description
          publicId
          userId
          user: userByUserId {
            id
            username
            displayName
          }
          ...TrackPlayer_track
        }
      }
    }
    ...TrackPlayer_user
  }
  ${TrackPlayer.fragments.user}
  ${TrackPlayer.fragments.track}
`;

const ProfileTracklistDesktop = ({ user, currentUser, myself }) => (
  <div className="md:ml-6 w-full relative">
    <div
      className="hidden md:inline-block inline-block text-sm border-b text-gray-darkest border-gray-darkest font-medium"
      style={{ paddingTop: '6px', paddingBottom: '6px' }}
    >
        Beats
      </div>
    {user.displayTracks.edges.length ? (
      <ul className="pt-3">
        {user.displayTracks.edges.map(track => (
          <TrackPlayer
            key={track.node.id}
            track={track.node}
            tracks={user.displayTracks.edges}
            currentUser={currentUser}
            user={user}
            className="pb-4"
          />
          ))}
      </ul>
      ) : (
        <div className="flex" style={{ height: '300px' }}>
          <MpcIcon svgClassName={'svg-mpcIcon'} />
          <div className="w-full">
            {myself ? (
              <div className="ml-6 flex flex-col">
                <div className="mb-3">{'You currently have no beats.'}</div>
                <Link
                  to="/tracks/create"
                  className="btn btn-normal py-2 text-gray-darkest font-semibold text-center"
                  style={{ width: '212px' }}
                >
                  Upload A Beat
                </Link>
              </div>
            ) : (
              <div className="ml-6 flex flex-col">
                <div className="mb-3">{`${
                  user.displayName
                } currently has no beats.`}</div>
              </div>
            )}
          </div>
        </div>
      )}
  </div>
  );

ProfileTracklistDesktop.propTypes = propTypes;
ProfileTracklistDesktop.defaultProps = defaultProps;
ProfileTracklistDesktop.fragments = { user: PROFILE_TRACKLIST_FRAG };

export default connect(null, soundbarActions)(ProfileTracklistDesktop);
