import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';
import get from 'lodash/get';
import RegisterForm from './RegisterForm';

const propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const RegisterPage = ({ location: { search }, history: { push } }) => {
  const admin = get(qs.parse(search), 'admin');
  if (admin !== 'true') push('/');
  return (
    <div className="container flex justify-center mt-12">
      <div
        className="pcont p3 lu-w30 mu-w50 w90 center"
        style={{ maxWidth: '450px', display: 'inline-block' }}
      >
        <h1 className="title center">Register</h1>
        <RegisterForm />
      </div>
    </div>
  );
};

RegisterPage.propTypes = propTypes;

export default withRouter(RegisterPage);
