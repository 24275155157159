import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import Jaguar from './Jaguar';
import LandingStaffPicks from './Panda/LandingStaffPicks';
import Panda from './Panda';
import LandingNewTracks from './Jaguar/LandingNewTracks';
import Gorilla from './Gorilla';
import LandingTopGenres from './Gorilla/LandingTopGenres';
import LandingTopMoods from './Gorilla/LandingTopMoods';

const LANDING_FRAG = gql`
  fragment LandingExplore_query on Query {
    staffPicksTracks(first: $maxTracks) {
      ...LandingStaffPicks_tracks
    }
    genresByGenreCount: genresByGenreCount(top: $maxGenres) {
      ...LandingTopGenres_genres
    }
    moodsByMoodCount: moodsByMoodCount(top: $maxMoods) {
      ...LandingTopMoods_moods
    }
    tracksByRecentlyCreated(top: $maxNewTracks) {
      ...LandingNewTracks_tracks
    }
  }
  ${LandingStaffPicks.fragments.tracks}
  ${LandingTopGenres.fragments.genres}
  ${LandingTopMoods.fragments.moods}
  ${LandingNewTracks.fragments.tracks}
`;

const propTypes = {
  query: PropTypes.object.isRequired,
  soundbarIsVisible: PropTypes.bool.isRequired,
};

/* eslint-disable react/no-find-dom-node */
class LandingExplore extends PureComponent {
  render() {
    const options = {
      sectionClassName: 'section',
      anchors: ['staffpicks', 'hottracks', 'genresmoods'],
      scrollBar: false,
      navigation: true,
      verticalAlign: false,
      sectionPaddingTop: '0px',
      sectionPaddingBottom: '0px',
      sectionPaddingRight: '0px',
      sectionPaddingLeft: '0px',
      arrowNavigation: true,
    };
    const {
      staffPicksTracks,
      tracksByRecentlyCreated,
      genresByGenreCount,
      moodsByMoodCount,
    } = this.props.query;
    const { soundbarIsVisible } = this.props;
    const soundbarHeight = '60px';
    return (
      <div>
        <Panda
          staffPicksTracks={filter(
            LandingStaffPicks.fragments.tracks,
            staffPicksTracks,
          )}
          soundbarIsVisible={soundbarIsVisible}
          soundbarHeight={soundbarHeight}
          removeOtherTracksOnPlay
        />

        <Jaguar
          tracksByRecentlyCreated={filter(
            LandingNewTracks.fragments.tracks,
            tracksByRecentlyCreated,
          )}
          soundbarIsVisible={soundbarIsVisible}
          soundbarHeight={soundbarHeight}
          removeOtherTracksOnPlay
        />
        <Gorilla
          genresByGenreCount={filter(
            LandingTopGenres.fragments.genres,
            genresByGenreCount,
          )}
          moodsByMoodCount={filter(
            LandingTopMoods.fragments.moods,
            moodsByMoodCount,
          )}
          soundbarIsVisible={soundbarIsVisible}
          soundbarHeight={soundbarHeight}
        />
      </div>
    );
  }
}

LandingExplore.propTypes = propTypes;
LandingExplore.fragments = { query: LANDING_FRAG };

export default connect(state => ({
  soundbarIsVisible: state.soundbar.visible,
}))(LandingExplore);
