export const soundbarSetVisible = visible => ({
  type: 'SOUNDBAR_SET_VISIBLE',
  visible,
});

export const soundbarSetPlaying = playing => ({
  type: 'SOUNDBAR_SET_PLAYING',
  playing,
});

export const soundbarSetProgress = progress => ({
  type: 'SOUNDBAR_SET_PROGRESS',
  progress,
});

export const soundbarUpdate = payload => ({
  type: 'SOUNDBAR_UPDATE',
  payload,
});

export const soundbarSetDuration = duration => ({
  type: 'SOUNDBAR_SET_DURATION',
  duration,
});

export const soundbarSetStalled = stalled => ({
  type: 'SOUNDBAR_SET_STALLED',
  stalled,
});

export const soundbarSetWaiting = waiting => ({
  type: 'SOUNDBAR_SET_WAITING',
  waiting,
});

export const soundbarSetSeeking = (seeking, seekPosition) => ({
  type: 'SOUNDBAR_SET_SEEKING',
  seeking,
  seekPosition,
});

export const soundbarSetSeekPosition = seekPosition => ({
  type: 'SOUNDBAR_SET_SEEKPOSITION',
  seekPosition,
});

export const soundbarUpdateTime = (update, updatedTime) => ({
  type: 'SOUNDBAR_UPDATE_TIME',
  update,
  updatedTime,
});

export const soundbarSetTrack = (track, progress) => ({
  type: 'SOUNDBAR_SET_TRACK',
  track,
  progress,
});

export const soundbarAddTracks = tracks => ({
  type: 'SOUNDBAR_ADD_TRACKS',
  tracks,
});

export const soundbarRemoveTracks = trackIds => ({
  type: 'SOUNDBAR_REMOVE_TRACKS',
  trackIds,
});

export const soundbarRemoveAllTracks = includingCurrent => ({
  type: 'SOUNDBAR_REMOVE_ALL_TRACKS',
  includingCurrent,
});

export const soundbarSetCurrentTrackIndex = trackIndex => ({
  type: 'SOUNDBAR_SET_CURRENT_TRACK_INDEX',
  trackIndex,
});

export const soundbarNextTrack = () => ({
  type: 'SOUNDBAR_NEXT_TRACK',
});

export const soundbarPreviousTrack = () => ({
  type: 'SOUNDBAR_PREVIOUS_TRACK',
});

export const soundbarToggleRepeat = () => ({
  type: 'SOUNDBAR_TOGGLE_REPEAT',
});

export const soundbarShufflePlaylist = () => ({
  type: 'SOUNDBAR_SHUFFLE_PLAYLIST',
});

export const soundbarToggleAutoplay = () => ({
  type: 'SOUNDBAR_TOGGLE_AUTOPLAY',
});

// export default exports;
