import graphql from 'babel-plugin-relay/macro';
import promiseMutation from 'utils/promise-mutation';

const mutation = graphql`
  mutation CreateUserTalentMutation($input: CreateUserTalentInput!) {
    createUserTalent(input: $input) {
      userTalent {
        userId
        talentId
      }
    }
  }
`;

const commit = (environment, userId, talentId) =>
  promiseMutation(environment, {
    mutation,
    variables: { input: { userTalent: { userId, talentId } } },
  });

export default commit;
