import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isDesktop } from 'utils/screentype';
import Arrows from './Arrows';
import SlideTrack from './SlideTrack';

const propTypes = {
  children: PropTypes.array.isRequired,
  columns: PropTypes.number,
  rows: PropTypes.number,
  marginBottom: PropTypes.number,
  marginRight: PropTypes.number,
  lazyLoad: PropTypes.bool,
  lazyLoadEarly: PropTypes.number,
  leftArrowStyle: PropTypes.object,
  rightArrowStyle: PropTypes.object,
  slide: PropTypes.bool,
  paddingLeft: PropTypes.number,
  hasArrows: PropTypes.bool,
  extraSlide: PropTypes.bool,
};

const defaultProps = {
  columns: 3,
  rows: 1,
  marginBottom: 0,
  marginRight: 0,
  lazyLoad: false,
  lazyLoadEarly: 0,
  hasArrows: true,
  leftArrowStyle: {},
  rightArrowStyle: {},
  slide: false,
  paddingLeft: 0,
  extraSlide: false,
};

class Carousel extends Component {
  constructor(props) {
    super(props);
    const loadAhead = props.lazyLoadEarly + 1;
    const elementsPerCycle = props.columns * props.rows;
    this.state = {
      loadAhead,
      elementsPerCycle,
      index: 0,
      lazyLoadedIndex: elementsPerCycle * loadAhead,
      max: this.props.children.length,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.columns !== this.props.columns) {
      this.setState({ elementsPerCycle: nextProps.columns * this.props.rows });
    }
  }

  arrowSlide = (direction) => {
    this.setState({ move: direction });
  };


  changeIndex = (newIndex) => {
    const { elementsPerCycle, loadAhead, lazyLoadedIndex } = this.state;
    this.setState({
      index: newIndex,
      lazyLoadedIndex: Math.max(
        lazyLoadedIndex,
        newIndex + (elementsPerCycle * loadAhead),
      ),
    });
  };

  render() {
    const {
      rows,
      marginBottom,
      lazyLoad,
      children,
      leftArrowStyle,
      rightArrowStyle,
      columns,
      slide,
      paddingLeft,
      hasArrows,
      marginRight,
      extraSlide,
    } = this.props;
    const { max, index, lazyLoadedIndex, elementsPerCycle, move } = this.state;
    const totalColumns = Math.ceil(children.length / rows);
    return (
      <Arrows
        arrowSlide={this.arrowSlide}
        index={index}
        elementsPerCycle={elementsPerCycle}
        changeIndex={this.changeIndex}
        max={max}
        leftArrowStyle={leftArrowStyle}
        rightArrowStyle={rightArrowStyle}
        hasArrows={hasArrows}
        rows={rows}
        marginBottom={marginBottom}
        marginRight={marginRight}
        lazyLoad={isDesktop() ? lazyLoad : false}
        lazyLoadedIndex={lazyLoadedIndex}
        columns={columns}
        totalColumns={totalColumns}
        slide={slide}
        paddingLeft={paddingLeft}
      >
        <SlideTrack
          rows={rows}
          marginBottom={marginBottom}
          marginRight={marginRight}
          lazyLoad={isDesktop() ? lazyLoad : false}
          max={max}
          lazyLoadedIndex={lazyLoadedIndex}
          index={index}
          columns={columns}
          totalColumns={totalColumns}
          slide={slide}
          paddingLeft={paddingLeft}
          move={move}
          arrowSlide={this.arrowSlide}
          extraSlide={extraSlide}
        >
          {children}
        </SlideTrack>
      </Arrows>
    );
  }
}

Carousel.propTypes = propTypes;

Carousel.defaultProps = defaultProps;

export default Carousel;
