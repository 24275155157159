import graphql from 'babel-plugin-relay/macro';
import promiseMutation from 'utils/promise-mutation';

const mutation = graphql`
  mutation DeleteUserArtistMutation(
    $input: DeleteUserArtistByUserIdAndArtistIdInput!
  ) {
    deleteUserArtistByUserIdAndArtistId(input: $input) {
      deletedUserArtistId
    }
  }
`;

const commit = (environment, userId, artistId) =>
  promiseMutation(environment, {
    mutation,
    variables: { input: { userId, artistId } },
  });

export default commit;
