import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import { avatarImageUrl } from 'config';
import PropTypes from 'prop-types';
import withS3 from 'utils/S3';
import get from 'lodash/get';
import ProfileSummaryDesktop from './ProfileSummaryDesktop';
import ProfileBannerDesktop from './ProfileBannerDesktop';
import ProfileTracklistDesktop from './ProfileTracklistDesktop';
import '../ProfileBanner.css';

const propTypes = {
  user: PropTypes.object.isRequired,
  currentUser: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
  myself: PropTypes.bool.isRequired,
  s3Upload: PropTypes.func.isRequired,
  s3Context: PropTypes.object,
  s3AddMetadata: PropTypes.func.isRequired,
  s3DestroyContext: PropTypes.func.isRequired,
  removeOtherTracksOnPlay: PropTypes.bool,
  addOtherTracksOnPlay: PropTypes.bool,
};

const defaultProps = {
  removeOtherTracksOnPlay: false,
  addOtherTracksOnPlay: false,
  s3Context: {},
};

const PROFILE_DESKTOP_FRAGMENT = gql`
  fragment ProfileDesktop_user on User {
    id
    picture
    ...ProfileBannerDesktop_user
    ...ProfileSummaryDesktop_user
    ...ProfileTracklistDesktop_user
  }
  ${ProfileBannerDesktop.fragments.user}
  ${ProfileSummaryDesktop.fragments.user}
  ${ProfileTracklistDesktop.fragments.user}
`;

class ProfileDesktop extends Component {
  static fragments = { user: PROFILE_DESKTOP_FRAGMENT };

  componentWillUnmount() {
    this.props.s3DestroyContext();
  }

  getImageUrl = () => {
    const { user, s3Context } = this.props;
    if (get(user, 'picture')) {
      return `url(${avatarImageUrl}${user.picture})`;
    } else if (s3Context.uploads) {
      const imageUploaded = s3Context.uploads.filter(
        u =>
          u.id === `avatars/${s3Context.metadata.imageId}` &&
          u.status === 'uploaded',
      )[0];
      return imageUploaded && `url(${s3Context.metadata.image.preview})`;
    } else {
      return null;
    }
  };

  render() {
    const {
      user,
      auth,
      s3Upload,
      s3Context,
      s3AddMetadata,
      currentUser,
      myself,
      removeOtherTracksOnPlay,
      addOtherTracksOnPlay,
    } = this.props;

    const image = this.getImageUrl();

    return (
      <div className="relative overflow-auto">
        <ProfileBannerDesktop
          user={filter(ProfileBannerDesktop.fragments.user, user)}
          auth={auth}
          s3Upload={s3Upload}
          s3Context={s3Context}
          s3AddMetadata={s3AddMetadata}
          currentUser={currentUser}
          myself={myself}
          image={image}
        />
        <div className="mx-auto w-desk flex mt-3 flex-col md:flex-row">
          <ProfileSummaryDesktop
            user={filter(ProfileSummaryDesktop.fragments.user, user)}
            myself={myself}
            currentUser={currentUser}
          />
          <ProfileTracklistDesktop
            user={filter(ProfileTracklistDesktop.fragments.user, user)}
            currentUser={currentUser}
            myself={myself}
            removeOtherTracksOnPlay={removeOtherTracksOnPlay}
            addOtherTracksOnPlay={addOtherTracksOnPlay}
          />
        </div>
      </div>
    );
  }
}

ProfileDesktop.propTypes = propTypes;
ProfileDesktop.defaultProps = defaultProps;

export default compose(
  connect(state => ({ auth: state.auth })),
  withS3,
)(ProfileDesktop);
