import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import PublicRoute from 'components/Route/PublicRoute';
import Invites from './Invites';
import InviteError from './InviteError';

const propTypes = {
  match: PropTypes.object.isRequired,
};

const InvitesPage = ({ match }) => (
  <Switch>
    <PublicRoute exact path={`${match.url}`} component={Invites} />
    <PublicRoute path={`${match.url}/error`} component={InviteError} />
  </Switch>
);

InvitesPage.propTypes = propTypes;

export default InvitesPage;
