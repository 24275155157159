import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import AuthRoute from 'components/Route/AuthRoute';
import ProjectsPage from 'pages/ProjectsPage';
import { connect } from 'react-redux';
import actionCreators from 'actions';
import setupRealtime from '../../realtime';

class ProjectsWrapper extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { token, userId } = this.props.auth;
    if (token && userId) setupRealtime(this.props.dispatch, actionCreators, token);
  }

  componentWillReceiveProps(nextProps) {
    const { token, userId } = this.props.auth;
    const { userId: nextUserId } = nextProps.auth;
    if (token && !userId && nextUserId) setupRealtime(this.props.dispatch, actionCreators, token);
  }

  render() {
    const { url } = this.props.match;
    return (
      <Switch>
        <ProjectsPage path={url} exact />
        {/* <AuthRoute path={`${url}/:id`} component={GridPage} /> */}
      </Switch>
    );
  }
}

export default connect()(ProjectsWrapper);
