import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import uuidV4 from 'uuid/v4';
import pick from 'lodash/pick';
import compact from 'lodash/compact';
import firebase from 'utils/firebase';
import * as authActions from 'actions/auth';
import { getUserEmail } from 'rest/users';
import EmailInput from 'components/common/forms/EmailInput';

const fields = ['email', 'artist', 'producer'];
const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.password) {
    errors.password = 'Required';
  } else if (values.password.length < 8) {
    errors.password = 'Password must be at least 8 characters';
  }

  return errors;
};

const asyncValidate = values =>
  getUserEmail(values)
    .then((response) => {
      if (response.message === 'Someone has this email.') {
        throw { email: 'This email is taken' }; // eslint-disable-line
      }
    });

class SignUpForm extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    onAfterSubmit: PropTypes.func,
    error: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
  };

  static defaultProps = {
    error: '',
    next: null,
    onAfterSubmit: () => {},
    submitting: false,
  };

  handleSubmit = (data) => {
    const { history, onAfterSubmit } = this.props;
    const refinedData = pick(data, ['email', 'password']);
    const talents = compact([data.artist && 'artist', data.producer && 'producer']);
    if (talents.length === 0) {
      throw new SubmissionError({
        _error: 'A talent is required',
      });
    }
    const signup = Object.assign({}, refinedData, { createdAt: new Date(), id: uuidV4(), talents });
    return firebase
      .ref(`/signups/${signup.id}`)
      .set(signup)
      .then(() => {
        console.log('success!');
        history.push('/signup/success');
        onAfterSubmit();
      })
      .catch((err) => {
        throw new SubmissionError({
          _error: err.message,
        });
      });
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  render() {
    const { error, handleSubmit, submitting } = this.props;
    return (
      <form onSubmit={handleSubmit(this.handleSubmit)}>
        <Field name="email" component={EmailInput} />

        <div className="font-semibold text-left mb-2">Talent</div>
        <div className="flex mb-4">
          <div className="flex">
            <Field
              name="artist"
              type="checkbox"
              component="input"
              style={{ marginTop: 2 }}
            />
            <div className="ml-3">Artist</div>
          </div>
          <div className="flex ml-6">
            <Field
              name="producer"
              type="checkbox"
              component="input"
              style={{ marginTop: 2 }}
            />
            <div className="ml-3">Producer</div>
          </div>
        </div>
        {error && <div className="error">{error}</div>}
        <div className="field">
          <p className="control">
            <button
              type="submit"
              className="btn button is-success"
              disabled={submitting || error}
            >
              Sign up
            </button>
          </p>
        </div>
      </form>
    );
  }
}

export default compose(
  withRouter,
  connect(
    state => ({ auth: state.auth }),
    authActions,
  ),
  reduxForm({
    form: 'login',
    fields,
    validate,
    asyncValidate,
  }),
)(SignUpForm);
