// If route is only for private use (when user is logged in)

import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const propTypes = {
  auth: PropTypes.object.isRequired,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  location: PropTypes.object.isRequired,
};

const PrivateRoute = ({ auth, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      auth.isAuthenticated
        ? <Component {...props} auth={auth} />
        : <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
    )}
  />
);

PrivateRoute.propTypes = propTypes;

export default withRouter(
  connect(state => ({ auth: state.auth }))(PrivateRoute),
);
