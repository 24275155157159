import React from 'react';
import SignUpForm from './SignUpForm';

const SignUpBlock = () => (
  <div className="pcont p3 lu-w30 mu-w50 w90 center" style={{ maxWidth: '450px', display: 'inline-block' }}>
    <h1 className="title center">Sign up</h1>
    <SignUpForm />
  </div>
);

export default SignUpBlock;
