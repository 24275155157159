import React, { Component } from 'react';
import PropTypes from 'prop-types';
import formatMediaQuery from 'utils/format-media-query';

const propTypes = {
  children: PropTypes.object.isRequired,
  breakpoints: PropTypes.array,
  updateSettings: PropTypes.bool,
  settings: PropTypes.object,
};

const defaultProps = {
  breakpoints: [],
  updateSettings: false,
  settings: {},
};

class Breakpoints extends Component {
  state = {
    currentBreakpoint: null,
  };

  componentDidMount() {
    const { breakpoints } = this.props;
    if (breakpoints) {
      const sortedBreakpointList = breakpoints.sort(
        (a, b) => a.breakpoint - b.breakpoint,
      );
      this.sortedBreakpointList = sortedBreakpointList;
      this.registerBreakpoints();
    }
  }

  createHandler = (query, breakpoint) => {
    window.matchMedia(query).addListener((e) => {
      if (e.matches) {
        this.setState({ currentBreakpoint: breakpoint });
      }
    });
  };

  initialQueryCheck = (query, breakpoint) => {
    if (window.matchMedia(query).matches) {
      this.setState({ currentBreakpoint: breakpoint });
    }
  };

  handleQuery = (query, breakpoint) => {
    this.initialQueryCheck(query, breakpoint);
    this.createHandler(query, breakpoint);
  };

  registerBreakpoints = () => {
    const breakpoints = this.sortedBreakpointList.map(
      breakpointObj => breakpointObj.breakpoint,
    );

    breakpoints.forEach((breakpoint, index) => {
      let bQuery;
      if (index === breakpoints.length - 1) {
        bQuery = formatMediaQuery({ minWidth: breakpoint });
      } else {
        bQuery = formatMediaQuery({
          minWidth: breakpoints[index],
          maxWidth: breakpoints[index + 1],
        });
      }
      this.handleQuery(bQuery, breakpoint);
    });

    // Register media query for default/smallest size
    const defaultQuery = formatMediaQuery({ maxWidth: breakpoints[0] });

    this.handleQuery(defaultQuery, null);
  };

  overrideSettings = (i) => {
    const breakks = this.sortedBreakpointList.slice(0, i + 1);
    return breakks.reduce(
      (acc, curr) => Object.assign({}, acc, curr),
      this.props.settings,
    );
  };

  updateSettings = () => {
    let settings = this.props.settings;
    if (this.props.updateSettings) {
      if (this.state.currentBreakpoint) {
        const index = this.sortedBreakpointList.findIndex(
          breakk => breakk.breakpoint === this.state.currentBreakpoint,
        );
        settings = this.overrideSettings(index);
      }
    }
    return settings;
  };

  render() {
    const settings = this.updateSettings();
    const { children } = this.props;
    const newChildren = React.Children.map(children, child =>
      React.cloneElement(child, {
        currentBreakpoint: this.state.currentBreakpoint,
        ...settings,
      }),
    )[0];
    return newChildren;
  }
}

Breakpoints.propTypes = propTypes;

Breakpoints.defaultProps = defaultProps;

export default Breakpoints;
