import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { QueryRenderer, graphql } from 'react-relay';
import { withRouter } from 'react-router-dom';
import { List, ListItem } from 'material-ui/List';
import SortableResultsTable from './SortableResultsTable';

const propTypes = {
  variables: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  simple: PropTypes.bool,
  history: PropTypes.object.isRequired,
  options: PropTypes.array,
};

const defaultProps = {
  simple: true,
  options: [],
};

class TagsResults extends Component {
  render() {
    if (this.props.variables.search) {
      return (
        <QueryRenderer
          query={graphql`
          query TagsResultsQuery($search: String!){
            tags: searchTags(search: $search, first:10){
              edges {
                cursor
                node {
                  id
                  tag
                }
              }
            }
          }`}
          variables={this.props.variables}
          environment={this.props.auth.environment}
          render={({ error, props }) => {
            if (error) {
              console.log(error);
            }
            if (props && props.tags.edges.length) {
              if (!this.props.simple) {
                // Set up header info and sort configs
                // Note the "id" in columnData must exactly match the key
                // in userData you wish to sort by!
                const columnData = [
                  {
                    id: 'tag',
                    numeric: false,
                    disablePadding: true,
                    label: 'Tags',
                  },
                ];

                const tagData =
                  props.tags &&
                  props.tags.edges.map(tagEdge => ({
                    id: tagEdge.node.id,
                    tag: tagEdge.node.tag,
                  }));

                return (
                  <SortableResultsTable
                    tableData={{ columns: columnData, data: tagData }}
                    linkType="tag"
                    options={this.props.options}
                  />
                );
              }

              // Default behavior
              return (
                <div>
                  <List>
                    {props.tags &&
                      props.tags.edges.map(tagEdge =>
                        (<ListItem
                          key={tagEdge.node.id}
                          primaryText={`# ${tagEdge.node.tag}`}
                          rightIcon={
                            <i
                              className="fa fa-hashtag"
                              style={{ float: 'left', color: 'black' }}
                            />
                          }
                          onClick={() => {
                            this.props.onSelect();
                            this.props.history.push(
                              `/tags/${tagEdge.node.tag}`,
                            );
                          }}
                        />),
                      )}
                  </List>
                </div>
              );
            }
            return (
              <List>
                <ListItem
                  key="notagsresults"
                  primaryText="No tags match your query"
                  leftIcon={<i className="fa fa-frown-o" />}
                  rightIcon={<i className="fa fa-hashtag" />}
                />
              </List>
            );
          }}
        />
      );
    }
    return null;
  }
}

TagsResults.propTypes = propTypes;
TagsResults.defaultProps = defaultProps;

export default withRouter(TagsResults);
