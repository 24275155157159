import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom';

const BUY_TRACK = gql`
  fragment Buy_track on Track {
    id
    purgatory
    publicId
    products: productsByTrackId {
      totalCount
      edges {
        node {
          price
        }
      }
    }
  }
`;

const propTypes = {
  track: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  className: PropTypes.string,
  shouldLink: PropTypes.bool,
};

const defaultProps = {
  className: 'flex items-center font-bold text-lg',
  shouldLink: true,
};

const Buy = ({
  className,
  history: { push },
  track: {
    id,
    purgatory,
    publicId,
    products: { totalCount, edges: productEdges },
  },
  shouldLink,
}) => {
  if (!totalCount || purgatory) return <span />;
  const minPrice = Math.min.apply(
    null,
    (productEdges || []).map(edge => edge.node.price),
  );

  const linkToTrackPage = (e) => {
    e.stopPropagation();
    push(`/tracks/${publicId}#buy-track`);
  };

  return (
    <span
      id={`tp-buy-${id}`}
      className={className}
      role="presentation"
      onClick={shouldLink && linkToTrackPage}
    >
      {`$${Math.ceil(minPrice / 100)}`}
    </span>
  );
};

Buy.propTypes = propTypes;
Buy.defaultProps = defaultProps;
Buy.fragments = { track: BUY_TRACK };

export default withRouter(Buy);
