import decode from 'jwt-decode';
import { register, login } from 'rest/auth';
import xor from 'lodash/xor';
import Cookies from 'js-cookie';
import { GATrackId } from 'config';
import serverCheck from 'utils/server-check';
import { requestWrapper } from './helper';

const isServer = serverCheck();

export const signOut = () => {
  Cookies.remove('token');
  return { type: 'SIGN_OUT' };
};

export const logIn = requestWrapper('LOGIN', login);

export const registerUser = requestWrapper('REGISTER', register);

const tokenContents = ['aud', 'exp', 'iat', 'iss', 'role', 'user_id', 'username', 'skill'];

const hasTokenContents = token => xor(Object.keys(token), tokenContents).length === 0;

export const loadUser = (payload) => {
  let token;
  try {
    token = decode(payload);
  } catch (e) {
    return signOut();
  }

  const { exp, user_id } = token;
  const then = Number(exp);
  const now = Math.floor(Date.now() / 1000);

  if (!hasTokenContents(token) || now >= then) {
    return signOut();
  }

  // if (!isServer) {
  //   window.gtag('config', GATrackId, { user_id });
  // }

  return {
    type: 'LOAD_USER',
    payload,
  };
};

