export const FETCH_POSTS_REQUEST = 'FETCH_POSTS_REQUEST';
export const FETCH_POSTS_FAILURE = 'FETCH_POSTS_FAILURE';
export const FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS';

export const ADD_POST_REQUEST = 'ADD_POST_REQUEST';
export const ADD_POST_FAILURE = 'ADD_POST_FAILURE';
export const ADD_POST_SUCCESS = 'ADD_POST_SUCCESS';

export const RESET_POSTS = 'RESET_POSTS';

export default module.exports;
