import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { soundbarSetPlaying } from 'actions/soundbar';
import CircularProgress from '@material-ui/core/CircularProgress';

import './musicControls.css';

const propTypes = {
  playing: PropTypes.bool.isRequired,
  stalled: PropTypes.bool.isRequired,
  waiting: PropTypes.bool.isRequired,
  iconStyle: PropTypes.object,
  togglePlay: PropTypes.func.isRequired,
  circularClassName: PropTypes.string,
  className: PropTypes.string,
  circularSize: PropTypes.number,
};

const defaultProps = {
  iconStyle: {},
  className: 'music-icon self-center text-gray-darkest text-4xl',
  circularClassName: '',
  circularSize: 42,
};

const Play = ({
  iconStyle,
  togglePlay,
  stalled,
  waiting,
  playing,
  className,
  circularClassName,
  circularSize,
}) => {
  const renderStalling = () => (
    <i className={classnames('material-icons', className)} style={iconStyle}>
      {'report_problem'}
    </i>
  );

  const renderWaiting = () => (
    <div className="relative flex">
      <i className={classnames('material-icons', className)} style={iconStyle}>
        {'pause'}
      </i>
      <CircularProgress
        color="black"
        size={circularSize}
        classes={{
          root: classnames('absolute pin-l', circularClassName),
        }}
      />
    </div>
  );

  const renderGoodToGo = () => (
    <i className={classnames('material-icons', className)} style={iconStyle}>
      {playing ? 'pause' : 'play_arrow'}
    </i>
  );

  let playState;
  if (stalled) playState = renderStalling;
  else if (waiting) playState = renderWaiting;
  else playState = renderGoodToGo;

  return (
    <a
      role="button"
      className="flex outline-none"
      tabIndex={0}
      onClick={togglePlay}
    >
      {playState()}
    </a>
  );
};

Play.propTypes = propTypes;
Play.defaultProps = defaultProps;

export default connect(
  state => ({
    playing: state.soundbar.playing,
    stalled: state.soundbar.stalled,
    waiting: state.soundbar.waiting,
  }),
  { soundbarSetPlaying },
)(Play);
