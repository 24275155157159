import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array,
};

const defaultProps = {
  style: {},
  className: '',
  disabled: false,
  options: [],
};

const BasicDropdown = ({ id, value, style, className, disabled, onChange, options }) => (
  <select
    id={id}
    style={style}
    className={className}
    value={value}
    disabled={disabled}
    onChange={e => onChange({ id: e.target.value, target: e.target })}
  >
    {options &&
      options.map(option => (
        <option key={option.value || option.label} value={option.value}>
          {option.label || option.value}
        </option>
      ))}
  </select>
);

BasicDropdown.propTypes = propTypes;
BasicDropdown.defaultProps = defaultProps;

export default BasicDropdown;
