import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { avatarImageUrl } from 'config';
import displayImage from 'utils/display-image';
import { displayTime } from 'utils/date';
import PlugImage from 'components/common/PlugImage';

const propTypes = {
  comment: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  deleteable: PropTypes.bool,
};

const defaultProps = {
  deleteable: false,
};

const Comment = ({ comment, deleteable, onDelete }) => {
  const { author, createdAt, body } = comment;

  return (
    <li className="mb-4">
      <article className="flex items-center">
        <figure>
          <Link
            to={`/${author.username}`}
            className="block sat-contrast-hover"
            style={{ width: '64px', height: '64px' }}
          >
            <PlugImage
              imageSource={displayImage(avatarImageUrl, author.picture)}
              className="rounded-full cover h-full"
              style={{ width: '64px', height: '64px' }}
              alt="this is fake"
            />
            {/* <img
              className="rounded-full cover h-full"
              alt="this is fake"
              src={displayImage(avatarImageUrl, author.picture)}
            /> */}
          </Link>
        </figure>
        <div className="ml-3 flex-grow flex-shrink">
          <div className="flex-row justify-between w-9/10">
            <Link className="font-semibold" to={`/${author.username}`}>
              {author.displayName}
            </Link>{' '}
            <small className="hidden lg:block">{displayTime(createdAt)}</small>
          </div>
          <div>{body}</div>
        </div>
        {deleteable && (
          <div className="pin-r self-start">
            <button className="delete" onClick={onDelete} />
          </div>
        )}
      </article>
    </li>
  );
};

Comment.propTypes = propTypes;
Comment.defaultProps = defaultProps;
Comment.fragments = {
  comment: gql`
    fragment Comment_comment on Comment {
      id
      body
      createdAt
      author: userByUserId {
        id
        username
        displayName
        picture
      }
    }
  `,
};

export default Comment;
