import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { QueryRenderer, graphql } from 'react-relay';
import { avatarImageUrl } from 'config';
import { withRouter } from 'react-router-dom';
import Avatar from 'material-ui/Avatar';
import { List, ListItem } from 'material-ui/List';
import SortableResultsTable from './SortableResultsTable';

const propTypes = {
  variables: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  simple: PropTypes.bool,
  history: PropTypes.object.isRequired,
  showColumns: PropTypes.array,
  options: PropTypes.array,
};

const defaultProps = {
  simple: true,
  showColumns: null,
  options: [],
};

class UsersResults extends Component {
  render() {
    if (this.props.variables.search) {
      return (
        <QueryRenderer
          query={graphql`
          query UsersResultsQuery($search: String!){
            users: searchUsers(search:$search, first:10){
              edges {
                cursor
                node {
                  id
                  displayName
                  username
                  picture
                  tracksByUserId{
                    totalCount
                  }
                  followsByFollowerId{
                    totalCount
                  }
                }
              }
            }
          }`}
          variables={this.props.variables}
          environment={this.props.auth.environment}
          render={({ error, props }) => {
            if (error) {
              console.log(error);
            }
            if (props && props.users.edges.length) {
              if (!this.props.simple) {
                // Set up header info and sort configs
                // Note the "id" in columnData must exactly match the key
                // in userData you wish to sort by!
                let columnData = [];
                if (this.props.showColumns && this.props.showColumns.length) {
                  if (this.props.showColumns.includes('picture')) {
                    columnData.push({
                      id: 'picture',
                      numeric: false,
                      disablePadding: true,
                      label: '',
                    });
                  }
                  if (this.props.showColumns.includes('username')) {
                    columnData.push({
                      id: 'username',
                      numeric: false,
                      disablePadding: true,
                      label: 'Users',
                    });
                  }
                  if (this.props.showColumns.includes('displayname')) {
                    columnData.push({
                      id: 'displayName',
                      numeric: false,
                      disablePadding: true,
                      label: 'Display Name',
                    });
                  }
                  if (this.props.showColumns.includes('tracks')) {
                    columnData.push({
                      id: 'tracks',
                      numeric: true,
                      disablePadding: true,
                      label: 'Tracks',
                    });
                  }
                  if (this.props.showColumns.includes('follows')) {
                    columnData.push({
                      id: 'follows',
                      numeric: true,
                      disablePadding: true,
                      label: 'Follows',
                    });
                  }
                } else {
                  columnData = [
                    {
                      id: 'picture',
                      numeric: false,
                      disablePadding: true,
                      label: 'Picture',
                    },
                    {
                      id: 'username',
                      numeric: false,
                      disablePadding: true,
                      label: 'Users',
                    },
                    {
                      id: 'displayName',
                      numeric: false,
                      disablePadding: true,
                      label: 'Display Name',
                    },
                    {
                      id: 'tracks',
                      numeric: true,
                      disablePadding: true,
                      label: 'Tracks',
                    },
                    {
                      id: 'follows',
                      numeric: true,
                      disablePadding: true,
                      label: 'Follows',
                    },
                  ];
                }

                const userData = props.users.edges.map(userEdge => ({
                  id: userEdge.node.id,
                  username: userEdge.node.username,
                  displayName: userEdge.node.displayName,
                  tracks: userEdge.node.tracksByUserId.totalCount,
                  follows: userEdge.node.followsByFollowerId.totalCount,
                  picture: avatarImageUrl + userEdge.node.picture,
                }));

                return (
                  <SortableResultsTable
                    tableData={{ columns: columnData, data: userData }}
                    linkType="user"
                    onSelect={this.props.onSelect}
                    options={this.props.options}
                  />
                );
              }

              // Default behavior
              return (
                <div>
                  <List>
                    {props.users &&
                      props.users.edges.map(userEdge =>
                        (<ListItem
                          key={userEdge.node.id}
                          primaryText={userEdge.node.username}
                          leftAvatar={
                            <Avatar
                              src={avatarImageUrl + userEdge.node.picture}
                              style={{ objectFit: 'cover' }}
                            />
                          }
                          rightIcon={
                            <i
                              className="fa fa-user"
                              style={{ float: 'left', color: 'black' }}
                            />
                          }
                          onClick={() => {
                            this.props.onSelect();
                            this.props.history.push(
                              `/${userEdge.node.username}`,
                            );
                          }}
                        />),
                      )}
                  </List>
                </div>
              );
            }
            return (
              <List>
                <ListItem
                  key="nousersresults"
                  primaryText="No users match your query"
                  leftIcon={<i className="fa fa-frown-o" />}
                  rightIcon={<i className="fa fa-user" />}
                />
              </List>
            );
          }}
        />
      );
    }
    return null;
  }
}

UsersResults.propTypes = propTypes;
UsersResults.defaultProps = defaultProps;

export default withRouter(UsersResults);
