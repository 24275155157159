import { MODAL_SHOW, MODAL_HIDE, MODAL_UPDATE_STYLES } from 'types/modals';

export const showModal = (name, data) => {
  if (typeof window !== 'undefined') {
    document.body.style.top = `-${window.pageYOffset}px`;
  }
  return {
    type: MODAL_SHOW,
    payload: { name, data },
  };
};

export const hideModal = () => (dispatch) => {
  dispatch({
    type: MODAL_HIDE,
  });
  return Promise.resolve();
};

export const updateStyles = payload => ({
  type: MODAL_UPDATE_STYLES,
  payload,
});

export default module.exports;
