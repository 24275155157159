import React from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter, Redirect } from 'react-router-dom';
import QueryRenderer from 'components/QueryRenderer';
import { connect } from 'react-redux';
import { compose } from 'redux';

const noop = () => {};

const propTypes = {
  query: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object,
  setVariables: PropTypes.func,
  privacy: PropTypes.string,
  componentProps: PropTypes.object,
};

const defaultProps = {
  setVariables: noop,
  match: { params: {} },
  privacy: 'NONE',
  componentProps: {},
};

const RelayRoute = ({ auth, component, query, privacy, setVariables, componentProps, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (privacy === 'PRIVATE' && !auth.isAuthenticated) {
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      } else if (privacy === 'PUBLIC' && auth.isAuthenticated) {
        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        );
      }
      const params = props.match.params;
      const variables = setVariables === noop ? params : setVariables({ ...auth, ...params });
      return (
        <QueryRenderer
          auth={auth}
          query={query}
          component={component}
          variables={variables}
          {...componentProps}
          {...props}
        />
      );
    }}
  />
);

RelayRoute.propTypes = propTypes;
RelayRoute.defaultProps = defaultProps;

export default compose(withRouter, connect(state => ({ auth: state.auth })))(
  RelayRoute,
);
