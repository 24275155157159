import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import displayTrackImage from 'utils/display-track-image';
import { apiUrl, trackUrl } from 'config';
import Track from 'components/Tracks/Track';
import { filter } from 'graphql-anywhere';

const propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const TRACK_PAGE = gql`
  query TrackPage($publicId: String!) {
    track: trackByPublicIdAndDeleted(publicId: $publicId, deleted: false) {
      id
      name
      description
      fileUrl
      picture
      user: userByUserId {
        displayName
        username
      }
      ...Track_track
    }
  }
  ${Track.fragments.track}
`;

const TrackPage = ({
  match: {
    params: { publicId },
  },
  location: { pathname },
}) => (
  <Query query={TRACK_PAGE} variables={{ publicId }}>
    {({ loading, error, data }) => {
      if (loading) {
        return (
          <div className="mt-12 ml-16">
            <CircularProgress size={50} style={{ color: '#2a585d' }} />
          </div>
        );
      }
      if (error) return <div>Error</div>;
      console.log('data', data);

      return (
        <div id="track-page">
          <Helmet>
            <title>{`${data.track.user.displayName} - ${
              data.track.name
            }`}</title>
            <meta property="description" content={data.track.description} />
            <meta
              property="og:title"
              content={`${data.track.user.displayName} - ${data.track.name}`}
            />
            <meta
              property="og:description"
              content={data.track.description || data.track.name}
            />
            <meta property="og:type" content="music.song" />
            <meta property="og:url" content={`${apiUrl}${pathname}`} />
            <meta
              property="og:image"
              content={displayTrackImage(
                data.track.fileUrl,
                data.track.picture,
              )}
            />
            <meta property="og:image:type" content="image/jpeg" />
            <meta property="og:image:width" content="128" />
            <meta property="og:image:height" content="128" />
            <meta
              property="og:audio"
              content={`${trackUrl}${data.track.fileUrl}/tagged.mp3`}
            />
            <meta property="og:audio:type" content="audio/mpeg" />
            <link rel="canonical" href={`${apiUrl}${pathname}`} />
          </Helmet>
          <Track track={filter(Track.fragments.track, data.track)} />
        </div>
      );
    }}
  </Query>
);

TrackPage.propTypes = propTypes;

export default withRouter(TrackPage);
