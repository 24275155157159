import { fetch, authHeader } from 'utils/fetch';

export function postFeedback(feedback) {
  return fetch('/api/issues/new', {
    method: 'POST',
    body: JSON.stringify(feedback),
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
  });
}

export default postFeedback;
