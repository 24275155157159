import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import startsWith from 'lodash/startsWith';
import classnames from 'classnames';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import nontransparentNavPaths from 'utils/non-transparent-paths';
import Desktop from './Desktop';

const propTypes = {
  type: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  s3: PropTypes.object.isRequired,
};

const s3ContextToWatch = 'CreateTrack';

const navbarClasses = {
  transparent: '',
  semiTransparent: 'navbar-semiTransparent-bg',
  nonTransparent: 'bgcolor-primary nav-borderColor',
};

class Navbar extends React.Component {
  state = { scrollUp: false, scrollOffset: null, isTopOfPage: null };

  componentDidMount = () => {
    const offset = window.pageYOffset;
    this.setState({ scrollOffset: offset, isTopOfPage: offset === 0 });
    window.addEventListener('scroll', this.handleScroll);
  };

  checkIfTopOfPage = (newOffset) => {
    if (newOffset > 0 && this.state.isTopOfPage) {
      this.setState({ isTopOfPage: false });
    } else if (newOffset === 0 && !this.state.isTopOfPage) {
      this.setState({ isTopOfPage: true });
    }
  };

  findScrollDirection = (newOffset) => {
    if (this.state.scrollOffset < newOffset) {
      this.setState({ scrollUp: false, scrollOffset: newOffset });
    } else {
      this.setState({ scrollUp: true, scrollOffset: newOffset });
    }
  };

  handleScroll = () => {
    const newOffset = window.pageYOffset;
    this.checkIfTopOfPage(newOffset);
    this.findScrollDirection(newOffset);
  };

  colorNavbar = (pathname) => {
    const semiTransparent = !nontransparentNavPaths.filter(path => pathname.startsWith(path)).length;

    if (semiTransparent) {
      return this.state.isTopOfPage
        ? navbarClasses.transparent
        : navbarClasses.semiTransparent;
    } else {
      return navbarClasses.nonTransparent;
    }
  };

  render() {
    const {
      location: { pathname },
      type,
      s3,
    } = this.props;
    const isDesktop = type === 'desktop';
    if (startsWith(pathname, '/projects/') && pathname.length > 10) {
      return <div />;
    }
    const navbarClass = this.colorNavbar(pathname);

    const isTrackPage = startsWith(pathname, '/track');

    return (
      <nav
        className={classnames(
          'flex items-center z-40 flex-no-shrink nav-height w-full sm:fixed',
          this.state.scrollUp ? 'fixed' : 'absolute',
          navbarClass,
        )}
        style={
          !isDesktop && startsWith(pathname, '/search')
            ? { display: 'none' }
            : {}
        }
      >
        <Desktop
          s3ContextToWatch={s3[s3ContextToWatch]}
          type={type}
          isTrackPage={isTrackPage}
        />
      </nav>
    );
  }
}

Navbar.propTypes = propTypes;

export default compose(
  withRouter,
  connect(state => ({
    type: state.windowSession.type,
    s3: state.s3,
  })),
)(Navbar);
