import React from 'react';

const ErrorPage = () => (
  <div className="mt6 center">
    <h3 className="title">404</h3>
    <div>{'We can\'t find the page you are looking for.'}</div>
  </div>
);

export default ErrorPage;
