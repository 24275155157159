import React from 'react';
import PropTypes from 'prop-types';
import Breakpoints from 'utils/Breakpoints';
import Carousel from './Carousel';

const propTypes = {
  children: PropTypes.array.isRequired,
  settings: PropTypes.object,
  breakpoints: PropTypes.array,
};

const defaultProps = {
  breakpoints: [],
  settings: {},
};

const SetupBreakpoints = ({ settings, children, breakpoints }) => (
  <Breakpoints updateSettings settings={settings} breakpoints={breakpoints}>
    <Carousel>{children}</Carousel>
  </Breakpoints>
);

SetupBreakpoints.propTypes = propTypes;

SetupBreakpoints.defaultProps = defaultProps;

export default SetupBreakpoints;
