import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import TextArea from 'components/common/TextArea';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  error: PropTypes.bool,
};

const defaultProps = {
  error: false,
};

const AddDescription = ({
  handleSubmit,
  submitting,
  submitSucceeded,
  error,
}) => (
  <form onSubmit={handleSubmit}>
    <Field
      name="description"
      htmlFor="description"
      placeholder="Add a description..."
      component={TextArea}
    />

    {submitSucceeded && (
    <p className="help is-success">
          Your user info has been updated successfully!
        </p>
      )}

    <div className="field is-grouped">
      <p className="control">
        <button type="submit" disabled={submitting || error}>
            Submit
          </button>
      </p>
    </div>
  </form>
  );

AddDescription.propTypes = propTypes;
AddDescription.defaultProps = defaultProps;

export default compose(
  reduxForm({
    form: 'UserSettings',
  }),
  withRouter,
)(AddDescription);
