import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import * as toastActions from 'actions/toasts';
import ExitButton from 'components/common/ExitButton';
import Profile from 'components/Profile';

const propTypes = {
  onClose: PropTypes.func.isRequired,
  track: PropTypes.object.isRequired,
  showMessage: PropTypes.func.isRequired,
};

const DELETE_TRACK_FRAG = gql`
  fragment DeleteTrackModal_track on Track {
    id
    name
    picture
    fileUrl
  }
`;

const DELETE_TRACK_MUTATION = gql`
  mutation DeleteTrackByIdMutation($input: UpdateTrackByIdInput!, $trackCondition: TrackCondition!) {
    deleteTrack: updateTrackById(input: $input) {
      user: userByUserId {
        ...ProfileContainer_user
      }
    }
  }
  ${Profile.fragments.user}
`;

const DeleteTrackModal = ({ onClose, showMessage, track }) => (
  <Mutation
    mutation={DELETE_TRACK_MUTATION}
    onCompleted={onClose}
    onError={(err) => {
      showMessage({ type: 'error', text: err.message });
    }}
  >
    {deleteTrack => (
      <div id="delete-track-modal" className="p-4">
        <div className="flex justify-between mb-6">
          <h1 className="title" style={{ marginBottom: 0 }}>
            Delete Track
          </h1>
          <ExitButton id="delete-track-exit" onClick={onClose} />
        </div>
        <div className="mb-6">
          Are you sure you want to delete <strong>{track.name}</strong>?
        </div>
        <div className="field is-grouped">
          <div className="control">
            <button
              type="submit"
              onClick={() => {
                deleteTrack({
                  variables: {
                    input: { id: track.id, trackPatch: { deleted: true } },
                    trackCondition: { deleted: false },
                  },
                });
              }}
              className="btn btn-primary btn-short"
            >
              Delete
            </button>
          </div>
          <div className="control">
            <button className="btn btn-link btn-short" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    )}
  </Mutation>
);

DeleteTrackModal.propTypes = propTypes;
DeleteTrackModal.fragments = { track: DELETE_TRACK_FRAG };

export default connect(
  null,
  toastActions,
)(DeleteTrackModal);
