import React, { Component } from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import detectMobile from 'utils/detect-mobile';
import { setScreenType } from 'utils/screentype';
import screenConfig from 'css/screenSizes';
import formatMediaQuery from 'utils/format-media-query';
import { changeWindowSettings } from 'actions/windowSession';
import animate from 'utils/animate';

// formats screen sizes from css folder into media queries
const screenKeys = Object.keys(screenConfig);
const screenSizes = new Map(
  screenKeys.map((key, i) => {
    const breakpoint = screenConfig[key];
    let query;
    if (i === screenKeys.length - 1) {
      query = formatMediaQuery({ 'min-width': breakpoint });
    } else {
      query = formatMediaQuery({
        'min-width': screenConfig[key],
        'max-width': screenConfig[screenKeys[i + 1]],
      });
    }
    return [key, query];
  }),
);
//

class MediaQueryProvider extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    type: PropTypes.string,
    size: PropTypes.string,
    changeWindowSettings: PropTypes.func.isRequired,
  };

  static defaultProps = {
    type: '',
    size: '',
  }


  componentDidMount() {
    this.checkMediaQuery();
    this.createResizeHandler();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.animatedQueryCheck);
  }

  checkMediaQuery = () => {
    let size = 'default';
    screenSizes.forEach((val, key) => {
      const media = window.matchMedia(val);
      if (media.matches) {
        size = key;
      }
    });
    if (size !== this.props.size) {
      this.props.changeWindowSettings({ size });
    }

    const type = detectMobile() ? 'mobile' : 'desktop';
    console.log('type', type);
    if (type !== this.props.type) {
      setScreenType(type);
      this.props.changeWindowSettings({ type });
    }
  };

  animatedQueryCheck = animate(this.checkMediaQuery);

  createResizeHandler = () => {
    window.addEventListener('resize', this.animatedQueryCheck);
  };

  render() {
    return this.props.children;
  }
}

export default compose(
  withRouter,
  connect(
    state => ({
      size: state.windowSession.size,
      type: state.windowSession.type,
    }),
    { changeWindowSettings },
  ),
)(MediaQueryProvider);
