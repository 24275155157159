const camelToDash = str =>
  str
    .replace(/(^[A-Z])/, ([first]) => first.toLowerCase())
    .replace(/([A-Z])/g, ([letter]) => `-${letter.toLowerCase()}`);

const formatMediaQuery = (query) => {
  let mediaQuery = '';
  const features = Object.keys(query);
  features.forEach((feature, index) => {
    let size = query[feature];
    const formattedFeature = camelToDash(feature);
    if (typeof size === 'number') {
      size = `${size}px`;
    }
    mediaQuery += `(${formattedFeature}: ${size})`;
    if (index < features.length - 1) {
      mediaQuery += ' and ';
    }
  });
  return mediaQuery;
};

export default formatMediaQuery;
