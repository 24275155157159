import Cookies from 'js-cookie';
import decode from 'jwt-decode';
import firebase from 'utils/firebase';

export const fetchNotificationsLoading = () => ({
  type: 'FETCH_NOTIFICATIONS_LOADING',
});

export const fetchNotificationsFailure = err => ({
  type: 'FETCH_NOTIFICATIONS_FAILURE',
  payload: err,
});

export const fetchNotificationsSuccess = payload => ({
  type: 'FETCH_NOTIFICATIONS_SUCCESS',
  payload,
});

export const fetchNotifications = () => (dispatch) => {
  dispatch(fetchNotificationsLoading());
  const token = Cookies.get('token');
  const { user_id: userId } = decode(token);
  if (!userId) {
    return null;
  }
  return firebase
    .ref(`notifications/${userId}/events`)
    .orderByChild('createdAt')
    .limitToFirst(100)
    .on('value', (snapshot) => {
      const notifications = snapshot.val();
      dispatch(fetchNotificationsSuccess(notifications));
      return notifications;
    });
};

export const fetchUnreadNotificationsLoading = () => ({
  type: 'FETCH_UNREAD_NOTIFICATIONS_LOADING',
});

export const fetchUnreadNotificationsFailure = err => ({
  type: 'FETCH_UNREAD_NOTIFICATIONS_FAILURE',
  payload: err,
});

export const fetchUnreadNotificationsSuccess = payload => ({
  type: 'FETCH_UNREAD_NOTIFICATIONS_SUCCESS',
  payload,
});

export const fetchUnreadNotifications = () => (dispatch) => {
  dispatch(fetchUnreadNotificationsLoading());
  const token = Cookies.get('token');
  const { user_id: userId } = decode(token);
  if (!userId) return null;
  return firebase
    .ref(`notifications/${userId}/unreadEvents`)
    .on('value', (snapshot) => {
      const unreadNotif = snapshot.val();
      dispatch(fetchUnreadNotificationsSuccess(unreadNotif));
      return unreadNotif;
    });
};

export const sendReadReceiptsLoading = () => ({
  type: 'NOTIFICATIONS_READ_RECEIPTS_LOADING',
});

export const sendReadReceiptsFailure = err => ({
  type: 'NOTIFICATIONS_READ_RECEIPTS_FAILURE',
  payload: err,
});

export const sendReadReceiptsSuccess = payload => ({
  type: 'NOTIFICATIONS_READ_RECEIPTS_SUCCESS',
  payload,
});

export const sendReadReceipts = () => (dispatch) => {
  dispatch(sendReadReceiptsLoading());
  const token = Cookies.get('token');
  const { user_id: userId } = decode(token);
  if (!userId) return null;
  return firebase
    .ref(`notifications/${userId}/unreadEvents`)
    .set({})
    .then(() => {
      dispatch(sendReadReceiptsSuccess());
      return {};
    })
    .catch((err) => {
      dispatch(sendReadReceiptsFailure(err));
      return Promise.reject(err);
    });
};
