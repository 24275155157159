import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Avatar from 'material-ui/Avatar';
import { Link } from 'react-router-dom';
import SortableResultsTableHead from './SortableResultsTableHead';

const propTypes = {
  tableData: PropTypes.object.isRequired,
  linkType: PropTypes.string,
  onSelect: PropTypes.func,
  spacer: PropTypes.bool,
  options: PropTypes.array,
};

const defaultProps = {
  data: {},
  linkType: null,
  onSelect: () => {},
  spacer: false,
  options: [],
};

class SortableResultsTable extends Component {
  constructor(props) {
    super(props);

    // Selected doesn't do much atm but might be useful to leave in
    this.state = {
      order: 'asc',
      orderBy: 'username',
      selected: [],
      data: props.tableData.data,
    };
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    /* eslint-disable no-confusing-arrow */
    const data = this.state.data.sort(
      (a, b) =>
        order === 'desc' ? b[orderBy] > a[orderBy] : a[orderBy] > b[orderBy],
    );
    /* eslint-enable no-confusing-arrow */

    this.setState({ data, order, orderBy });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const { data, order, orderBy, selected } = this.state;

    // Below follows some amazingly convoluted code with ridiculous ternaries
    // Refactor later. The logic goes like this:
    // The keys in tableData.columns are used to generate a <TableCell /> for each
    // key. Remember, the keys for our headers and cell must match!

    // Two exceptions:
    // pictures: should always be in an <img> tag or <Avatar />
    // links: if it's a user id, track id, or tag id, link to the appropriate page

    return (
      <Table>
        <SortableResultsTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={this.handleRequestSort}
          columnData={this.props.tableData.columns}
        />
        <TableBody>
          {data.length > 0 &&
            data.map((n) => {
              const isSelected = this.isSelected(n.id);
              return (
                <TableRow
                  hover
                  onClick={event => this.handleClick(event, n.id)}
                  role="checkbox"
                  aria-checked={isSelected}
                  tabIndex="-1"
                  key={n.id}
                  selected={isSelected}
                  style={{ height: 20, fontSize: 16 }}
                >
                  {this.props.tableData.columns.map((column, index) =>
                    (<TableCell
                      disablePadding
                      key={column.id}
                      style={{ padding: 4, verticalAlign: 'middle' }}
                    >
                      {column.id === 'picture'
                        ? <Avatar
                          src={n[column.id]}
                          style={{ objectFit: 'cover', display: 'block' }}
                        />
                        : this.props.linkType
                          ? this.props.linkType === 'user'
                            ? column.id === 'username'
                              ? <Link
                                style={{ color: 'orange', fontWeight: 500 }}
                                to={`/${n.username}`}
                                onClick={this.props.onSelect}
                              >
                                {this.props.options.includes('icons') &&
                                <i
                                  className="fa fa-user"
                                  style={{
                                    float: 'left',
                                    color: 'black',
                                    margin: '0 10px',
                                  }}
                                />}
                                {n[column.id]}
                              </Link>
                              : <p>
                                {n[column.id]}
                              </p>
                            : this.props.linkType === 'track'
                              ? column.id === 'track'
                                ? <Link
                                  style={{ fontWeight: 500 }}
                                  to={`/tracks/${n.publicId}`}
                                  onClick={this.props.onSelect}
                                >
                                  {this.props.options.includes('icons') &&
                                  <i
                                    className="fa fa-music"
                                    style={{
                                      float: 'left',
                                      color: 'black',
                                      margin: '0 10px',
                                    }}
                                  />}
                                  {n[column.id]}
                                </Link>
                                : <p>
                                  {n[column.id]}
                                </p>
                              : this.props.linkType === 'tag'
                                ? column.id === 'tag'
                                  ? <Link
                                    style={{
                                      color: 'coral',
                                      fontWeight: 500,
                                    }}
                                    to={`/tags/${n.tag}`}
                                    onClick={this.props.onSelect}
                                  >
                                    {this.props.options.includes('icons') &&
                                    <i
                                      className="fa fa-hashtag"
                                      style={{
                                        float: 'left',
                                        color: 'black',
                                        margin: '0 10px',
                                      }}
                                    />}
                                    {n[column.id]}
                                  </Link>
                                  : <p>
                                    {n[column.id]}
                                  </p>
                                : <p>
                                    Invalid Link Type - Currently, links can go
                                    to
                                    /users, /track, or /tags
                                  </p>
                          : n.id}
                    </TableCell>),
                  )}
                </TableRow>
              );
            })}
          {!data.length &&
            <span style={{ padding: 15, fontSize: 16 }}>No results</span>}
        </TableBody>
        {this.props.spacer && <div id="spacer" style={{ height: 20 }} />}
      </Table>
    );
  }
}

SortableResultsTable.propTypes = propTypes;
SortableResultsTable.defaultProps = defaultProps;

const styleSheet = createStyles(theme => ({
  paper: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
}));

export default withStyles(styleSheet)(SortableResultsTable);
