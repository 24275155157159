import React from 'react';
import gql from 'graphql-tag';
import Settings from 'components/Settings';
import { Query } from 'react-apollo';
import CircularProgress from '@material-ui/core/CircularProgress';

const SETTINGS_QUERY = gql`
  query SettingsPageQuery {
    user: currentUser {
      ...Settings_user
    }
  }
  ${Settings.fragments.user}
`;

const SettingsPage = () => (
  <Query query={SETTINGS_QUERY}>
    {({ loading, error, data }) => {
      if (loading) {
        return (
          <div className="mt-12 ml-16">
            <CircularProgress size={50} style={{ color: '#2a585d' }} />
          </div>
        );
      }
      if (error) return <div>Error</div>;

      return <Settings user={data.user} />;
    }}
  </Query>
);

export default SettingsPage;
