/**
 * @flow
 * @relayHash 871b75840da0f1f42e2587bb2d83edc5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TrackForm_allGenres$ref = any;
type TrackForm_allMoods$ref = any;
export type CreateTrackPageQueryVariables = {|
  genreCondition: {
    id?: ?number,
    genre?: ?string,
    parent?: ?number,
  },
|};
export type CreateTrackPageQueryResponse = {|
  +user: ?{|
    +private: ?{|
      +userId: ?any,
      +email: ?string,
      +stripeUser: ?boolean,
    |},
  |},
  +allGenres: ?{|
    +$fragmentRefs: TrackForm_allGenres$ref,
  |},
  +allMoods: ?{|
    +$fragmentRefs: TrackForm_allMoods$ref,
  |},
|};
*/


/*
query CreateTrackPageQuery(
  $genreCondition: GenreCondition!
) {
  user: currentUser {
    private {
      userId
      email
      stripeUser
    }
  }
  allGenres: allGenres(condition: $genreCondition) {
    ...TrackForm_allGenres
  }
  allMoods: allMoods {
    ...TrackForm_allMoods
  }
}

fragment TrackForm_allGenres on GenresConnection {
  edges {
    node {
      genreId: id
      genre
    }
  }
}

fragment TrackForm_allMoods on MoodsConnection {
  edges {
    node {
      mood
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "genreCondition",
    "type": "GenreCondition!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "LinkedField",
  "alias": "user",
  "name": "currentUser",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "private",
      "storageKey": null,
      "args": null,
      "concreteType": "UserPrivate",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "userId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "email",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "stripeUser",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
},
v2 = [
  {
    "kind": "Variable",
    "name": "condition",
    "variableName": "genreCondition",
    "type": "GenreCondition"
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "CreateTrackPageQuery",
  "id": null,
  "text": "query CreateTrackPageQuery(\n  $genreCondition: GenreCondition!\n) {\n  user: currentUser {\n    private {\n      userId\n      email\n      stripeUser\n    }\n  }\n  allGenres: allGenres(condition: $genreCondition) {\n    ...TrackForm_allGenres\n  }\n  allMoods: allMoods {\n    ...TrackForm_allMoods\n  }\n}\n\nfragment TrackForm_allGenres on GenresConnection {\n  edges {\n    node {\n      genreId: id\n      genre\n    }\n  }\n}\n\nfragment TrackForm_allMoods on MoodsConnection {\n  edges {\n    node {\n      mood\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateTrackPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      v1,
      {
        "kind": "LinkedField",
        "alias": "allGenres",
        "name": "allGenres",
        "storageKey": null,
        "args": v2,
        "concreteType": "GenresConnection",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "TrackForm_allGenres",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "allMoods",
        "name": "allMoods",
        "storageKey": null,
        "args": null,
        "concreteType": "MoodsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "TrackForm_allMoods",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateTrackPageQuery",
    "argumentDefinitions": v0,
    "selections": [
      v1,
      {
        "kind": "LinkedField",
        "alias": "allGenres",
        "name": "allGenres",
        "storageKey": null,
        "args": v2,
        "concreteType": "GenresConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "GenresEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Genre",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": "genreId",
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "genre",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "allMoods",
        "name": "allMoods",
        "storageKey": null,
        "args": null,
        "concreteType": "MoodsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "MoodsEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Mood",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "mood",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
(node/*: any*/).hash = 'b0d0785f882de1df773111bd26bb9bbe';
module.exports = node;
