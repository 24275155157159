import * as auth from './auth';
import uploadToS3 from './s3';
import projects from './projects';
import clips from './clips';
import posts from './posts';
import tracks from './tracks';
import users from './users';
import session from './session';
import universal from './universal';
import modals from './modals';
import checkout from './checkout';
import windowSession from './windowSession';

// export {default as auth} from './auth';
export {default as uploadToS3} from './s3';

export default {
  ...auth,
  ...uploadToS3,
  ...projects,
  ...clips,
  ...posts,
  ...tracks,
  ...users,
  ...session,
  ...universal,
  ...modals,
  ...checkout,
  ...windowSession,
};
