/**
 * @flow
 * @relayHash 599ffc19158d26d774a07cd4beac709c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Followers_user$ref = any;
export type FollowersPageQueryVariables = {|
  username: string,
|};
export type FollowersPageQueryResponse = {|
  +user: ?{|
    +$fragmentRefs: Followers_user$ref,
  |},
|};
*/


/*
query FollowersPageQuery(
  $username: String!
) {
  user: userByUsernameAndDeletedAndSkill(username: $username, deleted: false, skill: PRODUCER) {
    ...Followers_user
  }
}

fragment Followers_user on User {
  id
  displayName
  username
  picture
  ...FollowerList_user
}

fragment FollowerList_user on User {
  followers: followsByFollowedId(first: 100, orderBy: CREATED_AT_DESC) {
    totalCount
    edges {
      node {
        nodeId
        follower: userByFollowerId {
          ...Follower_follower
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment Follower_follower on User {
  username
  displayName
  picture
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "username",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "deleted",
    "value": false,
    "type": "Boolean!"
  },
  {
    "kind": "Literal",
    "name": "skill",
    "value": "PRODUCER",
    "type": "UserSkill!"
  },
  {
    "kind": "Variable",
    "name": "username",
    "variableName": "username",
    "type": "String!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "displayName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "picture",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "FollowersPageQuery",
  "id": null,
  "text": "query FollowersPageQuery(\n  $username: String!\n) {\n  user: userByUsernameAndDeletedAndSkill(username: $username, deleted: false, skill: PRODUCER) {\n    ...Followers_user\n  }\n}\n\nfragment Followers_user on User {\n  id\n  displayName\n  username\n  picture\n  ...FollowerList_user\n}\n\nfragment FollowerList_user on User {\n  followers: followsByFollowedId(first: 100, orderBy: CREATED_AT_DESC) {\n    totalCount\n    edges {\n      node {\n        nodeId\n        follower: userByFollowerId {\n          ...Follower_follower\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment Follower_follower on User {\n  username\n  displayName\n  picture\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "FollowersPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "user",
        "name": "userByUsernameAndDeletedAndSkill",
        "storageKey": null,
        "args": v1,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "Followers_user",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FollowersPageQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "user",
        "name": "userByUsernameAndDeletedAndSkill",
        "storageKey": null,
        "args": v1,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          v2,
          v3,
          v4,
          {
            "kind": "LinkedField",
            "alias": "followers",
            "name": "followsByFollowedId",
            "storageKey": "followsByFollowedId(first:100,orderBy:\"CREATED_AT_DESC\")",
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 100,
                "type": "Int"
              },
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "CREATED_AT_DESC",
                "type": "[FollowsOrderBy!]"
              }
            ],
            "concreteType": "FollowsConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "FollowsEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Follow",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "nodeId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": "follower",
                        "name": "userByFollowerId",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                          v3,
                          v2,
                          v4
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": "followers",
            "name": "followsByFollowedId",
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 100,
                "type": "Int"
              },
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "CREATED_AT_DESC",
                "type": "[FollowsOrderBy!]"
              }
            ],
            "handle": "connection",
            "key": "FollowerList_followers",
            "filters": [
              "orderBy"
            ]
          }
        ]
      }
    ]
  }
};
})();
(node/*: any*/).hash = 'd59fee5cbe35dc81d5f3b8eae802cece';
module.exports = node;
