/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from 'relay-runtime';
declare export opaque type DownloadFree_track$ref: FragmentReference;
export type DownloadFree_track = {|
  +name: string,
  +fileUrl: ?string,
  +$refType: DownloadFree_track$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "DownloadFree_track",
  "type": "Track",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fileUrl",
      "args": null,
      "storageKey": null
    }
  ]
};
(node/*: any*/).hash = '040bcb38410ea7b145a18add0bdfbf60';
module.exports = node;
