import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { addProjectAsync } from 'actions/projects';

const propTypes = {
  history: PropTypes.object.isRequired,
  addProjectAsync: PropTypes.func.isRequired,
};

const Collaboration = ({ history, ...props }) => {
  const handleNewProject = () =>
    props
      .addProjectAsync({
        name: 'Untitled project',
        bpm: 120,
      })
      .then((project) => {
        history.push(`/projects/${project.id}`);
      });

  return (
    <div className="cont py-4 px-6">
      <div className="relative mb-3">
        <div className="text-sm font-bold inline-block text-gray-dark">
          Collab
          <span className="ml-2 font-light">Coming Soon</span>
        </div>
        <i
          className="material-icons absolute section-color pin-r text-2xl"
          style={{
            marginTop: '-2px',
          }}
        >
          group_add
        </i>
      </div>
      <div className="text-gray-dark text-sm leading-normal mb-3">
        Share tracks with other artists and collaborate in one central place.
      </div>
      <button
        className="btn btn-pill font-bold text-xs w-full py-2"
        onClick={() => {
          handleNewProject();
        }}
        disabled
      >
        Create Project
      </button>
    </div>
  );
};

Collaboration.propTypes = propTypes;

export default compose(connect(null, { addProjectAsync }), withRouter)(
  Collaboration,
);

// export default MusicSection;
