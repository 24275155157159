import React from 'react';
import Privacy from 'components/Static/Privacy';

const PrivacyPage = () => (
  <div id="privacy-page" className="container">
    <Privacy />
  </div>
);

export default PrivacyPage;
