import React from 'react';
import PropTypes from 'prop-types';
import { QueryRenderer } from 'react-relay';
import { Redirect } from 'react-router-dom';

const propTypes = {
  variables: PropTypes.object.isRequired,
  query: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  loadingComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
    .isRequired,
};

const NewQR = ({
  auth,
  variables,
  query,
  component: Component,
  loadingComponent: LoadingComponent,
  ...rest
}) => (
  <QueryRenderer
    environment={auth.environment}
    variables={variables}
    query={query}
    render={({ error, props }) => {
      if (error) {
        return <Redirect to="/error" />;
      } else if (props) {
        return <Component {...props} {...rest} auth={auth} />;
      }
      return LoadingComponent || <div>Loading ...</div>;
    }}
  />
);

NewQR.propTypes = propTypes;

export default NewQR;
