import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import { connect } from 'react-redux';
import LandingTopMood from './LandingTopMood';

const TOP_MOODS_FRAG = gql`
  fragment LandingTopMoods_moods on MoodCountsConnection {
    edges {
      node {
        mood
        ...LandingTopMood_mood
      }
    }
  }
  ${LandingTopMood.fragments.mood}
`;

const propTypes = {
  moods: PropTypes.object.isRequired,
  screenSize: PropTypes.string.isRequired,
};

const LandingTopMoods = ({ moods, screenSize }) => {
  const maxRowNumber = Math.ceil(moods.edges.length / 2);

  const renderScrollContainer = () => (
    <div className="overflow-x-scroll overflow-y-hidden scroll-invisible">
      <div className="whitespace-no-wrap mb-2" style={{ paddingLeft: '5%' }}>
        {moods.edges
          .slice(0, maxRowNumber)
          .map(mood => (
            <LandingTopMood key={`mood${mood.node.mood}`} mood={mood.node} />
          ))}
      </div>
      <div className="whitespace-no-wrap" style={{ paddingLeft: '5%' }}>
        {moods.edges
          .slice(maxRowNumber)
          .map(mood => (
            <LandingTopMood key={`mood${mood.node.mood}`} mood={mood.node} />
          ))}
      </div>
    </div>
  );

  const renderColumnContainer = () => (
    <div>
      {moods.edges.map(mood => (
        <LandingTopMood key={`mood${mood.node.mood}`} mood={filter(LandingTopMood.fragments.mood, mood.node)} />
      ))}
    </div>
  );
  return (
    <div className="pt-7 md:pt-0">
      <div className="font-bold pb-6 text-lg color-white text-center w-9/10 mx-auto md:pb-8 md:text-left md:w-full">
        Top Moods
      </div>
      {screenSize === 'default' || screenSize === 'sm'
        ? renderScrollContainer()
        : renderColumnContainer()}
    </div>
  );
};

LandingTopMoods.propTypes = propTypes;
LandingTopMoods.fragments = { moods: TOP_MOODS_FRAG };

export default connect(state => ({ screenSize: state.windowSession.size }))(
  LandingTopMoods,
);
