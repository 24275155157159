import React from 'react';
import PropTypes from 'prop-types';
import graphql from 'babel-plugin-relay/macro';
import createFragContainer from 'utils/create-frag-container';
import TrackPlayer from 'components/common/TrackPlayer';

const likedFrag = graphql`
  fragment LikedTracks_user on User {
    id
    likes: likesByUserId(first: 100) @connection(key: "LikedTracks_likes") {
      totalCount
      edges {
        node {
          track: trackByTrackId {
            id
            name
            picture
            fileUrl
            description
            publicId
            userId
            user: userByUserId {
              id
              username
              displayName
              ...TrackPlayer_user
            }
            ...TrackPlayer_track
          }
        }
      }
    }
  }
`;

const propTypes = {
  user: PropTypes.object.isRequired,
};

const LikedTracks = ({ user }) => (
  <div id="liked-tracks">
    <div className="subtitle">Collection</div>
    <ul className="sm:mx-6 my-4">
      {user.likes.edges.map(likesEdge => (
        <TrackPlayer
          key={likesEdge.node.track.id}
          className="pt-4 pb-2 sm:pb-4 sm:pt-0"
          track={likesEdge.node.track}
          tracks={user.likes.edges.map(edge => edge.node.track)}
          currentUser={user.id}
          user={likesEdge.node.track.user}
        />
      ))}
    </ul>
  </div>
);

LikedTracks.propTypes = propTypes;

export default createFragContainer([likedFrag], LikedTracks);
