import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  style: PropTypes.object,
};

const defaultProps = {
  style: {},
};

const Cart = ({ style }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill={style.color}
      d="m 9,964.36221 c -1.1046,0 -2,0.8954 -2,2 0,1.1046 0.8954,2 2,2 l 12.375,0 10.5312,49.74999 c 0.2555,1.2291 1.0765,2.2629 2.0938,2.25 l 50,0 c 1.0566,0.015 2.0312,-0.9433 2.0312,-2 0,-1.0567 -0.9746,-2.015 -2.0312,-2 l -48.375,0 -10.6562,-50.43749 c -0.1983,-0.884 -1.0629,-1.5702 -1.9688,-1.5625 z m 22,12 6,29.99999 49,0 7,-29.99999 z m 13,49.99999 c -3.8659,0 -7,3.134 -7,7 0,3.866 3.1341,7 7,7 3.866,0 7,-3.134 7,-7 0,-3.866 -3.134,-7 -7,-7 z m 30,0 c -3.8659,0 -7,3.134 -7,7 0,3.866 3.1341,7 7,7 3.866,0 7,-3.134 7,-7 0,-3.866 -3.134,-7 -7,-7 z"
      transform="translate(0 -952.362)"
    />
  </svg>
);

Cart.propTypes = propTypes;
Cart.defaultProps = defaultProps;

export default Cart;
