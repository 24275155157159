import React from 'react';
import { Link } from 'react-router-dom';
import LoginBlock from './LoginBlock';

const Login = () => (
  <div className="container mt-8 pt-8 text-center">
    <LoginBlock />
    <div className="mt-12 text-gray-dark">
      No account yet?&nbsp;
      <Link to="/signup" className="font-semibold hover:text-gray-darker">
        Sign up
      </Link>
      &nbsp;with Plug Music now.
    </div>
  </div>
);

export default Login;
