import JSZip from 'jszip';

const changeToArrayBuffer = (zipObject, fileName) =>
  new Promise((resolve, reject) => {
    zipObject.files[fileName].async('nodebuffer').then((data) => {
      // data is now our actual data (Uint8Array)
      // Clean our input: trim null values, only work with .mp3's and
      // .wav's, get rid of weird OSX shit
      if (
        data.length
        // && (fileName.endsWith('.wav') || fileName.endsWith('.mp3')) &&
        // !fileName.startsWith('__MACOSX/')
      ) {
        const file = new File([data], `${fileName}`, {
          type: fileName.endsWith('.wav') ? 'audio/wav' : 'audio/mp3',
        });
        resolve(file);
      } else {
        reject(new Error('Invalid File'));
      }
    });
  });


const filterForAudioFiles = unzippedFile =>
  Object.keys(unzippedFile.files).map((filename) => {
    if (
      (filename.endsWith('.wav') || filename.endsWith('.mp3')) &&
      !filename.startsWith('__MACOSX/')
    ) {
      return filename;
      // (add the file to our promises)
      // promises.push(changeToArrayBuffer(zip, filename));
    }
    return null;
  }).filter(audio => audio);


const createPromises = (droppedZip) => {
  const zip = new JSZip();
  return zip
    .loadAsync(droppedZip)
    .then((unzip) => {
      const rawAudioFiles = filterForAudioFiles(unzip);
      const promises = rawAudioFiles.map(rawAudio => changeToArrayBuffer(zip, rawAudio));
      return promises;
    });
};


export const unzip = zip =>
  new Promise(resolve =>
    createPromises(zip)
    .then((fileConversionPromises) => {
      Promise.all(fileConversionPromises)
      .then(files => resolve(files));
    }),
);

export const makeZip = (files, fileName) =>
  new Promise((resolve) => {
    const zip = new JSZip();
    // Add each file to our zip
    files.forEach((file) => {
      // Note: file.name will be foldername/filename
      // This is shorthand for creating a folder of 'foldername' containing file of 'filename'
      zip.file(file.name, file);
    });

    zip.generateAsync({ type: 'blob' })
    .then(blob =>
      resolve(new File([blob], `${fileName}.zip`, { type: 'application/zip' })),
    );
  });

export default module.exports;
