import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { soundbarToggleRepeat } from 'actions/soundbar';

const propTypes = {
  repeat: PropTypes.bool.isRequired,
  soundbarToggleRepeat: PropTypes.func.isRequired,
  iconStyle: PropTypes.object,
};

const defaultProps = {
  iconStyle: {},
};

const Repeat = ({ iconStyle, ...props }) => {
  const toggleRepeat = () => {
    props.soundbarToggleRepeat();
  };

  return (
    <a
      role="button"
      tabIndex={0}
      className="outline-none flex"
      onClick={toggleRepeat}
      style={{ color: props.repeat || 'gray', userSelect: 'none' }}
    >
      <i
        className={classnames(
          'material-icons font-semibold self-center text-xl',
          props.repeat ? 'selected-icon' : 'icon-color',
        )}
        style={iconStyle}
      >
        repeat
      </i>
    </a>
  );
};

Repeat.propTypes = propTypes;
Repeat.defaultProps = defaultProps;

export default connect(
  state => ({
    repeat: state.soundbar.repeat,
  }),
  { soundbarToggleRepeat },
)(Repeat);
