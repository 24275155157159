import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const ExploreDesktop = ({ className }) => (
  <Link to="/explore" className={classnames('flex justify-center items-center nowrap', className)}>
    <i className="fa fa-compass text-lg pl-3 color-bg" aria-hidden="true" />
    <div className="desc px3 color-bg" style={{ paddingLeft: '6px' }}>Find Beat</div>
  </Link>
);

ExploreDesktop.propTypes = propTypes;
ExploreDesktop.defaultProps = defaultProps;

export default ExploreDesktop;
