import merge from 'lodash/merge';
import prodEnv from 'environments/production.json';
import devEnv from 'environments/development.json';

const production = process.env.REACT_APP_ENV === 'production';

let localEnv = {};

try {
  localEnv = require('environments/local.json');
} catch (e) {
  console.warn('You are missing an environments local.json file');
}

const env = production ? merge(localEnv, prodEnv) : merge(devEnv, localEnv);

export const apiUrl = env.ROOT_URL;
export const newApiUrl = env.NEW_ROOT_URL;
export const s3Url = env.S3.URL;
export const s3Bucket = env.S3.BUCKET;
export const cloudfrontUrl = env.CLOUDFRONT.URL;
export const trackUrl = `${cloudfrontUrl}/track/`;
export const avatarImageUrl = `${cloudfrontUrl}/avatars/`;
export const moodImageUrl = `${cloudfrontUrl}/app-images/moods/`;
export const genreImageUrl = `${cloudfrontUrl}/app-images/genres/`;
export const tagImageUrl = `${cloudfrontUrl}/app-images/tags/`;
export const iconUrl = `${cloudfrontUrl}/app-images/icons/`;
export const secret = '12345678';
export const stripeClientId = env.STRIPE_CLIENT_ID;
export const stipeRedirectUri = env.BROWSER_URL + env.STIPE_REDIRECT_URI;
export const stripePublic = env.STRIPE_PUBLIC_KEY;
export const GATrackId = 'UA-122805537-1';

export const firebaseConfig = env.FIREBASE_CONFIG;

export default env;
