import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { genreImageUrl } from 'config';
import displayImage from 'utils/display-image';
import PlugImage from 'components/common/PlugImage';

const TOP_GENRE = gql`
  fragment LandingTopGenre_genre on GenreCount {
    genre
  }
`;

const propTypes = {
  genre: PropTypes.object.isRequired,
};

const LandingTopGenre = ({ genre }) => (
  <Link
    className="flex h-full w-full"
    to={`/genres/${genre.genre}`}
    id={`genre${genre.genre}`}
  >
    <div className="flex relative pointer sat-contrast-hover w-full">
      <PlugImage
        imageSource={displayImage(genreImageUrl, genre.genre)}
        alt="Genre"
        className="flex-1 cover h-full w-full"
      />
      {/* <img
        src={displayImage(genreImageUrl, genre.genre)}
        alt="Genre"
        className="flex-1 cover br-4"
      /> */}
      <div
        style={{ left: '8px', top: '12px' }}
        className="text-base font-bold absolute color-white text-capitalize md:text-lg"
      >
        {genre.genre}
      </div>
    </div>
  </Link>
);

LandingTopGenre.propTypes = propTypes;
LandingTopGenre.fragments = { genre: TOP_GENRE };

export default LandingTopGenre;
