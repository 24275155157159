
export const FETCH_TRACKS_REQUEST = 'FETCH_TRACKS_REQUEST';
export const FETCH_TRACKS_FAILURE = 'FETCH_TRACKS_FAILURE';
export const FETCH_TRACKS_SUCCESS = 'FETCH_TRACKS_SUCCESS';

export const ADD_TRACK_CLIENT = 'ADD_TRACK_CLIENT';
export const ADD_TRACK_REQUEST = 'ADD_TRACK_REQUEST';
export const ADD_TRACK_FAILURE = 'ADD_TRACK_FAILURE';
export const ADD_TRACK_SUCCESS = 'ADD_TRACK_SUCCESS';

export const TRASH_TRACK_CLIENT = 'TRASH_TRACK_CLIENT';

export const DELETE_TRACK_REQUEST = 'DELETE_TRACK_REQUEST';
export const DELETE_TRACK_FAILURE = 'DELETE_TRACK_FAILURE';
export const DELETE_TRACK_SUCCESS = 'DELETE_TRACK_SUCCESS';

export const UPDATE_TRACK_REQUEST = 'UPDATE_TRACK_REQUEST';
export const UPDATE_TRACK_FAILURE = 'UPDATE_TRACK_FAILURE';
export const UPDATE_TRACK_SUCCESS = 'UPDATE_TRACK_SUCCESS';

export const UPDATE_VOLUME = 'UPDATE_VOLUME';
export const UPDATE_NAME = 'UPDATE_NAME';

export const TOGGLE_MUTE = 'TOGGLE_MUTE';
export const TOGGLE_SOLO = 'TOGGLE_SOLO';

export const TOGGLE_ARM = 'TOGGLE_ARM';

export const RESET_TRACKS = 'RESET_TRACKS';

export default module.exports;
