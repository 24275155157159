import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ExitButton from 'components/common/ExitButton';
import { userRequest } from 'rest/users';
import * as toastActions from 'actions/toasts';
import SellBeatsModalForm from './SellBeatsModalForm';

const propTypes = {
  onClose: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
};

const SellBeatsModal = ({ onClose, showMessage }) => {
  const sendUserRequest = (data) => {
    userRequest(data)
      .then((response) => {
        showMessage({ type: 'success', text: response.message });
      })
      .catch((err) => {
        showMessage({ type: 'error', text: err.message });
      });
    // window.gtag('event', 'Register', {
    //   event_category: 'SellBeats',
    //   event_label: data.email,
    // });
    onClose();
  };

  return (
    <div id="sell-beats-modal" className="p-10">
      <div className="flex justify-between mb-6">
        <h1 className="title" style={{ marginBottom: 0 }}>
          Request to join the site
        </h1>
        <ExitButton id="delete-track-exit" onClick={onClose} />
      </div>
      <div className="my-6">
        <h2 className="font-normal font-serif md:w-4/5 text-xl">
          We are actively building the Plug Music website to provide the best
          experience for beat makers. As such, we are placing registrations on
          an <span className="font-bold">invitation list</span> that we will
          follow up with.
        </h2>
      </div>
      <SellBeatsModalForm onSubmit={sendUserRequest} />
    </div>
  );
};

SellBeatsModal.propTypes = propTypes;

export default connect(
  state => ({ toasts: state.toasts.get('data') }),
  toastActions,
)(SellBeatsModal);
