import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import SuggestedProducers from './SuggestedProducers';

const propTypes = {
  auth: PropTypes.object.isRequired,
};

const defaultProps = {
  user: {},
  followSuggestions: {},
};

const SUGGESTED_PRODUCERS = gql`
  query SuggestedProducersContainerQuery(
    $userId: UUID!
    $popularUsersToSuggest: Int!
    $randomUsersToSuggest: Int!
  ) {
    followSuggestions: getFollowSuggestions(
      userid: $userId
      popularcount: $popularUsersToSuggest
      randomcount: $randomUsersToSuggest
    ) {
      ...SuggestedProducers_users
    }
  }
  ${SuggestedProducers.fragments.users}
`;

const SuggestedProducersContainer = ({ auth }) => (
  <Query
    query={SUGGESTED_PRODUCERS}
    variables={{
      userId: auth.userId,
      popularUsersToSuggest: 2,
      randomUsersToSuggest: 2,
    }}
  >
    {({ loading, error, data }) => {
      if (loading) {
        return <div>Loading ...</div>;
      }
      if (error) {
        return <div>Error</div>;
      }
      return <SuggestedProducers users={data.followSuggestions} />;
    }}
  </Query>
);

SuggestedProducersContainer.propTypes = propTypes;
SuggestedProducersContainer.defaultProps = defaultProps;

export default SuggestedProducersContainer;
