import Immutable, { fromJS } from 'immutable';

const initialState = fromJS({
  data: {},
  unread: {},
  isLoading: false,
  error: '',
});

export default function notifications(state = initialState, { type, payload }) {
  switch (type) {
  case 'FETCH_NOTIFICATIONS_LOADING':
    return state.set('isLoading', true).set('error', '');

  case 'FETCH_NOTIFICATIONS_FAILURE':
  case 'FETCH_UNREAD_NOTIFICATIONS_FAILURE':
  case 'NOTIFICATIONS_READ_RECEIPTS_FAILURE':
    return state.set('isLoading', false).set('error', payload);

  case 'FETCH_NOTIFICATIONS_SUCCESS':
    return state.withMutations((map) => {
      map
          .set('isLoading', false)
          .set('error', '')
          .update('data', data => data.mergeDeep(payload));
    });

  case 'FETCH_UNREAD_NOTIFICATIONS_SUCCESS': {
    return state.withMutations((map) => {
      map
          .set('error', '')
          .set('unread', Immutable.Map(payload || {}))
          .set('isLoading', false);
    });
  }

  case 'NOTIFICATIONS_READ_RECEIPTS_SUCCESS': {
    return state
        .set('unread', Immutable.Map())
        .set('error', '')
        .set('isLoading', false);
  }

  case 'FETCH_UNREAD_NOTIFICATIONS_LOADING':
  case 'NOTIFICATIONS_READ_RECEIPTS_LOADING':
  default:
    return state;
  }
}
