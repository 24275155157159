/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type TrackDesktop_user$ref = any;
type TrackMobile_user$ref = any;
import type { FragmentReference } from 'relay-runtime';
declare export opaque type TrackPlayer_user$ref: FragmentReference;
export type TrackPlayer_user = {|
  +$fragmentRefs: (TrackDesktop_user$ref & TrackMobile_user$ref),
  +$refType: TrackPlayer_user$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "TrackPlayer_user",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "TrackDesktop_user",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "TrackMobile_user",
      "args": null
    }
  ]
};
(node/*: any*/).hash = 'a9c2ccfdd7d7d73a6270646bfc73d2f9';
module.exports = node;
