/**
 * @flow
 * @relayHash e62f3039cbd4fb478e300567bc34d94b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SoldBeats_beats$ref = any;
type SoldBeats_query$ref = any;
export type ProjectsPageQueryVariables = {| |};
export type ProjectsPageQueryResponse = {|
  +coolProjects: {|
    +edges: $ReadOnlyArray<{|
      +node: ?{|
        +id: any,
        +name: string,
        +updatedAt: any,
        +createdAt: any,
        +createdBy: ?any,
        +userProjectsByProjectId: {|
          +edges: $ReadOnlyArray<{|
            +node: ?{|
              +userByUserId: ?{|
                +id: any,
                +username: ?string,
                +displayName: string,
                +picture: ?string,
              |},
            |},
          |}>,
        |},
      |},
    |}>,
  |},
  +beats: {|
    +$fragmentRefs: SoldBeats_beats$ref,
  |},
  +query: {|
    +$fragmentRefs: SoldBeats_query$ref,
  |},
|};
*/


/*
query ProjectsPageQuery {
  coolProjects: getAllProjects {
    edges {
      node {
        id
        name
        updatedAt
        createdAt
        createdBy
        userProjectsByProjectId {
          edges {
            node {
              userByUserId {
                id
                username
                displayName
                picture
              }
            }
          }
        }
      }
    }
  }
  beats: getBeatsSold {
    ...SoldBeats_beats
  }
  query {
    ...SoldBeats_query
  }
}

fragment SoldBeats_beats on BeatsSoldsConnection {
  totalCount
  ...SoldBeatsList_beats
}

fragment SoldBeats_query on Query {
  totalDollarsSold
}

fragment SoldBeatsList_beats on BeatsSoldsConnection {
  edges {
    node {
      productId
      licenseType
      trackName
      soldAt
      price
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "LinkedField",
  "alias": "coolProjects",
  "name": "getAllProjects",
  "storageKey": null,
  "args": null,
  "concreteType": "ProjectsConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "ProjectsEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Project",
          "plural": false,
          "selections": [
            v0,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "name",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "updatedAt",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "createdAt",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "createdBy",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "userProjectsByProjectId",
              "storageKey": null,
              "args": null,
              "concreteType": "UserProjectsConnection",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "edges",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "UserProjectsEdge",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "node",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "UserProject",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "userByUserId",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "User",
                          "plural": false,
                          "selections": [
                            v0,
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "username",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "displayName",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "picture",
                              "args": null,
                              "storageKey": null
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ProjectsPageQuery",
  "id": null,
  "text": "query ProjectsPageQuery {\n  coolProjects: getAllProjects {\n    edges {\n      node {\n        id\n        name\n        updatedAt\n        createdAt\n        createdBy\n        userProjectsByProjectId {\n          edges {\n            node {\n              userByUserId {\n                id\n                username\n                displayName\n                picture\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n  beats: getBeatsSold {\n    ...SoldBeats_beats\n  }\n  query {\n    ...SoldBeats_query\n  }\n}\n\nfragment SoldBeats_beats on BeatsSoldsConnection {\n  totalCount\n  ...SoldBeatsList_beats\n}\n\nfragment SoldBeats_query on Query {\n  totalDollarsSold\n}\n\nfragment SoldBeatsList_beats on BeatsSoldsConnection {\n  edges {\n    node {\n      productId\n      licenseType\n      trackName\n      soldAt\n      price\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ProjectsPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      v1,
      {
        "kind": "LinkedField",
        "alias": "beats",
        "name": "getBeatsSold",
        "storageKey": null,
        "args": null,
        "concreteType": "BeatsSoldsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "SoldBeats_beats",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "query",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "SoldBeats_query",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ProjectsPageQuery",
    "argumentDefinitions": [],
    "selections": [
      v1,
      {
        "kind": "LinkedField",
        "alias": "beats",
        "name": "getBeatsSold",
        "storageKey": null,
        "args": null,
        "concreteType": "BeatsSoldsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "BeatsSoldsEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "BeatsSold",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "productId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "licenseType",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "trackName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "soldAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "price",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "query",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalDollarsSold",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
(node/*: any*/).hash = '1bd0b87c09f16de67078bd0069fd5916';
module.exports = node;
