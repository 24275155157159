import { saveStorage } from 'utils/storage';

const storageMiddleware = store => next => (action) => {
  const oldCartItems = store.getState().cart.get('items');
  const result = next(action);
  const newCartItems = store.getState().cart.get('items');
  if (oldCartItems !== newCartItems && oldCartItems.size + newCartItems.size > 0) {
    console.log('save cart'); // eslint-disable-line
    saveStorage('cart', JSON.stringify(newCartItems.toJS()));
  }
  return result;
};

export default storageMiddleware;
