import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom';
import capitalize from 'lodash/capitalize';
import classnames from 'classnames';
import createFragContainer from 'utils/create-frag-container';
import displayPrice from 'utils/display-price';
import * as modalActions from 'actions/modals';
import * as cartActions from 'actions/cart';
import LicenseContent from './LicenseContent';

const description = {
  basic: 'Lease the beat with restrictions',
  premium: 'Lease the beat with fewer restrictions',
  exclusive: 'Own the exclusive right to the beat',
};

const propTypes = {
  product: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  screenType: PropTypes.object.isRequired,
  addCartItem: PropTypes.func.isRequired,
  trackId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

class TrackProduct extends Component {
  state = {
    expanded: this.props.product.type.toLowerCase() === 'basic',
    information: false,
  };

  render() {
    const {
      trackId, product, showModal, addCartItem, history,
    } = this.props;
    const { expanded, informational } = this.state;
    const productType = product.type.toLowerCase();
    const grammarArticle = productType === 'exclusive' ? 'an' : 'a';
    const button = {};
    if (productType === 'basic') {
      button.color = 'btn-green';
      button.border = 'border-green-dark';
    } else if (productType === 'premium') {
      button.color = 'btn-yellow';
      button.border = 'border-yellow-custom';
    } else if (productType === 'exclusive') {
      button.color = 'btn-blue';
      button.border = 'border-blue-dark';
    }

    const isDesktop = this.props.screenType !== 'mobile';

    return (
      <div
        id="track-product"
        className={classnames(
          'pb-4 md:border-b-0 md:border-t-0 md:px-4 md:py-4 md:w-1/3',
          {
            'border-t md:border-l pt-3': productType !== 'basic',
            'border-b': productType === 'exclusive',
          },
        )}
        role="presentation"
        onClick={() => !isDesktop && this.setState({ expanded: !expanded })}
      >
        <div className={classnames('flex')}>
          <div className="">
            <p
              className={classnames('block text-black text-lg font-bold pb-2')}
            >
              {capitalize(product.type)}
            </p>
            <div className="flex text-black tracking-wide text-gray-darkest">
              {displayPrice(product.price)}
            </div>
            {/* <p className="text-xs text-gray-darker pt-2">
              {description[product.type.toLowerCase()]}
            </p> */}
          </div>
          <i
            className={classnames('fa ml-auto text-xl pt-1', {
              'fa-caret-up': expanded,
              'fa-caret-down': !expanded,
              'md:hidden': isDesktop,
            })}
          />
        </div>
        {(expanded || isDesktop) && (
          <div>
            <div
              role="button"
              tabIndex={0}
              to={`/checkouts/${product.id}/new`}
              className="text-lg font-bold"
              onClick={() => {
                addCartItem(product.id, product.price, trackId);
                history.push('/cart');
              }}
            >
              <div
                className={classnames(
                  'btn flex justify-center mt-5',
                  button.color,
                  button.border,
                )}
              >
                Add to Cart
              </div>
            </div>
            <p
              className={classnames(
                'font-semibold inline-block font-semibold pt-3 pb-1 border-b cursor-pointer',
                button.border,
              )}
              onClick={(e) => {
                e.stopPropagation();
                if (isDesktop) {
                  showModal('LicenseModal', { type: productType });
                } else {
                  this.setState({ informational: !informational });
                }
              }}
              role="presentation"
            >
              {`What is ${grammarArticle} ${productType} license?`}
            </p>
            {informational && <LicenseContent type={productType} />}
          </div>
        )}
      </div>
    );
  }
}

TrackProduct.propTypes = propTypes;
TrackProduct.fragments = {
  product: gql`
    fragment TrackProduct_product on Product {
      id
      type
      price
    }
  `,
};

export default compose(
  withRouter,
  connect(
    state => ({ modals: state.modals, screenType: state.windowSession.type }),
    { ...modalActions, ...cartActions },
  ),
)(TrackProduct);
