import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const propTypes = {
  message: PropTypes.string.isRequired,
};

const ValidateError = ({ message }) => (
  <div>
    <h2>Error!</h2>
    <div>{message}</div>
  </div>
);

ValidateError.propTypes = propTypes;

export default connect(state => ({
  message: state.users.get('validationMessage'),
}))(ValidateError);
