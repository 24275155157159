import React from 'react';
import Cart from 'components/Cart';

const CartPage = () => (
  <div id="cart-page" className="mt-12 flex justify-center">
    <Cart />
  </div>
);

export default CartPage;
