import Immutable from 'immutable';
import {
  CHECKOUT_ERROR,
  CHECKOUT_LOADING,
  CHECKOUT_SUCCESS,
  CHECKOUT_FAILURE,
  CHECKOUT_RELOAD,
  CHECKOUT_ADD_GUEST,
} from 'types/checkout';

const initialState = Immutable.fromJS({
  loading: false,
  status: 'pending',
  guest: '',
  errors: {},
});

export default function checkout(state = initialState, { type, payload }) {
  switch (type) {
  case CHECKOUT_LOADING:
    return state.set('loading', true);

  case CHECKOUT_SUCCESS:
    return initialState.set('status', 'success');

  case CHECKOUT_FAILURE:
    return state.set('status', 'failure');

  case CHECKOUT_ERROR: {
    const errors = state.get('errors').merge(payload);
    return state.set('errors', errors);
  }

  case CHECKOUT_ADD_GUEST:
    return state.set('guest', payload);

  case CHECKOUT_RELOAD:
    return initialState;

  default:
    return state;
  }
}
