import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import { moodImageUrl } from 'config';
import displayImage from 'utils/display-image';
import PlugImage from 'components/common/PlugImage';

const TOP_MOOD = gql`
  fragment LandingTopMood_mood on MoodCount {
    mood
  }
`;

const propTypes = {
  mood: PropTypes.object.isRequired,
};

const LandingTopMood = ({ mood }) => (
  <Link to={`/moods/${mood.mood}`} key={`mood${mood.mood}`} className="md:block md:text-left md:pb-2">
    <div
      className="inline-flex flex-none rounded mr-3 p-3 align-center
      browse-mood-content browse-list-hover pointer landing-mood-color md:mr-0 md:pl-0
      md:bg-transparent"
    >
      <PlugImage
        imageSource={displayImage(moodImageUrl, mood.mood)}
        alt="Mood"
        className="browse-mood-icons"
      />
      {/* <img
        src={displayImage(moodImageUrl, mood.mood)}
        alt="Mood"
        className="browse-mood-icons"
      /> */}
      <div className="browse-mood-text fm capitalize color-white">{mood.mood}</div>
    </div>
  </Link>
);

LandingTopMood.propTypes = propTypes;
LandingTopMood.fragments = { mood: TOP_MOOD };

export default LandingTopMood;
