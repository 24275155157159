import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import { Query } from 'react-apollo';
import DeliverBeat from 'components/Checkout/DeliverBeat';

const DELIVER_BEAT_QUERY = gql`
  query DeliverBeatPageQuery($orderId: UUID!) {
    order: orderById(id: $orderId) {
      ...DeliverBeat_order
    }
  }
  ${DeliverBeat.fragments.order}
`;

const propTypes = {
  match: PropTypes.object.isRequired,
};

const DeliverBeatPage = ({ match: { params: { orderId } } }) => (
  <Query query={DELIVER_BEAT_QUERY} variables={{ orderId }}>
    {({ loading, error, data }) => {
      if (loading) return <div>Loading ...</div>;
      if (error) return <div>Error</div>;

      return (
        <DeliverBeat order={filter(DeliverBeat.fragments.order, data.order)} />
      );
    }}
  </Query>
);

DeliverBeatPage.propTypes = propTypes;

export default DeliverBeatPage;
