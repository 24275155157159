import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import * as modalActions from 'actions/modals';

const propTypes = {
  showModal: PropTypes.func.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const SellBeatsButton = ({ showModal, className }) => (
  <button
    id="sell-beats-button"
    className={classnames(
      'btn btn-pill font-semibold px-8 py-1 py-2 text-sm',
      className,
    )}
    onClick={() => showModal('SellBeatsModal')}
  >
    Join Plug Music
  </button>
);

SellBeatsButton.propTypes = propTypes;
SellBeatsButton.defaultProps = defaultProps;

export default connect(
  null,
  modalActions,
)(SellBeatsButton);
