import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const propTypes = {
  message: PropTypes.string.isRequired,
};


const InviteError = ({ message }) => (
  <div>
    <h2>Email error!</h2>
    <div>{message}</div>
  </div>
);

InviteError.propTypes = propTypes;

export default connect(state => ({ message: state.users.get('error') }))(InviteError);
