import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import startsWith from 'lodash/startsWith';
import nonTransparentPaths from 'utils/non-transparent-paths';
import AppRoutes from '../routes';

const propTypes = {
  location: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const Main = ({ location: { pathname }, auth: { isAuthenticated } }) => (
  <main
    className={classnames('scrolling-touch min-h-screen', {
      'mt-12': nonTransparentPaths.some(path => startsWith(pathname, path)) || isAuthenticated,
    })}
    style={{
      flexGrow: 1,
    }}
  >
    <AppRoutes />
  </main>
);

Main.propTypes = propTypes;

export default compose(
  withRouter,
  connect(state => ({
    auth: state.auth,
  })),
)(Main);
