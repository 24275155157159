import { fetch, authHeader } from 'utils/fetch';

export function fetchPostsApi(projectId) {
  return fetch(`/api/projects/${projectId}/posts`, {
    headers: authHeader(),
  });
}

export function addPostApi(post) {
  return fetch('/api/posts/new', {
    method: 'POST',
    body: JSON.stringify(post),
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
  });
}

export default module.exports;
