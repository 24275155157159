import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AudioSliderLg from 'components/Soundbar/Controls/MusicControls/AudioSliderLg';
import Repeat from 'components/Soundbar/Controls/MusicControls/Repeat';
import LikeIconContainer from 'components/Soundbar/Controls/SocialControls/LikeIconContainer';
import Previous from 'components/Soundbar/Controls/MusicControls/Previous';
import Play from 'components/Soundbar/Controls/MusicControls/Play';
import Next from 'components/Soundbar/Controls/MusicControls/Next';
import { AudioWrapper } from 'utils/AudioControls';

const propTypes = {
  currentTrack: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

const defaultProps = {
  currentTrack: {},
};

const SoundbarContent = ({
  currentTrack: { username, userDisplayName, publicId, name },
  onClose,
}) => (
  <AudioWrapper>
    {({ togglePlay, canPlayPrevious, previous, canPlayNext, next }) => (
      <div className="w-9/10 mx-auto relative">
        <div className="my-6 leading-normal flex justify-between">
          <div>
            <Link to={`/tracks/${publicId}`} onClick={onClose}>
              <p className="font-bold text-lg">{name}</p>
            </Link>
            <Link to={`/${username}`} onClick={onClose}>
              <p className="font-semibold text-gray-darker text-sm">
                {userDisplayName}
              </p>
            </Link>
          </div>
          <Link to={`/tracks/${publicId}`} onClick={onClose}>
            <div className="inline-block border px-3 py-1 rounded-full text-sm">
              Buy Now
            </div>
          </Link>
        </div>
        <AudioSliderLg />
        <div className="flex mt-2 justify-between">
          <LikeIconContainer iconStyle={{ fontSize: '26px' }} />
          <div className="flex">
            <Previous
              iconStyle={{ fontSize: '44px' }}
              previous={previous}
              canPlayPrevious={canPlayPrevious}
            />
            <Play
              iconStyle={{ fontSize: '80px', padding: '0 8px' }}
              circularClassName={'circularprogress-large'}
              circularSize={76}
              togglePlay={togglePlay}
            />
            <Next
              iconStyle={{ fontSize: '44px' }}
              next={next}
              canPlayNext={canPlayNext}
            />
          </div>
          <Repeat iconStyle={{ fontSize: '26px' }} />
        </div>
      </div>
    )}
  </AudioWrapper>
);

SoundbarContent.propTypes = propTypes;
SoundbarContent.defaultProps = defaultProps;

export default connect(state => ({
  currentTrack: state.soundbar.currentTrack,
}))(SoundbarContent);
