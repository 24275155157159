import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import pick from 'lodash/pick';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import withS3 from 'utils/S3';
import { isDesktop } from 'utils/screentype';
import * as authActions from 'actions/auth';
import * as modalActions from 'actions/modals';
import SettingsForm from './SettingsForm';

const uuidV4 = require('uuid/v4');

const propTypes = {
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  s3Upload: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
};

const SETTINGS_MUTATION = gql`
  mutation UpdateUserByIdMutation($input: UpdateUserByIdInput!) {
    updateUser: updateUserById(input: $input) {
      user {
        id
        username
        displayName
        description
      }
    }
  }
`;

class Settings extends Component {
  static fragments = {
    user: gql`
      fragment Settings_user on User {
        id
        username
        displayName
        picture
        description
        createdAt
        private {
          stripeUser
        }
      }
    `,
  };

  constructor(props) {
    super(props);
    this.state = { uploading: false };
  }

  uploadImage = user =>
    new Promise((resolve) => {
      user.username = user.username.toLowerCase(); // eslint-disable-line
      console.log('user', user.picture, this.props.user.picture);
      // Did we submit an image object?
      if (user.picture !== this.props.user.picture) {
        // Generate a new uuid to invalidate cache and upload with that
        const imageId = uuidV4();
        console.log('new user picture', user.picture, imageId);

        this.setState({
          uploadImageId: imageId,
          uploading: true,
        });

        this.props.s3Upload(user.picture, `avatars/${imageId}`, () => {
          console.log('image id ', imageId);
          return resolve(imageId);
        });
      } else {
        return resolve();
      }
      return null;
    });

  render() {
    const {
      user,
      showModal,
      signOut,
      auth: { userId },
    } = this.props;
    const stripeUser = get(user, 'private.stripeUser');
    const userForm = pick(user, [
      'displayName',
      'username',
      'description',
      'picture',
    ]);

    return (
      <div className="my-8 lg:mx-8 mx-4">
        <h1 className="title">Settings</h1>

        <Mutation mutation={SETTINGS_MUTATION}>
          {updateUser => (
            <SettingsForm
              stripeUser={!!stripeUser}
              onSubmit={(data) => {
                this.uploadImage(data).then((imageId) => {
                  console.log('image id', imageId);
                  const userPatch = imageId
                    ? Object.assign({}, data, { picture: imageId })
                    : data;
                  console.log('user patch', userPatch);
                  updateUser({
                    variables: { input: { userPatch, id: userId } },
                  });
                });
              }}
              initialValues={userForm}
              username={user.username}
              uploading={this.state.uploading || false}
            />
          )}
        </Mutation>

        <div className="mt-8 mb-6 text-center">
          <button
            className="btn btn-inverse"
            onClick={() =>
              showModal('DeactivateAccountModal', { userId: user.id })
            }
          >
            Deactivate account
          </button>
        </div>

        {!isDesktop() && (
          <div className="my-6 text-center">
            <button className="btn btn-normal" onClick={() => signOut()}>
              Log Out
            </button>
          </div>
        )}
      </div>
    );
  }
}

Settings.propTypes = propTypes;

export default compose(
  connect(
    state => ({ s3: state.s3, auth: state.auth }),
    {
      ...modalActions,
      ...authActions,
    },
  ),
  withS3,
)(Settings);
