import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import stripe2x from 'assets/powered_by_stripe_s_dark@2x.png';
import displayPrice from 'utils/display-price';
import CartItem from './CartItem';

const propTypes = {
  cart: PropTypes.instanceOf(Immutable.List).isRequired,
  totalPrice: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
};

const Cart = ({ cart, totalPrice, history }) => (
  <div className="justify-center max-w-2xl md:w-3/4 sm:w-5/6 w-full">
    <div className="flex items-center">
      <h1
        className="sm:text-3xl title mr-3 text-lg"
        style={{ marginBottom: 0 }}
      >
        {cart.size} items in your cart
      </h1>
      <a href="https://stripe.com/" target="_blank" rel="noopener noreferrer">
        <img
          alt="Powered by Stripe"
          src={stripe2x}
          style={{ width: 119, height: 26 }}
        />
      </a>
    </div>
    <div id="cart" className="Cart">
      {cart.size === 0 ? (
        <div className="font-semibold">Your cart is empty</div>
      ) : (
        <Fragment>
          <div className="sm:w-2/3 w-full sm:pr-4 py-2">
            {cart.map(item => (
              <CartItem key={item.get('id')} id={item.get('id')} />
            ))}
          </div>
          <div className="border-l-0 border-t sm:w-1/3 sm:pl-4 py-2 sm:border-l sm:border-t-0 w-full">
            <div className="font-semibold mb-2">
              Item(s) total:{' '}
              <span className="font-bold text-black">
                {displayPrice(totalPrice)}
              </span>
            </div>
            <button
              className="btn w-full"
              onClick={() => history.push('/checkout')}
            >
              Proceed to checkout
            </button>
          </div>
        </Fragment>
      )}
    </div>
  </div>
);

Cart.propTypes = propTypes;

export default compose(
  withRouter,
  connect(state => ({
    cart: state.cart.get('items', []),
    totalPrice: state.cart
      .get('items', [])
      .reduce((sum, item) => sum + item.get('price'), 0),
  })),
)(Cart);
