import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ExitButton from 'components/common/ExitButton';
import { postFeedback } from 'rest/feedback';
import { SubmissionError } from 'redux-form';
import * as toastActions from 'actions/toasts';
import FeedbackForm from './FeedbackForm';

const propTypes = {
  onClose: PropTypes.func,
  showMessage: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const defaultProps = {
  onClose: () => {},
};

class FeedbackModal extends Component {
  handleFeedbackSubmit = (values) => {
    const {
      auth: { isAuthenticated, userId },
      onClose,
      showMessage,
    } = this.props;
    const data = isAuthenticated ? { userId, ...values } : values;
    postFeedback(data)
      .then((response) => {
        showMessage({ type: 'success', text: response.message });
      })
      .catch((err) => {
        showMessage({ type: 'error', text: err.message });
      });
    // window.gtag('event', 'Feedback', {
    //   event_category: 'Feedback',
    //   event_label: data.email,
    // });
    onClose();
  };

  render() {
    const { onClose } = this.props;
    return (
      <div id="delete-track-modal" className="p-10">
        <div className="flex justify-between mb-6">
          <h1 className="title" style={{ marginBottom: 0 }}>
            Feedback
          </h1>
          <ExitButton id="feedback-exit" onClick={onClose} />
        </div>
        <div className="mb-6">
          <h2 className="subtitle text-xl md:w-4/5">
            Let us know how we can improve this website.
          </h2>
        </div>
        <FeedbackForm onSubmit={this.handleFeedbackSubmit} />
      </div>
    );
  }
}

FeedbackModal.propTypes = propTypes;
FeedbackModal.defaultProps = defaultProps;

export default connect(state => ({ auth: state.auth }), toastActions)(FeedbackModal);
