import React from 'react';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import { Query } from 'react-apollo';
import CartCheckout from 'components/Checkout/CartCheckout';

const CHECKOUT_QUERY = gql`
  query CartCheckoutPageQuery {
    user: currentUser {
      id
      ...CartCheckout_user
    }
  }
  ${CartCheckout.fragments.user}
`;

const CheckoutPage = () => (
  // <Query query={CHECKOUT_QUERY}>
  //   {({ loading, error, data }) => {
  //     console.log('error from checkout query', error);
  //     if (loading) return <div>Loading ...</div>;
  //     if (error) return <div>Error</div>;

  //     return (
  //       <CartCheckout user={filter(CartCheckout.fragments.user, data.user)} />
  //     );
  //   }}
  // </Query>
  <CartCheckout user={null} />

);

export default CheckoutPage;
