import fetch from 'utils/fetch'; // eslint-disable-line

export function register(data) {
  return fetch('/api/users/new', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'Application/json',
    },
  });
}

export function login(data) {
  return fetch('/api/login', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
