import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import RelayRoute from 'components/Route/RelayRoute';
import Following from 'components/Follow/Following';

const followingQuery = graphql`
  query FollowingPageQuery($username: String!) {
    user: userByUsernameAndDeletedAndSkill(username: $username, deleted: false, skill: PRODUCER) {
      ...Following_user
    }
  }
`;

const FollowingPage = props => (
  <RelayRoute
    {...props}
    component={Following}
    query={followingQuery}
  />
);

export default FollowingPage;
