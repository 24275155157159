import React, { Component } from 'react';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';
import get from 'lodash/get';
// import { stripeClientId, stripeRedirectUri } from 'config';
import { stripeClientId } from 'config';
import { getToken } from 'rest/stripe';
import stripeLogo from 'assets/stripe-logo1.png';
import connect1x from 'assets/light-on-dark.png';
import connect2x from 'assets/light-on-dark@2x.png';
import connect3x from 'assets/light-on-dark@3x.png';

class StripeConnect extends Component {
  static fragments = {
    user: gql`
      fragment StripeConnect_user on User {
        private {
          userId
          email
          stripeUser
        }
      }
    `,
  };

  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  state = { loading: false };

  componentWillMount() {
    const {
      history: { push },
      location: { search },
      user: {
        private: { stripeUser },
      },
    } = this.props;
    if (stripeUser) push('/');
    const { code, state } = qs.parse(search);
    if (code) {
      const from = state ? JSON.parse(atob(state)) : '/';
      this.setState({ loading: true });
      getToken(code).then(() => push(from));
    }
  }

  render() {
    if (this.state.loading) return <div>Connecting with Stripe ...</div>;
    const from = get(this.props.location, 'state.from');
    const state = from ? btoa(JSON.stringify(from)) : '';
    const parameters = {
      // redirect_uri: stripeRedirectUri,
      client_id: stripeClientId,
      state,
    };
    const query = qs.stringify(parameters);
    return (
      <div
        id="stripe-connect"
        className="vw50 mx-auto text-center min-h-screen"
      >
        <div className="h-12 md:h-48" />
        <h1 className="title text-5xl font-semibold">Time to Sell Beats</h1>
        <div className="mb-6 text-2xl font-light flex-center flex-wrap">
          <div className="flex items-center" style={{ height: 60 }}>
            We use
          </div>
          <div className="flex items-center" style={{ height: 60 }}>
            <img alt="Stripe Logo" src={stripeLogo} style={{ width: 120 }} />
          </div>
          <div className="flex items-center" style={{ height: 60 }}>
            to make your
          </div>
          &nbsp;
          <div className="flex items-center" style={{ height: 60 }}>
            payments
          </div>
          &nbsp;
          <div className="flex items-center" style={{ height: 60 }}>
            fast
          </div>
          &nbsp;
          <div className="flex items-center" style={{ height: 60 }}>
            and secure
          </div>
        </div>
        <a
          href={`https://connect.stripe.com/express/oauth/authorize?${query}`}
          style={{ display: 'inline-block' }}
        >
          <img alt="Connect with Stripe" src={connect1x} srcSet={`${connect2x} 2x, ${connect3x} 3x`} />
        </a>
      </div>
    );
  }
}

export default withRouter(StripeConnect);
