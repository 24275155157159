import graphql from 'babel-plugin-relay/macro';
import { commitMutation } from 'react-relay';

const mutation = graphql`
  mutation CreateHashtagMutation($input: CreateHashtagInput!) {
    createHashtag(input: $input) {
      track: trackByTrackId {
        ...TrackPlayer_track
      }
    }
  }
`;

const commit = (environment, hashtag) =>
  commitMutation(environment, {
    mutation,
    variables: { input: { hashtag } },
  });

export default commit;
