import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchResultsRelay from './SearchResultsRelay';

const propTypes = {
  query: PropTypes.string,
  showing: PropTypes.bool.isRequired,
  navBarSetShowing: PropTypes.func,
  containerStyle: PropTypes.object,
  usersResults: PropTypes.number,
  tracksResults: PropTypes.number,
  genresResults: PropTypes.number,
  moodsResults: PropTypes.number,
  tagsResults: PropTypes.number,
};

const defaultProps = {
  query: '',
  navBarSetShowing: () => {},
  containerStyle: {},
  usersResults: 3,
  tracksResults: 3,
  genresResults: 3,
  moodsResults: 3,
  tagsResults: 3,
};

class SearchExploreDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 'a',
      exploring: true,
      showing: false || this.props.showing,
    };
  }

  componentWillReceiveProps(nextProps) {
    // Swaps to Explore when there are no search terms.
    if (!nextProps.query) {
      // Prevent swapping back to Explore when query is blank
      // this.setState({ value: 'a', exploring: true });
    } else {
      this.setState({ value: 'b', exploring: false });
    }

    if (nextProps.showing) {
      this.setState({ showing: true });
    } else {
      this.setState({ showing: false });
    }
  }

  handleHide = () => {
    this.setState({ showing: false });
    if (this.props.navBarSetShowing) {
      this.props.navBarSetShowing(false);
    }
  };

  handleChange = (value) => {
    this.setState({
      value,
      showing: true,
    });
    if (value === 'a') {
      this.setState({
        exploring: true,
      });
    } else {
      this.setState({
        exploring: false,
      });
    }
  };

  render() {
    return (
      this.state.showing &&
      !!this.props.query && (
        <div
          style={{
            width: '300px',
            position: 'absolute',
            top: '48px', // This is the navbar height. We might want to do this dynamically
            color: '#4e4e4e',
            backgroundColor: 'white',
            borderRadius: '4px',
            border: '2px solid #cecece',
            ...this.props.containerStyle,
          }}
        >
          <SearchResultsRelay
            queryString={this.props.query}
            handleHide={this.handleHide}
            usersResults={this.props.usersResults}
            tracksResults={this.props.tracksResults}
            genresResults={this.props.genresResults}
            moodsResults={this.props.moodsResults}
            tagsResults={this.props.tagsResults}
          />
        </div>
      )
    );
  }
}

SearchExploreDropdown.propTypes = propTypes;
SearchExploreDropdown.defaultProps = defaultProps;

export default SearchExploreDropdown;
