import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import NotificationListItem from './NotificationListItem';

const propTypes = {
  notifications: PropTypes.instanceOf(Immutable.Map).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const NotificationList = ({ notifications, isLoading }) => {
  if (isLoading) return <div>Loading ...</div>;
  return (
    <div>
      {notifications.size > 0 ? (
        notifications
          .toList()
          .sort((a, b) => (a >= b ? -1 : 1))
          .map(notification => (
            <NotificationListItem
              key={notification.get('id')}
              notification={notification}
            />
          ))
      ) : (
        <div key="no-notications">No notifications</div>
      )}
    </div>
  );
};

NotificationList.propTypes = propTypes;

export default connect(({ notifications }) => ({
  notifications: notifications.get('data'),
  isLoading: notifications.get('isLoading'),
}))(NotificationList);
