/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type TrackPlayer_track$ref = any;
type TrackPlayer_user$ref = any;
export type ProductType = ('BASIC' | 'EXCLUSIVE' | 'PREMIUM' | '%future added value');
import type { FragmentReference } from 'relay-runtime';
declare export opaque type PurchasedTracks_user$ref: FragmentReference;
export type PurchasedTracks_user = {|
  +id: any,
  +products: {|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<{|
      +node: ?{|
        +price: number,
        +type: ProductType,
        +track: ?{|
          +id: any,
          +name: string,
          +picture: ?string,
          +fileUrl: ?string,
          +description: ?string,
          +publicId: ?string,
          +userId: any,
          +user: ?{|
            +id: any,
            +username: ?string,
            +displayName: string,
            +$fragmentRefs: TrackPlayer_user$ref,
          |},
          +$fragmentRefs: TrackPlayer_track$ref,
        |},
      |},
    |}>,
  |},
  +$refType: PurchasedTracks_user$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "PurchasedTracks_user",
  "type": "User",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "products"
        ]
      }
    ]
  },
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "LinkedField",
      "alias": "products",
      "name": "__PurchasedTracks_products_connection",
      "storageKey": null,
      "args": null,
      "concreteType": "ProductsConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ProductsEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Product",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "price",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "type",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": "track",
                  "name": "trackByTrackId",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Track",
                  "plural": false,
                  "selections": [
                    v0,
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "name",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "picture",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "fileUrl",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "description",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "publicId",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "userId",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": "user",
                      "name": "userByUserId",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "User",
                      "plural": false,
                      "selections": [
                        v0,
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "username",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "displayName",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "FragmentSpread",
                          "name": "TrackPlayer_user",
                          "args": null
                        }
                      ]
                    },
                    {
                      "kind": "FragmentSpread",
                      "name": "TrackPlayer_track",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
(node/*: any*/).hash = 'c198e55e7f177fbd00b76b13c518f933';
module.exports = node;
