import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import PublicRoute from 'components/Route/PublicRoute';
import Validate from './Validate';
import ValidateSuccess from './ValidateSuccess';
import ValidateError from './ValidateError';

const propTypes = {
  match: PropTypes.object.isRequired,
};

const ValidatePage = ({ match: { url } }) => (
  <Switch>
    <PublicRoute exact path={`${url}`} component={Validate} />
    <PublicRoute path={`${url}/success`} component={ValidateSuccess} />
    <PublicRoute path={`${url}/error`} component={ValidateError} />
  </Switch>
);

ValidatePage.propTypes = propTypes;

export default ValidatePage;
