import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const propTypes = {
  message: PropTypes.object.isRequired,
};

const ValidateSuccess = ({ message }) => (
  <div className="my-8 text-center">
    <h2 className="title">Success!</h2>
    <div className="subtitle">{message}</div>
  </div>
);

ValidateSuccess.propTypes = propTypes;

export default connect(state => ({
  message: state.users.get('validationMessage'),
}))(ValidateSuccess);
