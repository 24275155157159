import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { compose } from 'redux';
import createFragContainer from 'utils/create-frag-container';
import { Link } from 'react-router-dom';
import graphql from 'babel-plugin-relay/macro';
import { avatarImageUrl } from 'config';
import PlugImage from 'components/common/PlugImage';
import displayTrackImage, {
  displaySmallImage,
} from 'utils/display-track-image';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { reset } from 'redux-form';
import { onDesktop } from 'utils/screentype';
import * as modalActions from 'actions/modals';
import CreateComment from 'components/Tracks/Track/CreateComment';
import CreateCommentMutation from 'mutations/comment/CreateCommentMutation';
import { AudioWrapper, AudioSlider } from 'utils/AudioControls';
import Hashtags from './Hashtags';
import CommentList from './CommentList';
import TrackBody from './TrackBody';
import TrackButtons from './TrackButtons';

const trackDesktopFrag1 = graphql`
  fragment TrackDesktop_track on Track {
    id
    name
    picture
    fileUrl
    description
    publicId
    ...TrackBody_track
    ...TrackButtons_track
    ...Hashtags_track
    ...CommentList_track
  }
`;

const trackDesktopFrag2 = graphql`
  fragment TrackDesktop_user on User {
    id
    username
    displayName
    ...TrackBody_user
  }
`;


class TrackDesktop extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    relay: PropTypes.object.isRequired,
    track: PropTypes.object.isRequired,
    tracks: PropTypes.array.isRequired,
    currentUser: PropTypes.string,
    className: PropTypes.string,
    purchasedType: PropTypes.string,
    user: PropTypes.object.isRequired,
    showModal: PropTypes.func.isRequired,
    soundbar: PropTypes.object.isRequired,
    resetForm: PropTypes.func.isRequired,
  };

  static contextTypes = {
    myself: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    currentUser: '',
    className: '',
    purchasedType: '',
    removeOtherTracksOnPlay: false,
    addOtherTracksOnPlay: null,
  };

  state = {
    showComment: false,
    showTags: false,
    otherTracksRemoved: false,
    otherTracksAdded: false,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleRequestClose = () => {
    this.setState({
      contextMenu: false,
    });
  };

  handleDeleteClick = () => {
    const { track: { id }, showModal } = this.props;
    this.handleRequestClose();
    showModal('DeleteTrackModal', { trackId: id });
  };

  handleShowTags = () => {
    this.handleRequestClose();
    this.setState({ showTags: !this.state.showTags });
  };


  handleCreateComment = (data) => {
    const {
      auth: { userId },
      track: { id: trackId },
      relay: { environment },
      resetForm,
    } = this.props;
    const body = data.comment;
    const comment = { userId, trackId, body };
    CreateCommentMutation(environment, comment);
    resetForm('CreateComment');
  };

  render() {
    const {
      track,
      tracks,
      currentUser,
      user,
      className,
      soundbar,
      auth: { userId, isAuthenticated },
      showModal,
      purchasedType,
    } = this.props;
    const { myself } = this.context;
    const currentTrackId = get(soundbar, 'currentTrack.id');
    const isCurrentTrack = currentTrackId === track.id;
    const isPlaying = isCurrentTrack && soundbar.playing;
    const { anchorEl } = this.state;

    return (
      <AudioWrapper track={track} tracks={tracks}>
        {({ togglePlay }) => (
          <li className={className}>
            <div
              className="bg-white p-2 rounded-px-2"
              style={onDesktop({ border: '#dad9d9 1px solid' })}
            >
              <article className="flex w-full relative">
                <Link to={`/tracks/${track.publicId}`}>
                  <PlugImage
                    imageSource={displaySmallImage(
                      track.fileUrl,
                      track.picture,
                    )}
                    fallbacks={[
                      displayTrackImage(track.fileUrl, track.picture),
                    ]}
                    placeholder={`${avatarImageUrl}placeholder.png`}
                    className={'profile-trackplayer-img-desk'}
                  />
                </Link>
                <div className="w-full flex flex-col ml-3 md:ml-4">
                  <TrackBody
                    track={track}
                    user={user}
                    mine={myself}
                    purchasedType={purchasedType}
                    togglePlay={togglePlay}
                    isPlaying={isPlaying}
                    onEdit={() =>
                      showModal('EditTrackModal', { trackId: track.id })
                    }
                  />
                  <AudioSlider trackId={track.id} />
                  <TrackButtons
                    track={track}
                    userId={userId}
                    mine={myself}
                    toggleComments={() =>
                      this.setState({ showComment: !this.state.showComment })
                    }
                    onClick={this.handleClick}
                  />
                </div>
              </article>
              {this.state.showTags && (
                <div className="w-full inline">
                  <Hashtags
                    track={track}
                    containerStyle={{
                      borderTop: '1px solid #cccccc',
                      paddingTop: '14px',
                      marginTop: '20px',
                      fontSize: '14px',
                    }}
                    showNoTags
                  />
                </div>
              )}
              {this.state.showComment && (
                <div>
                  <CommentList
                    track={track}
                    mine={myself}
                    currentUser={currentUser}
                  />
                  <CreateComment
                    onSubmit={this.handleCreateComment}
                    disabled={!isAuthenticated}
                  />
                </div>
              )}
            </div>
            <Menu
              id="more-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
            >
              <MenuItem
                onClick={() => {
                  this.handleDeleteClick();
                  this.handleClose();
                }}
              >
                Delete Track
              </MenuItem>
              <MenuItem
                onClick={() => {
                  this.handleShowTags();
                }}
              >
                {this.state.showTags ? 'Hide Tags' : 'Show Tags'}
              </MenuItem>
            </Menu>
          </li>
        )}
      </AudioWrapper>
    );
  }
}

export default compose(
  connect(
    state => ({ soundbar: state.soundbar, auth: state.auth }),
    Object.assign({ resetForm: reset }, modalActions),
  ),
  createFragContainer([trackDesktopFrag1, trackDesktopFrag2]),
)(TrackDesktop);
