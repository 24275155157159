import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import Carousel from 'utils/Carousel';
import LandingTopGenre from './LandingTopGenre';

const TOP_GENRES_FRAG = gql`
  fragment LandingTopGenres_genres on GenreCountsConnection {
    edges {
      node {
        ...LandingTopGenre_genre
      }
    }
  }
  ${LandingTopGenre.fragments.genre}
`;

const propTypes = {
  genres: PropTypes.object.isRequired,
};

const LandingTopGenres = ({ genres }) => (
  <div className="h-full mx-auto flex flex-col" style={{ maxHeight: '550px' }}>
    <div className="font-bold text-lg pb-6 color-white text-center md:pb-8">
      Top Genres
    </div>
    <Carousel
      settings={{
        columns: 2,
        rows: 2,
        slide: false,
        marginBottom: 20,
        hasArrows: false,
        marginRight: 16,
      }}
      breakpoints={[
        {
          breakpoint: 768,
          columns: 3,
          marginBottom: 36,
        },
        {
          breakpoint: 998,
          columns: 4,
        },
      ]}
    >
      {genres.edges.map(genre => (
        <LandingTopGenre
          key={`genre${genre.node.genre}`}
          genre={filter(LandingTopGenre.fragments.genre, genre.node)}
        />
      ))}
    </Carousel>
  </div>
);

LandingTopGenres.propTypes = propTypes;
LandingTopGenres.fragments = { genres: TOP_GENRES_FRAG };

export default LandingTopGenres;
