import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import PropTypes from 'prop-types';
import * as authActions from 'actions/auth';
import EmailInput from 'components/common/forms/EmailInput';
import PasswordInput from 'components/common/forms/PasswordInput';

const fields = ['email', 'password'];
const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.password) {
    errors.password = 'Required';
  } else if (values.password.length < 8) {
    errors.password = 'Password must be at least 8 characters';
  }

  return errors;
};

class LoginForm extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    logIn: PropTypes.func.isRequired,
    error: PropTypes.string,
    next: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
  };

  static defaultProps = {
    error: '',
    next: null,
    submitting: false,
  };

  handleSubmit = (data) => {
    const { location: { state }, next } = this.props;
    const { from } = next || state || { from: { pathname: '/', hash: '' } };

    return this.props
      .logIn(data)
      .then(() => {
        this.props.history.push(from);
      })
      .catch((err) => {
        console.log('error', err);
        throw new SubmissionError({
          _error: err.message,
        });
      });
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  render() {
    const { error, handleSubmit, submitting } = this.props;
    return (
      <form onSubmit={handleSubmit(this.handleSubmit)}>
        <Field name="email" component={EmailInput} />

        <Field name="password" component={PasswordInput} />
        {error && <div className="error">{error}</div>}
        <div className="field">
          <p className="control">
            <button
              type="submit"
              className="btn button is-success"
              disabled={submitting || error}
            >
              Log in
            </button>
          </p>
        </div>
      </form>
    );
  }
}

export default compose(
  withRouter,
  connect(state => ({ auth: state.auth }), authActions),
  reduxForm({
    form: 'login',
    fields,
    validate,
  }),
)(LoginForm);
