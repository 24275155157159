import React from 'react';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import PropTypes from 'prop-types';
import TrackLike from './TrackLike';

const propTypes = {
  track: PropTypes.object.isRequired,
};

const TRACK_LIKE_LIST = gql`
  fragment TrackLikeList_track on Track {
    likes: likesByTrackId(
      first: 100
    ) @connection(key: "TrackLikeList_likes") {
      edges {
        node {
          userId,
          ...TrackLike_like
        }
      },
      totalCount
    }
  }
  ${TrackLike.fragments.like}
`;

const TrackLikeList = ({ track }) => (
  <div>
    <div className="flex-row items-center">
      <h2 className="title mr2">Likes</h2>
      {'  '}
      <div className="subtitle">({track.likes.totalCount})</div>
    </div>
    <div className="flex-row flex-wrap">
      {track.likes.edges.map(like => (
        <TrackLike key={like.node.userId} like={filter(TrackLike.fragments.like, like.node)} />
      ))}
    </div>
  </div>
);

TrackLikeList.propTypes = propTypes;
TrackLikeList.fragments = { track: TRACK_LIKE_LIST };

export default TrackLikeList;
