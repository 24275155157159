import graphql from 'babel-plugin-relay/macro';
import { commitMutation } from 'react-relay';

const mutation = graphql`
  mutation DeleteCommentByIdMutation($input: DeleteCommentByIdInput!) {
    deleteCommentById(input: $input) {
      track: trackByTrackId {
        ...TrackDesktop_track
      }
    }
  }
`;

const commit = (environment, comment) =>
  commitMutation(environment, {
    mutation,
    variables: { input: comment },
  });

export default commit;
