import graphql from 'babel-plugin-relay/macro';
import promiseMutation from 'utils/promise-mutation';

const mutation = graphql`
  mutation UpdateTrackByIdMutation($input: UpdateTrackByIdInput!) {
    updateTrackById(input: $input) {
      track {
        ...TrackPlayer_track
      }
    }
  }
`;

const commit = (environment, track, trackId) =>
  promiseMutation(environment, {
    mutation,
    variables: { input: { id: trackId, trackPatch: track } },
  });

export default commit;
