import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { displayTime } from 'utils/date';
import NotificationSenderName from './NotificationSenderName';
import NotificationImage from './NotificationImage';

const propTypes = {
  notification: PropTypes.object.isRequired,
};

const NotificationListItem = ({ notification }) => (
  <div id={notification.get('id')} className="flex-row items-center my-3">
    <NotificationImage sender={notification.get('sender')} />
    <div className="flex flex-wrap">
      <NotificationSenderName sender={notification.get('sender')} />
      <div className="mr-1">{notification.get('action')}</div>
      <Link to={notification.getIn(['object', 'slug'])} className="mr-1 link bold">
        {notification.getIn(['object', 'name'])}
      </Link>
      <div className="mr-1">on</div>
      <div className="mr-1">{displayTime(notification.get('createdAt'))}</div>
    </div>
  </div>
);

NotificationListItem.propTypes = propTypes;

export default NotificationListItem;
