import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import LandingExplore from './LandingExplore';

const LANDING_QUERY = gql`
  query LandingExploreContainerQuery(
    $maxTracks: Int!
    $maxGenres: Int!
    $maxMoods: Int!
    $maxNewTracks: Int!
    # $maxUsers: Int!
    # $maxTags: Int!
  ) {
    query {
      ...LandingExplore_query
    }
  }
  ${LandingExplore.fragments.query}
`;

const LandingExploreContainer = () => (
  <Query
    query={LANDING_QUERY}
    variables={{
      maxTracks: 13,
      maxGenres: 8,
      maxMoods: 7,
      maxNewTracks: 12,
    }}
  >
    {({ loading, error, data }) => {
      if (loading) return <div>Loading ...</div>;
      if (error) return <div>Error</div>;

      return <LandingExplore query={data.query} />;
    }}
  </Query>
);

export default LandingExploreContainer;
