import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  currentlyLiking: PropTypes.bool,
  handleCreateLike: PropTypes.func.isRequired,
  handleDeleteLike: PropTypes.func.isRequired,
  iconStyle: PropTypes.object.isRequired,
};

const defaultProps = {
  currentlyLiking: false,
};

const LikeIcon = ({ iconStyle, currentlyLiking, handleCreateLike, handleDeleteLike }) => {
  if (currentlyLiking) {
    return (
      <a role="button" tabIndex={0} className="pl-2 outline-none flex" onClick={handleDeleteLike}>
        <i className="material-icons icon-color text-xl select-none self-center" style={iconStyle}>
          favorite
        </i>{' '}
      </a>
    );
  }
  return (
    <a role="button" tabIndex={0} className="pl-2 outline-none flex" onClick={handleCreateLike}>
      <i
        className="material-icons icon-color text-xl select-none self-center"
        style={iconStyle}
      >
        favorite_border
      </i>
    </a>
  );
};

LikeIcon.propTypes = propTypes;
LikeIcon.defaultProps = defaultProps;

export default LikeIcon;
