/**
 * @flow
 * @relayHash d6155ebf3cd422aa91c0b3f07d7fac49
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateUserArtistMutationVariables = {|
  input: {
    clientMutationId?: ?string,
    userArtist: {
      publicId?: ?any,
      userId: any,
      artistId: string,
      createdAt?: ?any,
    },
  },
|};
export type CreateUserArtistMutationResponse = {|
  +createUserArtist: ?{|
    +userArtist: ?{|
      +userId: any,
      +artistId: string,
    |},
  |},
|};
*/


/*
mutation CreateUserArtistMutation(
  $input: CreateUserArtistInput!
) {
  createUserArtist(input: $input) {
    userArtist {
      userId
      artistId
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateUserArtistInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createUserArtist",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "CreateUserArtistInput!"
      }
    ],
    "concreteType": "CreateUserArtistPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userArtist",
        "storageKey": null,
        "args": null,
        "concreteType": "UserArtist",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "userId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "artistId",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CreateUserArtistMutation",
  "id": null,
  "text": "mutation CreateUserArtistMutation(\n  $input: CreateUserArtistInput!\n) {\n  createUserArtist(input: $input) {\n    userArtist {\n      userId\n      artistId\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateUserArtistMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateUserArtistMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node/*: any*/).hash = 'd7bff6c81664f3119780a0ad85f3a693';
module.exports = node;
