import graphql from 'babel-plugin-relay/macro';
import promiseMutation from 'utils/promise-mutation';

const mutation = graphql`
  mutation CreateUserAlbumMutation($input: CreateUserAlbumInput!) {
    createUserAlbum(input: $input) {
      userAlbum {
        userId
        albumId
      }
    }
  }
`;

const commit = (environment, userId, albumId) =>
  promiseMutation(environment, {
    mutation,
    variables: { input: { userAlbum: { userId, albumId } } },
  });

export default commit;
