import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import LoginForm from 'components/Login/LoginForm';
import GuestForm from './GuestForm';

class PreCheckout extends Component {
  static propTypes = {
    product: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    checkout: PropTypes.object.isRequired,
  };

  componentWillMount() {
    const {
      auth: { isAuthenticated },
      history: { replace },
      checkout,
      product: { id },
    } = this.props;
    const guest = checkout.get('guest');
    if (isAuthenticated || guest) {
      replace(`/checkouts/${id}/new`);
    }
  }

  render() {
    const { product: { id, track: { name, user: { username, displayName } } } } = this.props;
    const from = { pathname: `/checkouts/${id}/new` };
    return (
      <div id="pre-checkout-form" className="page-width mx-auto my-8">
        <div className="text-center mb-8 pb-6">
          <div className="title" style={{ marginBottom: 0 }}>{name}</div>
          <Link to={`/${username}`} className="link subtitle">{displayName}</Link>
        </div>
        <div className="pcont flex items-center p-6">
          <div className="w-1/2 border-r pr-6">
            <div className="subtitle font-semibold">Continue as guest</div>
            <GuestForm next={{ from }} />
          </div>
          <div className="w-1/2 pl-6">
            <div className="subtitle font-semibold">Log in</div>
            <LoginForm next={{ from }} />
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(state => ({ auth: state.auth, checkout: state.checkout })),
  withRouter,
)(PreCheckout);
