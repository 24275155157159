import React from 'react';
import PropTypes from 'prop-types';
import graphql from 'babel-plugin-relay/macro';
import Cookies from 'js-cookie';
import ExitButton from 'components/common/ExitButton';
import createFragContainer from 'utils/create-frag-container';
import UpdateUserByIdMutation from 'mutations/UpdateUserByIdMutation';

const deactivateFrag = graphql`
  fragment DeactivateAccountModal_user on User {
    id
    username
  }
`;

const propTypes = {
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  relay: PropTypes.object.isRequired,
};

const DeactivateAccountModal = ({ user: { id, username }, relay: { environment }, onClose }) => {
  const handleDeleteSubmit = () => {
    UpdateUserByIdMutation(environment, { deleted: true }, id).then(() => {
      Cookies.remove('token');
      // window.location = '/';
    });
  };

  return (
    <div id="deactivate-modal" className="p-4">
      <div className="flex justify-between mb-6">
        <h1 className="title" style={{ marginBottom: 0 }}>
          Deactivate Account
        </h1>
        <ExitButton id="deactivate-account-exit" onClick={onClose} />
      </div>
      <div className="mb-6">
        Are you sure you want to deactivate your account <span className="font-semibold">{username}</span>? This action cannot be undone.
      </div>
      <div className="field is-grouped">
        <div className="control">
          <button
            type="submit"
            onClick={handleDeleteSubmit}
            className="btn btn-primary btn-short"
          >
            Deactivate
          </button>
        </div>
        <div className="control">
          <button className="btn btn-link btn-short" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

DeactivateAccountModal.propTypes = propTypes;

export default createFragContainer([deactivateFrag])(DeactivateAccountModal);
