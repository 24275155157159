import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as notificationActions from 'actions/notifications';
import NotificationList from './NotificationList';

class Notifications extends Component {
  static propTypes = {
    fetchNotifications: PropTypes.func.isRequired,
    sendReadReceipts: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.fetchNotifications();
    this.props.sendReadReceipts();
  }

  render() {
    return (
      <div id="notifications" className="my-6">
        <NotificationList />
      </div>
    );
  }
}

export default connect(null, notificationActions)(Notifications);
