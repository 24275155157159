import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import PlugImage from "components/common/PlugImage";
import displayImage from "utils/display-image";
import TrackPlayer from "components/common/ApolloTrackPlayer";
import { isDesktop } from "utils/screentype";

const propTypes = {
  tracks: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
  background: PropTypes.string,
  textBackgroundColor: PropTypes.string,
};

const defaultProps = {
  background: "",
  textBackgroundColor: "",
};

const GenreAndMoodDisplay = ({
  tracks,
  name,
  url,
  totalCount,
  background,
  textBackgroundColor,
}) => {
  const displayTotal =
    totalCount === 1 ? `${totalCount} track` : `${totalCount} tracks`;

  return (
    <div className="">
      <div
        className="relative md:hidden"
        style={{
          height: "40vh",
          background,
        }}
      >
        <PlugImage
          imageSource={displayImage(url, name)}
          alt="Genre"
          className="flex-1 h-full w-full absolute cover max-w-none"
        />
        <div
          className="text-white absolute text-center text-3xl font-bold music bg-black-true w-full text-capitalize"
          style={{
            border: "3px solid #ffffff",
            borderLeft: "none",
            borderRight: "none",
            top: "30%",
            backgroundColor: textBackgroundColor,
          }}
        >
          {name}
        </div>
      </div>
      <div className="hidden md:block">
        <div
          className="sudo-navbar navbar-semiTransparent-bg"
          style={{ height: "48px" }}
        />
        <div className="mx-auto py-8 md:w-desk">
          <h1 className="title">{name}</h1>
          <h2 className="subtitle">{displayTotal}</h2>
        </div>
      </div>
      <ul
        className={classnames("w-9/10 mx-auto mb-5 md:w-desk", {
          "mt-4": isDesktop(),
        })}
      >
        {tracks.map((trackNode) => {
          const track = trackNode.node;
          return (
            <TrackPlayer
              key={track.id}
              className={classnames(
                { "mb-4": isDesktop() },
                { "track-li": !isDesktop() }
              )}
              track={track}
              user={track.user}
              listStyle={{ marginBottom: 10 }}
              tracks={tracks}
              removeOtherTracksOnPlay
            />
          );
        })}
      </ul>
    </div>
  );
};

GenreAndMoodDisplay.propTypes = propTypes;
GenreAndMoodDisplay.defaultProps = defaultProps;

export default GenreAndMoodDisplay;
