import { Map, fromJS } from 'immutable';
import * as types from 'types';

const initialState = fromJS({
  initialLoad: false,
  isFetching: false,
  projectId: '',
  error: '',
  items: [],
});

export default function posts(state = initialState, { type, payload }) {
  if (!(state instanceof Map)) state = fromJS(state); // eslint-disable-line

  switch (type) {
  case types.RESET_POSTS:
    return initialState;

  case types.UPDATE_PROJECT_ID:
    return state.set('projectId', payload);

  case types.FETCH_POSTS_REQUEST:
  case types.ADD_POST_REQUEST:
    return state.withMutations((map) => {
      map.set('isFetching', true).set('error', '');
    });

  case types.FETCH_POSTS_SUCCESS:
    return state.withMutations((map) => {
      map
          .set('items', fromJS(payload))
          .set('isFetching', false)
          .set('error', '');
    });

  case types.ADD_POST_SUCCESS: {
    const find = state
        .get('items')
        .find(post => post.get('id') === payload.id);
    const sameProject = state.get('projectId');
    if (!find && sameProject) {
      const items = state.get('items').push(Map(payload));
      return state.withMutations((map) => {
        map.set('items', items).set('isFetching', false).set('error', '');
      });
    }
    return state.withMutations((map) => {
      map.set('isFetching', false).set('error', '');
    });
  }

  case types.FETCH_POSTS_FAILURE:
  case types.ADD_POST_FAILURE:
    return state.withMutations((map) => {
      map.set('isFetching', false).set('error', payload);
    });

  default:
    return state;
  }
}
