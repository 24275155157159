import React from 'react';
import injectTapEventPlugin from 'react-tap-event-plugin';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { ApolloProvider } from 'react-apollo';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Cookies from 'js-cookie';
import { loadUser } from 'actions/auth';
import { loadCartItems } from 'actions/cart';
import { loadStorage } from 'utils/storage';
import { apiUrl } from 'config';
import createStore from '../store/createStore';
import Layout from '../routes/Layout';

// //////////////////////////////////////////////////////////
// 1. Click the public page
// 2. Click the protected page
// 3. Log in
// 4. Click the back button, note the URL each time


const store = createStore();

console.log('api_url', apiUrl);

const httpLink = createHttpLink({ uri: `${apiUrl}/api/graphql` });

const authLink = setContext((_, { headers }) => {
  const token = Cookies.get('token');
  if (token) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  }
  return {
    headers,
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    dataIdFromObject: object => object.nodeId || object.id || defaultDataIdFromObject(object),
  }).restore(window.__APOLLO_STATE__),
  ssrForceFetchDelay: 100,
});

const App = () => {
  const token = Cookies.get('token');
  const cart = loadStorage('cart');
  if (token) {
    store.dispatch(loadUser(token));
  }
  if (cart) {
    store.dispatch(loadCartItems(cart));
  }

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <Router>
          <Layout />
        </Router>
      </Provider>
    </ApolloProvider>
  );
};

export default App;
