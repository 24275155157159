import React from 'react';
import PremiumLicense from 'components/Licenses/PremiumLicense';

const PremiumExamplePage = () => (
  <div id="premium-example-page" className="container">
    <PremiumLicense />
  </div>
);

export default PremiumExamplePage;
