import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  columnData: PropTypes.array.isRequired,
};

// Expects columnData to look like:
// [
//   id: (string, the matching key in the table's data to sort by),
//   label: (string, the text to show up in the table),
//   numeric: (bool, whether the data to sort is alpha or numeric),
//   disablePadding: (bool, whether to pad the header's cell or not)
// ]

class SortableResultsTableHead extends Component {
  createSortHandler = property => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, columnData } = this.props;
    return (
      <TableHead>
        <TableRow style={{ height: 20, fontSize: 16 }}>
          {columnData.map(
            column =>
              (<TableCell
                key={column.id}
                numeric={column.numeric}
                disablePadding={column.disablePadding || false}
                style={{ textAlign: 'left', padding: 10 }}
              >
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={order}
                  onClick={this.createSortHandler(column.id)}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

SortableResultsTableHead.propTypes = propTypes;

export default SortableResultsTableHead;
