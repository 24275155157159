import React from "react";
import gql from "graphql-tag";
import { filter } from "graphql-anywhere";
import { Query } from "react-apollo";
import CircularProgress from "@material-ui/core/CircularProgress";
import StripeConnect from "components/StripeConnect";

const STRIPE_QUERY = gql`
  query StripeConnectPageQuery {
    user: currentUser {
      ...StripeConnect_user
    }
  }
  ${StripeConnect.fragments.user}
`;

const StripeConnectPage = () => (
  <Query query={STRIPE_QUERY}>
    {({ loading, error, data }) => {
      if (loading) {
        return (
          <div className="mt-12 ml-16">
            <CircularProgress size={50} style={{ color: "#2a585d" }} />
          </div>
        );
      }
      if (error) {
        console.log("stripe error", error);
        return <div>Error</div>;
      }

      return (
        <StripeConnect user={filter(StripeConnect.fragments.user, data.user)} />
      );
    }}
  </Query>
);

export default StripeConnectPage;
